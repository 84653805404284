import { useQuery } from '@apollo/client';
import SVG from 'assets/svgs/svg';
import { FileCard, Col, Row } from 'common-ui';
import { match } from 'functions/FunctionalMatch';
import prettyBytes from 'pretty-bytes';
import { GET_DEAL_DOCUMENTS } from 'query/deal';
import {
  GetDealDocuments,
  GetDealDocumentsVariables,
} from 'query/__generated__/GetDealDocuments';
import { theme } from 'style/ORSNNTheme';
import styled, { css } from 'styled-components';
import { FileType } from '__generated__/globalTypes';
import { FileStatus, UploadStatus, useUploadZone } from '../utils';

const HalfOfRow = styled(Col)`
  width: 45%;
`;

const Header = styled.h3`
  margin-left: 0.5rem;
  margin-bottom: 0.9375rem;
`;

type FilesContainerProps = {
  isDragActive: boolean;
  centerContents: boolean;
};

const FilesContainer = styled.div<FilesContainerProps>`
  border: 2px dotted
    ${(props) =>
      (props.isDragActive && props.theme.color.brandMainPink) ||
      props.theme.color.grey500};
  display: flex;
  flex-direction: column;
  height: 280px;
  ${(props) =>
    props.centerContents &&
    css`
      align-items: center;
      justify-content: center;
    `}
  width: 100%;

  a {
    color: ${(props) => props.theme.color.brandMainPink};
  }

  ${FileCard} {
    margin-bottom: 0.5rem;
  }
`;

const BodyPlaceholder = () => (
  <>
    <SVG name="backup" fill="#fff" width="32px" height="32px" />
    <p>
      <a href="# ">Choose</a> or drag files into this box
    </p>
    <p>Up to 1000 xls, csv, or pdf files can be uploaded to a deal</p>
  </>
);

const PendingIcon = () => (
  <SVG
    name={'error-outline'}
    fill={theme.color.grey500}
    width="16px"
    height="16px"
  />
);
const InProgressIcon = () => (
  <SVG name={'backup'} fill={theme.color.warningEmphasis} width="16px" height="16px" />
);
const CompleteIcon = () => (
  <SVG
    name={'check-circle'}
    fill={theme.color.successDefault}
    width="16px"
    height="16px"
  />
);
const ErrorIcon = () => (
  <SVG
    name={'error-outline'}
    fill={theme.color.red}
    width="16px"
    height="16px"
  />
);

function renderIcon(file: FileStatus) {
  switch (file.uploadStatus) {
    case UploadStatus.Pending:
      return <PendingIcon />;
    case UploadStatus.InProgress:
      return <InProgressIcon />;
    case UploadStatus.Complete:
      return <CompleteIcon />;
    case UploadStatus.Error:
      return <ErrorIcon />;
  }
}

function renderName(file: FileStatus) {
  return `${file.name} - ${prettyBytes(file.size)}`;
}

type Props = {
  dealId: string;
  allowUpload: boolean;
};

const DealDocuments = ({ dealId, allowUpload }: Props): JSX.Element => {
  const variables = { id: dealId };
  const { loading, error, data, refetch } = useQuery<
    GetDealDocuments,
    GetDealDocumentsVariables
  >(GET_DEAL_DOCUMENTS, {
    variables,
  });
  const { uploadFiles, getRootProps, getInputProps, isDragActive } =
    useUploadZone(dealId, FileType.DEAL_FILE, [variables], refetch);

  const uploadedFilesBody = match<never, JSX.Element>()
    .on(loading, () => <h5>loading...</h5>)
    .on(error != null, () => <h5>{error?.message}</h5>)
    .on(data == null || data.deal == null, () => <h5>No Data</h5>)
    .otherwise(() => (
      <HalfOfRow>
        {data?.deal?.documents.map((document) => {
          const { name, documentSizeBytes, documentUrl } = document;
          return (
            <FileCard
              key={documentUrl}
              name={name}
              size={documentSizeBytes}
              url={documentUrl}
            />
          );
        })}
      </HalfOfRow>
    ));

  const noDocumentsToRender = uploadFiles.length === 0;
  const dropzoneBody = allowUpload ? (
    <HalfOfRow>
      <FilesContainer
        centerContents={noDocumentsToRender}
        isDragActive={isDragActive}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {noDocumentsToRender ? (
          <BodyPlaceholder />
        ) : (
          <ul>
            {uploadFiles.map((file) => (
              <li key={file.name}>
                {renderIcon(file)}
                <span> {renderName(file)}</span>
              </li>
            ))}
          </ul>
        )}
      </FilesContainer>
    </HalfOfRow>
  ) : (
    <></>
  );

  return (
    <>
      <Header>Uploaded Files</Header>
      <Row>
        {uploadedFilesBody}
        {dropzoneBody}
      </Row>
    </>
  );
};

export default DealDocuments;
