import { BiddingAndPricingCard } from './dealCards/BiddingAndPricingCard';
import { CardWrapper } from './dealCards/CardWrapper';
import { CounterpartyCard } from './dealCards/CounterpartyCard';
import { LoansCard } from './dealCards/LoansCard';
import { StratsCard } from './dealCards/StratsCard';

export const cardsConfig = {
  counterparty: {
    name: 'Counterparty Details',
    Comp: CounterpartyCard,
    needAccess: false,
    needBid: false,
  },
  strats: {
    name: 'Stratifications',
    Comp: StratsCard,
    needAccess: false,
    needBid: false,
  },
  loans: {
    name: 'Loan Level Data',
    Comp: LoansCard,
    needAccess: true,
    needBid: false,
  },
  documents: {
    name: 'Deal Documents',
    Comp: CardWrapper,
    needAccess: true,
    needBid: false,
  },
  carve: {
    name: 'Trade Carve Manager',
    Comp: CardWrapper,
    needAccess: true,
    needBid: false,
  },
  bidding: {
    name: 'Bidding and Pricing',
    Comp: BiddingAndPricingCard,
    needAccess: true,
    needBid: false,
  },
  diligence: {
    name: 'Diligence',
    Comp: CardWrapper,
    needAccess: true,
    needBid: true,
  },
  settlement: {
    name: 'Settlement',
    Comp: CardWrapper,
    needAccess: true,
    needBid: true,
  },
  complition: {
    name: 'Deal Completion',
    Comp: CardWrapper,
    needAccess: true,
    needBid: true,
  },
};
export type CardsKey = keyof typeof cardsConfig;
export const cardsOrder: CardsKey[] = [
  'counterparty',
  'strats',
  'loans',
  'documents',
  'carve',
  'bidding',
  'diligence',
  'settlement',
  'complition',
];

export const statusTrackerConfig = [
  {
    label: 'Data Request',
    status: 'Completed',
    icon: 'complete',
    description:
      '<seller-name> has approved your request for information on transaction: <Transaction-id-number>',
  },
  {
    label: 'Inquiry',
    description: 'Research the initial offering',
    subtasks: [
      {
        cardsKey: 'counterparty' as CardsKey,
      },
      {
        cardsKey: 'strats',
        status: '',
      },
      {
        cardsKey: 'loans',
      },
      {
        cardsKey: 'documents',
      },
    ],
  },
  {
    label: 'Bidding',
    description: 'Evaluation and bid on the prospective transactions',
    subtasks: [
      {
        cardsKey: 'carve',
      },
      {
        cardsKey: 'bidding',
      },
    ],
  },
  {
    label: 'Negotiation',
    icon: 'not-started',
  },
  {
    label: 'Diligence',
    icon: 'not-started',
  },
  {
    label: 'Settlement',
    icon: 'not-started',
  },
  {
    label: 'Completion',
    icon: 'not-started',
  },
];
