import { gql } from '@apollo/client';

const CarveDiffTableFragments = {
  PerformanceSummary: gql`
    fragment CarveSummary on PerformanceSummary {
      loan_count
      current_balance_cents
      original_balance_cents
      wa_age_months
      wa_remaining_loan_terms_months
      wa_coupon
      wa_borrower_credit_score
      wa_ltv
      wa_dti
    }
  `,
};

const GET_CARVE = gql`
  query GetCarve($id: ID!, $filters: [Filter!]) {
    deal(id: $id) {
      id
      performance_summary(filters: $filters) {
        ...CarveSummary
      }
    }
  }
  ${CarveDiffTableFragments.PerformanceSummary}
`;

export default CarveDiffTableFragments;
export { GET_CARVE };
