import {PlacesType} from 'react-tooltip';
import { Tooltip } from 'react-tooltip';
import { styled } from 'style/ORSNNTheme';

export type TooltipProps = {
    content: string;
    place?: PlacesType;
    offset?: number;
    delayShow?: number;
    delayHide?: number;
    followCursor?: boolean;
}

export function getAttrToShowTooltip(props: TooltipProps) {
  return {
    'data-tooltip-id': 'tooltip',
    'data-tooltip-html': props.content,
    'data-tooltip-place': props.place || 'top',
    'data-tooltip-offset': props.offset || 10,
    'data-tooltip-delay-show': props.delayShow,
    'data-tooltip-delay-hide': props.delayHide,
    'data-tooltip-float': props.followCursor
  };
}


export const StyledTooltip = styled(Tooltip) < { $darker?: boolean }>`
  background-color: ${props => props.$darker ? props.theme.color.slate300 : props.theme.color.slate200};
  color: ${props => props.theme.color.slate50};
  font: ${props => props.theme.typography.primarySmall};
  font-weight: ${props => props.theme.typography.primaryBoldWeight};
  padding: 12px 16px;
  max-width: 300px;
  z-index: 1; //this is a hack to make sure the tooltip is on top of dropdowns and such. too lazy to portal it properly
`;