import Dinero from 'dinero.js';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { gql } from '@apollo/client';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { theme } from 'style/ORSNNTheme';
import { ChartCashFlowMonth } from './gql/__generated__/ChartCashFlowMonth';

const ChartFragments = {
  cashFlowMonth: gql`
    fragment ChartCashFlowMonth on CashFlowMonth {
      month
      cash_flow_cents
    }
  `,
};

type Props = {
  cashFlowMonths?: ChartCashFlowMonth[];
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: Payload<ValueType, NameType>[];
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps): JSX.Element => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${formatCurrency(payload[0].value as number)}`}</p>
      </div>
    );
  } else {
    return (<>
    </>);
  }
};

const formatCurrency = (value: number) =>
  Dinero({ amount: value, currency: 'USD' }).toFormat('$0,0.00');

const Chart = ({ cashFlowMonths }: Props): JSX.Element => cashFlowMonths ? (
  <ResponsiveContainer width="99%" height="99%">
    <BarChart data={cashFlowMonths}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <Bar dataKey="cash_flow_cents" fill={theme.color.blue} />
      <Tooltip
        content={(props) =>
          CustomTooltip({
            ...props,
          })
        } />
    </BarChart>
  </ResponsiveContainer>
) : (
  <></>
);

export default Chart;
export { ChartFragments };
