import { useQuery } from '@apollo/client';
import { Value as Numeric, Spinner } from 'common-ui';
import { Table } from 'baseui/table-semantic';
import { ColConfig, getFieldConfig } from 'configs/columns';
import { FilterableField } from '__generated__/globalTypes';
import { GET_CARVE } from './fragments';
import { CarveSummary } from './__generated__/CarveSummary';
import { GetCarve, GetCarveVariables } from './__generated__/GetCarve';

type CarveDiffTableProps = {
  dealId: string;
  filteredPool?: CarveSummary | null;
};

type ConfigType = {
  [key in keyof CarveSummary]: ColConfig;
};

const configFiller = {
  inputFormat: null,
  sortSelector: null,
  filterSelector: null,
};

const CONFIG: ConfigType = {
  current_balance_cents: {
    ...(getFieldConfig(FilterableField.current_balance_cents) as ColConfig),
    string: 'C BAL',
  },
  original_balance_cents: {
    ...(getFieldConfig(FilterableField.original_balance_cents) as ColConfig),
    string: 'O BAL',
  },
  loan_count: {
    ...configFiller,
    display: (value: number) => value.toLocaleString(),
    string: '# LOANS',
    selector: 'loan_count',
  },
  wa_age_months: {
    ...configFiller,
    display: (value: number) => value.toLocaleString(),
    string: 'WALA',
    selector: 'wa_age_months',
  },
  wa_remaining_loan_terms_months: {
    ...configFiller,
    display: (value: number) => value.toLocaleString(),
    string: 'WAM',
    selector: 'wa_remaining_loan_terms_months',
  },
  wa_coupon: {
    ...configFiller,
    display: (value: number) => `${((value ?? 0) * 100).toFixed(3)}%`,
    string: 'GWAC',
    selector: 'wa_coupon',
  },
  wa_borrower_credit_score: {
    ...configFiller,
    display: (value: number) => value.toLocaleString(),
    string: 'FICO',
    selector: 'wa_borrower_credit_score',
  },
  wa_ltv: {
    ...configFiller,
    display: (value: number) => `${((value ?? 0) * 100).toFixed(2)}%`,
    string: 'LTV',
    selector: 'wa_ltv',
  },
  wa_dti: {
    ...configFiller,
    display: (value) => `${((value ?? 0) * 100).toFixed(2)}%`,
    string: 'DTI',
    selector: 'wa_dti',
  },
};

const CarveDiffTable = ({
  dealId,
  filteredPool,
}: CarveDiffTableProps): JSX.Element => {
  const { loading, data, error } = useQuery<GetCarve, GetCarveVariables>(
    GET_CARVE,
    {
      variables: { id: dealId },
      fetchPolicy: 'cache-and-network',
    }
  );

  const tableData =
    data?.deal != null
      ? Object.keys(data.deal.performance_summary)
          .filter((k) => k !== '__typename')
          .map((k) => k as keyof CarveSummary)
          .map((key) => {
            const fieldConfig = CONFIG[key];
            const label = fieldConfig.string;
            const rawBaseData = data.deal?.performance_summary[key];

            const displayBaseData = (
              <Numeric>{fieldConfig.display(rawBaseData)}</Numeric>
            );
            const displayCarveData = (() => {
              if (filteredPool != null) {
                const rawCarveData = filteredPool[key];
                return <Numeric>{fieldConfig.display(rawCarveData)}</Numeric>;
              }
              return null;
            })();
            return [label, displayBaseData, displayCarveData];
          })
      : [];

  const hasCarveData = filteredPool != null;
  const columns = ['', 'BASE', ...(hasCarveData ? ['CARVE'] : [])];

  return (
    <>
      <Spinner loading={loading} />
      {error && <div>{error.message}</div>}
      <Table columns={columns} data={tableData} />
    </>
  );
};

export default CarveDiffTable;
export { default as CarveDiffTableFragments } from './fragments';
