import Select, {OptionProps, InputProps, components, MenuProps, ControlProps, Props as SelectProps, SingleValue, MultiValue, ActionMeta} from 'react-select';
import Input from '../inputs/Input/Input';
import {PillButton} from '../Buttons';
import {SvgIcon} from '../Icons/SvgIcon/SvgIcon';
import './PillButtonCombobox.scss';
import { useCallback } from 'react';

export type PillButtonComboboxProps<T, Multi extends boolean> = {
  options: T[];
  value?: T | T[];
  manuLabel?: string;
  comboLabel?: string;
  inputPlaceholder?: string;
  onClose?: () => void;
  onValueSelected: Exclude<SelectProps<T>['onChange'], undefined>;
  getOptionLabel?: SelectProps<T>['getOptionLabel'];
  getOptionValue?: SelectProps<T>['getOptionValue'];
  filterOption?: SelectProps<T>['filterOption'];
  isMulti?: Multi;
};
export function PillButtonCombobox<T extends object, Multi extends boolean>(props: PillButtonComboboxProps<T, Multi>){
  const {onValueSelected, onClose, comboLabel, manuLabel, inputPlaceholder, value, ...selectProps} = props;

  function handleValueChange(value: SingleValue<T> | MultiValue<T>, action: ActionMeta<T>){
    if (!value) {
      return;
    }
    onValueSelected(value, action);
    if (!selectProps.isMulti){
      onClose?.();
    }
    
  }

  const boundControl = useCallback((props: ControlProps<T, Multi>) => <Control<T, Multi> {...props} label={comboLabel} onCloseBtnClick={onClose}/>, [comboLabel, onClose]);
  const boundInput = useCallback((props: InputProps<T, Multi>) => <ComboInput<T, Multi> {...props} inputPlaceholder={inputPlaceholder}/>, [inputPlaceholder]);
  const boundMenu = useCallback((props: MenuProps<T, Multi>) => <Menu<T, Multi> {...props} label={manuLabel}/>, [manuLabel]);
  const boundOption = useCallback((props: OptionProps<T, Multi>) => <OptionComp<T, Multi> {...props}/>, []);
  const boundNoOptions = useCallback(() => <div className="pill-combo__no_results">No results found</div>, []);

  return (
    <Select<T, Multi>
      {...selectProps}
      defaultValue={value}
      unstyled={true}
      onChange={handleValueChange}
      isSearchable={true}
      className="pill-combo"
      menuIsOpen={true}
      maxMenuHeight={180}
      components={{
        Control: boundControl,
        Input: boundInput,
        Option: boundOption,
        DropdownIndicator: () => null,
        Menu: boundMenu,
        Placeholder: () => null,
        NoOptionsMessage: boundNoOptions,
      }}
      styles={{
        menu: () => ({}),
        valueContainer: (base) => ({ ...base, padding: 4 }),
      }}
      controlShouldRenderValue={false}
      backspaceRemovesValue={false}
      isClearable={false}
      escapeClearsValue={true}
      hideSelectedOptions={!selectProps.isMulti}
      tabSelectsValue={false}
    />
  );
}

function OptionComp<T, Multi extends boolean>(props: OptionProps<T, Multi>) {
  
  return (
    <div
      ref={props.innerRef}
      {...props.innerProps}
      className="pill-combo__option"
      role="option"
      aria-selected={props.isSelected}
    >
      <PillButton
        isActive={props.isSelected}
        isFocused={props.isFocused}
        iconName={props.isSelected ? 'pill-close' : 'pill-plus'}
        description={props.label}
      />
    </div>
  );
}

function ComboInput<T, Multi extends boolean>({...props}: InputProps<T, Multi> & {inputPlaceholder?: string} ) {
  //TODO: find a better way to remove props that don't belong to the input, normally there are innerProps...
  //or a better way to style the input
  const {inputPlaceholder, capture, crossOrigin, clearValue, getClassNames, cx, getStyles, getValue, hasValue, isHidden, isMulti, isRtl, selectOption, selectProps, setValue, innerRef, inputClassName, isDisabled, ...inputProps} = props;
  return <Input ref={props.innerRef} placeholder={inputPlaceholder} autoFocus {...inputProps}/>;
}

function Menu<T, Multi extends boolean>(props: MenuProps<T, Multi> & {label?: string}){
  return (
    <>
      {props.label ? <div className="pill-combo__menu_desc">{props.label}</div> : null}
      <components.Menu {...props}>{props.children}</components.Menu>
    </>
  );
}

function Control<T, Multi extends boolean>(props: ControlProps<T, Multi> & {label?: string, onCloseBtnClick?: () => void}){
  return (
    <>
      {(props.label || props.onCloseBtnClick) 
        ? <div className='pill-combo__header'>
            <span className='pill-combo__label'>{props.label}</span>
            <button 
              aria-label="Close" 
              className="pill-combo__close" 
              onClick={props.onCloseBtnClick}
              tabIndex={0}
            >
              <SvgIcon name="close" />
            </button>
          </div> 
        : null }
      <components.Control {...props}>{props.children}</components.Control>
    </>
  );
}