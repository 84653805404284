import React, { useMemo, useState } from 'react';
import TapeUploadFlow from './TapeUploadFlow';
import { HeaderMapInput, LoanField } from '__generated__/globalTypes';
import { useApolloClient, useQuery } from '@apollo/client';
import { SUBMIT_FILE_HEADER_MAP } from 'query/submitFileHeaderMap';
import {
  SubmitFileHeaderMap,
  SubmitFileHeaderMapVariables,
} from 'query/__generated__/SubmitFileHeaderMap';
import { FieldsMap } from './fieldMapping/MapRequiredFieldsCard';
import _ from 'lodash';
import { PortfolioLayout } from 'features/pages/portfolio/Portfolio/PortfolioLayout';
import { GET_UPLOADED_FILES_WITH_HEADERS } from 'query/getUploadedFilesWithHeaders';
import { GetUploadedFilesWithHeaders } from 'query/__generated__/GetUploadedFilesWithHeaders';
import TapeUploadContext from './loanTapeUploader/TapeUploadContext';
import { FileIngestOperationProvider } from './FileIngestOperation';
import { MapFieldsOperationProvider } from './MapFieldsOperation';

export const submitFileHeaderMap = async (
  client: ReturnType<typeof useApolloClient>,
  fileId: string,
  headerMaps: HeaderMapInput[]
) => {
  const query = {
    mutation: SUBMIT_FILE_HEADER_MAP,
    variables: {
      fileId: fileId,
      headerMaps: headerMaps,
    },
  };

  const result = await client.mutate<
    SubmitFileHeaderMap,
    SubmitFileHeaderMapVariables
  >(query);
  if (!result.data) {
    throw new Error('Could not submit field mappings');
  }
  return result.data;
};

export const mapFieldsMapToHeaderMaps = (
  fieldsMap: FieldsMap
): HeaderMapInput[] => {
  return _.map(fieldsMap, (file_column, fieldId) => {
    return {
      file_column,
      field: LoanField[fieldId as keyof typeof LoanField],
    };
  });
};

export const TapeUploadPage: React.FC = () => {
  /*
   * Fetch list of user uploaded loan tape files.
   */
  const { data, refetch } = useQuery<GetUploadedFilesWithHeaders>(
    GET_UPLOADED_FILES_WITH_HEADERS,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 5000,
    }
  );
  const uploadedTapes = data?.user.company.files;

  const [selectedFileId, setSelectedFileId] = useState<string | undefined>();

  const selectedFile = useMemo(() => {
    return uploadedTapes?.find((tape) => tape.id === selectedFileId);
  }, [selectedFileId, uploadedTapes]);

  return (
    <PortfolioLayout hideSummery={true}>
      <TapeUploadContext.Provider
        value={{
          uploadedTapes: data?.user.company.files,
          refetch: refetch,
          selectedFileId,
          setSelectedFileId,
          selectedFile,
        }}
      >
        <FileIngestOperationProvider>
          <MapFieldsOperationProvider>
            <TapeUploadFlow uploadedTapes={uploadedTapes} />
          </MapFieldsOperationProvider>
        </FileIngestOperationProvider>
      </TapeUploadContext.Provider>
    </PortfolioLayout>
  );
};
