import { FormConfigEntry } from 'common-ui';
import { mapValues } from 'lodash';
import * as yup from 'yup';

export type CashFlowsValidValues = {
  cdr: number;
  cpr: number;
  lsr: number;
  marketYield: number;
  daysDelay: number;
};

export type CashFlowsInputFormValues = {
  [key in keyof CashFlowsValidValues]: number | undefined;
};

export type FormConfig = {
  [k in keyof CashFlowsValidValues]: FormConfigEntry;
};

export const INPUT_CONFIG: FormConfig = {
  cpr: {
    label: 'CPR',
    placeholder: 'Prepayment',
    tip: 'Conditional Prepayment Rate is the percentage of loan pool’s principal that is paid off ahead of the amortization schedule.',
    unit: '%',
    yupSchema: yup.number().min(0).max(100).required(),
    type: 'number',
  },
  cdr: {
    label: 'CDR',
    placeholder: 'Default Rate',
    tip: 'Conditional Default Rate is the percentage of loans where borrowers have fallen more than 90 days behind.',
    unit: '%',
    yupSchema: yup.number().min(0).max(100).required(),
    type: 'number'
  },
  lsr: {
    label: 'LSR',
    placeholder: 'Loss Severity',
    tip: 'The percentage of the principal amount recovered in the event of a default.',
    unit: '%',
    yupSchema: yup.number().min(-5).max(100).required(),
    type: 'number'
  },
  marketYield: {
    label: 'Market Yield',
    placeholder: 'Market Yield',
    tip: 'Baseline interest rate (referenced in NPV calculation)',
    unit: '%',
    yupSchema: yup.number().min(-5).max(100).required(),
    type: 'number'
  },
  daysDelay: {
    label: 'Delay',
    placeholder: undefined,
    tip: "The number of days before the owner of the loan receives the borrower's payment after the interest-accrual period", // eslint-disable-line quotes
    unit: 'Days',
    yupSchema: yup.number().min(0).required().integer(),
    type: 'number'
  },
};

export const INITIAL_FORM_VALUES: CashFlowsInputFormValues = {
  ...mapValues(INPUT_CONFIG, () => undefined),
  daysDelay: 30,
};
