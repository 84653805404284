import { SvgIcon } from 'common-ui';
import styled from 'styled-components';

// Error styling: https://www.figma.com/file/k0BCnAOFwrRh6GU5KKacJq/Orsnn-Product-DS?type=design&node-id=515-1332&mode=design&t=iaimmRHWJabyA0iq-4
const StyledErrorMessage = styled.span`
  font-size: 11px;
  color: #e95c7b;
  align-items: center;
  padding: 2px;

  display: flex;

  svg {
    transform: translateY(-1px);
    margin-right: 4px;
  }
`;
const ErrorMessageContainer = styled.div`
  position: relative;
`;

const ErrorMessage: React.FC = ({ children }) => {
  return (
    <ErrorMessageContainer>
      {children ? (
        <StyledErrorMessage>
          <SvgIcon name="Icon-error" />
          {children}
        </StyledErrorMessage>
      ) : null}
    </ErrorMessageContainer>
  );
};

export default ErrorMessage;
