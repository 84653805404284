import { useState } from 'react';
import { DateTime } from 'luxon';
import { Datepicker } from 'baseui/datepicker';
import { Moment } from 'moment';

export type DatePickerProps = {
  defaultValue: Date | DateTime | undefined | null;
  id: string;
  onChange: (value: DateTime | null) => void;
  isOutsideRange?: (day: Moment) => boolean;
  openDirection?: 'up' | 'down';
};

function DateInput(props: DatePickerProps): JSX.Element {
  const [date, setDate] = useState(() => {
    if (props.defaultValue instanceof Date) {
      return props.defaultValue;
    } else if (props.defaultValue && props.defaultValue.toJSDate) {
      return props.defaultValue.toJSDate();
    }
    return new Date();
  });
  const handleOnChange: (args: { date: Date | Date[] }) => void = ({
    date,
  }) => {
    setDate(date as Date);
    props.onChange(DateTime.fromJSDate(date as Date));
  };
  return (
    <Datepicker
      aria-label="Select a date"
      range={false}
      value={date}
      onChange={handleOnChange}
      formatString="MM/dd/yyyy"
      placeholder="MM/DD/YYYY"
      mask="99/99/9999"
    />
  );
}

export default DateInput;
