import { gql } from '@apollo/client';
import { CarveDiffTableFragments } from '../CarveDiffTable';

const BidCardFragments = {
  TimelineCard: gql`
    fragment BidCard on TimelineCard {
      ... on BidCard {
        actions {
          title
          type
          emphasis
        }
        carve_criteria_section
        upb_criteria_section
        can_edit_counteroffer
        counteroffer_details_section
        bid {
          carve {
            field_name
            operator
            operand
            operandList
          }
          carve_summary {
            ...CarveSummary
          }
          terms {
            id
            name
            documentUrl
            documentSizeBytes
          }
          bid_basis_points
          seller_bid_basis_points
          price {
            rate
            price
            unpaid_balance
          }
        }
      }
    }
    ${CarveDiffTableFragments.PerformanceSummary}
  `,
};

export default BidCardFragments;
