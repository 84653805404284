import { BaseButton } from 'common-ui';
import { YieldBidButtonGroup } from './BiddingAndPricingSection.styles';
import { useState } from 'react';
import BidStipulations from './BidStipulations';
import { Stipulations } from './BidDetails';
import { CarveTableRowType } from './CarveTable';
import YieldMatrix from './YieldMatrix';

interface BidParametersProps {
  carve: CarveTableRowType;

  // TODO(kentskinner): stipulations should probably just be part of BidDetails.
  stipulations: Stipulations;
  updateStipulation: (key: string, value: any) => void;
}

const BidParameters = (props: BidParametersProps) => {
  const [showYieldMatrix, setShowYieldMatrix] = useState<boolean>(true);

  return (
    <>
      <YieldBidButtonGroup>
        <BaseButton
          type="tertiary"
          label="yield-matrix"
          isSelected={showYieldMatrix}
          onClick={() => setShowYieldMatrix(true)}
        >
          Yield Matrix
        </BaseButton>
        <BaseButton
          type="tertiary"
          label="bid-stipulations"
          isSelected={!showYieldMatrix}
          onClick={() => setShowYieldMatrix(false)}
        >
          Bid Stipulations
        </BaseButton>
      </YieldBidButtonGroup>

      {showYieldMatrix ? (
        <YieldMatrix carve={props.carve} />
      ) : (
        <BidStipulations
          stipulations={props.stipulations}
          updateStipulation={props.updateStipulation}
        />
      )}
    </>
  );
};

export default BidParameters;
