import React, { useState } from 'react';
import './radio-button-group.scss';
import RadioButton from './RadioButton';

export interface RadioButtonOption {
  label: React.ReactNode;
  value: string;
}

export interface RadioButtonGroupProps {
  name: string;
  options: RadioButtonOption[];
  defaultVal?: string | null;
  onChange?: (selected: string) => void;
  customClass?: string;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  name,
  options,
  defaultVal = null,
  onChange,
  customClass = '',
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(
    defaultVal
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);

    if (onChange) {
      onChange(e.target.value);
    }
  };

  const className = `radio-button-group ${customClass}`.trim();

  return (
    <div className={className}>
      {options.map((option, index) => (
        <RadioButton
          key={option.value}
          value={option.value}
          name={name}
          checked={selectedOption === option.value}
          label={option.label}
          onChange={handleChange}
        />
      ))}
    </div>
  );
};

export default RadioButtonGroup;
