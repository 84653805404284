import { useQuery } from '@apollo/client';
import { FileType } from '__generated__/globalTypes';
import { GetDocumentLibrary } from 'query/__generated__/GetDocumentLibrary';
import { GET_DOCUMENT_LIBRARY } from 'query/documentLibrary';

export function useDocumentLibrary(fileType: FileType) {
  const { data, error, loading, refetch } = useQuery<GetDocumentLibrary>(
    GET_DOCUMENT_LIBRARY,
    {
      variables: { fileType },
      fetchPolicy: 'cache-and-network',
    }
  );

  const documents = data?.user.company.documents;

  return { documents, loading, error, refetch };
}
