import { CellContext, ColumnDef } from '@tanstack/react-table';
import { formatCentsToDollars, toPercentage } from './formatting';
import { CarveTableRowType } from './CarveTable';
import { ChangeEvent } from 'react';
import { PercentageInput } from 'common-ui/inputs/Inputs-styled';
import { styled } from 'style/ORSNNTheme';
import { FIELD_CONFIG } from 'configs/columns';

const BidPriceContainer = styled.div`
  // This prevents the text input from getting too high and changing the row height.
  height: 24px;

  // Prevent the bid price field from getting unreasonable wide
  max-width: 100px;
`;

export const getColumnDefinitions = (
  onBidPxChanged: (id: string, bidPx: number | null) => void
): ColumnDef<CarveTableRowType>[] => {
  const handleBidPxInputChanged = (
    props: CellContext<CarveTableRowType, unknown>,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const price = inputValue ? Number(inputValue) : null;
    if (price === null || !isNaN(price)) {
      onBidPxChanged(props.row.original.id, price);
    }
  };

  return [
    {
      accessorKey: 'name',
      header: 'Carve Name',
      meta: {
        headerNoPill: true,
      },
    },
    {
      accessorKey: 'bidPx',
      header: 'Bid Px',
      cell: (props) => {
        const value = props.getValue();
        const displayValue = value !== null ? Number(value) : null;

        return props.row.getIsSelected() ? (
          <BidPriceContainer>
            <PercentageInput
              value={displayValue}
              onChange={(event) => handleBidPxInputChanged(props, event)}
            />
          </BidPriceContainer>
        ) : (
          <span
            style={{
              display: 'block',
              padding: '0 10px 0 0',
              width: '100%',
              textAlign: 'right',
            }}
          >
            {displayValue !== null ? `${displayValue}%` : '-'}
          </span>
        );
      },
      meta: {
        numeric: true,
        headerNoPill: true,
      },
    },
    {
      accessorKey: 'servicingFee',
      header: 'Servicing Fee',
      cell: (props) => <span>{toPercentage(Number(props.getValue()))}</span>,
      meta: {
        numeric: true,
        headerNoPill: true,
      },
    },
    {
      accessorKey: 'UPB',
      header: 'UPB',
      meta: {
        headerNoPill: true,
        numeric: true,
      },
      cell: (props) => (
        <span>{formatCentsToDollars(Number(props.getValue()))}</span>
      ),
    },
    {
      accessorKey: 'GWAC',
      header: 'GWAC',
      cell: (props) => (
        <span>{FIELD_CONFIG.servicing_rate.display(props.getValue())}</span>
      ),
      meta: {
        headerNoPill: true,
        numeric: true,
      },
    },
    {
      accessorKey: 'numLoans',
      header: '# Loans',
      meta: {
        headerNoPill: true,
        numeric: true,
      },
    },
    {
      accessorKey: 'WALA',
      header: 'WALA',
      meta: {
        headerNoPill: true,
        numeric: true,
      },
    },
    {
      accessorKey: 'WAM',
      header: 'WAM',
      meta: {
        headerNoPill: true,
        numeric: true,
      },
    },
    {
      accessorKey: 'LTV',
      header: 'LTV',
      cell: (props) => (
        <span>{FIELD_CONFIG.ltv.display(props.getValue())}</span>
      ),
      meta: {
        headerNoPill: true,
        numeric: true,
      },
    },
    {
      accessorKey: 'DTI',
      header: 'DTI',
      cell: (props) => (
        <span>{FIELD_CONFIG.dti.display(props.getValue())}</span>
      ),
      meta: {
        headerNoPill: true,
        numeric: true,
      },
    },
    {
      accessorKey: 'DQ',
      header: 'DQ',
      meta: {
        headerNoPill: true,
        numeric: true,
      },
    },
  ];
};
