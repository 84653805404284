import { Card, CardHeading, CardInstructions, StyledFooter } from '../styles';
import { styled } from 'style/ORSNNTheme';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import FileHeadersList from './FileHeadersList';
import FieldsList from './FieldsList';
import { AssetClass, LoanField } from '__generated__/globalTypes';
import { SingleValue } from 'react-select';
import { FIELD_CONFIG } from '../loanFields';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { MappingCardFile } from './__generated__/MappingCardFile';
import Automapper from './Automapper';
import { useMapFieldsOperation } from '../MapFieldsOperation';
import { useTapeUploadContext } from '../loanTapeUploader/TapeUploadContext';
import { Button } from 'baseui/button';

const MappingCardFragments = {
  file: gql`
    fragment MappingCardFile on File {
      id
      fileName: file_name
      assetClass: asset_class
      headers
      field_mapping {
        file_column
        field
      }
      state
    }
  `,
};

const MappingSection = styled.div`
  display: flex;
  gap: 30px;
  font-size: 14px;
  height: 640px;
`;

const SelectedFile = styled.div`
  // These css properties are copied directly from Figma.
  // https://www.figma.com/file/urbIZ8Fnnln4Knx1lfo7mV/High-Level-Screens?type=design&node-id=7277-167813&t=1X4Bsalnys7AEFBl-4
  background: rgba(224, 40, 221, 0.28);
  color: rgba(240, 147, 238, 1);
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  padding: 4px;
  gap: 6px;
  width: 296px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 15px;

  .common-ui-dropdown {
    margin-top: 10px;

    // TODO(kentskinner): this doesn't work. Figure out how to change Select border color.
    border-color: ${(props) => props.theme.color.themeAccentEmphasis};
  }
`;

export interface FieldIdAndLabel {
  fieldId: LoanField;
  label: string;
}

const convertToFieldIdAndLabel = (fc: {
  field: LoanField;
  label: string;
}): FieldIdAndLabel => {
  return {
    fieldId: fc.field,
    label: fc.label,
  };
};

const assetClassOptions = Object.values(AssetClass).map((assetClass) => ({
  value: assetClass,
  label: assetClass.toString(),
}));

const requiredFieldsForAssetClass = (
  assetClass: AssetClass
): FieldIdAndLabel[] => {
  return FIELD_CONFIG[assetClass].requiredFields.map(convertToFieldIdAndLabel);
};

const optionalFieldsForAssetClass = (
  assetClass: AssetClass
): FieldIdAndLabel[] => {
  return FIELD_CONFIG[assetClass].optionalLoanFields.map(
    convertToFieldIdAndLabel
  );
};

export type FieldsMap = {
  [fieldId: string]: string;
};

const mappingArrayToMap = (
  mapping: {
    field: LoanField;
    file_column: string;
  }[]
) => {
  return _.fromPairs(
    mapping.map(({ field, file_column }) => [field, file_column])
  );
};

const getInitialUnmappedHeaders = (selectedFile: MappingCardFile) => {
  const { headers = [], field_mapping } = selectedFile;
  const fieldsMap = mappingArrayToMap(field_mapping);
  const mappedHeaders = _.values(fieldsMap);
  return _.difference(headers, mappedHeaders);
};

export const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1;
`;

type Props = {
  selectedFile: MappingCardFile;
  allowAssetClassChange?: boolean;
  confirmButtonDisabled?: boolean;
  disabled?: boolean;
};

const MapRequiredFieldsCard = ({
  selectedFile,
  allowAssetClassChange = false,
  disabled,
}: Props): JSX.Element => {
  const { startMapFields } = useMapFieldsOperation();
  const { selectedFileId } = useTapeUploadContext();

  const [fieldsMap, setFieldsMap] = useState<FieldsMap>(
    mappingArrayToMap(selectedFile.field_mapping)
  );
  const [unmappedHeaders, setUnmappedHeaders] = useState<string[]>(
    getInitialUnmappedHeaders(selectedFile)
  );
  const [assetClass, setAssetClass] = useState<AssetClass>(
    selectedFile.assetClass
  );

  const requiredFields = requiredFieldsForAssetClass(assetClass);
  const optionalFields = optionalFieldsForAssetClass(assetClass);

  const areAllRequiredFieldsMapped = requiredFields.every(
    (field) => field.fieldId in fieldsMap
  );

  const handleSelectAssetClass = (
    option: SingleValue<{ value: AssetClass; label: string }>
  ) => {
    if (option && option.value !== assetClass) {
      setAssetClass(option.value);
      setFieldsMap({});
      setUnmappedHeaders(selectedFile.headers || []);
    }
  };

  const onAutoMap = (
    fieldsMap: FieldsMap,
    remainingUnmappedHeaders: string[]
  ) => {
    setFieldsMap(fieldsMap);
    setUnmappedHeaders(remainingUnmappedHeaders);
  };

  const onMap = (fieldId: string, header: string) => {
    setFieldsMap((prevMappings) => ({
      ...prevMappings,
      [fieldId]: header,
    }));
    setUnmappedHeaders((unmappedHeaders) =>
      unmappedHeaders.filter((h) => h !== header)
    );
  };

  const onUnmap = (fieldId: string) => {
    // Add the unmapped header back to the unmappedHeaders list
    const unmappedHeader = fieldsMap[fieldId];
    setUnmappedHeaders((prevUnmappedHeaders) => [
      ...prevUnmappedHeaders,
      unmappedHeader,
    ]);

    const updatedMap: FieldsMap = { ...fieldsMap };
    delete updatedMap[fieldId];
    setFieldsMap(updatedMap);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Card>
        {disabled && <DisabledOverlay />}
        <CardHeading>Map File Columns to Fields</CardHeading>
        <CardInstructions>
          We've recognized some fields that need to be manually mapped to our
          system. Drag fields from your loan tape over to ORSNN defined fields.
        </CardInstructions>
        <SelectedFile>
          <div>{selectedFile.fileName} is selected</div>
          {allowAssetClassChange && (
            <Dropdown
              isMulti={false}
              placeholder="Select Asset Class"
              options={assetClassOptions}
              onChange={handleSelectAssetClass}
              value={
                assetClass
                  ? { value: assetClass, label: assetClass.toString() }
                  : null
              }
            />
          )}
        </SelectedFile>
        <MappingSection>
          <FieldsList
            requiredFields={requiredFields}
            optionalFields={optionalFields}
            map={fieldsMap}
            onMap={onMap}
            onUnmap={onUnmap}
          />
          <FileHeadersList unmappedHeaders={unmappedHeaders} />
        </MappingSection>
        <StyledFooter>
          <Automapper
            requiredFields={requiredFields}
            optionalFields={optionalFields}
            currentFieldMap={fieldsMap}
            unmappedHeaders={unmappedHeaders}
            onAutoMap={onAutoMap}
          />
          <Button
            disabled={!areAllRequiredFieldsMapped}
            onClick={() =>
              selectedFileId && startMapFields(selectedFileId, fieldsMap)
            }
          >
            Map Fields
          </Button>
        </StyledFooter>
      </Card>
    </DndProvider>
  );
};

export default MapRequiredFieldsCard;
export { MappingCardFragments };
