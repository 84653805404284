import { PropsWithChildren } from 'react';
import { styled } from 'style/ORSNNTheme';
import { IndeterminateCheckboxProps, IndeterminateCheckbox } from './IndeterminateCheckbox';

export type CheckboxWithLabelProps = PropsWithChildren<IndeterminateCheckboxProps>;

//TODO: add styles to the label, didn't have a use case, just wanted to delete deprecated checkbox
const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  color: ${(props) => props.theme.color.fgLabelDefault};
  font: ${(props) => props.theme.typography.primaryBody};
`;


export function CheckboxWithLabel(props: CheckboxWithLabelProps){
  const { children, ...rest } = props;
  return (
    <StyledLabel>
      <IndeterminateCheckbox {...rest} />
      {children}
    </StyledLabel>
  );
}