import {abbrToKeyMap, stratificationsConfig, StratKey} from './startifications.config';
import {compact} from 'lodash';

export function abbrToKeys(abbrStr: string) {
  const abbr = abbrStr.split(',');
  const keys = abbr.map((abbr) => {
    const key = abbrToKeyMap[abbr];
    if (!key) {
      return null;
    }
    return key;
  });

  return compact(keys);
}

export function keysToAbbr(keys: StratKey[]) {
  const abbrs = keys.map((key) => {
    const abbr = stratificationsConfig[key as StratKey].abbr;
    return abbr;
  });

  return compact(abbrs).join(',');
}