import { forwardRef, ForwardRefRenderFunction } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ListDealMessages_dealMessages_entities } from './__generated__/ListDealMessages';
import { Spinner } from 'baseui/spinner';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: 16px;
  background-color: #1f1f1f;
  box-shadow: 0px 0px 1px #0d0d0d, 0px 2px 4px rgba(13, 13, 13, 0.9);
  border-radius: 0px 6px 6px 6px;
  white-space: pre-wrap;
`;

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

const Author = styled.div<{ $isActiveUser: boolean }>`
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.$isActiveUser ? '#FFFFFF' : '#9E9E9E')};
`;

const Timestamp = styled.div`
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #757575;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface MessageProps {
  dealMessage: ListDealMessages_dealMessages_entities;
  isLoading?: boolean;
}

const UnwrappedMessage: ForwardRefRenderFunction<
  HTMLDivElement,
  MessageProps
> = ({ dealMessage, isLoading }, ref) => {
  if (isLoading) {
    return (
      <Layout>
        <ContentContainer>
          <SpinnerContainer>
            <Spinner color="#e85ee5" aria-label="loading more messages" />
          </SpinnerContainer>
        </ContentContainer>
      </Layout>
    );
  }
  const { given_name, family_name, is_current_user } = dealMessage.author;

  return (
    <Layout ref={ref}>
      <Metadata>
        <Author $isActiveUser={is_current_user}>
          {`${given_name} ${family_name || ''}`}
        </Author>
        <Timestamp>
          {moment
            .unix(Number(dealMessage.created_time))
            .format('MM-DD-YY @ h:mmA')}
        </Timestamp>
      </Metadata>
      <ContentContainer>{dealMessage.message}</ContentContainer>
    </Layout>
  );
};

export const Message = forwardRef(UnwrappedMessage);
