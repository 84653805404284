import { CheckboxWithLabel, IndeterminateCheckbox } from 'common-ui';
import { ColumnsContainer } from './BiddingAndPricingSection.styles';
import { FC, ReactNode, useState } from 'react';
import { NumberInput, TextInput } from 'common-ui/inputs/Inputs-styled';
import { styled } from 'style/ORSNNTheme';
import { DatePicker } from 'baseui/datepicker';
import React from 'react';
import { FileType } from '__generated__/globalTypes';
import { Stipulations } from './BidDetails';
import DocumentList from 'features/common-elements/DocumentList';
import { useDocumentLibrary } from 'common-ui/hooks/useDocumentLibrary';

const FormRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin: 10px 0;
`;

const StyledInput = styled.div`
  width: 134px;
  display: flex;
  font-size: 14px;
`;

const FieldLabel = styled.span`
  font-size: 14px;
`;

interface ToggleableInputProps {
  label: string;
  children: ReactNode;
  initiallyChecked: boolean;
}

const ToggleableInput: FC<ToggleableInputProps> = ({
  label,
  children,
  initiallyChecked,
}) => {
  const [isChecked, setIsChecked] = useState(initiallyChecked);

  return (
    <FormRow>
      <CheckboxWithLabel
        checked={isChecked}
        onChange={(event) => setIsChecked(event.target.checked)}
      >
        <FieldLabel>{label}</FieldLabel>
      </CheckboxWithLabel>
      <StyledInput>
        {React.cloneElement(children as React.ReactElement, {
          disabled: !isChecked,
        })}
      </StyledInput>
    </FormRow>
  );
};

interface CustomBidStipulationProps {
  stipulationText: string | null;
  setStipulationText: (value: string) => void;
  placeholder: string;
  initiallyChecked: boolean;
}

const CustomBidStipulation = (props: CustomBidStipulationProps) => {
  const [isChecked, setIsChecked] = useState(props.initiallyChecked);

  return (
    <FormRow>
      <IndeterminateCheckbox
        checked={isChecked}
        onChange={(event) => setIsChecked(event.target.checked)}
      />
      <TextInput
        style={{ fontSize: 14 }}
        disabled={!isChecked}
        withPrefix={false}
        placeholder={props.placeholder}
        value={props.stipulationText || ''}
        onChange={(event) => props.setStipulationText(event.target.value)}
      />
    </FormRow>
  );
};

interface BidStipulationsProps {
  stipulations: Stipulations;
  updateStipulation: (key: string, value: any) => void;
}

const BidStipulations = (props: BidStipulationsProps) => {
  const { stipulations, updateStipulation } = props;
  const { documents } = useDocumentLibrary(FileType.TRANSACTION_DOCUMENT);
  return (
    <ColumnsContainer>
      <div>
        <div>Select Stipulations</div>
        <FormRow>
          <CheckboxWithLabel
            checked={stipulations.serviceRetained}
            onChange={(event) =>
              updateStipulation('serviceRetained', event.target.checked)
            }
          >
            <FieldLabel>Service Retained</FieldLabel>
          </CheckboxWithLabel>
        </FormRow>
        <ToggleableInput
          label="Percentage of Collateral Diligence"
          initiallyChecked={!!stipulations.percentageOfCollateralDiligence}
        >
          <NumberInput
            style={{ fontSize: 14 }}
            withPrefix={false}
            min="0"
            max="100"
            value={stipulations.percentageOfCollateralDiligence || ''}
            onChange={(event) =>
              updateStipulation(
                'percentageOfCollateralDiligence',
                parseFloat(event.target.value)
              )
            }
          />
        </ToggleableInput>

        <ToggleableInput
          label="Min Net Yield to Buyer"
          initiallyChecked={!!stipulations.minNetYieldToBuyer}
        >
          <NumberInput
            style={{ fontSize: 14 }}
            withPrefix={false}
            min="0"
            max="100"
            value={stipulations.minNetYieldToBuyer || ''}
            onChange={(event) =>
              updateStipulation(
                'minNetYieldToBuyer',
                parseFloat(event.target.value)
              )
            }
          />
        </ToggleableInput>

        <ToggleableInput
          label="Diligence Completion Date"
          initiallyChecked={!!stipulations.diligenceCompletionDate}
        >
          <DatePicker
            size="compact"
            formatString="MM/dd/yyyy"
            placeholder="MM/DD/YYYY"
            mask="99/99/9999"
            onChange={(dates) => {
              const date = Array.isArray(dates) ? dates[0] : dates;
              updateStipulation('diligenceCompletionDate', date);
            }}
          />
        </ToggleableInput>

        <ToggleableInput
          label="Settlement Date"
          initiallyChecked={!!stipulations.settlementDate}
        >
          <DatePicker
            size="compact"
            formatString="MM/dd/yyyy"
            placeholder="MM/DD/YYYY"
            mask="99/99/9999"
            // TODO(kentskinner): setting the date seems to crash the component.
            // value={stipulations.settlementDate || new Date()}
            onChange={(date) => {
              if (!Array.isArray(date)) {
                updateStipulation('settlementDate', date);
              }
            }}
          />
        </ToggleableInput>

        <CustomBidStipulation
          initiallyChecked={!!stipulations.custom1}
          placeholder="Enter Custom Stipulation 1..."
          stipulationText={stipulations.custom1}
          setStipulationText={(text) => updateStipulation('custom1', text)}
        />

        <CustomBidStipulation
          initiallyChecked={!!stipulations.custom2}
          placeholder="Enter Custom Stipulation 2..."
          stipulationText={stipulations.custom2}
          setStipulationText={(text) => updateStipulation('custom2', text)}
        />

        <CustomBidStipulation
          initiallyChecked={!!stipulations.custom3}
          placeholder="Enter Custom Stipulation 3..."
          stipulationText={stipulations.custom3}
          setStipulationText={(text) => updateStipulation('custom3', text)}
        />
      </div>

      <div style={{ width: '50%' }}>
        <div>Select Transaction Documents</div>
        <DocumentList documents={documents} />
      </div>
    </ColumnsContainer>
  );
};

export default BidStipulations;
