import { gql } from '@apollo/client';

export const CREATE_PUBLIC_OFFERING = gql`
  mutation CreatePublicOffering(
    $listingId: ID!
    $allowBanks: Boolean!
    $allowCreditUnions: Boolean!
    $executionType: ExecutionType!
    $expirationDate: DateTime!
    $isPublic: Boolean!
    $isServicingNegotiable: Boolean!
    $performanceClass: PerformanceClass!
    $pricingType: PricingType!
    $pricingRate: Float
    $servicingPreference: ServicingPreference!
    $servicingFeeRate: Float
  ) {
    createPublicOffering(
      input: {
        listing_id: $listingId
        allow_banks: $allowBanks
        allow_credit_unions: $allowCreditUnions
        execution_type: $executionType
        expiration_date: $expirationDate
        is_public: $isPublic
        is_servicing_negotiable: $isServicingNegotiable
        performance_class: $performanceClass
        pricing_type: $pricingType
        pricing_rate: $pricingRate
        servicing_preference: $servicingPreference
        servicing_fee_rate: $servicingFeeRate
        template_document_ids: []
      }
    ) {
      id
    }
  }
`;
