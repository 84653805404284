import { gql } from '@apollo/client';
const WireConfirmationCardFragments = {
  TimelineCard: gql`
    fragment WireConfirmationCard on TimelineCard {
      ... on WireConfirmationCard {
        actions {
          title
          type
          emphasis
        }
        wire_details {
          account_name
          account_number
          bank_name
          routing_number
        }
      }
    }
  `,
};

export default WireConfirmationCardFragments;
