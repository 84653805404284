import { Amplify, Auth, Analytics } from 'aws-amplify';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import config from './config';
import * as serviceWorker from './serviceWorker';
import GlobalStyle from 'style/GlobalStyle';
import { AuthContextProvider } from './context/AuthContext';

import { AuthOptions, AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  isReference,
  Reference,
  StoreObject,
} from '@apollo/client';

import introspectionResult from './introspection-result';
import awsconfig from './aws-exports';
import {
  ListDealMessages_dealMessages,
  ListDealMessages_dealMessages_entities,
} from 'features/deals/Messaging/__generated__/ListDealMessages';

Amplify.configure({
  ...awsconfig,
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'orsnn',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        graphql_endpoint_iam_region: config.apiGateway.REGION,
      },
      {
        name: 'orsnn-file',
        endpoint: config.fileGateway.URL,
        region: config.fileGateway.REGION,
      },
      {
        name: 'orsnn-smart_header',
        endpoint: config.smartHeaderGateway.URL,
        region: config.smartHeaderGateway.REGION,
      },
    ],
    aws_appsync_graphqlEndpoint: config.apiGateway.URL,
    aws_appsync_region: config.apiGateway.REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
  Analytics: {
    AWSPinpoint: {
      // Amazon Pinpoint App Client ID
      appId: awsconfig.aws_mobile_analytics_app_id,
      // Amazon service region
      region: awsconfig.aws_mobile_analytics_app_region,
      mandatorySignIn: false,
    },
  },
});

Analytics.autoTrack('session', {
  enable: true,
  provider: 'AWSPinpoint',
});

Analytics.autoTrack('pageView', {
  enable: true,
  eventName: 'pageView',
  type: 'SPA',
  provider: 'AWSPinpoint',
  getUrl: () => {
    return window.location.origin + window.location.pathname;
  },
});

Analytics.autoTrack('event', {
  enable: true,
  events: ['click'],
  selectorPrefix: 'data-amplify-analytics-',
  provider: 'AWSPinpoint',
});

// https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/450#issuecomment-586542120
// https://github.com/awslabs/aws-mobile-appsync-sdk-js#using-authorization-and-subscription-links-with-apollo-client-no-offline-support
const authOptions: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const ApolloLinkConfig = {
  url: config.apiGateway.URL,
  region: config.apiGateway.REGION,
  auth: authOptions,
  disableOffline: true,
};

const link = ApolloLink.from([
  createAuthLink(ApolloLinkConfig),
  createSubscriptionHandshakeLink(ApolloLinkConfig),
]);

function itemPaginationCachePolicy<T extends StoreObject | Reference>() {
  return {
    // We only care about these filters and sort when creating new cache entry
    // otherwise, if only pagination changes, then merge the query result
    keyArgs: ['filters', 'sort'],
    merge(
      existing: T[] = [],
      incoming: T[] = [],
      { args }: { args: any }
    ): T[] {
      const merged = existing.slice(0);
      const { offset, page_size } = args.pagination;
      let i = 0;
      // replace the existing with the incoming
      for (; i < incoming.length; ++i) {
        merged[offset + i] = incoming[i];
      }
      // if incoming is less than page_size, then it mean there are no more
      // elements after the last element of incoming so slicing
      return page_size > incoming.length ? merged.slice(0, offset + i) : merged;
    },

    read(existing: T[] = [], { args }: { args: any }): T[] {
      const { offset, page_size } = args.pagination;
      return existing.slice(offset, offset + page_size);
    },
  };
}

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies: {
      UserCompany: {
        fields: {
          loans: itemPaginationCachePolicy<Reference>(),
        },
      },
      Deal: {
        fields: {
          loans: itemPaginationCachePolicy<Reference>(),
        },
      },
      File: {
        fields: {
          processing_report: {
            merge(existing = {}, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      Query: {
        fields: {
          listings: itemPaginationCachePolicy<Reference>(),
          dealMessages: {
            keyArgs: ['deal_id'],
            merge(
              existing: ListDealMessages_dealMessages | undefined,
              incoming: ListDealMessages_dealMessages,
              { readField }
            ): ListDealMessages_dealMessages | undefined {
              const ids = new Set();
              const entities: ListDealMessages_dealMessages_entities[] = [];
              const rawEntities = [
                ...(existing?.entities || []),
                ...(incoming.entities || []),
              ];

              rawEntities.forEach((e) => {
                if (e === null) {
                  return;
                }
                if (!isReference(e)) {
                  return;
                }
                const id = readField('id', e);
                if (ids.has(id)) {
                  return;
                }
                entities.push(e);
                ids.add(id);
              });

              return {
                entities: [...entities].sort((a, b) => {
                  if (!isReference(a)) {
                    return 0;
                  }
                  if (!isReference(b)) {
                    return 0;
                  }

                  return (
                    Number(readField('created_time', a)) -
                    Number(readField('created_time', b))
                  );
                }),
                last_evaluated_key: incoming?.last_evaluated_key,
              };
            },
          },
        },
      },
    },
  }),
  connectToDevTools: process.env.NODE_ENV !== 'production',
});

Modal.setAppElement(document.getElementById('modal') as HTMLElement);

ReactDOM.render(
  <>
    <ApolloProvider client={client}>
      <GlobalStyle />
      <IntlProvider locale="en-US">
        <BrowserRouter>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </BrowserRouter>
      </IntlProvider>
    </ApolloProvider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export { client as apolloClient };
