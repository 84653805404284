import { Row } from 'common-ui';
import { lighten, transitions } from 'polished';
import { Theme, css, styled } from 'style/ORSNNTheme';

const backgroundColor = (props: { theme: Theme }) =>
  lighten(0.1, props.theme.color.darkPrimaryLabel);

const SubmitButton = styled.button.attrs({
  'data-amplify-analytics-on': 'click',
  'data-amplify-analytics-name': 'click',
})`
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 3px;
  background-color: ${(props) => props.theme.color.darkPrimaryLabel};
  color: ${(props) => props.theme.color.fgDefault};
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  padding: 16px 32px;
  margin-bottom: 8px;

  ${transitions(['background-color'], '200ms ease-in')};

  &:hover {
    background-color: ${backgroundColor};
  }
`;

const Card = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.accentMuted};
  border-radius: 4px;
  margin-top: 8px;
  padding: 20px;
  background-color: ${(props) => props.theme.color.bgCanvas};
  font-size: 14px;
`;

const CardHeading = styled.h2`
  color: ${(props) => props.theme.color.grey6};
  font-size: 18px;
  margin-bottom: 10px;
`;

const CardInstructions = styled.p`
  color: ${(props) => props.theme.color.grey500};
  font-size: 12px;
  margin: 5px 0;
`;

const Table = styled.table`
  color: ${(props) => props.theme.color.fgDefault};
  border-spacing: 10px;
  th {
    border-radius: 25px;
    background-color: ${(props) => props.theme.color.grey800};
    padding: 5px 15px;
    font-weight: normal;
  }
`;

// TODO(kentskinner): obviously this needs some work. It is a bare minimum to get
// something looking vaguely reasonable on the Storybook page.
const UploadedFileHeaderMapSelector = styled.div`
  color: ${(props) => props.theme.color.fgDefault};
  display: flex;

  span {
    width: 200px;
  }

  div {
    width: 400px;
  }
`;

type RowProps = {
  highlight?: boolean;
};

const TitleRow = styled(Row)<RowProps>`
  font-size: 14px;
  margin: 0;
  margin-top: 15px;
  color: ${(props) => (props.highlight ? props.theme.color.red : '#757a7d')};
`;

const ValuesRow = styled(Row)<RowProps>`
  font-size: 14px;
  margin: 0;
  margin-top: 15px;
  font-weight: Bold;
  ${(props) =>
    props.highlight &&
    css`
      color: ${props.theme.color.red};
    `}
`;

const StyledFooter = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0.75rem;
  height: 56px;
  border: none;
  padding: 0;

  .btn-group {
    width: 100%;
    height: 100%;

    .cancel {
      background-color: ${(props) => props.theme.color.grey100};
      color: ${(props) => props.theme.color.grey900};
    }

    .submit {
      background-color: ${(props) => props.theme.color.darkPrimaryLabel};
      color: ${(props) => props.theme.color.fgDefault};
    }
  }

  .modal-icons {
    color: white;
    fill: white;
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: stretch;
  button {
    flex-basis: 100%;
  }
`;

const REPORT_DOWNLOAD_MESSAGE = `Some records may have input errors or unusual values.  You can download the error reporting file and correct the noted errors for re-upload`;

export {
  Card,
  CardHeading,
  CardInstructions,
  Table,
  ButtonGroup,
  SubmitButton,
  TitleRow,
  ValuesRow,
  StyledFooter,
  REPORT_DOWNLOAD_MESSAGE,
  UploadedFileHeaderMapSelector,
};
