import { State } from 'configs/states';

export const statesMatrix: Record<State, {x: number, y: number}> = {
  'AK': { x: 0, y: 0 },
  'ME': { x: 11, y: 0 },
  'WI': { x: 6, y: 1 },
  'VT': { x: 10, y: 1 },
  'NH': { x: 11, y: 1 },
  'WA': { x: 1, y: 2 },
  'ID': { x: 2, y: 2 },
  'MT': { x: 3, y: 2 },
  'ND': { x: 4, y: 2 },
  'MN': { x: 5, y: 2 },
  'IL': { x: 6, y: 2 },
  'MI': { x: 7, y: 2 },
  'NY': { x: 9, y: 2 },
  'MA': { x: 10, y: 2 },
  'OR': { x: 1, y: 3 },
  'NV': { x: 2, y: 3 },
  'WY': { x: 3, y: 3 },
  'SD': { x: 4, y: 3 },
  'IA': { x: 5, y: 3 },
  'IN': { x: 6, y: 3 },
  'OH': { x: 7, y: 3 },
  'PA': { x: 8, y: 3 },
  'NJ': { x: 9, y: 3 },
  'CT': { x: 10, y: 3 },
  'RI': { x: 11, y: 3 },
  'CA': { x: 1 , y: 4 },
  'UT': { x: 2 , y: 4 },
  'CO': { x: 3 , y: 4 },
  'NE': { x: 4 , y: 4 },
  'MO': { x: 5 , y: 4 },
  'KY': { x: 6 , y: 4 },
  'WV': { x: 7 , y: 4 },
  'VA': { x: 8 , y: 4 },
  'MD': { x: 9 , y: 4 },
  'DE': { x: 10, y: 4 },
  'AZ': { x: 2 , y: 5 },
  'NM': { x: 3 , y: 5 },
  'KS': { x: 4 , y: 5 },
  'AR': { x: 5 , y: 5 },
  'TN': { x: 6 , y: 5 },
  'NC': { x: 7 , y: 5 },
  'SC': { x: 8 , y: 5 },
  'DC': { x: 9 , y: 5 },
  'OK': { x: 4 , y: 6 },
  'LA': { x: 5 , y: 6 },
  'MS': { x: 6 , y: 6 },
  'AL': { x: 7 , y: 6 },
  'GA': { x: 8 , y: 6 },
  'HI': { x: 0 , y: 7 },
  'TX': { x: 4 , y: 7 },
  'FL': { x: 9 , y: 7 },
};