import { getStringAsKeyOf } from 'functions/typeUtils';
import { ASSET_CLASS_IDS } from 'models/AssetClass';
import { useMatch, useSearchParams } from 'react-router-dom';
import { MainRouteKeys, PortfolioRouteKeys, PORTFOLIO_ROUTES_CONFIG, MarketplaceRouteKeys, MAIN_ROUTES_CONFIG, mainRouteMatch, PAGE_PARAM, FILTERS_PARAM } from './navigation.config';
import { filterValuesToSearchQuery, searchQueryToFilterValues, FilterValue } from 'features/common-elements';
import { PortfolioFilterConfig, abbrToFilterField, filterFieldToAbbr } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';

export function getMainRoute(mainRouteKey: MainRouteKeys) {
  return `${mainRouteKey}/*`;
}

export function getPortfolioRoute(portfolioRouteKey: PortfolioRouteKeys) {
  const configFor2ndLevel = PORTFOLIO_ROUTES_CONFIG[portfolioRouteKey];
  // if (configFor2ndLevel.usesAssetClass) {
  //   return `${portfolioRouteKey}/:${MAIN_ROUTES_CONFIG.portfolio.assetClassParam}?/*`;
  // }
  return `${portfolioRouteKey}/*`;
}

export function getMarketPlaceRoute(marketplaceRouteKey: MarketplaceRouteKeys) {
  return `${marketplaceRouteKey}/*`;
}

export function useCurrent2ndLevelRuoteConfig(mainRouteKey: MainRouteKeys) {
  const match = useMatch(mainRouteMatch[mainRouteKey]);
  const page = match?.params?.[PAGE_PARAM];
  if (!page) {
    return null;
  }
  const pageRouteKey = getStringAsKeyOf(PORTFOLIO_ROUTES_CONFIG, page.toLowerCase());
  if (!pageRouteKey) {
    return null;
  }
  return PORTFOLIO_ROUTES_CONFIG[pageRouteKey];
}

export function usePortfolioAssetClass() {
  const portfolioMatch = useMatch(mainRouteMatch.portfolio);
  const assetClass = portfolioMatch?.params['*']?.split('/')[0];
  return getStringAsKeyOf(ASSET_CLASS_IDS, assetClass?.toLowerCase());
}

export function usePortfolioFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filtersStr = searchParams.get(FILTERS_PARAM);
  const currentFilters = filtersStr ? searchQueryToFilterValues(filtersStr, abbrToFilterField) : [];

  function setFilters(filters: FilterValue<PortfolioFilterConfig>[]) {
    const query = filterValuesToSearchQuery(filters, filterFieldToAbbr);
    const params = new URLSearchParams(searchParams);
    params.set(FILTERS_PARAM, query);
    setSearchParams(params, { preventScrollReset: true });
  }

  return [currentFilters, setFilters] as const;
}

export function useSortingHelper<Key extends string>(){
  const [searchParams, setSearchParams] = useSearchParams();
  const sortStr = searchParams.get('sort')?.split(',') || [];
  const sort = sortStr.map(str => {
    const [field, desc] = str.split('^');
    return {
      id: field as Key,
      desc: desc === 'true' ? true : false
    };
  });

  function setSort(sortArr: {id: Key, desc: boolean}[]){
    const str = sortArr.map(it => `${it.id}^${it.desc ? 'true': 'false'}`).join(',');
    const params = new URLSearchParams(searchParams);
    params.set('sort', str);
    setSearchParams(params, { preventScrollReset: true });
  }

  return [sort, setSort] satisfies [{ id: Key, desc: boolean }[], (state: { id: Key, desc: boolean }[]) => void];
}