import { gql } from '@apollo/client';
import LoanDatatableFragments from 'features/drilldown/LoanDatatable/fragments';
import { StratificationsFragments } from 'features/drilldown/Stratifications';
import { PoolSummaryFragments } from 'features/loanPool/PoolSummary';

export const GET_DEAL_LOANS_QUERY = gql`
  query GetDealLoans(
    $id: ID!
    $sort: Sort!
    $pagination: Pagination!
    $filters: [Filter!]
  ) {
    deal(id: $id) {
      id
      loans(sort: $sort, pagination: $pagination, filters: $filters) {
        id
        ...LoanDatatableLoan
      }
    }
    }
    ${LoanDatatableFragments.loan}`;


export const GET_DEAL_STRATS_QUERY = gql`
  query GetDealStrats($id: ID!) {
    deal(id: $id) {
      id
      performanceData: performance_summary {
        ...PoolSummaryPerformanceSummary
        ...StratificationsPerformanceSummary
      }
    }
  }
  ${PoolSummaryFragments.performanceSummary}
  ${StratificationsFragments.performanceSummary}
`;