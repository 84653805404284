import { ResponsiveBar, BarDatum, BarSvgProps, BarTooltipProps, BarCustomLayerProps } from '@nivo/bar';
import { useTheme } from 'styled-components';
import {chartColors, chartTheme, CommonChartProps, formatValue, StyledTooltip} from './chartsCommons';
import { useIntl } from 'react-intl';
import { PropsWithChildren } from 'react';
import { Margin } from '@nivo/core';

export function BarChart<T>(props: CommonChartProps<T> & {
  yAxisLabel: string;
  layer?: (props: BarCustomLayerProps<BarDatum>) => JSX.Element;
  chartMargin?: Partial<Margin>
  hideTooltip?: boolean;
}) {
  const theme = useTheme();
  const intl = useIntl();

  const passedInLayer = props.layer || (() => null);
  return (
    <ResponsiveBar
      data={props.data as BarDatum[]}
      indexBy={props.keyField as BarSvgProps<BarDatum>['indexBy']}
      keys={[(props.valueField as string) || 'value']}
      groupMode="grouped"
      margin={{
        top: 20,
        right: 20,
        bottom: 80,
        left: 80,
        ...props.chartMargin,
      }}
      colorBy="indexValue"
      enableLabel={false}
      enableGridY={false}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        format: props.formatKey,
        tickRotation: -45,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        format: (value) =>
          formatValue(intl, value, {
            ...props.valueFormatOptions,
            notation: 'compact',
          }),
        legend: props.yAxisLabel,
        legendPosition: 'middle',
        legendOffset: -60,
      }}
      theme={chartTheme(theme)}
      colors={chartColors(theme)}
      borderRadius={4}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
      padding={0.4}
      valueFormat={(value) =>
        formatValue(intl, value, props.valueFormatOptions)
      }
      tooltip={props.hideTooltip ? () => null : BarTooltip}
      layers={['grid', 'axes', 'bars', passedInLayer]}
    />
  );
}

function BarTooltip(props: PropsWithChildren<BarTooltipProps<BarDatum>>) {
  if (!props.value) return null;
  return <StyledTooltip>{props.formattedValue}</StyledTooltip>;
}
