import React, { InputHTMLAttributes } from 'react';
import { styled } from 'style/ORSNNTheme';
import { css } from 'styled-components';

type InputProps = {
  withPrefix: boolean;
  withPostfix?: boolean;
  error?: boolean;
  disabled?: boolean;
};

const Input = styled.input<InputProps>`
  background-color: ${(props) => props.theme.color.inputBgDefault};
  border-radius: 0;
  border: 1px solid
    ${(props) =>
      props.error
        ? props.theme.color.errorDefault
        : props.disabled
        ? props.theme.color.inputBorderDisabled
        : props.theme.color.inputBorderDefault};
  color: ${(props) =>
    props.disabled
      ? props.theme.color.inputFgDisabled
      : props.theme.color.inputText};

  &:focus {
    box-shadow: none;
  }
  width: 100%;
  padding-left: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  flex: 1 1;
  min-width: 0;
  margin-bottom: 0;
  ${(props) =>
    props.withPrefix &&
    css`
      border-left: none;
    `};
  ${(props) =>
    props.withPostfix &&
    css`
      border-right: none;
    `};
`;
const TextInput = styled(Input).attrs({ type: 'text' })``;
const PasswordInput = styled(Input).attrs({ type: 'password' })``;

/*
 * Since no-one is using NumberInput but me (kentskinner), I am going to update it to the new theme colors.
 */
const NumberInput = styled(Input).attrs({ type: 'number' })`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  border: 1px solid
    ${(props) =>
      props.error
        ? props.theme.color.errorDefault
        : props.disabled
        ? props.theme.color.inputBorderDisabled
        : props.theme.color.slate400};

  color: ${(props) =>
    props.error
      ? props.theme.color.errorDefault
      : props.disabled
      ? props.theme.color.inputFgDisabled
      : props.theme.color.inputText};
`;

const Prefix = styled.div`
  border: ${(props) => props.theme.color.inputBorderDefault} 1px solid;
  border-right: none;
  background-color: ${(props) => props.theme.color.inputBgDefault};
  display: flex;
  align-items: center;
  padding: 0 0.25rem;
  color: ${(props) => props.theme.color.inputText};
`;

const Postfix = styled.div`
  border: ${(props) => props.theme.color.inputBorderDefault} 1px solid;
  border-left: none;
  background-color: ${(props) => props.theme.color.inputBgDefault};
  display: flex;
  align-items: center;
  padding: 0 0.25rem;
  color: ${(props) => props.theme.color.inputText};
`;

const InputGroup = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.25rem;
`;

const StandardTextInput = styled(TextInput)``;

/**
 * NumberInputWithUnits component.
 *
 * This component extends the functionality of a standard input field to include
 * a unit label which can be aligned either to the left or the right of the input field.
 */

const InputWrapper = styled.div`
  position: relative;

  // min-width of 82px is just enough to fit 999.99
  min-width: 82px;
`;

const InputUnitLabel = styled.label<{ error?: boolean; disabled?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 2px;
  color: ${(props) =>
    props.error
      ? props.theme.color.errorDefault
      : props.disabled
      ? props.theme.color.inputFgDisabled
      : props.theme.color.inputText};
`;

interface NumberInputWithUnitsProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  unit: string;
  leftAligned?: boolean;
  style?: React.CSSProperties;
  error: boolean;
}
const NumberInputWithUnits: React.FC<NumberInputWithUnitsProps> = ({
  unit,
  leftAligned = false,
  style = {},
  error,
  disabled,
  ...props
}) => {
  const inputStyle: React.CSSProperties = {
    paddingRight: leftAligned ? '0px' : '26px',
    paddingLeft: leftAligned ? '26px' : '0px',
    textAlign: leftAligned ? 'left' : 'right',
    ...style,
  };

  return (
    <InputWrapper>
      {leftAligned && (
        <InputUnitLabel style={{ left: 10 }} error={error} disabled={disabled}>
          {unit}
        </InputUnitLabel>
      )}
      <NumberInput
        style={inputStyle}
        withPrefix={false}
        error={error}
        disabled={disabled}
        {...props}
      />
      {!leftAligned && (
        <InputUnitLabel style={{ right: 10 }} error={error} disabled={disabled}>
          {unit}
        </InputUnitLabel>
      )}
    </InputWrapper>
  );
};

// Deprecated

interface NumberInputProps {
  value: number | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CurrencyInput: React.FC<NumberInputProps> = ({ value, onChange }) => {
  return (
    <NumberInputWithUnits
      unit="$"
      leftAligned={true}
      value={value ?? ''}
      onChange={onChange}
      error={false}
    />
  );
};

const PercentageInput: React.FC<NumberInputProps> = ({ value, onChange }) => {
  return (
    <NumberInputWithUnits
      unit="%"
      leftAligned={false}
      value={value ?? ''}
      onChange={onChange}
      error={false}
    />
  );
};

export {
  InputGroup,
  TextInput,
  PasswordInput,
  NumberInput,
  Prefix,
  Postfix,
  Input,
  CurrencyInput,
  PercentageInput,
  NumberInputWithUnits,
  StandardTextInput,
};
