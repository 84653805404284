import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Avatar from '../Avatar/Avatar';
import { SvgIcon } from '../Icons/SvgIcon/SvgIcon';
import './dropdown.scss';

type Icon = 'avatar' | 'svg';
type DropdownPosition = 'right' | 'left';

type DropdownOption = {
  name: string;
  id: string;
  onClick: () => void; // function called for selected option
};

export interface DropdownProps {
  options: DropdownOption[];
  label: string;
  iconType?: Icon;
  iconName?: string;
  position: DropdownPosition;
}

export const DeprecatedDropdown = ({
  options,
  label,
  position = 'left',
  iconType,
  iconName,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // outside click handling
  useEffect(() => {
    const handler = () => setIsOpen(false);

    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const getIconContent = () => {
    if (iconType === 'svg' && iconName) {
      return (
        <span className="dropdown__icon">
          <SvgIcon name={iconName} />
        </span>
      );
    }

    if (iconType === 'avatar') {
      return (
        <span className="dropdown__avatar">
          <Avatar name={label} />
        </span>
      );
    }
  };

  return (
    <div className="dropdown">
      <button className="dropdown__button" onClick={handleDropdownClick}>
        {getIconContent()}
        <span className="dropdown__content">{label}</span>
        <SvgIcon name="chevron-down" />
      </button>
      {isOpen && (
        <ul
          className={classnames('dropdown__container', {
            'dropdown__container--left': position === 'left',
            'dropdown__container--right': position === 'right',
          })}
        >
          {options.map((option) => {
            return (
              <li key={option.id} className="dropdown__option">
                <button
                  className="dropdown__option_button"
                  onClick={option.onClick}
                >
                  {option.name}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export type { DropdownOption };
