import {
  ApolloQueryResult,
  MutationUpdaterFn,
  useMutation,
} from '@apollo/client';
import DELETE_S3_FILE_MUTATION from 'mutation/deleteS3File';
import GET_FILE_POST_URLS_MUTATION from 'mutation/getFilePostUrls';
import {
  DeleteS3File,
  DeleteS3FileVariables,
} from 'mutation/__generated__/DeleteS3File';
import {
  GetFilePostUrls,
  GetFilePostUrlsVariables,
} from 'mutation/__generated__/GetFilePostUrls';
import { GET_DEAL_DOCUMENTS } from 'query/deal';
import {
  GetDealDocuments,
  GetDealDocumentsVariables,
} from 'query/__generated__/GetDealDocuments';
import { useCallback, useEffect, useState } from 'react';
import {
  DropzoneInputProps,
  DropzoneRootProps,
  useDropzone,
} from 'react-dropzone';
import uuid from 'uuid/v4';
import { FileType } from '__generated__/globalTypes';

enum UploadStatus {
  Pending,
  InProgress,
  Complete,
  Error,
}

export type FileStatus = {
  id: string;
  name: string;
  size: number;
  type: string;
  uploadStatus: UploadStatus;
  blob?: File;
};

// This is intended to be used in useEffect hooks
// Example:
//   useEffect(
//     () => actionWhenConditionMet(stopPolling, DealStatus.COMPLETE == data?.deal?.state.status),
//     [dependencies])
// The code above will execute stopPolling when deal status is COMPLETE
function actionWhenConditionMet(action: Function, condition: boolean) {
  console.log({ condition });
  if (true === condition) {
    action();
  }
  return () => {
    action();
  };
}

function updateFileInStatusList(
  filesList: FileStatus[],
  fileId: string,
  uploadStatus: UploadStatus
): FileStatus[] {
  const fileIndex = filesList.findIndex((file) => file.id === fileId);
  const file = filesList[fileIndex];
  return [
    ...filesList.slice(0, fileIndex),
    { ...file, uploadStatus },
    ...filesList.slice(fileIndex + 1),
  ];
}

function sendFile(file: File, postUrl: string) {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', postUrl);
  xhr.send(file);
}

const allowedFileMimeTypes = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/jpeg',
  'image/png',
  'text/comma-separated-values',
];

type UploadZoneHook = {
  uploadFiles: FileStatus[];
  getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
  isDragActive: boolean;
  deleteFile: (id: string) => void;
};

const useUploadZone = (
  entityId: string,
  fileType: FileType,
  variablesList: GetDealDocumentsVariables[],
  refetch?: (
    variables?: Partial<GetDealDocumentsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetDealDocuments> | undefined>
  // TODO remove refetch function and just use variablesList https://orsnn.atlassian.net/browse/ORSNN-943
): UploadZoneHook => {
  const [uploadFiles, setUploadFiles] = useState<Array<FileStatus>>([]);
  const [getFilePostUrl] = useMutation<
    GetFilePostUrls,
    GetFilePostUrlsVariables
  >(GET_FILE_POST_URLS_MUTATION);
  const [deleteS3File] = useMutation<DeleteS3File, DeleteS3FileVariables>(
    DELETE_S3_FILE_MUTATION
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const fileStatuses = acceptedFiles.map((file) => ({
        id: uuid(),
        name: file.name,
        size: file.size,
        type: file.type,
        uploadStatus: UploadStatus.Pending,
        blob: file,
      }));
      setUploadFiles((previousFiles) => [...previousFiles, ...fileStatuses]);
      async function uploadAcceptedFiles() {
        fileStatuses.forEach(async (file) => {
          setUploadFiles((previousUploadFiles) =>
            updateFileInStatusList(
              previousUploadFiles,
              file.id,
              UploadStatus.InProgress
            )
          );
          const update: MutationUpdaterFn<GetFilePostUrls> = async (
            _cache,
            result
          ) => {
            if (result.data != null) {
              sendFile(file.blob, result.data.getFilePostUrl);
              setUploadFiles((previousUploadFiles) =>
                updateFileInStatusList(
                  previousUploadFiles,
                  file.id,
                  UploadStatus.Complete
                )
              );
            } else {
              setUploadFiles((previousUploadFiles) =>
                updateFileInStatusList(
                  previousUploadFiles,
                  file.id,
                  UploadStatus.Error
                )
              );
            }
          };
          getFilePostUrl({
            variables: {
              parentId: entityId,
              fileType,
              fileName: file.name,
              contentType: file.type,
            },
            update,
            refetchQueries: () =>
              variablesList.map((variables) => ({
                query: GET_DEAL_DOCUMENTS,
                variables,
              })),
          });
        });
      }
      uploadAcceptedFiles();
    },
    [setUploadFiles, entityId, getFilePostUrl, fileType, variablesList]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    accept: allowedFileMimeTypes,
    onDrop,
  });

  useEffect(() => {
    const incompleteFiles = uploadFiles.filter(
      (file) => file.uploadStatus !== UploadStatus.Complete
    );
    if (incompleteFiles.length !== uploadFiles.length) {
      setTimeout(() => {
        refetch && refetch({ id: entityId, fileType }); // TODO use apolloclient v3 https://orsnn.atlassian.net/browse/ORSNN-943
      }, 3000);
    }
  }, [entityId, uploadFiles, refetch, fileType]);

  function deleteFile(id: string) {
    const deletedFile = uploadFiles.find((file) => file.id === id);
    const deletedFileIndex = uploadFiles.findIndex((file) => file.id === id);
    if (deletedFile == null) return;
    setUploadFiles((previousUploadFiles) =>
      updateFileInStatusList(
        previousUploadFiles,
        deletedFile.id,
        UploadStatus.InProgress
      )
    );
    deleteS3File({
      variables: {
        parentId: entityId,
        fileType,
        fileName: deletedFile.name,
      },
      refetchQueries: () =>
        variablesList.map((variables) => ({
          query: GET_DEAL_DOCUMENTS,
          variables,
        })),
      update: () => {
        setUploadFiles((previous) => {
          const newFiles = previous;
          newFiles.splice(deletedFileIndex, 1);
          return newFiles;
        });
      },
    });
  }

  return {
    uploadFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    deleteFile,
  };
};

export { useUploadZone, UploadStatus, actionWhenConditionMet };
