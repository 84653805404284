export const PREDEFINED_PARTICIPATION_PERCENTS = [98, 95];
export const DEFAULT_PARTICIPATION_PERCENT =
  PREDEFINED_PARTICIPATION_PERCENTS[0];
export const MINIMUM_PARTICIPATION_PERCENT = 0.0;
export const MAXIMUM_PARTICIPATION_PERCENT = 100.0;

export const PREDEFINED_SERVICING_PRICES = [0.25, 0.5];
export const DEFAULT_SERVICING_PRICE = PREDEFINED_SERVICING_PRICES[0];
export const MINIMUM_SERVICING_PRICE = 0.25;
export const MAXIMUM_SERVICING_PRICE = 5.0;
