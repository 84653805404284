import {
  typographyBadgeXSmallCondensedBold,
  typographyBodyPrimaryMedium,
} from 'common-ui/typography';
import { styled } from 'style/ORSNNTheme';

export const HomePage = styled.div`
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  width: 612px;
`;

export const Charts = styled.div`
  display: flex;
  gap: 10px;
  margin: 5px 0 20px;
`;

export const Chart = styled.div`
  width: 602px;
  height: 387px;
  background-color: ${(props) => props.theme.color.bgSurface};
  border: 1px solid ${(props) => props.theme.color.accentDisabled};
  border-radius: 7px;
  padding: 12px;
`;

export const SectionTitle = styled.div`
  font-size: 24px;
  line-height: 30px;
  margin: 40px 0 32px 0;
  color: ${(props) => props.theme.color.fgDefault};
`;

export const News = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.theme.color.fgDefault};
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const StyledProminentNewsItem = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  padding-bottom: 25px;
`;
export const StyledRegularNewsItem = styled.div`
  height: 188px;
  position: relative;

  // background-color: ${(props) => props.theme.color.bgSurface};
  border: 1px solid ${(props) => props.theme.color.accentMuted};
  border-color: linear-gradient(
    106.57deg,
    rgba(21, 85, 236, 0.2) -57.01%,
    rgba(255, 35, 225, 0.2) 95.42%
  );
  border-radius: 8px;
  width: 23%;

  padding: 10px 20px 0 20px;
`;

export const StyledNewsListItem = styled.div`
  width: 100%;
  height: 112px;
  border: 1px solid rgba(21, 85, 236, 0.2);
  border-radius: 8px;
  padding: 7px 0 0 14px;
  ${typographyBodyPrimaryMedium}
  position: relative;
`;

export const NewsSource = styled.span`
  color: ${(props) => props.theme.color.infoDefault};
  background-color: ${(props) => props.theme.color.infoSubtle};
  border-radius: 3px;
  padding: 1px 4px 1px 4px;
  ${typographyBadgeXSmallCondensedBold}
`;

export const PublicationDate = styled.span`
  color: ${(props) => props.theme.color.slate50};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 5px;
`;
export const Subject = styled.div`
  margin: 20px 0;
  color: ${(props) => props.theme.color.slate100};
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
`;
export const Summary = styled.div`
  color: ${(props) => props.theme.color.slate50};
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin: 20px 0;
`;

export const ReadTime = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.color.slate100};
`;

export const NumberOfViews = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.color.accentEmphasis};
  padding-left: 10px;
`;

export const ReadTimeAndViewsLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
`;

export const SmallTitle = styled.div`
  margin-top: 2px;
  // color: ${(props) => props.theme.color.slate100};
  color: '#fff';
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  // height: 87px;
  // overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

export const StyledChart = styled.div`
  h2 {
    color: ${(props) => props.theme.color.slate100};
    font-size: 18px;
    text-wrap: nowrap;
    letter-spacing: 0.5px;
  }

  font-size: 10px;
`;
