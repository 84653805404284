import React from 'react';
import {
  useFloating,
  useDismiss,
  useInteractions,
  FloatingPortal,
  FloatingFocusManager,
} from '@floating-ui/react';
import { ProgressIndicator } from 'common-ui/ProgressIndicator/ProgressIndicator';
import { BaseButton } from 'common-ui';
import {
  Overlay,
  OverlayContent,
  IconContainer,
  Title,
  Description,
  ProgressWrapper,
  ButtonWrapper,
  LoadAnim,
  SuccessIcon,
} from './InProgressOverlay.styles';

interface InProgressOverlayProps {
  open: boolean;
  cancel: () => void;
  onSuccessClicked: () => void;
  title: string;
  description?: string;
  progress: number;
  cancelButtonText?: string;
  successButtonText?: string;
  isSuccess?: boolean;
}

export const InProgressOverlay = ({
  open,
  cancel,
  onSuccessClicked,
  title,
  description,
  progress,
  cancelButtonText = 'Cancel', // default value
  successButtonText = 'OK', // default value
  isSuccess,
}: InProgressOverlayProps) => {
  const { context } = useFloating({
    open,
    onOpenChange: (open) => open || cancel(),
  });

  const dismiss = useDismiss(context, { outsidePress: false });
  const { getFloatingProps } = useInteractions([dismiss]);

  if (!open) {
    return null;
  }

  const Icon = isSuccess ? SuccessIcon : LoadAnim;
  const buttonType = isSuccess ? 'primary' : 'secondary';
  const buttonText = isSuccess ? successButtonText : cancelButtonText;
  const onButtonClicked = isSuccess ? onSuccessClicked : cancel;

  return (
    <FloatingPortal>
      <Overlay>
        <FloatingFocusManager context={context}>
          <OverlayContent {...getFloatingProps()}>
            <IconContainer>
              <Icon />
            </IconContainer>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <ProgressWrapper>
              <ProgressIndicator percent={progress} />
            </ProgressWrapper>
            <ButtonWrapper>
              <BaseButton
                type={buttonType}
                label="action"
                onClick={onButtonClicked}
                size="medium"
              >
                {buttonText}
              </BaseButton>
            </ButtonWrapper>
          </OverlayContent>
        </FloatingFocusManager>
      </Overlay>
    </FloatingPortal>
  );
};
