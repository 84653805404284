import Header from 'app-level/HeaderNav/Header/Header';
import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import BaseWeb from 'style/BaseWeb';
import 'style/main.css';
import 'style/main.scss'; // TODO: Remove once we migrate from SCSS into styled-components
import ORSNNTheme, { styled, theme } from 'style/ORSNNTheme';
import UserAuthentication from './features/UserAuthentication';
import { AuthContext, AuthState } from './context/AuthContext';
import { DataContextProvider } from './context/DataContext';
import Home from 'features/pages/home/Home';
// new pages
import Marketplace from 'features/pages/marketplace/Marketplace';
import MarketData from 'features/pages/market-data/MarketData';
import Portfolio from 'features/pages/portfolio/Portfolio/Portfolio';
import {getMainRoute, useGetMainPageUrl, NavigationStateProvider} from 'app-level/navigation';
import { StyledTooltip } from 'common-ui';

const StyledApp = styled.div`
  height: 100%;
  margin: 0;
  background-color: ${theme.color.bgApp};
  transition: all 0.3s;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.88px;
  color: ${theme.color.fgDefault};

  #bui-1 {
    // required for headernav popover to show above everything
    z-index: 1;
  }
`;

const AppLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .page-container {
    flex: 1;
    overflow: auto;
  }
`;

const App = (): JSX.Element => {
  const { state } = useContext(AuthContext);

  const isUserSignedIn =
    [AuthState.SignedIn].includes(state.authState) && state.user != null;

  const getUrl = useGetMainPageUrl();
  

  const authenticatedApp = (
    <NavigationStateProvider>
      <AppLayout>
        <Header />
        <div className="page-container">
          <DataContextProvider>
            <Routes>
              <Route path={getMainRoute('portfolio')} element={<Portfolio />} />
              <Route
                path={getMainRoute('marketplace')}
                element={<Marketplace />}
              />
              <Route
                path={getMainRoute('market_data')}
                element={<MarketData />}
              />
              <Route path={getMainRoute('home')} element={<Home />} />
              <Route
                path="*"
                element={<Navigate to={getUrl('home')} replace />}
              />
            </Routes>
          </DataContextProvider>
        </div>
      </AppLayout>
    </NavigationStateProvider>
  );
  return (
    <StyledApp>
      <ORSNNTheme>
        <BaseWeb>
          {isUserSignedIn ? authenticatedApp : <UserAuthentication />}
          <StyledTooltip id="tooltip"/>
        </BaseWeb>
      </ORSNNTheme>
    </StyledApp>
  );
};

export default App;
