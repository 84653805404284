
import { gql } from '@apollo/client';

const GET_LISTINGS = gql`
  query GetListings {
    user {
      id
      company {
        id
        listings {
          id
          name
          asset_class
          created_date
          provided_name
          loan_count
          ... on PublicListing {
            servicing_fee_rate
          }
          t_current_balance_cents
          wa_coupon
          wa_loan_age_months
          wa_remaining_loan_terms_months
          wa_ltv
          wa_dti
          ... on UserCompanyListing {
            is_public
          }
        }
      }
    }
  }
`;

export { GET_LISTINGS };