import { createContext, useContext } from 'react';
import { BidDetails } from './BidDetails';

interface BiddingPricingContextProps {
  bidDetails?: BidDetails;
  updateBidDetails: (bidDetails: BidDetails) => void;
}

const BiddingPricingContext = createContext<
  BiddingPricingContextProps | undefined
>(undefined);

export const useBiddingPricingContext = () => {
  const context = useContext(BiddingPricingContext);
  if (!context) {
    throw new Error(
      'This component must be used within a BiddingPricingContext.Provider'
    );
  }
  return context;
};

export default BiddingPricingContext;
