import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumberInputWithUnits } from 'common-ui/inputs/Inputs-styled';
import ErrorMessage from '../../../../common-ui/form/ErrorMessage';

interface CashFlowModelingInputProps {
  fieldName: string;
  value: string;
  onValueChange?: (value: string) => void;
  unit?: string;
  max?: number;
}
const validateInput = (inputValue: string, max: number) => {
  const value = Number(inputValue);
  return !isNaN(value) && value >= 0 && value <= max;
};

const CashFlowModelingInput: React.FC<CashFlowModelingInputProps> = ({
  fieldName,
  value,
  onValueChange,
  unit = '%',
  max = 100,
}) => {
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  useEffect(() => {
    setIsValid(validateInput(value, max));
  }, [value, max]);

  return (
    <Container>
      <Header>
        <Label>{fieldName}</Label>
        <ValidationIcon>
          <FontAwesomeIcon
            icon={isValid ? faCircleCheck : faCircleXmark}
            color={isValid ? '#9BDB72' : '#EB5757'}
          />
        </ValidationIcon>
      </Header>
      <StyledNumberInputWithUnits
        unit={unit}
        value={value}
        error={!isValid}
        onChange={handleInputChange}
      />
      <ErrorMessage>
        {!isValid && (
          <div>
            <span>Value between</span>
            <br />
            <span>0-{max}</span>
          </div>
        )}
      </ErrorMessage>
    </Container>
  );
};

const ValidationIcon = styled.div``;

const Container = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledNumberInputWithUnits = styled(NumberInputWithUnits)`
  width: 132px;
  height: 28px;
  border-radius: 3px;
  font-size: 12px;
`;

const Label = styled.label`
  flex: 1;
  text-align: left;
`;

export default CashFlowModelingInput;
