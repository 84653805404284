import { gql } from '@apollo/client';

// newsArticles(sort: NewsSort!, filters: [NewsFilter!], pagination: Pagination): [NewsArticle!]!

const GET_NEWS_ARTICLES = gql`
  query GetNewsArticles(
    $sort: NewsSort!
    $filters: [NewsFilter!]
    $pagination: Pagination
  ) {
    newsArticles(sort: $sort, filters: $filters, pagination: $pagination) {
      id
      publication_date
      subject
      url
      sentiment_score
      summary
      news_category
      news_source
    }
  }
`;

export { GET_NEWS_ARTICLES };
