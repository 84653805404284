import { BaseButton } from 'common-ui';
import { DialogActions, StyledHeading } from 'common-ui/Dialog.styles';
import bidSuccessImgPath from 'assets/pngs/bid-success.png';

export interface BidSucceededDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const BidSucceededDialog: React.FC<BidSucceededDialogProps> = ({
  onConfirm,
  onCancel,
}) => {
  return (
    <>
      <img src={bidSuccessImgPath} alt="bid succeeded" />{' '}
      <StyledHeading>Your Bid was successful!</StyledHeading>
      <div style={{ width: 290 }}>
        Would you like to track your bid or return back to the Marketplace?
      </div>
      <DialogActions>
        <BaseButton
          type="secondary"
          size="medium"
          label="cancel"
          onClick={() => onCancel()}
        >
          Marketplace
        </BaseButton>
        <BaseButton size="medium" label="confirm" onClick={() => onConfirm()}>
          <b>Active Trades</b>
        </BaseButton>
      </DialogActions>
    </>
  );
};
