import React from 'react';
import { CartesianGrid, Legend, ReferenceLine } from 'recharts';
import { LineChart, Line, XAxis, YAxis, Tooltip, Label } from 'recharts';
import { BidDetails } from './BidDetails';
import styled from 'styled-components';

const AXIS_COLOR = '#868e95';
const PRICE_REFERENCE_COLOR = '#ffcf74';
const TITLE_COLOR = '#e8e9ec';
const LEGEND_TEXT_COLOR = '#e8e9ec';

interface TooltipProps {
  active?: boolean;
  payload?: Array<{ name: string; value: number; color: string }>;
  label?: string;
}

const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const roundedLabel = parseFloat(label || '').toFixed(2);
    return (
      <div style={tooltipStyle}>
        <p style={{ margin: 0, color: '#28303e' }}>Price: {roundedLabel}</p>
        {payload.map((item, index) => (
          <p key={index} style={{ color: item.color, margin: 0 }}>
            {`${item.name} : ${
              item.name.includes('Spread')
                ? item.value.toFixed(0) + ' bps'
                : item.value.toFixed(3) + ' %'
            }`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const tooltipStyle: React.CSSProperties = {
  backgroundColor: '#e8e9ec',
  border: 'none',
  borderRadius: '4px',
  padding: '10px',
  position: 'relative',
};

interface DataPoint {
  price: number;
  Yield: number;
  'Spread to spot': number;
  'Spread to forward': number;
}

const generateData = () => {
  const data: DataPoint[] = [];

  for (let price = 100; price <= 106; price += 0.1) {
    data.push({
      price,
      Yield: 5.75 + (-0.6 * (price - 100) + 0.02 * Math.pow(price - 100, 2)),
      'Spread to spot':
        (6.0 + (-0.5 * (price - 100) + 0.02 * Math.pow(price - 100, 2))) * 100,
      'Spread to forward':
        (6.125 + (-0.45 * (price - 100) + 0.02 * Math.pow(price - 100, 2))) *
        100,
    });
  }

  return data;
};

const data: DataPoint[] = generateData();

interface YieldToPriceChartProps {
  bidDetails: BidDetails;
  updateBidDetails: (details: BidDetails) => void;
}

const LineChartContainer = styled.div`
  border: 1px solid #3b4351;
  border-radius: 4px;
  padding: 15px 10px;
  background-color: #1f1f1f;
`;

const YieldToPriceChart: React.FC<YieldToPriceChartProps> = (props) => {
  const bidPx = props.bidDetails.bidPx;

  const handleChartClick = (e: any) => {
    if (e && e.activePayload && e.activePayload.length > 0) {
      const price = parseFloat(e.activePayload[0].payload.price.toFixed(2));
      props.updateBidDetails({
        ...props.bidDetails,
        bidPx: price,
      });
    }
  };

  return (
    <LineChartContainer>
      <LineChart
        width={555}
        height={384}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 5,
        }}
        onClick={handleChartClick}
      >
        <CartesianGrid stroke="#3b4351" opacity="30%" />
        <XAxis
          stroke={AXIS_COLOR}
          dataKey="price"
          padding={{ left: 30, right: 30 }}
          type="number"
          domain={[100, 106]}
          label={{ value: 'Price' }}
        />

        <YAxis
          stroke={AXIS_COLOR}
          type="number"
          domain={[2.75, 6.5]}
          tickFormatter={(tick) => tick.toFixed(3)}
          tickCount={16}
        >
          <Label value="YIELD" offset={15} position="top" />
        </YAxis>

        <YAxis
          stroke={AXIS_COLOR}
          yAxisId="right"
          orientation="right"
          type="number"
          //  domain={[0, 10]} // Adjust the domain as per your data
          tickFormatter={(tick) => tick.toFixed(2)}
          tickCount={8}
        >
          <Label value="Z-SPREAD" offset={15} position="top" dx={-10} />
        </YAxis>

        <Legend
          verticalAlign="top"
          height={60}
          align="right"
          iconType="circle"
          formatter={(value, entry, index) => (
            <span style={{ color: LEGEND_TEXT_COLOR }}>{value}</span>
          )}
        />

        <Tooltip content={<CustomTooltip />} />

        <Line type="linear" dataKey="Yield" stroke="#3142c4" dot={false} />
        <Line
          type="linear"
          dataKey="Spread to spot"
          stroke="#e028dd"
          dot={false}
          yAxisId="right"
        />
        <Line
          type="linear"
          yAxisId="right"
          dataKey="Spread to forward"
          stroke="#117c3d"
          dot={false}
        />

        {bidPx !== null && (
          <ReferenceLine
            x={bidPx}
            stroke={PRICE_REFERENCE_COLOR}
            label={{
              stroke: PRICE_REFERENCE_COLOR,
              position: 'top',
              value: `${bidPx}`,
            }}
          />
        )}
        <text x={10} y={15} fill={TITLE_COLOR} style={{ fontSize: '14px' }}>
          YIELD TO PRICE
        </text>
      </LineChart>
    </LineChartContainer>
  );
};

export default YieldToPriceChart;
