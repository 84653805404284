import { useEffect } from 'react';
import Uppy, { SuccessResponse, UppyFile } from '@uppy/core';

interface UseUppyEventHandlersParams {
  uppy: Uppy;
  onFileAdded: (file: UppyFile) => void;
  onProgress: (progress: number) => void;
  onUploadSuccess: (
    file:
      | UppyFile<Record<string, unknown>, Record<string, unknown>>
      | undefined,
    response: SuccessResponse
  ) => Promise<void>;
}

const useUppyEventHandlers = ({
  uppy,
  onFileAdded,
  onProgress,
  onUploadSuccess,
}: UseUppyEventHandlersParams) => {
  useEffect(() => {
    uppy.on('file-added', onFileAdded);
    uppy.on('progress', onProgress);
    uppy.on('upload-success', onUploadSuccess);

    return () => {
      uppy.off('file-added', onFileAdded);
      uppy.off('progress', onProgress);
      uppy.off('upload-success', onUploadSuccess);
    };
  }, [uppy, onFileAdded, onProgress, onUploadSuccess]);
};

export default useUppyEventHandlers;
