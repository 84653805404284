import { gql } from '@apollo/client';
import EventActionCardFragments from './EventActionCards/fragments';

const DEAL_STAGES_FRAGMENT = gql`
  fragment DealStagesDeal on Deal {
    id
    state {
      label
      loanDataVisible
      tooltip
      status
    }
    cards {
      __typename
      title
      actions {
        title
        type
        emphasis
      }
      ...EventActionCardTimelineCard
    }
  }
  ${EventActionCardFragments.TimelineCard}
`;

const DealStagesFragments = {
  Deal: DEAL_STAGES_FRAGMENT,
};

const GET_DEAL_TIMELINE = gql`
  query GetTimeline($id: ID!) {
    deal(id: $id) {
      id
      ...DealStagesDeal
    }
  }
  ${DealStagesFragments.Deal}
`;

export default DealStagesFragments;
export { GET_DEAL_TIMELINE, DEAL_STAGES_FRAGMENT };
