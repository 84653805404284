import { StyledBody } from 'baseui/card';
import BiddingAndPricingSection from 'features/pages/marketplace/BiddingAndPricing/BiddingAndPricingSection';

type Props = {
  dealId: string;
};

const BiddingAndPricingCardBody = (props: Props): JSX.Element => {
  return (
    <StyledBody>
      <BiddingAndPricingSection dealId={props.dealId} />
    </StyledBody>
  );
};

export default BiddingAndPricingCardBody;
