import { typographyParagraphSmallPrimaryRegular } from 'common-ui/typography';
import styled from 'styled-components';

export const CriteriaLabel = styled.div`
  ${typographyParagraphSmallPrimaryRegular}
  margin: 15px 0 8px;
  display: flex;
  justify-content: space-between;
`;

export const CriteriaBox = styled.div`
  width: 391px;
`;

export const Subheader = styled.div`
  ${typographyParagraphSmallPrimaryRegular}
  margin: 0 0 12px 0;
`;
