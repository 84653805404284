import { useMutation } from '@apollo/client';
import { Button } from 'baseui/button';
import { StyledBody } from 'baseui/card';
import { Textarea } from 'baseui/textarea';
import { SUBMIT_DATA_REQUEST } from 'mutation/submitDataRequest';
import {
  SubmitDataRequest,
  SubmitDataRequestVariables,
} from 'mutation/__generated__/SubmitDataRequest';
import { useState } from 'react';
import { RequestDataCard_RequestDataCard } from './__generated__/RequestDataCard';

type Props = { dealId: string } & RequestDataCard_RequestDataCard;

const RequestDataCardBody = (props: Props): JSX.Element => {
  const [submitDataRequest] = useMutation<
    SubmitDataRequest,
    SubmitDataRequestVariables
  >(SUBMIT_DATA_REQUEST);
  const [value, setValue] = useState('');

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    submitDataRequest({
      variables: {
        input: {
          deal_id: props.dealId,
          request: value,
        },
      },
    });
  };

  return (
    <StyledBody>
      <Textarea
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
      />
      <Button onClick={handleSubmit}>Send Request</Button>
    </StyledBody>
  );
};

export default RequestDataCardBody;
export { default as RequestDataCardFragments } from './fragments';
