import { CardsKey } from 'features/deals/deals.config';
import { createContext, useContext } from 'react';

const DealRoomContext = createContext<DealRoomContextProps | undefined>(
  undefined
);

export interface DealRoomContextProps {
  dealId: string;
  currentCard: CardsKey;
  setCurrentCard: (value: CardsKey) => void;
}

export const useDealRoomContext = () => {
  const context = useContext(DealRoomContext);
  if (!context) {
    throw new Error(
      'This component must be used within a DealRoomContext.Provider'
    );
  }
  return context;
};

export default DealRoomContext;
