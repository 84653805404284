import { gql } from '@apollo/client';
import LoanDatatableFragments from 'features/drilldown/LoanDatatable/fragments';
import { StratificationsFragments } from 'features/drilldown/Stratifications';
import { PoolSummaryFragments } from 'features/loanPool/PoolSummary';
import {
  createPaginationParam,
  createQueryFilters,
  DataContextPaginationCursor,
  DataContextState,
} from 'context/DataContext';
import {
  AssetClass,
  FilterableField,
  FilterOperator,
} from '__generated__/globalTypes';
import { GetDrilldownVariables } from './__generated__/GetDrilldown';

const GET_DRILLDOWN_QUERY = gql`
  query GetDrilldown(
    $sort: Sort!
    $pagination: Pagination!
    $assetClassFilter: Filter!
    $filters: [Filter!]
  ) {
    user {
      id
      company {
        id
        loans(sort: $sort, pagination: $pagination, filters: $filters) {
          id
          ...LoanDatatableLoan
        }
        performanceData: performance_summary(filters: $filters) {
          ...LoanDatatablePerformanceSummary
          ...PoolSummaryPerformanceSummary
          ...StratificationsPerformanceSummary
        }
        totalPerformanceData: performance_summary(
          filters: [$assetClassFilter]
        ) {
          ...PoolSummaryPerformanceSummary
        }
      }
    }
  }
  ${LoanDatatableFragments.loan}
  ${LoanDatatableFragments.performanceSummary}
  ${PoolSummaryFragments.performanceSummary}
  ${StratificationsFragments.performanceSummary}
`;

const toGetDrilldownVariables = (
  state: DataContextState,
  paginationCursors: DataContextPaginationCursor[],
  assetClass?: AssetClass
): GetDrilldownVariables => {
  const queryFilters = createQueryFilters(state.assetClass, state.filters);
  return {
    sort: state.sort,
    pagination: createPaginationParam(paginationCursors, state.pagination),
    assetClassFilter: {
      field_name: FilterableField.asset_class,
      operator: FilterOperator.IS,
      operand: assetClass,
    },
    filters: queryFilters,
  };
};

export { GET_DRILLDOWN_QUERY, toGetDrilldownVariables };
