import { gql } from '@apollo/client';

const RequestDataCardFragments = {
  TimelineCard: gql`
    fragment RequestDataCard on TimelineCard {
      ... on RequestDataCard {
        request
      }
    }
  `,
};

export default RequestDataCardFragments;
