import React from 'react';
import './avatar.scss';

interface AvatarProps {
  name: string;
}

const Avatar = ({
  name,
}: AvatarProps) => {
  const initial = name.charAt(0);

  return (
    <div className="avatar">{initial}</div>
  )
};

export default Avatar;