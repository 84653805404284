import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import {Spinner, Section, FormWithSingleInputs} from 'common-ui';
import Chart from 'features/drilldown/cashflows/Chart';
import MonthlyTable from 'features/drilldown/cashflows/MonthlyTable';
import SummaryTable from 'features/drilldown/cashflows/SummaryTable';
import { AddUserSetting } from 'features/drilldown/cashflows/__generated__/AddUserSetting';
import { GetUserSetting } from 'features/drilldown/cashflows/__generated__/GetUserSetting';
import { useEffect, useState } from 'react';
import { styled } from 'style/ORSNNTheme';
import {
  INPUT_CONFIG,
  CashFlowsValidValues,
  INITIAL_FORM_VALUES,
} from './configurations/cashflow-inputs';
import { GetDealCashFlows_deal_performanceSummary } from './gql/__generated__/GetDealCashFlows';
import { GetPortfolioCashFlows_user_company_performanceSummary } from './gql/__generated__/GetPortfolioCashFlows';

const SummaryRow = styled.div`
  display: flex;
`;

const SummaryTableContainer = styled.div`
  margin-right: 1rem;
  flex: 1 1;
`;

const ChartContainer = styled.div`
  display: inline-flex;
  flex: 2 2;
`;

const ADD_USER_SETTING = gql`
  mutation AddUserSetting($name: String!, $value: String!) {
    addUserSetting(name: $name, value: $value)
  }
`;

const GET_USER_SETTING = gql`
  query GetUserSetting($name: String!) {
    user {
      id
      setting(name: $name)
    }
  }
`;

type CashFlowDataDisplayProps = {
  useSummaryData: (inputValues: CashFlowsValidValues) => useSummaryDataReturnValue;
  userInputs: CashFlowsValidValues;
};

export type PerformanceSummary = 
    | GetDealCashFlows_deal_performanceSummary
    | GetPortfolioCashFlows_user_company_performanceSummary
    | undefined;

export type useSummaryDataReturnValue = {
  loading: boolean;
  data: PerformanceSummary;
  error: ApolloError | undefined;
}

export const CashFlowsDataDisplay = (
  props: CashFlowDataDisplayProps
): JSX.Element => {
  const {loading, data } = props.useSummaryData(props.userInputs);

  return (
    <>
      <Spinner loading={loading} />
      <SummaryRow>
        <SummaryTableContainer>
          <SummaryTable
            cashFlowTotals={data?.cashFlows.totals ?? null}
            performanceSummary={data ?? null}
          />
        </SummaryTableContainer>
        <ChartContainer>
          <Chart
            cashFlowMonths={
              data?.cashFlows.monthlyCashFlows
            }
          />
        </ChartContainer>
      </SummaryRow>
      <MonthlyTable
        monthlyCashFlows={
          data?.cashFlows.monthlyCashFlows ?? []
        }
      />
    </>
  );
};

type CashFlowsProps = {
  settingKey: string;
  useSummaryData: (inputValues: CashFlowsValidValues) => useSummaryDataReturnValue;
};

export const CashFlows = (props: CashFlowsProps): JSX.Element | null => {
  const [addUserSetting] = useMutation<AddUserSetting>(ADD_USER_SETTING);

  const { data, loading, error } = useQuery<GetUserSetting>(GET_USER_SETTING, {
    variables: { name: props.settingKey },
    fetchPolicy: 'network-only',
  });
  const [formValues, setFormValues] = useState<CashFlowsValidValues | null>(
    null
  );

  useEffect(() => {
    if (!loading && !error && data && data.user.setting) {
      try {
        const validatedFormValues: CashFlowsValidValues = JSON.parse(
          data.user.setting
        );
        setFormValues(validatedFormValues);
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          console.log(e); // eslint-disable-line no-console
        }
      }
    }
  }, [data, loading, error]);

  const handleSuccessfulSubmission = (
    validatedFormValues: CashFlowsValidValues
  ) => {
    setFormValues(validatedFormValues);
    addUserSetting({
      variables: {
        name: props.settingKey,
        value: JSON.stringify(validatedFormValues),
      },
    });
  };

  return (
    <Section>
      <FormWithSingleInputs<CashFlowsValidValues>
        formConfig={INPUT_CONFIG}
        onSubmit={handleSuccessfulSubmission}
        formValues={formValues || INITIAL_FORM_VALUES}
      />
      {formValues && (
        <CashFlowsDataDisplay useSummaryData={props.useSummaryData} userInputs={formValues} />
      )}
    </Section>
  );
};
