import { useMutation, useQuery } from '@apollo/client';
import { Button, KIND } from 'baseui/button';
import { Input } from 'baseui/input';
import { ListItem, ListItemLabel } from 'baseui/list';
import { Value as Numeric } from 'common-ui';
import { match } from 'functions/FunctionalMatch';
import { DateTime } from 'luxon';
import { ACCEPT_FUNDING_SCHEDULE } from 'mutation/acceptFundingSchedule';
import { REJECT_FUNDING_SCHEDULE } from 'mutation/rejectFundingSchedule';
import { SAVE_WIRE_DETAILS } from 'mutation/saveWireDetails';
import {
  AcceptFundingSchedule,
  AcceptFundingScheduleVariables,
} from 'mutation/__generated__/AcceptFundingSchedule';
import {
  RejectFundingSchedule,
  RejectFundingScheduleVariables,
} from 'mutation/__generated__/RejectFundingSchedule';
import {
  SaveWireDetails,
  SaveWireDetailsVariables,
} from 'mutation/__generated__/SaveWireDetails';
import { CALCULATE_BID_PRICE } from 'query/calculateBidPrice';
import {
  CalculateBidPrice,
  CalculateBidPriceVariables,
} from 'query/__generated__/CalculateBidPrice';
import React, {
  Dispatch,
  FormEvent,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { styled } from 'style/ORSNNTheme';
import { DealActionOptionType, Emphasis } from '__generated__/globalTypes';
import {
  basisPointsToPercent,
  Column,
  formatCalculatedPrice,
  isNotNull,
  List,
  ListItemStyleProps,
  SubjectText,
  TwoColumns,
  useDebouncedState,
} from '../utils';
import { FundingScheduleDetailsCard_FundingScheduleDetailsCard } from './__generated__/FundingScheduleDetailsCard';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

type Props = {
  dealId: string;
} & FundingScheduleDetailsCard_FundingScheduleDetailsCard;

const DEBOUNCE_DELAY = 2000;

type WireDetailsProps = Props & {
  accountNumber: string;
  setAccountNumber: (val: string) => void;
  bankName: string;
  setBankName: (val: string) => void;
  routingNumber: string;
  setRoutingNumber: (val: string) => void;
};

const WireDetails: React.VFC<WireDetailsProps> = ({
  accountNumber,
  setAccountNumber,
  bankName,
  setBankName,
  routingNumber,
  setRoutingNumber,
  can_edit_wire_details,
}) => {
  const inputConfigs = [
    {
      value: accountNumber,
      updateFn: setAccountNumber,
      placeholder: `Account Number`,
    },
    {
      value: bankName,
      updateFn: setBankName,
      placeholder: `Bank Name`,
    },
    {
      value: routingNumber,
      updateFn: setRoutingNumber,
      placeholder: `Routing Number`,
    },
  ];

  const handleChange = (value: string, setValue: (val: string) => void) => {
    if (can_edit_wire_details) {
      setValue(value);
    }
  };

  const makeOnChange =
    (config: {
      value: string;
      updateFn: (val: string) => void;
      placeholder: string;
    }) =>
    (event: FormEvent<HTMLInputElement>) => {
      handleChange(event.currentTarget.value.toString(), config.updateFn);
    };

  return (
    <>
      Wiring Info
      {inputConfigs.map((config) => (
        <Input
          key={config.placeholder}
          value={config.value}
          onChange={makeOnChange(config)}
          onBlur={makeOnChange(config)}
          placeholder={config.placeholder}
        />
      ))}
    </>
  );
};

const FundingScheduleDetailsCardBody = (props: Props): JSX.Element => {
  const [acceptFundingSchedule] = useMutation<
    AcceptFundingSchedule,
    AcceptFundingScheduleVariables
  >(ACCEPT_FUNDING_SCHEDULE);
  const [rejectFundingSchedule] = useMutation<
    RejectFundingSchedule,
    RejectFundingScheduleVariables
  >(REJECT_FUNDING_SCHEDULE);
  const [wireDetailsSaved, setWireDetailsSaved] = useState(
    props.presetWireDetails == null
  );
  const [accountNumber, dAccountNumber, setAccountNumber] = useDebouncedState(
    props.presetWireDetails?.account_number ?? ``,
    DEBOUNCE_DELAY
  );
  const [bankName, dBankName, setBankName] = useDebouncedState(
    props.presetWireDetails?.bank_name ?? ``,
    DEBOUNCE_DELAY
  );
  const [routingNumber, dRoutingNumber, setRoutingNumber] = useDebouncedState(
    props.presetWireDetails?.routing_number ?? ``,
    DEBOUNCE_DELAY
  );

  const [saveWireDetails] = useMutation<
    SaveWireDetails,
    SaveWireDetailsVariables
  >(SAVE_WIRE_DETAILS, {
    onCompleted: () => setWireDetailsSaved(true),
  });

  useEffect(() => {
    if (
      dAccountNumber.length > 0 &&
      dBankName.length > 0 &&
      dRoutingNumber.length > 0
    ) {
      saveWireDetails({
        variables: {
          input: {
            deal_id: props.dealId,
            account_number: dAccountNumber,
            bank_name: dBankName,
            routing_number: dRoutingNumber,
          },
        },
      });
    }
  }, [
    dAccountNumber,
    dBankName,
    dRoutingNumber,
    saveWireDetails,
    props.dealId,
  ]);

  const { data } = useQuery<CalculateBidPrice, CalculateBidPriceVariables>(
    CALCULATE_BID_PRICE,
    {
      variables: {
        input: {
          deal_id: props.dealId,
          bid_basis_points: props.buyerFundingSchedule.bid_basis_points,
        },
      },
    }
  );

  const handleClick = (actionType: DealActionOptionType) => {
    const input = {
      variables: {
        input: {
          deal_id: props.dealId,
        },
      },
    };
    if (DealActionOptionType.APPROVE_FUNDING_SCHEDULE === actionType) {
      acceptFundingSchedule(input);
    } else if (DealActionOptionType.REJECT_FUNDING_SCHEDULE === actionType) {
      rejectFundingSchedule(input);
    }
  };

  const purchaseDate = props.buyerFundingSchedule.purchase_date
    ? DateTime.fromISO(props.buyerFundingSchedule.purchase_date).toFormat(
        'LL-dd-yyyy'
      )
    : '';

  const { price } = formatCalculatedPrice({
    currentBalanceCents: data?.calculateBidPrice.unpaid_balance,
    priceCents: data?.calculateBidPrice.price,
    rate: data?.calculateBidPrice.rate,
  });

  const wireDetailsSection = (
    <Column>
      {props.should_show_wire_details ? (
        <WireDetails
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
          bankName={bankName}
          setBankName={setBankName}
          routingNumber={routingNumber}
          setRoutingNumber={setRoutingNumber}
          {...props}
        />
      ) : (
        `The seller will submit wiring info after accepting the funding schedule.`
      )}
    </Column>
  );

  const isWireDataFormComplete = Boolean(
    accountNumber && bankName && routingNumber
  );

  return (
    <Body>
      <SubjectText>
        {match<never, string>()
          .on(
            props.can_edit_wire_details && props.should_show_wire_details,
            () =>
              'View final carve, price, and purchase date.  Submit wire details to complete the deal.'
          )
          .on(
            props.should_show_wire_details,
            () => 'View the final carve, price, purchase date, and wire details'
          )
          .otherwise(() => 'View the final carve, price, and purchase date.')}
      </SubjectText>
      <TwoColumns>
        <Column>
          <span>BID</span>
          <List>
            <ListItem
              endEnhancer={() =>
                `${basisPointsToPercent(
                  props.buyerFundingSchedule.bid_basis_points
                )}%`
              }
              {...ListItemStyleProps}
            >
              <ListItemLabel>Price as % of UPB</ListItemLabel>
            </ListItem>
            <ListItem
              endEnhancer={() => <Numeric>{purchaseDate}</Numeric>}
              {...ListItemStyleProps}
            >
              <ListItemLabel>Purchase Date</ListItemLabel>
            </ListItem>
            <ListItem
              endEnhancer={() => <Numeric>{price}</Numeric>}
              {...ListItemStyleProps}
            >
              <ListItemLabel>Price</ListItemLabel>
            </ListItem>
          </List>
        </Column>
        {wireDetailsSection}
      </TwoColumns>
      <div>
        {props.actions.filter(isNotNull).map((action) => {
          return (
            <Button
              kind={
                action.emphasis === Emphasis.STRONG
                  ? KIND.primary
                  : KIND.secondary
              }
              key={action.type}
              disabled={
                DealActionOptionType.APPROVE_FUNDING_SCHEDULE === action.type &&
                (!wireDetailsSaved || !isWireDataFormComplete)
              }
              onClick={() => handleClick(action.type)}
            >
              {action.title}
            </Button>
          );
        })}
      </div>
    </Body>
  );
};

export default FundingScheduleDetailsCardBody;
export { default as FundingScheduleDetailsCardFragments } from './fragments';
