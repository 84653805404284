import { gql } from '@apollo/client';

const FundingScheduleInputCardFragments = {
  TimelineCard: gql`
    fragment FundingScheduleInputCard on TimelineCard {
      ... on FundingScheduleInputCard {
        actions {
          title
          type
          emphasis
        }
        can_edit_funding_schedule
        funding_schedule {
          bid_basis_points
          purchase_date
        }
      }
    }
  `,
};

export default FundingScheduleInputCardFragments;
