import React, { useState, useCallback, useEffect } from 'react';
import { DragDrop } from '@uppy/react';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import { AssetClass, FileState } from '__generated__/globalTypes';
import { Card, CardHeading, CardInstructions, StyledFooter } from '../styles';
import { Button } from 'baseui/button';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import { GetUploadedFilesWithHeaders_user_company_files as UploadedFile } from 'query/__generated__/GetUploadedFilesWithHeaders';
import TapesUploadedTable, {
  TapesUploadedTableRow,
} from '../components/TapesUploadedTable';
import { SingleValue } from 'react-select';
import {
  Placeholder,
  SectionHeader,
  DashboardContainer,
  CardBody,
  TapesUploadedPanel,
  UploadForm,
  LoadingMessage,
} from './LoanTapeUploader.styles';
import { AssetClassOptionType } from './AssetClassOptionType';
import useUppy from './useUppy';
import { UppyFile } from '@uppy/core';
import { useTapeUploadContext } from './TapeUploadContext';
import { usePreSignedUrlQuery } from './usePresignedUrlQuery';

export const assetClassOptions: AssetClassOptionType[] = Object.values(
  AssetClass
).map((assetClass) => ({
  value: assetClass,
  label: assetClass.toString(),
}));

const progressByState = (state: FileState) => {
  switch (state) {
    case FileState.NEW:
      return 80;
    case FileState.READING_FILE_HEADERS:
      return 90;
  }
  return undefined;
};

const tapesUploadedTableRowForUploadedFile = (uploadedFile: UploadedFile) => {
  return {
    rowId: uploadedFile.id,
    filename: uploadedFile.fileName,
    uploadedFile: uploadedFile,
    uploadComplete: true,
    progressPercent: progressByState(uploadedFile.state),
    state: uploadedFile.state,
  };
};

export interface LoanTapeUploaderProps {
  onClickNext?: () => void;
}

export default function LoanTapeUploader(props: LoanTapeUploaderProps) {
  const { uploadedTapes, refetch, selectedFileId, setSelectedFileId } =
    useTapeUploadContext();
  const { getPresignedUrl } = usePreSignedUrlQuery();

  // Using Function.prototype here instead of () => {} to satisfy the linter.
  const { onClickNext = Function.prototype } = props;
  const [loanType, setLoanType] = useState<AssetClass>();
  const [uploadsInProgress, setUploadsInProgress] = useState<UppyFile[]>([]);
  const [lastUploadedFileName, setLastUploadedFileName] = useState<
    string | null
  >(null);

  const onUploadSuccess = useCallback(
    (fileName: string) => {
      setLastUploadedFileName(fileName);
      refetch();
    },
    [refetch]
  );

  useEffect(() => {
    if (!selectedFileId && lastUploadedFileName) {
      const uploadedFile = uploadedTapes?.find(
        (tape) => tape.fileName === lastUploadedFileName
      );

      if (uploadedFile) {
        setSelectedFileId(uploadedFile.id);
      }
    }
  }, [uploadedTapes, selectedFileId, lastUploadedFileName, setSelectedFileId]);

  const uppy = useUppy(
    getPresignedUrl,
    loanType,
    setUploadsInProgress,
    onUploadSuccess
  );

  // This function calculates which tapes to show, and in what order, in the Tapes Uploaded table.
  // The idea is to put the tapes that are currently being uploaded at the top of table, along
  // with their progress bars. Meanwhile, a query is refreshing the list of previously uploaded
  // tapes every few seconds. This means there may be a delay between when a tape finishes uploading
  // and when we see it in the refreshed list of previously uploaded tapes. Therefore, we continue
  // to display the tapes as uploading, at the top of the table, until we can confirm that
  // it appears in the list of previously uploaded tapes.
  const calculateTapesUploadedTableRows = () => {
    const previouslyUploadedTapes: TapesUploadedTableRow[] =
      uploadedTapes?.map(tapesUploadedTableRowForUploadedFile) || [];

    const currentlyUploadingTapes: TapesUploadedTableRow[] =
      uploadsInProgress.map((uppyFile) => {
        return {
          rowId: uppyFile.id,
          filename: uppyFile.name,

          /* Multiply progress by 0.9 because uploading is only part of the process before a file can be mapped. */
          progressPercent: (uppyFile.progress?.percentage || 0) * 0.7,
          uploadComplete: uppyFile.progress?.uploadComplete,
        };
      });

    // If a tape has just finished uploading, leave it at the top of the table until its
    // filename shows up in the list of previously uploaded tapes.
    const uploadedTapeNames = new Set(
      previouslyUploadedTapes.map((tape) => tape.filename)
    );
    const filteredUploadingRows = currentlyUploadingTapes.filter((row) => {
      return !(row.uploadComplete && uploadedTapeNames.has(row.filename));
    });

    return [...filteredUploadingRows, ...previouslyUploadedTapes];
  };

  const uploadedTapesTableRows = calculateTapesUploadedTableRows();

  const handleAssetClassSelect = useCallback(
    (selectedOption: SingleValue<AssetClassOptionType>) => {
      if (selectedOption) {
        setLoanType(selectedOption.value);
      }
    },
    []
  );

  const handleRowClick = useCallback(
    (row: TapesUploadedTableRow): void => {
      setSelectedFileId(row.uploadedFile?.id);
    },
    [setSelectedFileId]
  );

  const handleCancelUpload = (fileId: string) => {
    uppy.removeFile(fileId);
  };

  const onClickNextButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClickNext();
    },
    [onClickNext]
  );

  return (
    <Card>
      <CardHeading>Upload Your Tapes</CardHeading>
      <CardInstructions>
        Upload one or all of your loan tapes for evaluation.
      </CardInstructions>
      <CardBody>
        <UploadForm>
          <SectionHeader>SELECT ASSET CLASS</SectionHeader>
          <Dropdown
            isMulti={false}
            options={assetClassOptions}
            onChange={handleAssetClassSelect}
            value={
              loanType ? { value: loanType, label: loanType.toString() } : null
            }
          />
          <DashboardContainer>
            {loanType ? (
              <DragDrop uppy={uppy} />
            ) : (
              <Placeholder>
                Select an asset type above to upload files.
              </Placeholder>
            )}
          </DashboardContainer>
        </UploadForm>
        <TapesUploadedPanel>
          <>
            <SectionHeader>TAPES UPLOADED</SectionHeader>

            {uploadedTapesTableRows.length > 0 ? (
              <TapesUploadedTable
                tableRows={uploadedTapesTableRows}
                selectedRowId={selectedFileId}
                onRowClick={handleRowClick}
                onCancelUploadClick={handleCancelUpload}
              />
            ) : (
              <LoadingMessage>
                {uploadedTapes === undefined
                  ? 'Loading...'
                  : 'No tapes uploaded yet'}
              </LoadingMessage>
            )}
          </>
        </TapesUploadedPanel>
      </CardBody>
      <StyledFooter>
        <Button
          size="compact"
          disabled={!selectedFileId}
          onClick={onClickNextButton}
        >
          NEXT: Map File Columns to Fields
        </Button>
      </StyledFooter>
    </Card>
  );
}
