import {CheckboxWithLabel, Section} from 'common-ui';
import { Dispatch, SetStateAction } from 'react';
import { styled } from 'style/ORSNNTheme';

const HeaderContainer = styled(Section).attrs({ dividerBottom: true })`
  display: flex;
  justify-content: left;
`;

type Props = {
  isSellerVisible: boolean;
  isBuyerVisible: boolean;
  isCompleteVisible: boolean;
  sellerToggle: Dispatch<SetStateAction<boolean>>;
  buyerToggle: Dispatch<SetStateAction<boolean>>;
  completeToggle: Dispatch<SetStateAction<boolean>>;
};

const InProgressHeader = (props: Props): JSX.Element => {
  return (
    <HeaderContainer>
      <span>Show pools:</span>
      <CheckboxWithLabel
        checked={props.isSellerVisible}
        onClick={() => props.sellerToggle((state) => !state)}
      >
        Selling
      </CheckboxWithLabel>
      <CheckboxWithLabel
        defaultChecked={props.isBuyerVisible}
        onClick={() => props.buyerToggle((state) => !state)}
      >
        Buying
      </CheckboxWithLabel>
      <CheckboxWithLabel
        defaultChecked={props.isCompleteVisible}
        onClick={() => props.completeToggle((state) => !state)}
      >
          Complete
      </CheckboxWithLabel>
      {/* TODO: 
      https://orsnn.atlassian.net/browse/RC1-155 */}
    </HeaderContainer>
  );
};

export default InProgressHeader;
