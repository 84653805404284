import { styled } from 'style/ORSNNTheme';

const Col = styled.div`
  display: inline-block;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;

  // &:nth-child(even) {
  //   background-color: ${(props) => props.theme.color.grey100}};
  // }
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.5rem;
  padding-bottom: 1rem;
  justify-content: start;
`;

export { Col, Row, Table };
