
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }

      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Company": [
      "UserCompany",
      "PrivateCompany",
      "PublicCompany"
    ],
    "Loan": [
      "AutoLoan",
      "HomeLoan",
      "CRELoan",
      "MemberBusinessLoan"
    ],
    "Listing": [
      "PublicListing",
      "UserCompanyListing"
    ],
    "TimelineCard": [
      "PlaceholderCard",
      "CounterpartyCard",
      "LoansCard",
      "StratificationsCard",
      "DealDocumentsCard",
      "CarveDetailsCard",
      "BidTermsCard",
      "PlaceBidCard",
      "BidCard",
      "RequestDataCard",
      "DataReviewCard",
      "CutOffTapeCard",
      "FundingScheduleInputCard",
      "FundingScheduleDetailsCard",
      "MasterParticipationAgreementCard",
      "ParticipationCertificateCard",
      "WireConfirmationCard"
    ]
  }
};

      export default result;
    