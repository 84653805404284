import { MainRouteKeys, MainKeyTo2ndLevelKeys } from 'app-level/navigation';

export type HeaderMenuItemConfig<T extends string> = {
  routeKey?: T;
  label: string;
  iconName?: string;
  disabled?: boolean;
};

export const MAIN_HEADER_CONFIG: HeaderMenuItemConfig<MainRouteKeys>[] = [
  {
    routeKey: 'home',
    label: 'Home',
    iconName: 'home',
  },
  {
    routeKey: 'portfolio',
    label: 'My Portfolio',
    iconName: 'portfolio',
  },
  {
    routeKey: 'marketplace',
    label: 'Marketplace',
    iconName: 'marketplace',
  },
  {
    routeKey: 'market_data',
    label: 'Market Data',
    iconName: 'market-data',
  },
];

export const SUB_HEADER_CONFIGS: {
  [key in MainRouteKeys]: HeaderMenuItemConfig<MainKeyTo2ndLevelKeys[key]>[];
} = {
  home: [],
  portfolio: [
    { routeKey: 'dashboard', label: 'Dashboard', iconName: 'dashboard' },
    {
      routeKey: 'stratifications',
      label: 'Stratifications',
      iconName: 'stratifications',
    },
    {
      routeKey: 'loan-level-data',
      label: 'Loan Level Data',
      iconName: 'loan-level-data',
    },
    {
      routeKey: 'pool-manager',
      label: 'Pool Manager',
      iconName: 'pool-manager',
    },
    {
      routeKey: 'cashflow-modeling',
      label: 'Cashflow Modeling',
      iconName: 'cashflow-modeling',
    },
    // { label: 'ALM AI', iconName: 'advanced-analytics', disabled: true },
    {
      routeKey: 'document-library',
      label: 'Document Library',
      iconName: 'document-library',
    },
    { routeKey: 'tape-upload', label: 'Tape Upload', iconName: 'tape-upload' },
  ],
  marketplace: [
    { routeKey: 'market-home', label: 'Market Home' },
    { routeKey: 'active_trades', label: 'Active Trades' },
  ],
  market_data: [],
};
