import { gql } from '@apollo/client';

const DELETE_S3_FILE_MUTATION = gql`
  mutation DeleteS3File(
    $parentId: ID!
    $fileType: FileType!
    $fileName: String!
  ) {
    deleteS3File(parentId: $parentId, fileType: $fileType, fileName: $fileName)
  }
`;
export default DELETE_S3_FILE_MUTATION;
