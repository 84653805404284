import { gql } from '@apollo/client';

export const CALCULATE_BID_PRICE = gql`
  query CalculateBidPrice($input: CalculateBidPriceInput!) {
    calculateBidPrice(input: $input) {
      price
      rate
      unpaid_balance
    }
  }
`;
