import { LoanTable } from 'features/drilldown/LoanDatatable/LoanTable';
import * as navigation from 'app-level/navigation';
import { useMutation, useQuery } from '@apollo/client';
import {
  GetDrilldown,
  GetDrilldownVariables,
} from 'query/__generated__/GetDrilldown';
import {
  CreateListing,
  CreateListingVariables,
} from 'mutation/__generated__/CreateListing';
import { GET_DRILLDOWN_QUERY } from 'query/drilldown';
import { ASSET_CLASS_IDS } from 'models/AssetClass';
import { filtersToQueryFilters } from 'features/common-elements/filters/filterValuesParsing';
import {
  AssetClass,
  Filter,
  FilterableField,
  FilterOperator,
  SortableField,
  SortDirection,
} from '__generated__/globalTypes';
import { createQueryFilters } from 'context/DataContext';
import { ModalNotStyled, Spinner } from 'common-ui';
import { PortfolioFilterConfig } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { useState } from 'react';
import { RowSelectionState } from '@tanstack/react-table';
import { PortfolioLayout } from './Portfolio/PortfolioLayout';
import { usePortfolioFilters } from './Portfolio/usePortfolioFilters';
import { PillFilters } from 'features/common-elements';
import CreatePoolForm, { FormValues } from './CreatePoolForm';
import { CREATE_LISTING } from 'mutation/createListing';
import { Button } from 'baseui/button';

const getDrilldownVariables = (
  assetClass: AssetClass | null,
  filters: Filter[],
  sorting: { id: SortableField; desc: boolean }[]
): GetDrilldownVariables => {
  const queryFilters = createQueryFilters(assetClass, filters);
  return {
    sort: {
      sort_field: sorting[0]?.id,
      sort_direction: sorting[0]?.desc ? SortDirection.desc : SortDirection.asc,
    },
    pagination: {},
    assetClassFilter: {
      field_name: FilterableField.asset_class,
      operator: FilterOperator.IS,
      operand: assetClass,
    },
    filters: queryFilters,
  };
};

const LoanLevelData = () => {
  const assetClass = navigation.usePortfolioAssetClass();
  const [portfolioFilters] = navigation.usePortfolioFilters();
  const gqlAssetClass = assetClass && ASSET_CLASS_IDS[assetClass];
  const filterProps = usePortfolioFilters();

  const queryFilters = filterProps.filtersConfig
    ? filtersToQueryFilters<PortfolioFilterConfig>(
        portfolioFilters,
        filterProps.filtersConfig
      )
    : [];
  const [loansSorting, setLoansSorting] =
    navigation.useSortingHelper<SortableField>();

  //probably should use a different query here or move it up to the portfolio page
  //but if it's possible I think smaller queries are easier to maintain
  const { data, loading, previousData } = useQuery<
    GetDrilldown,
    GetDrilldownVariables
  >(GET_DRILLDOWN_QUERY, {
    variables: getDrilldownVariables(gqlAssetClass, queryFilters, loansSorting),
    skip: !gqlAssetClass,
    fetchPolicy: 'no-cache', //TODO: remove this from here,
    //cache returns empty loans... not sure why
    // fetchPolicy: 'cache-and-network',
  });
  const [selectedLoans, setSelectedLoans] = useState<RowSelectionState>({});

  const [createListing] = useMutation<CreateListing, CreateListingVariables>(
    CREATE_LISTING
  );

  if (!gqlAssetClass || !filterProps.filtersConfig) {
    return <PortfolioLayout />;
  }

  const dataToShow = data || previousData;

  const createPool = (formValues: FormValues) => {
    const data = dataToShow?.user.company.loans;
    const selectedData = data?.filter((_, index) => selectedLoans[index]) || [];
    const accountIdList: string[] = selectedData.map(
      (loan) => loan.account_id as string
    );

    const filter: Filter = {
      field_name: FilterableField.account_id,
      operator: FilterOperator.IS,
      operandList: accountIdList,
    };

    createListing({
      variables: {
        name: formValues.poolName,
        assetClass: gqlAssetClass,
        filters: [filter],
      },
    });
  };

  return (
    <PortfolioLayout
      filterRow={
        <PillFilters
          {...filterProps}
          filtersConfig={filterProps.filtersConfig}
        />
      }
      actions={
        <ModalNotStyled
          trigger={
            <Button disabled={Object.keys(selectedLoans).length === 0}>
              Create Pool
            </Button>
          }
        >
          {({ closeModal }) => (
            <CreatePoolForm closeModal={closeModal} createPool={createPool} />
          )}
        </ModalNotStyled>
      }
    >
      {dataToShow ? (
        <LoanTable
          data={dataToShow?.user.company.loans ?? []}
          assetClass={gqlAssetClass}
          strings={{ empty: 'No Loans Found' }}
          selection={{
            selected: selectedLoans,
            onSelectionChange: setSelectedLoans,
          }}
          sorting={{
            state: loansSorting,
            onSortingChanged: setLoansSorting,
          }}
        />
      ) : null}
      <Spinner loading={loading} />
    </PortfolioLayout>
  );
};

export default LoanLevelData;

//this is the DELETE loan query
// const DELETE_LOAN = gql`
//   mutation DeleteLoan($id: ID!) {
//     deleteLoan(id: $id)
//   }
// `;

// const [deleteLoan, { called, loading: loadingDeleteLoan }] =
//   useMutation<DeleteLoan>(DELETE_LOAN);

// deleteLoan({
//   variables: { id: row.id },
//   update: (cache) => {
//     const normalizedId = cache.identify({
//       id: row.id,
//       __typename: assetClassToTypename(gqlAssetClass),
//     });
//     cache.evict({ id: normalizedId });
//     cache.gc();
//   },
// });
