import { usePopout, FloatingPortal } from '../hooks/usePopout';
import { RangeSlider } from './RangeSlider';
import {PillButton} from '../Buttons';
import { styled } from 'style/ORSNNTheme';

const StyledPopoutContainer = styled.div`
  height: 90px;
  width: 298px;
  border-radius: 8px;
  border-color: ${(props) => props.theme.color.brandMainPink};
  border-style: solid;
  padding: 16px 16px 22px 16px;
  background-color: ${(props) => props.theme.color.cardBackground};

  > div {
    margin-top: 24px;
  }
`;

export function RangePillButton(props: {
  min: number,
  max: number,
  name: string,
  initialValue: [number, number],
  onChange?: (values: [number, number]) => void,
  iconName: string
  onIconClick?: () => void
}) {

  const {refs, getFloatingProps, getReferenceProps, isOpen, floatingStyles} = usePopout();

  return (<>
    <PillButton 
      {...getReferenceProps()}
      ref={refs.setReference}
      description={props.name} 
      value={`${props.initialValue[0]} to ${props.initialValue[1]}`}
      iconName={props.iconName || 'pill-close'}
      iconOnClick={props.onIconClick}
    />

    {isOpen 
      ? <FloatingPortal>
          <StyledPopoutContainer ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <RangeSlider 
              defaultValue={props.initialValue} 
              min={props.min} 
              max={props.max} 
              onChange={(sliderValues) => props.onChange?.([sliderValues[0], sliderValues[1]])}
              withTooltip={true}
            />
          </StyledPopoutContainer>
        </FloatingPortal>
      : null
    }
  </>);
}