import { FunctionComponent } from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { DebugEngine, Provider as StyletronProvider } from 'styletron-react';
import {
  BaseProvider,
  BaseProviderOverrides,
  createDarkTheme,
  darkThemePrimitives,
} from 'baseui';
import { ThemePrimitives } from 'baseui/theme';
import { theme } from 'style/ORSNNTheme';

const debug =
  process.env.NODE_ENV === 'production' ? void 0 : new DebugEngine();
const engine = new Styletron();
const primitives: ThemePrimitives = {
  ...darkThemePrimitives,
  primary: theme.color.brandMainPink,
  primaryB: theme.color.deprecatedModalBackground,
};
const overrides = {
  colors: {
    buttonPrimaryText: theme.color.fgDefault,
    buttonPrimaryHover: theme.color.darkPrimaryLabel,
    buttonTertiaryHover: primitives.primary,
  },
};
const darkTheme = createDarkTheme(primitives, overrides);
darkTheme.borders.buttonBorderRadius = '4px';

const baseProviderOverrides: BaseProviderOverrides = {
  AppContainer: {
    style: {
      height: '100%',
    },
  },
};

const BaseWeb: FunctionComponent = ({ children }) => {
  return (
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <BaseProvider theme={darkTheme} overrides={baseProviderOverrides}>
        {children}
      </BaseProvider>
    </StyletronProvider>
  );
};

export default BaseWeb;
