import { gql, useMutation } from '@apollo/client';
import { KIND } from 'baseui/button';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  SIZE,
} from 'baseui/modal';
import { DEAL_URL, INPROGRESS_PATH, OVERVIEW_URL } from 'app-level/navigation/paths';
import { AuthContext } from 'context/AuthContext';
import { GetUserCompany_user_company } from 'context/__generated__/GetUserCompany';
import { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { styled } from 'style/ORSNNTheme';
import { FIELD_CONFIG } from '../../configs/columns';
import {
  LabelAndValue,
  LabeledRow,
  RowLabel,
  RowScrollBox,
  SummaryRow,
} from 'common-ui/layout/utils';
import { CreateDeal, CreateDealVariables } from './__generated__/CreateDeal';
import {
  PoolHeaderDeal,
  PoolHeaderDeal_buyer,
  PoolHeaderDeal_seller,
} from './__generated__/PoolHeaderDeal';
import {
  PoolHeaderListing,
  PoolHeaderListing_seller,
  PoolHeaderListing_seller_PrivateCompany,
  PoolHeaderListing_seller_UserCompany,
} from './__generated__/PoolHeaderListing';

const SummaryTitle = styled.div`
  font-size: 1.5rem;
  padding-bottom: 1rem;
  color: ${(props) => props.theme.color.brandMainPink};
`;

const ContactButton = styled.div`
  color: ${(props) => props.theme.color.brandMainPink};
  cursor: pointer;
  text-align: center;
`;

const COMPANY_FRAGMENT = gql`
  fragment HeaderCompany on Company {
    __typename
    type
    ... on PrivateCompany {
      id
      name
    }
    ... on UserCompany {
      id
      name
    }
  }
`;

const PoolHeaderFragments = {
  listing: gql`
    fragment PoolHeaderListing on Listing {
      id
      name
      provided_name
      assetClass: asset_class
      description
      createdDate: created_date
      seller: company {
        ...HeaderCompany
      }
      in_progress_deal_id
    }
    ${COMPANY_FRAGMENT}
  `,
  deal: gql`
    fragment PoolHeaderDeal on Deal {
      id
      createdDate: created_date
      buyer {
        ...HeaderCompany
      }
      seller {
        ...HeaderCompany
      }
    }
    ${COMPANY_FRAGMENT}
  `,
};

const CREATE_DEAL_MUTATION = gql`
  mutation CreateDeal($listingId: ID!) {
    createDeal(listing_id: $listingId) {
      id
    }
  }
`;

function renderCounterpartyName(
  userCompany: GetUserCompany_user_company | null,
  seller?: PoolHeaderListing_seller | PoolHeaderDeal_seller,
  buyer?: PoolHeaderDeal_buyer
) {
  if (seller == null) {
    return '';
  }

  if (isUsersCompany(seller, userCompany) && buyer != null) {
    switch (buyer.__typename) {
      case 'PrivateCompany':
        return (buyer as PoolHeaderListing_seller_PrivateCompany).name;
      case 'UserCompany':
        return (buyer as PoolHeaderListing_seller_UserCompany).name;
      default:
        return buyer.type;
    }
  } else {
    switch (seller.__typename) {
      case 'PrivateCompany':
        return (seller as PoolHeaderListing_seller_PrivateCompany).name;
      case 'UserCompany':
        return (seller as PoolHeaderListing_seller_UserCompany).name;
      default:
        return seller.type;
    }
  }
}

function isUsersCompany(
  seller: PoolHeaderListing_seller | PoolHeaderDeal_seller,
  userCompany: GetUserCompany_user_company | null
): boolean {
  return 'id' in seller && seller.id === userCompany?.id;
}

type Props = {
  listing?: PoolHeaderListing | null;
  deal?: PoolHeaderDeal | null;
  showContactSellerButton?: boolean;
};

const PoolHeader = ({
  listing,
  deal,
  showContactSellerButton,
}: Props): JSX.Element => {
  const {
    state: { company },
  } = useContext(AuthContext);
  const [createDeal, { error, called, loading, data }] = useMutation<
    CreateDeal,
    CreateDealVariables
  >(CREATE_DEAL_MUTATION);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const sendInquiry = () => {
    setIsModalOpen(false);
    if (listing?.id) {
      createDeal({
        variables: {
          listingId: listing.id,
        },
      });
    }
  };

  const queriedForExistingDeal =
    !error && called && !loading && data != null && data?.createDeal.id != null;
  if (queriedForExistingDeal && data != null && listing != null) {
    return (
      <Navigate
        to={`/${INPROGRESS_PATH}/${listing.assetClass}/${data.createDeal.id}${DEAL_URL}${OVERVIEW_URL}`}
      />
    );
  } else {
    const shouldShowContactSellerButton =
      showContactSellerButton &&
      listing != null &&
      !isUsersCompany(listing.seller, company) &&
      !listing.in_progress_deal_id;

    const contactModal = shouldShowContactSellerButton && (
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size={SIZE.auto}
      >
        <ModalHeader>Contact seller - Start your Deal</ModalHeader>
        <ModalBody>
          <p>
            When you contact a Seller to being a deal, the Seller will receive
            your request including your institution's identifying information.
          </p>
          <p>To proceed, click "Contact Seller," otherwise click "Cancel."</p>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind={KIND.secondary}
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton onClick={() => sendInquiry()}>
            Contact seller
          </ModalButton>
        </ModalFooter>
      </Modal>
    );

    const assetClassText = listing?.assetClass ?? ``;
    const counterpartyText = renderCounterpartyName(
      company,
      deal?.seller || listing?.seller,
      deal?.buyer
    );
    const listedOnText = listing
      ? FIELD_CONFIG.origination_date.display(listing.createdDate)
      : ``;

    return (
      <>
        {listing && listing.in_progress_deal_id && (
          <Navigate
            to={`/${INPROGRESS_PATH}/${listing.assetClass}/${listing.in_progress_deal_id}${DEAL_URL}${OVERVIEW_URL}`}
          />
        )}
        <SummaryTitle>
          {listing?.name ?? '-'}
          {listing?.provided_name ? ` - ${listing?.provided_name}` : <></>}
        </SummaryTitle>
        <LabeledRow>
          <RowLabel>DEAL:</RowLabel>
          <RowScrollBox>
            <SummaryRow>
              <LabelAndValue
                label={'ASSET CLASS'}
                value={assetClassText.padStart(5)}
              />
              <LabelAndValue
                label={`COUNTERPARTY`}
                value={counterpartyText.padStart(15)}
              />
              <LabelAndValue
                label={`LISTED ON`}
                value={listedOnText.padStart(8)}
              />
              {deal != null && (
                <LabelAndValue
                  label={`DEAL START`}
                  value={FIELD_CONFIG.origination_date.display(
                    deal.createdDate
                  )}
                />
              )}
              {shouldShowContactSellerButton ? (
                <ContactButton onClick={() => !loading && setIsModalOpen(true)}>
                  CONTACT SELLER
                </ContactButton>
              ) : (
                <></>
              )}
            </SummaryRow>
          </RowScrollBox>
        </LabeledRow>
        {contactModal}
      </>
    );
  }
};

export default PoolHeader;
export { PoolHeaderFragments, CREATE_DEAL_MUTATION };
