import { BarChart } from './BarChart';
import { PieChart } from './PieChart';
import { StatesHeatMap } from './StatesHeatMap';

export const CHARTS = {
  Bar: BarChart,
  Pie: PieChart,
  States: StatesHeatMap,
};

export type ChartType = keyof typeof CHARTS;
export type { CommonChartProps } from './chartsCommons';