import React, { useCallback, } from 'react';
import { useQuery } from '@apollo/client';
import { StyledBody } from 'baseui/card';
import _ from 'lodash';

import { GET_FILTER_BOUNDS } from 'features/core/filter/FilterInputs';
import {
  GetFilterBounds,
  GetFilterBoundsVariables,
} from 'features/core/filter/__generated__/GetFilterBounds';
import {
  CarveDetailsCard_CarveDetailsCard,
} from 'features/deals/DealStages/EventActionCards/CarveDetailsCardBody/__generated__/CarveDetailsCard';
import { TradeCarveManager } from 'features/pages/marketplace/TradeCarveManager/TradeCarveManager';
import { criteriaToFilters } from 'features/pages/marketplace/TradeCarveManager/criteriaToFilterMapper';
import { CarveSpecification, CarveAction } from './types';
import { useCarveFunctions, useCarveSpecs } from './hooks';

type Props = { dealId: string } & CarveDetailsCard_CarveDetailsCard;

const TradeCarveManagerContainer: React.FC<Props> = (props) => {

  const {
    allCarveSpecs,
    createDraftCarve,
    handleCarveUpdateLogic,
    setDeletingCarves,
    updateCarveState,
    updatePerformanceSummary,
  } = useCarveSpecs(props.carves || []);
  const carveFunctions = useCarveFunctions(props.dealId);
  const { updateDealCarve } = carveFunctions;

  const { data } = useQuery<GetFilterBounds, GetFilterBoundsVariables>(
    GET_FILTER_BOUNDS,
    {
      variables: { id: props.dealId },
      fetchPolicy: 'cache-and-network',
    }
  );
  const filterBounds = data?.deal?.performance_summary;

  const handleUpdateCarve = useCallback(async (carveSpec: CarveSpecification) => {
    if (carveSpec.isDraft) {
      handleCarveUpdateLogic(carveSpec, () => updatePerformanceSummary(props.dealId, carveSpec.id, carveSpec.criteria));
    } else {
      handleCarveUpdateLogic(carveSpec, () => {
        const filters = criteriaToFilters(carveSpec.criteria);
        return updateDealCarve({
          variables: {
            input: {
              id: carveSpec.id,
              name: carveSpec.name,
              carve: filters,
            },
          }
        });
      });
    }
  }, [updateDealCarve, updatePerformanceSummary, handleCarveUpdateLogic]);

  const handleCarveAction = useCallback(async (action: CarveAction) => {
    switch (action.type) {
      case 'save-carve':
        updateCarveState(action.id, {
          name: action.name,
          id: action.newId,
          isDraft: false,
        });
        break;

      case 'create-draft-carve': {
        await createDraftCarve(props.dealId, action.id, action.name, action.criteria);
        break;
      }

      case 'update-carve': {
        await handleUpdateCarve(action.carveSpec);
        break;
      }

      case 'delete-carve':
        setDeletingCarves((carves) => [...carves, action.id]);
        break;
    }
  }, [createDraftCarve, handleUpdateCarve]);

  return (
    <StyledBody>
      <TradeCarveManager
        dealId={props.dealId}
        filterBounds={filterBounds}
        carveSpecifications={allCarveSpecs}
        originalOffer={props.deal_performance_summary}
        onCarveAction={handleCarveAction}
        carveFunctions={carveFunctions}
      />
    </StyledBody>
  );
};

export default TradeCarveManagerContainer;
export { default as CarveDetailsCardFragments } from './fragments';
