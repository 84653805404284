import { gql } from '@apollo/client';

export const ACCEPT_FUNDING_SCHEDULE = gql`
  mutation AcceptFundingSchedule($input: AcceptFundingScheduleInput!) {
    acceptFundingSchedule(input: $input) {
      id
      state {
        status
        blocker
      }
    }
  }
`;
