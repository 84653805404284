import { gql } from '@apollo/client';

const FundingScheduleDetailsCardFragments = {
  TimelineCard: gql`
    fragment FundingScheduleDetailsCard on TimelineCard {
      ... on FundingScheduleDetailsCard {
        actions {
          title
          type
          emphasis
        }
        bid {
          carve {
            field_name
            operator
            operand
            operandList
          }
        }
        can_edit_wire_details
        should_show_wire_details
        buyerFundingSchedule: funding_schedule {
          bid_basis_points
          purchase_date
        }
        presetWireDetails: wire_details {
          account_number
          bank_name
          routing_number
        }
      }
    }
  `,
};

export default FundingScheduleDetailsCardFragments;
