import { gql, useQuery } from '@apollo/client';
import { Spinner } from 'common-ui';
import { StratKey } from 'features/drilldown/Stratifications/startifications.config';
import { StratCard } from 'features/drilldown/Stratifications/StratCard';
import { StratsSelection } from 'features/drilldown/Stratifications/StratsSelection';
import { useState } from 'react';
import { styled } from 'style/ORSNNTheme';
import { CardWrapper } from './CardWrapper';
import { PoolSummaryFragments } from 'features/loanPool/PoolSummary';
import { StratificationsFragments } from 'features/drilldown/Stratifications';
import { ListingStratsCardListing } from './__generated__/ListingStratsCardListing';

const ListingStratsCardFragments = {
  listing: gql`
    fragment ListingStratsCardListing on Listing {
      id
      assetClass: asset_class
      performanceData: performance_summary {
        ...PoolSummaryPerformanceSummary
        ...StratificationsPerformanceSummary
      }
    }
  ${PoolSummaryFragments.performanceSummary}
  ${StratificationsFragments.performanceSummary}
  `
}

const StratCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;

  & > * {
    flex: 1 500px;
    max-height: 550px;
  }
`;

type StartsCardProps = ListingStratsCardListing & {
  loading: boolean;
};

function ListingStratsCard(props: StartsCardProps) {
  const [strats, setStrats] = useState<StratKey[]>([]);

  return (
    <CardWrapper {...props} onClick={() => null} dealId={''} active={true} name="strats">
      {
        !props.loading ? (
          <>
            <StratsSelection
              selectedStrats={strats}
              onSelectedStratsChange={setStrats}
            />
            <StratCardsContainer>
              {strats.map((strat) => (
                <StratCard
                  key={strat}
                  strat={strat}
                  assetClass={props.assetClass}
                  summary={props.performanceData}
                  avgSummary={props.performanceData}
                />
              ))}
            </StratCardsContainer>
          </>
        ) : null}
      <Spinner loading={props.loading} positionRelative={true} />
    </CardWrapper>
  );
}

export default ListingStratsCard;

export {
  ListingStratsCardFragments,
}