import React from 'react';
import { carveFilterConfigs } from './carveFilterConfigs';
import styled from 'styled-components';
import { RangeSlider } from 'common-ui';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import { theme } from 'style/ORSNNTheme';
import { Criteria } from './CreateCarveForm';
import { GetFilterBounds_deal_performance_summary as FilterBounds } from 'features/core/filter/__generated__/GetFilterBounds';
import { MultiValue } from 'react-select';

interface FilterInputProps {
  criteria: Criteria;
  filterBounds: FilterBounds;
  onChange: (criteria: Criteria) => void;
}

export const FilterInput: React.FC<FilterInputProps> = ({
  criteria,
  filterBounds,
  onChange,
}) => {
  const fieldConfig = carveFilterConfigs[criteria.field];

  if (!fieldConfig) {
    console.log(
      `No field config found for field ${criteria.field}. Skipping filter input.`
    );
    return <div>No field config found for field {criteria.field}</div>;
  }

  const InputComponent =
    fieldConfig.type === 'select-multi' ? (
      <SelectMultiFilterInput criteria={criteria} onChange={onChange} />
    ) : fieldConfig.type === 'range' ? (
      <RangeFilterInput
        filterBounds={filterBounds}
        criteria={criteria}
        onChange={onChange}
      />
    ) : null;

  return InputComponent;
};

const roundDownToNearest = (value: number, nearest: number) =>
  Math.floor(value / nearest) * nearest;
const roundUpToNearest = (value: number, nearest: number) =>
  Math.ceil(value / nearest) * nearest;

export const RangeFilterInput = ({
  criteria,
  filterBounds,
  onChange,
}: {
  criteria: Criteria;
  filterBounds: FilterBounds;
  onChange: (criteria: Criteria) => void;
}) => {
  const fieldConfig = carveFilterConfigs[criteria.field];
  if (
    fieldConfig === undefined ||
    fieldConfig.minBoundKey === undefined ||
    fieldConfig.maxBoundKey === undefined
  ) {
    return <div>This field is not filterable</div>;
  }

  if (!filterBounds) {
    return <div>Could not find filter bounds for field {criteria.field}</div>;
  }

  if (typeof criteria.min !== 'number') {
    return (
      <div>
        Invalid min value for field {criteria.field}: {criteria.min}
      </div>
    );
  }

  if (typeof criteria.max !== 'number') {
    return (
      <div>
        Invalid max value for field {criteria.field}: {criteria.max}
      </div>
    );
  }

  const filterMin = filterBounds[fieldConfig.minBoundKey];
  const filterMax = filterBounds[fieldConfig.maxBoundKey];

  const filterMinNumber = roundDownToNearest(
    Number(filterMin),
    fieldConfig.step || 1
  );
  const filterMaxNumber = roundUpToNearest(
    Number(filterMax),
    fieldConfig.step || 1
  );

  return (
    <RangeSliderContainer>
      <RangeSlider
        min={filterMinNumber}
        max={filterMaxNumber}
        step={fieldConfig?.step}
        defaultValue={[Number(criteria.min), Number(criteria.max)]}
        onChange={(sliderValues: number[]) => {
          onChange({
            ...criteria,
            min: sliderValues[0],
            max: sliderValues[1],
          });
        }}
        tooltip={{
          tipFormatter: fieldConfig.valueFormatter,
          tipProps: {
            style: {
              marginTop: '7px',
              color: theme.color.accentDefault,
            },
          },
        }}
        minMaxFormatter={fieldConfig.valueFormatter}
        withTooltip={true}
        alwaysShowTooltip={true}
      />
    </RangeSliderContainer>
  );
};

interface Option {
  label: string;
  value: string;
}

const findInitialValues = (criteria: Criteria, options: Option[]) => {
  if (!criteria.values) return [];
  return options.filter((option) => criteria.values?.includes(option.value));
};

export const SelectMultiFilterInput = ({
  criteria,
  onChange,
}: {
  criteria: Criteria;
  onChange: (criteria: Criteria) => void;
}) => {
  const options = carveFilterConfigs[criteria.field]?.filterOptions ?? [];
  const initialValues = findInitialValues(criteria, options);

  const handleChange = (newValue: MultiValue<Option>) => {
    const newValues = newValue.map((value) => value.value);

    onChange({
      ...criteria,
      values: newValues,
    });
  };

  return (
    <Dropdown
      isMulti={true}
      options={options}
      defaultValue={initialValues}
      onChange={handleChange}
    />
  );
};

export const StyledRangeSlider = styled(RangeSlider)``;
export const RangeSliderContainer = styled.div`
  padding-top: 20px;
`;
