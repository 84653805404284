import { MAIN_ROUTES_CONFIG, useGet2ndLevelPageUrl } from 'app-level/navigation';
import { useParams } from 'react-router-dom';
import { SUB_HEADER_CONFIGS } from './headers.config';
import { SubHeaderMenuItem } from '../SubHeaderMenuItem/SubHeaderMenuItem';
import { getStringAsKeyOf } from 'functions/typeUtils';

export function SubHeader(){
  const getUrl = useGet2ndLevelPageUrl();

  const {mainSegment} = useParams();
  if (!mainSegment) return null;

  const mainRouteKey = getStringAsKeyOf(MAIN_ROUTES_CONFIG, mainSegment.toLowerCase());
  if (!mainRouteKey) return null;

  const subHeadersConfig = SUB_HEADER_CONFIGS[mainRouteKey];

  return (
    <>
      <div className="header__nav_sub_spacer" />
      <div className="header__nav_sub">
        {subHeadersConfig.map((config) => (
          <SubHeaderMenuItem
            key={config.routeKey}
            headerConfig={config}
            to={config.routeKey ? getUrl(mainRouteKey, config.routeKey): ''}
          />
        ))}
      </div>
    </>
  );
}