import * as React from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  FloatingFocusManager,
} from '@floating-ui/react';
import { ContentDiv, FloatingOverlayStyled } from './Dialog.styles';

interface Props {
  children: React.ReactNode;
  dialog: DialogProps;
  contentComponent?: React.ComponentType<any>;
}

export interface DialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refs: ReturnType<typeof useFloating>['refs'];
  context: ReturnType<typeof useFloating>['context'];
}

export const useDialog = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  return { isOpen, openDialog, closeDialog, setIsOpen, refs, context };
};

export const Dialog = ({
  dialog,
  children,
  contentComponent: Content = ContentDiv,
}: Props) => {
  const { context } = useFloating({
    open: dialog.isOpen,
    onOpenChange: dialog.setIsOpen,
  });
  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' });

  const { getFloatingProps } = useInteractions([click, role, dismiss]);
  return (
    <FloatingPortal>
      {dialog.isOpen && (
        <FloatingOverlayStyled lockScroll>
          <FloatingFocusManager context={context}>
            <div ref={dialog.refs.setFloating} {...getFloatingProps()}>
              <Content>{children}</Content>
            </div>
          </FloatingFocusManager>
        </FloatingOverlayStyled>
      )}
    </FloatingPortal>
  );
};

