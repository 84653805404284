import React from 'react';
import './radio-button-group.scss';

interface RadioButtonProps {
  value: string;
  name: string;
  checked: boolean;
  label: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  name,
  checked,
  label,
  onChange,
  className = '',
}) => (
  <label className={`radio-option${checked ? ' radio-option-checked' : ''} ${className}`}>
    <input
      className="radio-button"
      type="radio"
      id={value}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    {label}
  </label>
);

export default RadioButton;
