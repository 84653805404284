import { gql } from '@apollo/client';
import BidCardFragments from './BidCardBody/fragments';
import BidTermsCardFragments from './BidTermsCardBody/fragments';
import CarveDetailsCardFragments from './CarveDetailsCardBody/fragments';
import CounterpartyCardFragments from './CounterpartyCardBody/fragments';
import DealDocumentsCardFragments from './DealDocumentsCardBody/fragments';
import FundingScheduleDetailsCardFragments from './FundingScheduleDetailsCardBody/fragments';
import FundingScheduleInputCardFragments from './FundingScheduleInputCardBody/fragments';
import PlaceBidCardFragments from './PlaceBidCardBody/fragments';
import RequestDataCardFragments from './RequestDataCardBody/fragments';
import WireConfirmationCardFragments from './WireConfirmationCardBody/fragments';
import { StratsCardFragments } from 'features/deals/dealCards/StratsCard';
import { LoansCardFragments } from 'features/deals/dealCards/LoansCard';

const EventActionCardFragments = {
  TimelineCard: gql`
    fragment EventActionCardTimelineCard on TimelineCard {
      last_updated_date
      ...CounterpartyCard
      ...LoansCard
      ...StratsCard
      ...DealDocumentsCard
      ...CarveDetailsCard
      ...BidTermsCard
      ...BidCard
      ...PlaceBidCard
      ...RequestDataCard
      ...FundingScheduleInputCard
      ...FundingScheduleDetailsCard
      ...WireConfirmationCard
    }
    ${CounterpartyCardFragments.TimelineCard}
    ${LoansCardFragments.TimelineCard}
    ${StratsCardFragments.TimelineCard}
    ${DealDocumentsCardFragments.TimelineCard}
    ${BidTermsCardFragments.TimelineCard}
    ${CarveDetailsCardFragments.TimelineCard}
    ${RequestDataCardFragments.TimelineCard}
    ${BidCardFragments.TimelineCard}
    ${PlaceBidCardFragments.TimelineCard}
    ${FundingScheduleInputCardFragments.TimelineCard}
    ${FundingScheduleDetailsCardFragments.TimelineCard}
    ${WireConfirmationCardFragments.TimelineCard}
  `,
};

export default EventActionCardFragments;
