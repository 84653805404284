import { gql, useQuery } from '@apollo/client';
import { ListingField, ListingFilter } from '__generated__/globalTypes';
import { GetMarketplaceListings, GetMarketplaceListingsVariables, GetMarketplaceListings_listings } from './__generated__/GetMarketplaceListings';

export const MarketplaceDatatableFragments = {
  entry: gql`
    fragment MarketplaceDatatableListing on PublicListing {
      id
      name
      provided_name
      asset_class
      created_date
      loan_count
      pricing_type
      servicing_preference
      servicing_fee_rate
      t_current_balance_cents
      top_states
      wa_borrower_credit_score
      wa_coupon
      wa_current_balance_cents
      wa_dti
      wa_loan_age_months
      wa_remaining_loan_terms_months
      wa_ltv
      company {
        type
        obf_assets
        obf_net_worth
      }
      in_progress_deal_id
    }
  `,
  company: gql`
    fragment MarketplaceDatatableCompany on Company {
      type
      obf_assets
      obf_net_worth
    }
  `,
};
const query = gql`
  query GetMarketplaceListings(
    $sortField: ListingField
    $sortDirection: SortDirection
    $filters: [ListingFilter!]
    $pagination: Pagination
  ) {
    listings(
      sort: { sort_field: $sortField, sort_direction: $sortDirection }
      filters: $filters
      pagination: $pagination
    ) {
      id
      ...MarketplaceDatatableListing
    }
    listingCount(
      sort: { sort_field: $sortField, sort_direction: $sortDirection }
      filters: $filters
    ) {
      count
    }
  }
  ${MarketplaceDatatableFragments.entry}
`;

const EMPTY_ARR: GetMarketplaceListings_listings[] = [];

export function useMarketPlaceListings_gql(filters?: ListingFilter[]) {
  const { loading, data, fetchMore, previousData } = useQuery<
    GetMarketplaceListings,
    GetMarketplaceListingsVariables
  >(query, {
    variables: {
      pagination: {offset:0, page_size: 100},
      // pagination: createPaginationParam(paginationCursors, state.pagination),
      // sortDirection: state.sort.sortDirection,
      // sortField: state.sort.sortField,
      filters: filters || [],
      sortField: ListingField.created_date_seconds,
    },
    fetchPolicy: 'cache-and-network',
  });

  const listings = data?.listings || previousData?.listings || EMPTY_ARR;
  const listingCount = data?.listingCount.count || previousData?.listingCount.count ||  0;

  return {listings, listingCount, loading};
}