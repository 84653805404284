import { useState } from 'react';
import { PortfolioLayout } from './Portfolio/PortfolioLayout';
import {
  DataDisplayTable,
  DataDisplayTableProps,
  ModalNotStyled,
} from 'common-ui';
import {
  GetListings,
  GetListings_user_company_listings as Listing,
} from 'query/__generated__/GetListings';
import { GET_LISTINGS } from 'query/getListings';
import { useMutation, useQuery } from '@apollo/client';
import { RowSelectionState } from '@tanstack/table-core';
import { ColumnDef } from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import {
  CreatePublicOffering,
  CreatePublicOfferingVariables,
} from 'mutation/__generated__/CreatePublicOffering';
import { CREATE_PUBLIC_OFFERING } from 'mutation/createPublicOffering';
import CreateOfferingForm, { FormValues } from './CreateOfferingForm';
import { styled } from 'style/ORSNNTheme';
import { Button } from 'baseui/button';

interface PoolTableProps {
  data: Listing[];
  selection?: DataDisplayTableProps<Listing, keyof Listing>['selection'];
}

const MarketStatusBase = styled.div`
  display: inline-block;
  background-color: #6fcf97;
  width: 152px;
  text-align: center;
  color: #031b0d;
  padding: 4px 8px;
  border-radius: 4px;
  line-height: 16px;

  font-family: 'PT Sans Narrow';
  font-size: 14px;
  font-weight: 700;
`;

const InMarketStatus = styled(MarketStatusBase)`
  background-color: #6fcf97;
  color: #031b0d;
`;

const OffMarketStatus = styled(MarketStatusBase)`
  background-color: #32363e;
  color: #fff;
`;

const PoolTable = ({ data, selection }: PoolTableProps) => {
  const { formatNumber } = useIntl();

  const formatCentsToDollars = (cents: number): string => {
    if (isNaN(cents) || cents == null) return '-';
    return formatNumber(cents / 100, { style: 'currency', currency: 'USD' });
  };

  const toPercentage = (num: number): string => {
    if (isNaN(num) || num == null) return '-';
    return formatNumber(num, { style: 'percent', minimumFractionDigits: 2 });
  };

  const formatDateTime = (date: string): string => {
    if (!date) return '-';
    const luxonDate = DateTime.fromISO(date);
    return luxonDate.toFormat("MM/dd/yy ' @ ' h:mma ZZZZ");
  };

  const columns: ColumnDef<Listing, keyof Listing>[] = [
    {
      accessorKey: 'is_public',
      header: 'Pool Status',
      cell: (props) => {
        return props.getValue() ? (
          <InMarketStatus>In Market</InMarketStatus>
        ) : (
          <OffMarketStatus>Off Market</OffMarketStatus>
        );
      },
    },
    {
      accessorKey: 'provided_name',
      header: 'Loan Pool Name',
    },
    {
      accessorKey: 'created_date',
      header: 'Date Created',
      cell: (props) => <span>{formatDateTime(props.getValue())}</span>,
    },
    {
      accessorKey: 'asset_class',
      header: 'Asset Class',
    },
    {
      accessorKey: 't_current_balance_cents',
      header: 'UPB',
      cell: (props) => (
        <span>{formatCentsToDollars(Number(props.getValue()))}</span>
      ),
    },
    {
      accessorKey: 'loan_count',
      header: '# Loans',
    },
    {
      accessorKey: 'wa_coupon',
      cell: (props) => <span>{toPercentage(Number(props.getValue()))}</span>,
      header: 'GWAC',
    },
  ];
  return (
    <DataDisplayTable
      data={data}
      columns={columns}
      selection={selection}
      noDataMessage="no data"
    />
  );
};

const PoolManager = () => {
  const {
    data,
    error: queryError,
  } = useQuery<GetListings>(GET_LISTINGS, {
    fetchPolicy: 'cache-and-network',
  });
  const [createPublicOfferingMutation] = useMutation<
    CreatePublicOffering,
    CreatePublicOfferingVariables
  >(CREATE_PUBLIC_OFFERING);

  const [selectedPools, setSelectedPools] = useState<RowSelectionState>({});

  /*
   * User has submitted the "Create An Offering" modal form
   */
  const createOffering = async (formValues: FormValues) => {
    const listings = data?.user.company.listings;

    const selectedListings =
      listings?.filter((_, index) => selectedPools[index]) || [];

    // TODO(kentskinner): is it correct to create offerings for each selected item?
    try {
      for (const listing of selectedListings) {
        await createPublicOfferingMutation({
          variables: {
            ...formValues,
            listingId: listing.id,
          },
        });
      }
    } catch (error) {
      console.error('Error in creating offering:', error);
    }
  };

  return (
    <PortfolioLayout
      actions={
        <ModalNotStyled
          trigger={
            <Button disabled={Object.keys(selectedPools).length === 0}>
              Create Offering
            </Button>
          }
        >
          {({ closeModal }) => (
            <CreateOfferingForm
              closeModal={closeModal}
              createOffering={createOffering}
            />
          )}
        </ModalNotStyled>
      }
    >
      <>
        {queryError ? (
          <div>Error retrieving pools</div>
        ) : (
          <PoolTable
            data={data?.user.company.listings ?? []}
            selection={{
              selected: selectedPools,
              onSelectionChange: setSelectedPools,
            }}
          />
        )}
      </>
    </PortfolioLayout>
  );
};

export default PoolManager;
