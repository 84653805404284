/**
 * Design here: https://www.figma.com/file/urbIZ8Fnnln4Knx1lfo7mV/High-Level-Screens?type=design&node-id=5089-146902&mode=design&t=9HZlSXWSxwj25KKG-0
 */
import styled from 'styled-components';

import mainTaskComplete from '../../../assets/svgs/main-task-complete.svg';
import mainTaskNotStarted from '../../../assets/svgs/main-task-not-started.svg';
import mainTaskPending from '../../../assets/svgs/main-task-pending.svg';
import subTaskComplete from '../../../assets/svgs/subtask-complete.svg';
import { Button } from 'baseui/button';
import { useDealRoomContext } from './DealRoomContext';
import { gql } from '@apollo/client';
import { theme } from 'style/ORSNNTheme';
import { FC } from 'react';
import { StatusTrackerDeal } from './__generated__/StatusTrackerDeal';
import { TimelineStatus } from '__generated__/globalTypes';
import { Tooltip } from 'react-tooltip';

const StatusTrackerFragments = {
  deal: gql`
    fragment StatusTrackerDeal on Deal {
      state {
        status
        label
        tooltip
        blocker
      }
      timeline {
        title
        subject
        status
        calls_to_action {
          title
        }
      }
      listing {
        name
      }
    }
  `,
}

const Container = styled.div`
  position: relative;
  padding: 2px 20px 20px 20px;
  background-color: #171717;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DealName = styled.div`
  font-size: 18px;
  color: #f2f2f2;
`;

const AccessLabel = styled.span`
  color: #5dc389;
  font-size: 14px;
`;

const Track = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 17px;
  width: 1px;
  background-color: #2b2b2b;
`;

const TaskBase = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-color: #171717;
  position: absolute;
  left: 5px;
  width: 26px;
  height: 26px;

  top: -5px;
`;

const TaskComplete = styled(TaskBase)`
  background-image: url(${mainTaskComplete});
`;

const SubtaskComplete = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-color: #171717;
  background-image: url(${subTaskComplete});
  width: 10px;
  height: 10px;
  position: absolute;
  left: 12px;
  top: 1px;
`;

const TaskInProgress = styled(TaskBase)`
  background-image: url(${mainTaskPending});
`;

const TaskNotStarted = styled(TaskBase)`
  background-image: url(${mainTaskNotStarted});
`;

const TaskList = styled.div`
  padding: 0;
  position: relative;
`;

const TaskItem = styled.div`
  margin: 20px 0;
  padding-left: 40px;
  position: relative;
`;

const SubtaskItem = styled.div`
  margin: 20px 0;
  font-size: 10px;
  color: #bbc5d7;
  padding-left: 40px;
  position: relative;
`;

const TaskName = styled.span`
  font-size: 12px;
  color: #909199;
`;

const TaskDescription = styled.div`
  font-size: 10px;
  color: #909199;
  margin-top: 5px;
`;

const SubtaskName = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const StatusLabel = styled.span`
  margin-left: 20px;
  padding-left: 5px;
  color: #ffcf74;
  font-family: 'PT Sans Narrow';
  font-weight: 400;
  font-size: 10px;
  border-left: 1.5px solid #ffcf74;
`;

const RequestAccessButton = styled(Button).attrs({
  kind: 'primary',
  size: 'compact',
})`
  position: absolute;
  bottom: -5px;
  right: 20px;
  background-color: #731172 !important;
`;

const StatusColors = {
  UserAction: theme.color.warningDefault,
  CounterpartyAction: theme.color.grey900,
  Complete: theme.color.successDefault,
  Rejected: theme.color.grey900,
};

type StatusTrackerProps = {
  deal: StatusTrackerDeal | null;
}

const StatusTracker: FC<StatusTrackerProps> = (props) => {
  const { deal } = props;
  // TODO: use same query to render the cards and these subtasks, then useRef to scroll to the correct card
  const { setCurrentCard } = useDealRoomContext();

  return (
    <Container>
      <Header>
        <DealName>Deal - {deal?.listing.name}</DealName>
        <AccessLabel data-tooltip-id={deal?.listing.name}>{deal?.state.label}</AccessLabel>
        <Tooltip id={deal?.listing.name}>{deal?.state.tooltip}</Tooltip>
      </Header>

      <TaskList>
        <Track />
        {deal?.timeline.map((stage) => {
          return (
            <div key={stage.title}>
              <TaskItem>
                {stage.status === TimelineStatus.COMPLETE ? (
                  <TaskComplete />
                ) : stage.status === TimelineStatus.PENDING ? (
                  <TaskNotStarted />
                ) : (
                  <TaskInProgress />
                )}
                <div>
                  <TaskName>{stage.title}</TaskName>
                  {stage.status && stage.status !== TimelineStatus.PENDING && <StatusLabel>{stage.status}</StatusLabel>}
                </div>
                <TaskDescription>{stage.subject}</TaskDescription>
              </TaskItem>
              {stage.calls_to_action?.map((cta) => (
                <SubtaskItem key={cta.title}>
                  <SubtaskComplete />
                  <SubtaskName>
                    {cta.title}
                  </SubtaskName>
                </SubtaskItem>
              ))}
            </div>
          );
        })}
      </TaskList>

      <RequestAccessButton>Start New Bid</RequestAccessButton>
    </Container>
  );
};

export default StatusTracker;
export {
  StatusTrackerFragments,
}