import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import Uppy, { FileProgress, UppyFile } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import DropTarget from '@uppy/drop-target';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { SvgIcon, BaseButton } from 'common-ui';
import { GetPresignedUrlFunction } from 'features/loanTape/GetPresignedUrlFunction';
import { GetFilePostUrls, GetFilePostUrlsVariables } from 'mutation/__generated__/GetFilePostUrls';
import { FileType } from '__generated__/globalTypes';
import { useApolloClient } from '@apollo/client';
import { ProgressIndicator } from 'common-ui/ProgressIndicator/ProgressIndicator';
import GET_FILE_POST_URLS_MUTATION from 'mutation/getFilePostUrls';

import './file-upload.scss';

interface PassedProps {
  id: string;
  setIsOpen: (isOpen: boolean) => void;
  setRefetchData: (refetchData: boolean) => void;
}

// TODO: make component re-usable and move into a shared folder
const FileUpload = ({
  id,
  setIsOpen,
  setRefetchData
}: PassedProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<UppyFile[]>([]);
  const [error, setError] = useState<string>('');
  const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const client = useApolloClient();

  const uppy = useMemo(() => {
    return new Uppy({
      restrictions: {
        allowedFileTypes: ['.pdf', '.doc', '.csv', '.tiff'],
      }
    })
      .use(AwsS3, {})
      .use(DropTarget, {
        target: `#dropzone-${id}`,
      });
  }, [id]);

  const handleRestrictionFailed = (file?: UppyFile, error?: Error) => {
    setError(error?.message || '');
  };

  const handleFileAdded = (file: UppyFile) => {
    setFiles((prevFiles: UppyFile[]) => [...prevFiles, file]);
    setFileProgress((prevProgress) => ({
      ...prevProgress,
      [file.id]: 0,
    }));
  };

  const handleUploadProgress = (file: UppyFile | undefined, progress: FileProgress) => {
    setIsLoading(true);

    if (file) {
      const percentage = (progress.bytesUploaded / progress.bytesTotal) * 100;
      setFileProgress((prevProgress) => ({
        ...prevProgress,
        [file.id]: percentage,
      }));
    }
  };

  const handleClose = useCallback(() => {
    setIsLoading(false);
    setRefetchData(true);
    setIsOpen(false);
  }, [setIsOpen, setRefetchData]);

  // total progress for all uploaded files
  const handleProgress = useCallback((progress: number) => {
    if (progress === 100) {
      handleClose();
    }
  }, [handleClose]);

  // temporary upload error state until we get clearer error handling requirements
  const handleUploadError = () => {
    setError('Something went wrong. Please refresh and try again.');
  };

  useEffect(() => {
    uppy.on('restriction-failed', handleRestrictionFailed);
    uppy.on('file-added', handleFileAdded);
    uppy.on('upload-progress', handleUploadProgress);
    uppy.on('progress', handleProgress);
    uppy.on('upload-error', handleUploadError);

    return () => {
      uppy.off('restriction-failed', handleRestrictionFailed);
      uppy.off('file-added', handleFileAdded);
      uppy.off('upload-progress', handleUploadProgress);
      uppy.off('progress', handleProgress);
      uppy.off('upload-error', handleUploadError);
      uppy.close(); // Close Uppy when the component is unmounted
    };
  }, [handleProgress, id, uppy]);

  const handleUpload = () => {
    uppy.upload();
  };

  const handleCancelUpload = (file: UppyFile) => {
    uppy.removeFile(file.id);
    setFiles((prevFiles: UppyFile[]) => prevFiles.filter((prevFile: UppyFile) => prevFile.id !== file.id));
  };

  /*
   * Presigned URL for Amazon S3 upload.
   * TODO: consolidate this function to be reused across both the documents library and loan tape page
   */

  const getPresignedUrl: GetPresignedUrlFunction = useCallback(async (
    _fileType: string,
    fileName: string,
    contentType: string): Promise<string> => {
    const query = {
      mutation: GET_FILE_POST_URLS_MUTATION,
      variables: {
        parentId: '',
        fileType: id as FileType,
        fileName: fileName,
        contentType: contentType,
      }
    };

    const result = await client.mutate<GetFilePostUrls, GetFilePostUrlsVariables>(query);
    if (!result.data) {
      throw new Error('Could not obtain a pre-signed upload URL.');
    }
    return result.data.getFilePostUrl;
  }, [client, id]);

  useEffect(() => {
    uppy.getPlugin('AwsS3')?.setOptions({
      getUploadParameters: async (file: UppyFile) => {
        try {
          const presignedUrl = await getPresignedUrl(
            'DOCUMENTS_LIBRARY',
            file.name,
            file.type || '',
            undefined, // TODO: update this to add optional params to type
          );

          return {
            method: 'PUT',
            url: presignedUrl,
            fields: {},
            headers: {},
          };
        } catch (err) {
          console.error(err);

          // temporary upload error state until we get clearer error handling requirements
          setError('Something went wrong. Please refresh and try again.');
          return null;
        }
      },
    });
  }, [getPresignedUrl, uppy]);

  const handleFileInputClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // reset error message
    if (error) {
      setError('');
    }

    const addedFiles = Array.from(event.target.files as FileList);

    addedFiles.forEach((file) => {
      uppy.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file,
      });
    });

    // reset input value to enable re-adding same file name
    event.target.value = '';
  };

  return (
    <div className="file_upload">
      <div id={`dropzone-${id}`} className="file_upload__container">
        <SvgIcon name="document-upload" />
        <div className="file_upload__description">Drop your files here to upload...</div>
        <BaseButton
          label="Browse to upload"
          size="medium"
          type="secondary"
          onClick={handleFileInputClick}
          disabled={isLoading}
        >
          Browse to Upload
        </BaseButton>
        <input
          type="file"
          multiple
          ref={hiddenFileInput}
          onChange={handleFileInputChange}
          style={{ display: 'none' }}
        />
      </div>
      {
        error && <div className="file_upload__error">{error}</div>
      }
      {
        files.length > 0 && (
          <>
            <div className="file_upload__details_container">
              {files?.map((file: UppyFile) => (
                <div key={file.id} className="file_upload__progress_bar">
                  <span className="file_upload__progress_bar_file_name">{file.name}</span>
                  {fileProgress[file.id] > 0 && (
                    <ProgressIndicator percent={fileProgress[file.id]} />
                  )}
                  <button
                    className="file_upload__progress_bar_close"
                    onClick={() => handleCancelUpload(file)}
                    disabled={isLoading}
                  >
                    <SvgIcon name="close-round" />
                  </button>
                </div>
              ))}
            </div>
            <div className="file_upload__footer">
              <BaseButton
                label="Cancel"
                size="medium"
                type="secondary"
                onClick={handleClose}
              >
                Cancel
              </BaseButton>
              <BaseButton
                label="Upload"
                size="medium"
                type="primary"
                onClick={handleUpload}
                disabled={isLoading}
              >
                Upload
              </BaseButton>
            </div>
          </>
        )
      }
    </div>
  );
};

export default FileUpload;