import { useState } from 'react';
import { BaseButton, Modal } from 'common-ui';
import FileUpload from 'features/pages/portfolio/DocumentLibrary/FileUpload/FileUpload';
import DocumentDetails from 'features/pages/portfolio/DocumentLibrary/DocumentDetails';
import './document-category.scss';

export interface DocumentCategoryProps {
  id: string;
  name: string;
}

const DocumentCategory = ({
  id,
  name,
}: DocumentCategoryProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [refetchData, setRefetchData] = useState<boolean>(false);

  return (
    <div className="document-category">
      <div className="document-category__header">
        <div className="document-category__name">
          {name.toUpperCase()}
        </div>
        <div className="document-category__upload">
          <BaseButton
            icon="pill-plus"
            iconFill="#BDBDBD"
            isIconRight
            label="Document Upload"
            size="medium"
            type="tertiary"
            onClick={() => setIsModalOpen(true)}
          >
            Add a document
          </BaseButton>
        </div>
      </div>
      <div className="document-category__subheader">Checkbox selection enables template availabilty</div>
      <DocumentDetails refetchData={refetchData} setRefetchData={setRefetchData} fileType={id} />
      {
        isModalOpen && (
          <Modal
            headerText="Add A Document"
            subText="New document will be added to following section"
            caption={`"${name}"`}
            setIsOpen={setIsModalOpen}
            type="document-library"
          >
            <FileUpload id={id} setIsOpen={setIsModalOpen} setRefetchData={setRefetchData} />
          </Modal>
        )
      }
    </div>
  );
};

export default DocumentCategory;