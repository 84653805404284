import AwsS3 from '@uppy/aws-s3';
import Uppy, { SuccessResponse, UppyFile } from '@uppy/core';
import { DragDrop } from '@uppy/react';
import { Card, CardHeading, CardInstructions } from '../styles';
import {
  DashboardContainer,
  Placeholder,
  SectionHeader,
  UploadForm,
} from './LoanTapeUploader.styles';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import { AssetClass, FileState } from '__generated__/globalTypes';
import { AssetClassOptionType } from './AssetClassOptionType';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SingleValue } from 'react-select';
import { usePreSignedUrlQuery } from './usePresignedUrlQuery';
import { InProgressOverlay } from '../InProgressOverlay';
import { GetUploadedFilesWithHeaders_user_company_files as ServerFile } from 'query/__generated__/GetUploadedFilesWithHeaders';
import { ConfirmUploadDialog } from './ConfirmUploadDialog';
import useUppySetup from './useUppySetup';
import useUppyEventHandlers from './useUppyEventHandlers';
import { useFilePolling } from './useFilePolling';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { DialogActions, StyledHeading } from 'common-ui/Dialog.styles';
import { BaseButton } from 'common-ui';

// The instance of uppy is created here based on the documentation at:
// https://uppy.io/docs/react/#use
// Quote:
//
//   "The Uppy instance should not live inside the component but outside of it (for class
//    components, it should not be instantiated inside the render() method)."
const uppy = new Uppy({ autoProceed: false }).use(AwsS3);

export const assetClassOptions: AssetClassOptionType[] = Object.values(
  AssetClass
).map((assetClass) => ({
  value: assetClass,
  label: assetClass.toString(),
}));

enum UploadState {
  IDLE,
  CONFIRM,
  UPLOADING,
  WAITING_FOR_STATUS,
  SUCCESS,
  ERROR,
}

const calculateProgress = (
  uploadState: UploadState,
  uploadProgress: number
): number => {
  switch (uploadState) {
    case UploadState.UPLOADING:
      return uploadProgress * 0.5;
    case UploadState.WAITING_FOR_STATUS:
      return 50;
    case UploadState.SUCCESS:
      return 100;
    default:
      return 0;
  }
};

interface SingleFileLoadTapeUploaderProps {
  onSuccess: (file: ServerFile) => void;
}

const SingleFileLoadTapeUploader: React.FC<SingleFileLoadTapeUploaderProps> = ({
  onSuccess,
}) => {
  const { pollFiles } = useFilePolling();

  const { getPresignedUrl } = usePreSignedUrlQuery();
  const [loanType, setLoanType] = useState<AssetClass>();
  useUppySetup(uppy, getPresignedUrl, loanType);

  const [uploadState, setUploadState] = useState<UploadState>(UploadState.IDLE);
  const uploadStateRef = useRef<UploadState>(UploadState.IDLE);

  // Initialize and cleanup the polling abort controller
  const abortControllerRef = useRef<AbortController | null>(null);
  useEffect(() => {
    return () => {
      abortControllerRef.current?.abort();
    };
  }, []);

  useEffect(() => {
    // Update the ref whenever uploadState changes
    uploadStateRef.current = uploadState;
  }, [uploadState]);

  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const handleAssetClassSelect = useCallback(
    (selectedOption: SingleValue<AssetClassOptionType>) => {
      if (selectedOption) {
        setLoanType(selectedOption.value);
      }
    },
    []
  );

  const resetToIdle = () => {
    abortControllerRef.current?.abort();
    uppy.cancelAll();
    setUploadState(UploadState.IDLE);
  };

  const filename = uppy.getFiles()[0]?.name;

  const onFileAdded = (file: UppyFile) => {
    if (file) {
      setUploadState(UploadState.CONFIRM);
    }
  };

  const cancelUpload = () => {
    resetToIdle();
  };

  const confirmUpload = () => {
    setUploadProgress(0);
    setUploadState(UploadState.UPLOADING);
    uppy.upload().then((result) => {
      if (result.failed.length === 0) {
        console.log('Upload successful');
      } else {
        console.log('Some uploads failed:', result.failed);
      }
    });
  };

  const onProgress = (progress: number) => {
    setUploadProgress(progress);
  };

  // Once the file is uploaded S3, we wait for its status to become NEEDS_MAPPING.
  const waitForFileStatusChange = useCallback(
    async (file: UppyFile) => {
      setUploadState(UploadState.WAITING_FOR_STATUS);

      abortControllerRef.current = new AbortController();

      const serverFile = await pollFiles((server_file) => {
        const isMatch =
          server_file.fileName === file.name &&
          (server_file.state === FileState.NEEDS_MAPPING ||
            server_file.state === FileState.ERROR ||
            server_file.state === FileState.ERROR_PASSWORD);

        return isMatch;
      }, abortControllerRef.current?.signal).catch((error) => {
        setUploadState(UploadState.ERROR);
        return;
      });

      if (uploadStateRef.current !== UploadState.WAITING_FOR_STATUS) {
        // The upload was canceled.
        return;
      }

      if (
        !serverFile ||
        serverFile.state === FileState.ERROR_PASSWORD ||
        serverFile.state === FileState.ERROR
      ) {
        setUploadState(UploadState.ERROR);
        return;
      }
      onSuccess(serverFile);
      setUploadState(UploadState.SUCCESS);
    },
    [pollFiles, onSuccess]
  );

  const onFileFinishedUploadingToS3 = useCallback(
    async (
      file:
        | UppyFile<Record<string, unknown>, Record<string, unknown>>
        | undefined,
      _response: SuccessResponse
    ) => {
      if (file && uploadStateRef.current === UploadState.UPLOADING) {
        waitForFileStatusChange(file);
      }
    },
    [waitForFileStatusChange]
  );

  useUppyEventHandlers({
    uppy,
    onFileAdded,
    onProgress,
    onUploadSuccess: onFileFinishedUploadingToS3,
  });

  return (
    <Card>
      <CardHeading>Upload Your Tapes</CardHeading>
      <CardInstructions>
        Upload one of your loan tapes to add to your portfolio.
      </CardInstructions>
      <UploadForm>
        <SectionHeader>SELECT ASSET CLASS</SectionHeader>
        <Dropdown
          isMulti={false}
          options={assetClassOptions}
          onChange={handleAssetClassSelect}
          value={
            loanType ? { value: loanType, label: loanType.toString() } : null
          }
        />
        <DashboardContainer>
          {loanType ? (
            <DragDrop uppy={uppy} />
          ) : (
            <Placeholder>
              Select an asset type above to upload files.
            </Placeholder>
          )}
        </DashboardContainer>
      </UploadForm>
      <ConfirmUploadDialog
        open={uploadState === UploadState.CONFIRM}
        onClose={cancelUpload}
        onConfirm={confirmUpload}
        filename={filename}
      />
      <InProgressOverlay
        open={
          uploadState === UploadState.UPLOADING ||
          uploadState === UploadState.WAITING_FOR_STATUS ||
          uploadState === UploadState.SUCCESS
        }
        progress={calculateProgress(uploadState, uploadProgress)}
        cancel={cancelUpload}
        onSuccessClicked={() => {
          resetToIdle();
        }}
        successButtonText={'Next: Map Required Fields'}
        isSuccess={uploadState === UploadState.SUCCESS}
        title={
          uploadState === UploadState.SUCCESS
            ? 'Success!'
            : 'Uploading in progress...'
        }
        description={
          uploadState === UploadState.SUCCESS ? 'Upload complete' : filename
        }
      />

      <ErrorDialog uploadState={uploadState} onErrorDialogClose={resetToIdle} />
    </Card>
  );
};

interface ErrorDialogProps {
  uploadState: UploadState;
  onErrorDialogClose: () => void;
}

const ErrorDialog = ({ uploadState, onErrorDialogClose }: ErrorDialogProps) => {
  const dialog = useDialog();
  useEffect(() => {
    dialog.setIsOpen(uploadState === UploadState.ERROR);
  }, [uploadState]);
  return (
    <Dialog dialog={dialog}>
      <StyledHeading>Upload Error</StyledHeading>
      <div>There was an error uploading your file.</div>
      <DialogActions>
        <BaseButton size="medium" label="cancel" onClick={onErrorDialogClose}>
          OK
        </BaseButton>
      </DialogActions>
    </Dialog>
  );
};

export default SingleFileLoadTapeUploader;
