import { gql, useMutation } from '@apollo/client';
import { BaseButton, Modal } from 'common-ui';
import { CREATE_DEAL_MUTATION } from 'features/loanPool/PoolHeader';
import { CreateDeal, CreateDealVariables } from 'features/loanPool/__generated__/CreateDeal';
import { ListingSidebarListing } from './__generated__/ListingSidebarListing';
import { useState } from 'react';
import { useGet2ndLevelPageUrl } from 'app-level/navigation';
import { useNavigate } from 'react-router-dom';

const RequestAccessModal: React.FC<{ setIsOpen: (isOpen: boolean) => void; onAccept: () => void }> = ({
  setIsOpen,
  onAccept,
}) => {
  return (
    <Modal
      headerText="Request Access"
      subText="Access"
      caption="By requesting access, you are asking the company for permission to access this offering. You will share your identity with the request."
      setIsOpen={setIsOpen}
    >
      <div>
        <BaseButton type="primary" label="Accept" onClick={onAccept}>
          Accept
        </BaseButton>
        <BaseButton type="secondary" label="Decline" onClick={() => setIsOpen(false)}>
          Decline
        </BaseButton>
      </div>
    </Modal>
  );
};

const ListingSidebarFragments = {
  listing: gql`
    fragment ListingSidebarListing on Listing {
      id
      name
      company {
        __typename
      }
    }
  `,
}

type ListingSidebarProps = {
  listing: ListingSidebarListing;
}

const ListingSidebar = (props: ListingSidebarProps): JSX.Element => {
  const getUrl = useGet2ndLevelPageUrl();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [createDeal, { error, called, loading, data }] = useMutation<
    CreateDeal,
    CreateDealVariables
  >(CREATE_DEAL_MUTATION);
  const navigate = useNavigate();

  const isOtherCompanyListing = props.listing.company.__typename !== 'UserCompany';

  // TODO onclick should open a modal with a message about sharing access
  // TODO if the user accepts, call createDeal
  const handleAccept = () => {
    setModalIsOpen(false);

    createDeal({ variables: { listingId: props.listing.id } }).then(response => {
      const dealId = response.data?.createDeal.id;
      if (dealId) {
        // TODO use the navigation state to pass the dealId to the deal page and navigate instead of going back to the table
        navigate(getUrl('marketplace', 'deal'), { state: { dealId } });
      }
    });
  };

  return (
    <div className="progress">
      {isOtherCompanyListing ? (
        <>
          <BaseButton
            type="secondary"
            size="medium"
            label="Create Offering"
            onClick={() => setModalIsOpen(true)}
          >
            Request Access
          </BaseButton>

          {modalIsOpen ? <RequestAccessModal setIsOpen={setModalIsOpen} onAccept={handleAccept} /> : null}
        </>
      ) : null}
    </div>
  );
};

export default ListingSidebar;
export {
  ListingSidebarFragments,
}