import { gql } from '@apollo/client';

export const SummaryTableFragments = {
  cashFlowTotals: gql`
    fragment SummaryTableCashFlowTotals on CashFlowTotals {
      interest_cents
      principal_cents
      servicing_cents
      prepayments_cents
      defaults_minus_losses_cents
      subtotal_cents
      total_cents
      adjusted_total_cents
    }
  `,
  performanceSummary: gql`
    fragment SummaryTablePerformanceSummary on PerformanceSummary {
      current_balance_cents
    }
  `,
};

export const MonthlyTableFragments = {
  cashFlowMonth: gql`
    fragment MonthlyTableCashFlowMonth on CashFlowMonth {
      month
      starting_balance_cents
      payments_cents
      interest_cents
      servicing_cents
      prepayments_cents
      defaults_cents
      losses_cents
      ending_balance_cents
      cash_flow_cents
    }
  `,
};

export const ChartFragments = {
  cashFlowMonth: gql`
    fragment ChartCashFlowMonth on CashFlowMonth {
      month
      cash_flow_cents
    }
  `,
};