/*
 * Bidding and Pricing
 *
 * Figma: https://www.figma.com/file/urbIZ8Fnnln4Knx1lfo7mV/High-Level-Screens?type=design&node-id=5089-173720&mode=design&t=4ux2aW8xJEqsrMmt-4
 */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RowSelectionState } from '@tanstack/react-table';
import CarveTable, { CarveTableRowType } from './CarveTable';
import './BiddingAndPricingSection.scss';

import { useQuery } from '@apollo/client';
import {
  GetListings,
  GetListings_user_company_listings,
} from 'query/__generated__/GetListings';
import { GET_LISTINGS } from 'query/getListings';
import {
  BiddingAndPricingSectionContainer,
  BiddingAndPricingSectionSubHeader,
} from './BiddingAndPricingSection.styles';
import BidParameters from './BidParameters';
import BiddingPricingContext from './BiddingPricingContext';
import {
  DEFAULT_PARTICIPATION_PERCENT,
  DEFAULT_SERVICING_PRICE,
} from './constants';
import { BidDetails } from './BidDetails';
import { BidActionButtons } from './BidActionButtons';

// TODO(kentskinner): verify this mapping is correct.
const mapListingsToCarveData = (
  listings: GetListings_user_company_listings[] | undefined,
  getBidPx: (id: string) => number | null
): CarveTableRowType[] => {
  if (!listings) return [];
  return listings.map((listing) => {
    return {
      id: listing.id,
      name: listing.provided_name || listing.name,
      bidPx: getBidPx(listing.id),
      servicingFee:
        'servicing_fee_rate' in listing ? listing.servicing_fee_rate || 0 : 0,
      UPB: listing.t_current_balance_cents || 0,
      GWAC: listing.wa_coupon || 0,
      numLoans: listing.loan_count || 0,
      WALA: listing.wa_loan_age_months || 0,
      WAM: listing.wa_remaining_loan_terms_months || 0,
      LTV: listing.wa_ltv || 0,
      DTI: listing.wa_dti || 0,
    };
  });
};

interface BiddingAndPricingSectionProps {
  dealId?: string;
}

const BiddingAndPricingSection = ({
  dealId,
}: BiddingAndPricingSectionProps) => {
  const { data, loading, error } = useQuery<GetListings>(GET_LISTINGS, {});
  const [selectedCarves, setSelectedCarves] = useState<RowSelectionState>({});
  const [bidDetailsMap, setBidDetailsMap] = useState<
    Record<string, BidDetails>
  >({});

  const clearSelectedCarves = () => {
    setSelectedCarves({});
  };

  const handleBidPxChanged = (id: string, bidPx: number | null) => {
    setBidDetailsMap((prevMap) => {
      const currentDetails = prevMap[id];
      const newDetails = { ...currentDetails, bidPx };

      return { ...prevMap, [id]: newDetails };
    });
  };

  const updateBidDetails = (bidDetails: BidDetails) => {
    setBidDetailsMap((prevMap) => {
      const currentDetails = prevMap[bidDetails.carveId];
      const newDetails = {
        ...currentDetails,
        ...bidDetails,
      };

      return { ...prevMap, [bidDetails.carveId]: newDetails };
    });
  };

  const bidPxForListingId = useCallback(
    (id: string) => {
      if (id in bidDetailsMap) {
        return bidDetailsMap[id].bidPx;
      }
      return null;
    },
    [bidDetailsMap]
  );

  const carveData = useMemo(() => {
    return (
      mapListingsToCarveData(data?.user.company.listings, bidPxForListingId) ||
      []
    );
  }, [data, bidPxForListingId]);

  useEffect(() => {
    carveData.forEach((carve) => {
      if (!(carve.id in bidDetailsMap)) {
        setBidDetailsMap((prevMap) => ({
          ...prevMap,
          [carve.id]: {
            carveId: carve.id,
            bidPx: null,
            cpr: 0,
            cdr: 0,
            lsr: 0,
            delay: 0,
            participationPercent: {
              isCustom: false,
              value: '' + DEFAULT_PARTICIPATION_PERCENT,
              draftValue: '',
            },
            servicingPrice: {
              isCustom: false,
              value: '' + DEFAULT_SERVICING_PRICE,
              draftValue: '',
            },
          },
        }));
      }
    });
  }, [carveData, bidDetailsMap]);

  const selectedCarve = carveData.filter(
    (_, index) => selectedCarves[index]
  )[0];

  const initialBidStipulations = {
    serviceRetained: false,
    percentageOfCollateralDiligence: null,
    minNetYieldToBuyer: null,
    diligenceCompletionDate: null,
    settlementDate: null,
    custom1: null,
    custom2: null,
    custom3: null,
  };

  const [stipulations, setStipulations] = useState(initialBidStipulations);
  const updateStipulation = (key: string, value: any) => {
    setStipulations((prev) => ({ ...prev, [key]: value }));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <BiddingPricingContext.Provider
      value={{
        updateBidDetails,
        bidDetails: selectedCarve ? bidDetailsMap[selectedCarve.id] : undefined,
      }}
    >
      <BiddingAndPricingSectionContainer>
        <p>Create bid on pool or carve a new pool for bid.</p>
        <BiddingAndPricingSectionSubHeader>
          BID CARVE SELECTION
        </BiddingAndPricingSectionSubHeader>
        <p>
          Select a carve, below, to price and save your bid. You may send one or
          more bids at a time.
        </p>

        <BidActionButtons
          dealId={dealId}
          selectedCarve={selectedCarve}
          clearSelected={clearSelectedCarves}
        />

        <CarveTable
          data={carveData}
          selection={{
            selected: selectedCarves,
            onSelectionChange: setSelectedCarves,
          }}
          onBidPxChanged={handleBidPxChanged}
        />

        {selectedCarve && (
          <BidParameters
            carve={selectedCarve}
            stipulations={stipulations}
            updateStipulation={updateStipulation}
          />
        )}
      </BiddingAndPricingSectionContainer>
    </BiddingPricingContext.Provider>
  );
};

export default BiddingAndPricingSection;
