import { ComboDropdownValuePill } from 'common-ui/Combobox/ComboDropdownValuePill';
import {  Ref, useCallback, useRef } from 'react';
import Select, { Props, SelectInstance, InputProps} from 'react-select';
import {pickBy} from 'lodash';
import { useTheme } from 'styled-components';
import { PillButtonProps } from 'common-ui/Buttons';

export type PillDropdownProps<T> = Pick<
  Props<T, false>,
  'getOptionLabel' | 'getOptionValue' | 'options' | 'value' | 'onChange'
> & {
  iconOnClick?: PillButtonProps['iconOnClick'];
  iconName?: PillButtonProps['iconName'];
  description?: PillButtonProps['description'];
};

export function PillDropdown<T>(props: PillDropdownProps<T>){

  const ref = useRef<SelectInstance<T, false>>(null);
  const theme = useTheme();
  
  const ControlComp = useCallback(
    (controlProps: InputProps<T, false>) => { 
      const label = ref.current?.getOptionLabel(controlProps.getValue()[0] || {} as T);
      const inputProps = pickBy(controlProps, (_, key) => key.startsWith('on') || key.startsWith('aria-') || key.startsWith('tabIndex') ||  key.startsWith('role'));

      return (
        <ComboDropdownValuePill
          ref={controlProps.innerRef as Ref<HTMLButtonElement>}
          {...(inputProps as any)}
          description={props.description}
          value={label}
          iconName={props.iconName}
          iconOnClick={props.iconOnClick}
          isActive={controlProps.selectProps.menuIsOpen}
        />
      );},
    [props.description, props.iconName, props.iconOnClick]
  );

  return (
    <Select
      ref={ref}
      {...props}
      components={{
        Input: ControlComp,
        SingleValue: () => null,
        Placeholder: () => null,
        IndicatorsContainer: () => null,
      }}
      styles={{
        menu: (css) => ({
          ...css,
          background: 'white',
          width: 'fit-content',
          borderRadius: '4px',
          backgroundColor: theme.color.black,
          color: theme.color.fgDefault,
          font: theme.typography.primaryXSmall,
          border: `solid 1px ${theme.color.accentEmphasis}`,
          boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)',
        }),
        option: (css, optionProps) => ({
          ...css,
          padding: '4px 8px',
          backgroundColor:
            optionProps.isSelected || optionProps.isFocused
              ? theme.color.slate300
              : 'inherit',
          ':first-child': {
            borderRadius: '4px 4px 0px 0px',
          },
          ':last-child': {
            borderRadius: '0px 0px 4px 4px',
          },
        }),
      }}
      closeMenuOnSelect={true}
      unstyled={true}
      openMenuOnClick={true}
    />
  );
}