import { BaseButton } from 'common-ui';
import { DialogActions, StyledHeading } from 'common-ui/Dialog.styles';
import bidConfirmationImgPath from 'assets/pngs/bid-confirmation.png';
import { formatCurrency, formatPercent } from 'configs/columns';
import { BidDetails } from './BidDetails';
import { CarveTableRowType } from './CarveTable';
import styled from 'styled-components';
import { typographyMonoParagraphSmall } from 'common-ui/typography';

export interface BidConfirmationDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  bidDetails: BidDetails;
  selectedCarve: CarveTableRowType;
}

export const BidConfirmationDialog: React.FC<BidConfirmationDialogProps> = ({
  onConfirm,
  onCancel,
  bidDetails,
  selectedCarve,
}) => {
  const upb = Math.round(
    (selectedCarve.UPB * Number(bidDetails.participationPercent.value)) / 100
  );

  return (
    <>
      <img src={bidConfirmationImgPath} alt="confirm bid" />{' '}
      <StyledHeading>Bid Confirmation</StyledHeading>
      <div>Would you like to confirm your Bid of</div>
      <StyledTable style={{ width: 260 }}>
        <tbody>
          <StyledTableRow>
            <td>Price</td>
            <StyledTableCellValue>{bidDetails.bidPx}%</StyledTableCellValue>
          </StyledTableRow>
          <StyledTableRow>
            <td>GWAC</td>
            <StyledTableCellValue>
              {formatPercent(selectedCarve.GWAC, 3)}
            </StyledTableCellValue>
          </StyledTableRow>
          <StyledTableRow>
            <td>UPB</td>
            <StyledTableCellValue>{formatCurrency(upb)}</StyledTableCellValue>
          </StyledTableRow>
          <StyledTableRow>
            <td>Settle Date</td>
            <StyledTableCellValue>
              {new Date().toDateString()}
            </StyledTableCellValue>
          </StyledTableRow>
        </tbody>
      </StyledTable>
      <DialogActions>
        <BaseButton
          type="secondary"
          size="medium"
          label="cancel"
          onClick={() => onCancel()}
        >
          Cancel
        </BaseButton>
        <BaseButton size="medium" label="confirm" onClick={() => onConfirm()}>
          <b>Confirm Bid</b>
        </BaseButton>
      </DialogActions>
    </>
  );
};

const StyledTable = styled.table`
  border-collapse: collapse;

  margin-top: 12px;

  color: ${(props) => props.theme.color.slate200};
  font-size: 14px;
  line-height: 20px;
  th,
  td {
    font-weight: 400;
    padding: 8px;
  }
`;

const StyledTableRow = styled.tr`
  border-top: 1px solid ${(props) => props.theme.color.slate400};
`;

const StyledTableCellValue = styled.td`
  text-align: right;
  color: ${(props) => props.theme.color.slate100};
  ${typographyMonoParagraphSmall}
`;