import { css, styled } from 'style/ORSNNTheme';
import { keyframes } from 'styled-components';

export interface HorizontalProgressIndicatorProps {
  isLoading?: boolean;
}

const goForth = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
`;

export const HorizontalProgressIndicator = styled.div<{
  $isLoading?: boolean;
  $duration?: number;
}>`
  width: 100%;
  box-sizing: border-box;
  height: 16px;
  background: #323232;
  border-radius: 12px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  ${({ $isLoading, $duration = 3 }) =>
    $isLoading
      ? css`
          &::before {
            content: '';
            display: block;
            height: 8px;
            background: #e85ee5;
            border-radius: 8px;
            animation: ${goForth} ${$duration}s linear infinite;
          }
        `
      : ''}
`;
