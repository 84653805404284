import { gql } from '@apollo/client';

export const SAVE_WIRE_DETAILS = gql`
  mutation SaveWireDetails($input: SaveWireDetailsInput!) {
    saveWireDetails(input: $input) {
      account_name
      account_number
      routing_number
      bank_name
    }
  }
`;
