import { useState, useEffect, useMemo, FC } from 'react';
import { FetchResult, QueryResult, OperationVariables } from '@apollo/client';
import _ from 'lodash';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { KPI } from './types';
import { isMetricMatchingFilter } from './utils/helpers';
import { DropdownPillComboboxSingleAdd, PillButton } from 'common-ui';
import './pool-manager.scss';

import { SavePoolSettings } from 'query/__generated__/SavePoolSettings';
import { GetPoolSettings } from 'query/__generated__/GetPoolSettings';

interface PassedProps {
  title: string;
  numPools?: number;
  metrics: KPI[];
  addUserSetting: (value: string) => Promise<FetchResult<SavePoolSettings>>;
  savedPoolSettings: QueryResult<GetPoolSettings, OperationVariables>;
  userPoolSettings?: string[];
}

export const Metrics: FC<{
  metrics: KPI[];
  handleClick: (metric: KPI) => () => void;
}> = ({ metrics, handleClick }) => (
  <>
    {metrics.map((metric) => (
      <PillButton
        key={metric.description}
        description={metric.description}
        value={metric.value}
        onClick={handleClick(metric)}
        iconName="pill-close"
      />
    ))}
  </>
);

const PoolHeader: FC<PassedProps> = ({
  title,
  numPools,
  metrics,
  addUserSetting,
  savedPoolSettings,
  userPoolSettings,
}) => {
  const { loading, error } = savedPoolSettings;
  const [displayedMetrics, setDisplayedMetrics] = useState<KPI[]>([]);

  useEffect(() => {
    if (!loading && !error && userPoolSettings) {
      const accumulatedMetrics = userPoolSettings
        .map(userSettingMetric => metrics.find(metric => metric.selector === userSettingMetric))
        .filter(Boolean) as KPI[];
      setDisplayedMetrics(accumulatedMetrics);
    }
  }, [userPoolSettings, metrics, loading, error]);

  const saveUserSettings = (metricsToUpdate: KPI[]) => {
    const formattedMetrics = JSON.stringify(metricsToUpdate.map(metric => metric.selector));
    return addUserSetting(formattedMetrics);
  };

  const handleClick = (selectedMetric: KPI) => () => {
    const updatedMetrics = displayedMetrics.filter(metric => metric.selector !== selectedMetric.selector);
    saveUserSettings(updatedMetrics).then(() => setDisplayedMetrics(updatedMetrics));
  };

  const handleAddMetric = (selectedMetric: KPI) => {
    if (!displayedMetrics.some(metric => metric.selector === selectedMetric.selector)) {
      const updatedMetrics = [...displayedMetrics, selectedMetric];
      saveUserSettings(updatedMetrics).then(() => setDisplayedMetrics(updatedMetrics));
    }
  };

  const availableMetrics = useMemo(() => _.differenceBy(metrics, displayedMetrics, 'selector'), [displayedMetrics, metrics]);

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="pool-manager">
      <span className="pool-manager__desc">{title}</span>
      {numPools && <span className="pool-manager__num_pools">{`${numPools} Pools`}</span>}
      {loading ? <div>Loading...</div> : <Metrics metrics={displayedMetrics} handleClick={handleClick} />}
      <DropdownPillComboboxSingleAdd
        options={availableMetrics}
        getOptionLabel={(option: KPI) => option.description}
        getOptionValue={(option: KPI) => option.value}
        onValueSelected={handleAddMetric}
        buttonLabel="Add a metric to the Pool Summary"
        comboLabel="Search for a metric"
        inputPlaceholder="Enter a metric name..."
        manuLabel="All metrics (Ascending)"
        filterOption={(option: FilterOptionOption<KPI>, searchInput: string) => isMetricMatchingFilter(option, searchInput)}
      />
    </div>
  );
};

export default PoolHeader;
