import { gql } from '@apollo/client';
import DealStagesFragments from 'features/deals/DealStages/fragments';

export const SUBMIT_TERMS = gql`
  mutation SubmitTerms($input: SubmitTermsInput!) {
    submitTerms(input: $input) {
      id
      ...DealStagesDeal
    }
  }
  ${DealStagesFragments.Deal}
`;
