import { useLazyQuery, useMutation } from '@apollo/client';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, KIND } from 'baseui/button';
import { StyledBody } from 'baseui/card';
import { Alert, Delete, Plus, Spinner } from 'baseui/icon';
import { ListItem, ListItemLabel } from 'baseui/list';
import { CheckboxWithLabel, inputs } from 'common-ui';
import {
  FileStatus,
  UploadStatus,
  useUploadZone,
} from 'features/deals/utils';
import { SUBMIT_TERMS } from 'mutation/submitTerms';
import {
  SubmitTerms,
  SubmitTermsVariables,
} from 'mutation/__generated__/SubmitTerms';
import prettyBytes from 'pretty-bytes';
import { GET_DEAL_DOCUMENTS } from 'query/deal';
import {
  GetDealDocuments,
  GetDealDocumentsVariables,
} from 'query/__generated__/GetDealDocuments';
import { useState } from 'react';
import { styled } from 'style/ORSNNTheme';
import { Emphasis, FileType, Uploader } from '__generated__/globalTypes';
import { EventActionCardBodyProps } from '..';
import { GET_DEAL_TIMELINE } from '../../fragments';
import { isNotNull, List, ListItemStyleProps, StyledLabelBody } from '../utils';
import {
  BidTermsCard_BidTermsCard,
  BidTermsCard_BidTermsCard_buyer_documents,
  BidTermsCard_BidTermsCard_seller_documents,
} from './__generated__/BidTermsCard';

const ListContainer = styled.div`
  display: flex;
  padding
`;

const CustomButtonGroup = styled.div`
  display: flex;
`;

type Documents =
  | BidTermsCard_BidTermsCard_buyer_documents[]
  | BidTermsCard_BidTermsCard_seller_documents[];

function renderListCol(
  termsDocuments: Documents,
  isSelected: (termsId: string) => boolean,
  toggleDocument: (termsId: string) => void,
  canSelectTermsDocuments: boolean
): JSX.Element[] {
  return termsDocuments.map((doc) => {
    const { id, name, documentSizeBytes, documentUrl } = doc;

    const label = (
      <StyledLabelBody>
        {`${name} - ${prettyBytes(documentSizeBytes)}`}
        <a href={documentUrl}>
          <FontAwesomeIcon icon={faFileDownload} />
        </a>
      </StyledLabelBody>
    );

    if (canSelectTermsDocuments) {
      return (
        <ListItem key={name} {...ListItemStyleProps}>
          <ListItemLabel>
            <CheckboxWithLabel
              key={id}
              checked={isSelected(id)}
              onClick={() => toggleDocument(id)}
            >
              {label}
            </CheckboxWithLabel>
          </ListItemLabel>
        </ListItem>
      );
    } else {
      return (
        <ListItem key={name} {...ListItemStyleProps}>
          <ListItemLabel>{label}</ListItemLabel>
        </ListItem>
      );
    }
  });
}

function renderList(
  documents: Documents,
  isSelected: (termsId: string) => boolean,
  toggleDocument: (termsId: string) => void,
  canSelectTermsDocuments: boolean
) {
  return (
    <ListContainer>
      {[
        documents.slice(0, Math.ceil(documents.length / 2)),
        documents.slice(Math.ceil(documents.length / 2)),
      ].map((halfDocs, index) => (
        <List key={halfDocs[0]?.name ?? index}>
          {renderListCol(
            halfDocs,
            isSelected,
            toggleDocument,
            canSelectTermsDocuments
          )}
        </List>
      ))}
    </ListContainer>
  );
}

function renderIcon(file: FileStatus) {
  switch (file.uploadStatus) {
    case UploadStatus.Pending:
    case UploadStatus.InProgress:
      return <Spinner size={16} />;
    case UploadStatus.Complete:
      return <Delete size={16} />;
    case UploadStatus.Error:
      return <Alert size={16} />;
  }
}

type Props = EventActionCardBodyProps & BidTermsCard_BidTermsCard;

const BidTermsCardBody = (props: Props): JSX.Element => {
  const buyerVariables = {
    id: props.dealId,
    fileType: FileType.TERMS_DOCUMENT,
    uploader: Uploader.BUYER,
  };
  const [_getBuyerData, { data: buyerData, refetch: buyerRefetch }] =
    useLazyQuery<GetDealDocuments, GetDealDocumentsVariables>(
      GET_DEAL_DOCUMENTS,
      {
        variables: buyerVariables,
      }
    );

  const sellerVariables = {
    id: props.dealId,
    fileType: FileType.TERMS_DOCUMENT,
    uploader: Uploader.SELLER,
  };
  const [_getSellerData, { data: sellerData, refetch: sellerRefetch }] =
    useLazyQuery<GetDealDocuments, GetDealDocumentsVariables>(
      GET_DEAL_DOCUMENTS,
      {
        variables: sellerVariables,
      }
    );
  const [termsIds, setTermsIds] = useState<string[]>(
    props.bidDocument?.terms?.map((term) => term.id) ?? []
  );

  const toggleTermsDocument = (termsId: string) => {
    setTermsIds((current) =>
      current.includes(termsId)
        ? current.filter((id) => id !== termsId)
        : current.concat(termsId)
    );
  };

  const tempTermsIds = termsIds.slice().sort();
  const propsTermsIds =
    props.bidDocument?.terms
      ?.map((term) => term.id)
      .slice() // to be 'immutable' sort (nondestructive)
      .sort() ?? [];
  const isTermsUnchanged =
    tempTermsIds.length === propsTermsIds.length &&
    propsTermsIds.every((id, index) => id === tempTermsIds[index]);

  const isSelected = (termsId: string) => termsIds.includes(termsId);

  const [submitTerms, { loading }] = useMutation<
    SubmitTerms,
    SubmitTermsVariables
  >(SUBMIT_TERMS);

  async function refetch(
    _variables?: Partial<GetDealDocumentsVariables> | undefined
  ) {
    if (buyerRefetch != null && sellerRefetch != null) {
      return (
        (await buyerRefetch(buyerVariables)) &&
        (await sellerRefetch(sellerVariables))
      );
    }
  }
  const { uploadFiles, getRootProps, getInputProps, deleteFile } =
    useUploadZone(
      props.dealId,
      FileType.TERMS_DOCUMENT,
      [buyerVariables, sellerVariables],
      refetch
    );

  const optionalUploadSection = props.can_upload ? (
    <CustomButtonGroup>
      {uploadFiles.map((file) => {
        return (
          <inputs.PillButton
            key={file.name}
            endEnhancer={() => renderIcon(file)}
            onClick={() => deleteFile(file.id)}
          >
            {file.name}
          </inputs.PillButton>
        );
      })}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <inputs.PillButton endEnhancer={() => <Plus size={16} />}>
          Select New File
        </inputs.PillButton>
      </div>
    </CustomButtonGroup>
  ) : (
    <></>
  );

  const buyerDocuments = buyerData?.deal?.documents || props.buyer_documents;
  const sellerDocuments = sellerData?.deal?.documents || props.seller_documents;
  const canSelectTermsDocuments = props.actions.length > 0;

  const handleSubmitTerms = () => {
    submitTerms({
      variables: {
        input: {
          deal_id: props.dealId,
          terms: termsIds,
        },
      },
      refetchQueries: [
        {
          query: GET_DEAL_TIMELINE,
          variables: {
            id: props.dealId,
          },
        },
      ],
    });
  };

  return (
    <StyledBody>
      {optionalUploadSection}
      {buyerDocuments != null && buyerDocuments.length > 0 && (
        <>
          <h5>BUYER DOCUMENTS</h5>
          {renderList(
            buyerDocuments,
            isSelected,
            toggleTermsDocument,
            canSelectTermsDocuments
          )}
        </>
      )}
      {sellerDocuments != null && sellerDocuments.length > 0 && (
        <>
          <h5>SELLER DOCUMENTS</h5>
          {renderList(
            sellerDocuments,
            isSelected,
            toggleTermsDocument,
            canSelectTermsDocuments
          )}
        </>
      )}
      {props.actions.filter(isNotNull).map((action) => {
        return (
          <Button
            kind={
              action.emphasis === Emphasis.STRONG
                ? KIND.primary
                : KIND.secondary
            }
            key={action.type}
            disabled={isTermsUnchanged || loading}
            isLoading={loading}
            onClick={() => handleSubmitTerms()}
          >
            {action.title}
          </Button>
        );
      })}
    </StyledBody>
  );
};

export default BidTermsCardBody;
export { default as BidTermsCardFragments } from './fragments';
