
import { gql } from '@apollo/client';
import { ProcessingReportCardFragments } from 'features/loanTape/ProcessingReportCard';
import { TapesUploadedTableFragments } from 'features/loanTape/components/TapesUploadedTable';
import { MappingCardFragments } from 'features/loanTape/fieldMapping/MapRequiredFieldsCard';

const GET_UPLOADED_FILES_WITH_HEADERS = gql`
  query GetUploadedFilesWithHeaders {
    user {
      id
      company {
        id
        files {
          id
          state
          ...TapesUploadedTableFile
          ...MappingCardFile
          ...ProcessingReportCardFile
        }
      }
    }
  }
  ${MappingCardFragments.file}
  ${ProcessingReportCardFragments.file}
  ${TapesUploadedTableFragments.file}
`;

export { GET_UPLOADED_FILES_WITH_HEADERS };