import { ColumnDef } from '@tanstack/react-table';
import { IndeterminateCheckbox } from 'common-ui';
import { RadioButton } from 'common-ui/form/RadioButtonField';

export const radioButtonSelectionColumn: ColumnDef<any> = {
  id: 'selection',
  size: 40,
  cell: (cellContext) => (
    <RadioButton
      label={''}
      name={''}
      value={''}
      checked={cellContext.row.getIsSelected()}
      onChange={cellContext.row.getToggleSelectedHandler()}
    />
  ),
  meta: {
    headerNoPill: true,
  },
};

export const checkBoxSelectionColumn: ColumnDef<any> = {
  id: 'selection',
  size: 40,
  cell: (cellContext) => (
    <IndeterminateCheckbox
      checked={cellContext.row.getIsSelected()}
      onChange={cellContext.row.getToggleSelectedHandler()}
      disabled={!cellContext.row.getCanSelect()}
    />
  ),
  header: (headerContext) => {
    if (headerContext.table.options.enableMultiRowSelection !== false) {
      return (
        <IndeterminateCheckbox
          checked={headerContext.table.getIsAllRowsSelected()}
          indeterminate={headerContext.table.getIsSomeRowsSelected()}
          onChange={headerContext.table.getToggleAllRowsSelectedHandler()}
        />
      );
    }
    return null;
  },
  meta: {
    headerNoPill: true,
  },
};
