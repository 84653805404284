import React from 'react';
import { FieldIdAndLabel, FieldsMap } from './MapRequiredFieldsCard';
import { LoanField } from '__generated__/globalTypes';
import { BaseButton } from 'common-ui';

const mappings: Record<LoanField, string[]> = {
  [LoanField.account_id]: ['Mortgage Loan ID', 'ACCT#', 'LoanID'],
  [LoanField.borrower_credit_score]: [
    'Qualifying FICO',
    'Credit Score',
    'FICO Score',
  ],
  [LoanField.age_months]: [
    'Loan Age (Months)',
    'Age in Months',
    'Loan Duration (Months)',
  ],
  [LoanField.asset_class]: ['Asset Classification', 'Asset Class'],
  [LoanField.auto_original_value_cents]: [
    'Original Auto Value (Cents)',
    'Auto Original Value',
    'Car Initial Value',
  ],
  [LoanField.auto_vin]: ['Vehicle VIN', 'VIN Number'],
  [LoanField.city]: ['City', 'Borrower City', 'Property City'],
  [LoanField.cra]: ['CRA Status', 'Community Reinvestment Act Status'],
  [LoanField.current_balance_cents]: [
    'Current Balance',
    'Loan Balance',
    'Current Loan Balance',
  ],
  [LoanField.dti]: [
    'DTI',
    'Debt to Income Ratio',
    'DTI Ratio',
    'Total Debt to Income',
  ],
  [LoanField.interest_rate]: [
    'Interest Rate',
    'Current Interest Rate',
    'Rate of Interest',
    'Original Note Rate',
    'Current Note Rate',
  ],
  [LoanField.last_updated_date_seconds]: [
    'Last Update (Seconds)',
    'Last Updated Date',
  ],
  [LoanField.loan_terms_months]: ['Loan Term (Months)', 'Term in Months'],
  [LoanField.ltv]: ['LTV', 'Loan to Value Ratio', 'Original LTV'],
  [LoanField.maturity_date_seconds]: [
    'Maturity Date (Seconds)',
    'Loan Maturity Date',
    'Maturity Date',
  ],
  [LoanField.next_payment_date_seconds]: [
    'Next Payment Date (Seconds)',
    'Upcoming Payment Date',
    'Next Payment Date',
  ],
  [LoanField.occupancy]: ['Occupancy Status', 'Occupancy Type', 'Occupancy'],
  [LoanField.original_balance_cents]: [
    'Original Balance (Cents)',
    'Initial Loan Balance',
    'Original Loan Balance',
    'Advanced Amount',
  ],
  [LoanField.origination_date_seconds]: [
    'Origination Date (Seconds)',
    'Loan Start Date',
    'Origination Date',
    'Open Date',
  ],
  [LoanField.postal_code]: [
    'Postal Code',
    'ZIP Code',
    'Property Zip',
    'Borrower Zip',
  ],
  [LoanField.product]: ['Loan Product', 'Product Type'],
  [LoanField.property_description]: [
    'Property Description',
    'Home Description',
  ],
  [LoanField.property_year]: ['Vehicle_Year', 'Property Year'],
  [LoanField.purpose]: [
    'Loan Purpose',
    'Purpose for Loan',
    'Loan Type Description',
    'Purpose',
  ],
  [LoanField.remaining_loan_terms_months]: [
    'Remaining Loan Term (Months)',
    'Outstanding Term (Months)',
  ],
  [LoanField.servicing_rate]: ['Servicing Rate', 'Loan Servicing Rate'],
  [LoanField.state]: ['State', 'Borrower State', 'Property State'],
};

const normalizeHeader = (header: string) =>
  header.trim().toLowerCase().replace(/ /g, '_');

interface AutomapperProps {
  requiredFields: FieldIdAndLabel[];
  optionalFields: FieldIdAndLabel[];
  currentFieldMap: FieldsMap;
  unmappedHeaders: string[];
  onAutoMap: (fieldsMap: FieldsMap, remainingUnmappedHeaders: string[]) => void;
}

const Automapper: React.FC<AutomapperProps> = ({
  requiredFields,
  optionalFields,
  currentFieldMap,
  unmappedHeaders,
  onAutoMap,
}) => {
  const automap = () => {
    // Keep track of which headers have been mapped
    const normalizedUnmappedHeaders = unmappedHeaders.map((header) => {
      const normalized = normalizeHeader(header);
      return { original: header, normalized };
    });

    const newFieldsMap: FieldsMap = { ...currentFieldMap };

    const mapField = (fieldId: LoanField) => {
      if (newFieldsMap[fieldId]) return false; // already mapped, skip

      const fieldMappings = mappings[fieldId];
      if (fieldMappings) {
        for (const mapping of fieldMappings) {
          const normalizedMapping = normalizeHeader(mapping);
          const matchingHeaderIndex = normalizedUnmappedHeaders.findIndex(
            ({ normalized }) => normalized === normalizedMapping
          );

          if (matchingHeaderIndex !== -1) {
            newFieldsMap[fieldId] =
              normalizedUnmappedHeaders[matchingHeaderIndex].original;
            normalizedUnmappedHeaders.splice(matchingHeaderIndex, 1); // Remove the mapped header
            return true; // Successfully mapped
          }
        }
      }
      return false; // No mapping found
    };

    // First, map required fields
    requiredFields.forEach(({ fieldId }) => mapField(fieldId));

    // Then, map optional fields
    optionalFields.forEach(({ fieldId }) => mapField(fieldId));

    // Create an array of still-unmapped original headers
    const remainingUnmappedHeaders = normalizedUnmappedHeaders.map(
      ({ original }) => original
    );

    onAutoMap(newFieldsMap, remainingUnmappedHeaders);
  };

  return (
    <div style={{ margin: 20 }}>
      <BaseButton type="secondary" onClick={automap} label={'automap'}>
        Automap
      </BaseButton>
    </div>
  );
};

export default Automapper;