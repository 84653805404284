import { gql } from '@apollo/client';
import DealStagesFragments from 'features/deals/DealStages/fragments';

const ADVANCE_DEAL = gql`
  mutation AdvanceDeal(
    $id: ID!
    $action: DealActionOptionType!
    $extraData: JSONObject
  ) {
    advanceDeal(id: $id, action: $action, extraData: $extraData) {
      id
      ...DealStagesDeal
    }
  }
  ${DealStagesFragments.Deal}
`;

export { ADVANCE_DEAL };
