//these are still in use in old code - all should be deleted aventually

export enum DrilldownPaths {
  CASHFLOWS_URL = '/cashflows',
  DRILLDOWN_URL = '/drilldown',
  LOANS_URL = '/loans',
  PUBLISH_URL = '/publish',
  STRATS_URL = '/strats',
}

// URLs
export const DEAL_URL = '/deal';
export const OVERVIEW_URL = '/overview';
export const PORTFOLIO_URL = '/portfolio';

export const LISTING_PATH = 'listing';
export const MARKETPLACE_PATH = 'marketplace';
export const INPROGRESS_PATH = 'inprogress';
