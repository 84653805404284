// TODO: Fetch status from server
enum Party {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

enum Blocker {
  NEITHER = 'NEITHER',
  SELLER = 'SELLER',
  BUYER = 'BUYER',
  BOTH = 'BOTH',
}

export { Blocker, Party };
