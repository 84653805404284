import Auth from '@aws-amplify/auth';
import { AuthContext, AuthContextAction, AuthState } from 'context/AuthContext';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { animated as a, useSpring } from 'react-spring';
import { css, styled } from 'style/ORSNNTheme';
import FooterMenu from './FooterMenu';
import ForgotPassword from './ForgotPassword';
import HeaderMenu from './HeaderMenu';
import SignIn from './SignIn';

const Page = styled.div`
  background-color: ${(props) => props.theme.color.bgCanvas};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

type FormContainerProps = {
  canClick: boolean;
};

const FormContainer = styled.div<FormContainerProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 450px;
  min-height: 275px;
  transform: translateX(-50%) translateY(-50%);
  will-change: transform, opacity;
  align-items: center;
  background-color: ${(props) => props.theme.color.cardBackground};
  border-radius: 0.25rem;
  border: 1px solid ${(props) => props.theme.color.borderDefault};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem 1rem;
  text-align: center;
  color: ${(props) => props.theme.color.fgDefault};
  ${(props) =>
    !props.canClick &&
    css`
      pointer-events: none;
    `}
`;

type CardContainerProps = {
  canClick: boolean;
};
const CardContainer = ({
  canClick,
  children,
}: PropsWithChildren<CardContainerProps>) => {
  if (!children) {
    return <></>;
  }
  return (
    <FormContainer canClick={canClick}>
      <h3>ORSNN</h3>
      {children}
    </FormContainer>
  );
};

const UserAuthentication = (): JSX.Element => {
  const [flipped, setFlipped] = useState<boolean>(false);
  const { state, dispatch } = useContext(AuthContext);
  const { transform: signInTransform, opacity: forgotPasswordOpacity } =
    useSpring({
      opacity: flipped ? 1 : 0,
      transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
      config: { mass: 5, tension: 500, friction: 80 },
    });

  const signInOpacity = forgotPasswordOpacity.interpolate(
    (o?: number | string) =>
      1 - ((typeof o === 'number' ? o : parseInt(o ?? '0')) as number)
  );
  const forgotPasswordTransform = signInTransform.interpolate(
    (t) => `${t} rotateY(180deg)`
  );

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) =>
        dispatch({ type: AuthContextAction.SET_SIGNED_IN_USER, payload: user })
      )
      .catch((_err) => dispatch({ type: AuthContextAction.SIGN_OUT }));
  }, [dispatch]);

  return (
    <Page>
      <HeaderMenu />
      {state.authState === AuthState.SignedOut ? (
        <>
          <a.div style={{ opacity: signInOpacity, transform: signInTransform }}>
            <CardContainer canClick={!flipped}>
              <SignIn onFlip={() => setFlipped(true)} />
            </CardContainer>
          </a.div>
          <a.div
            style={{
              opacity: forgotPasswordOpacity,
              transform: forgotPasswordTransform,
            }}
          >
            <CardContainer canClick={flipped}>
              {flipped && <ForgotPassword onFlip={() => setFlipped(false)} />}
            </CardContainer>
          </a.div>
        </>
      ) : (
        <CardContainer canClick={true}>Loading...</CardContainer>
      )}
      <FooterMenu />
    </Page>
  );
};

export default UserAuthentication;
