import { gql } from '@apollo/client';

export const ADD_DEAL_MESSAGE = gql`
  mutation AddDealMessage($input: CreateDealMessageInput!) {
    addDealMessage(input: $input) {
      message
      id
    }
  }
`;

export const LIST_DEAL_MESSAGES = gql`
  query ListDealMessages(
    $deal_id: ID!
    $pagination: DealMessagePaginationInput
  ) {
    dealMessages(deal_id: $deal_id, pagination: $pagination) {
      entities {
        id
        message
        created_time
        author {
          given_name
          family_name
          is_current_user
        }
      }
      last_evaluated_key {
        id
        deal_id
        created_time
      }
    }
  }
`;
