import dashboardImgPath from 'assets/pngs/dashboard-overview.png';

import './portfolio-dashboard.scss';
import { PortfolioLayout } from '../Portfolio/PortfolioLayout';

const PortfolioDashboard = () => {
  return (
    <PortfolioLayout>
      <div className="portfolio-dashboard">
        <div className="portfolio-dashboard__welcome_container">
          <div className="portfolio-dashboard__header_text">Welcome.</div>
          <div className="portfolio-dashboard__header_subtext">
            Let's understand your portfolio.
          </div>
          <div className="portfolio-dashboard__desc">
            Select an asset type on the left to get started.
          </div>
        </div>
        <img
          className="portfolio-dashboard__img"
          src={dashboardImgPath}
          alt="dashboard overview"
        />
      </div>
    </PortfolioLayout>
  );
};

export default PortfolioDashboard;