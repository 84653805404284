import { DetailRow } from './BidSummary.styles';
import { useBiddingPricingContext } from './BiddingPricingContext';
import { CarveTableRowType } from './CarveTable';
import { formatCentsToDollars } from './formatting';

interface BidSummaryProps {
  carve: CarveTableRowType;
}

const BidSummary = (props: BidSummaryProps) => {
  const { bidDetails } = useBiddingPricingContext();
  if (!bidDetails) return null;

  const participationPercent =
    bidDetails.participationPercent.value !== ''
      ? Number(bidDetails.participationPercent.value)
      : null;
  const upbOfCarve =
    participationPercent !== null
      ? formatCentsToDollars((props.carve.UPB * participationPercent) / 100.0)
      : undefined;
  return (
    <>
      <DetailRow>
        <div>UPB of Carve @ &lt;button-%-above&gt;</div>
        <div>{upbOfCarve}</div>
      </DetailRow>
      <DetailRow>
        <div>Price</div>
        <div>{bidDetails.bidPx}</div>
      </DetailRow>
      <DetailRow>
        <div>Net Yield</div>
        <div>3.98%</div>
      </DetailRow>
      <DetailRow>
        <div>Spread</div>
        <div>67 bps</div>
      </DetailRow>
      <DetailRow>
        <div>Duration</div>
        <div>3.7</div>
      </DetailRow>
      <DetailRow>
        <div>CPR</div>
        <div>{bidDetails.cpr}</div>
      </DetailRow>
      <DetailRow>
        <div>CDR</div>
        <div>{bidDetails.cdr}</div>
      </DetailRow>
      <DetailRow>
        <div>Purchase Dollar Price</div>
        <div>$2,959,500</div>
      </DetailRow>
    </>
  );
};

export default BidSummary;
