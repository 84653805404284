import { Spinner } from 'common-ui';
import EventActionCards from 'features/deals/DealStages/EventActionCards';
import { useQueryWithPollingWhileVisible } from 'functions/useQueryWithPollingWhileVisible';
import { useEffect } from 'react';
import styled from 'styled-components';
import { DealStatus } from '__generated__/globalTypes';
import { GET_DEAL_TIMELINE } from './fragments';
import {
  GetTimeline,
  GetTimelineVariables,
  GetTimeline_deal_cards_PlaceBidCard,
} from './__generated__/GetTimeline';
import { useDealRoomContext } from 'features/pages/marketplace/DealRoomContext';
import { dummyCarveDetailsCard } from './fakeCardData';

const Layout = styled.div`
  display: flex;
  overflow: hidden;
`;

function getCardId(card: { title: string }, index: number): string {
  // TODO fix the logic here to handle repeated cards where the title and index could be the same
  return `${card.title.replaceAll(' ', '_')}_${index}`;
}

const POLLING_INTERVAL = 1000;

const DealStages = (): JSX.Element => {
  const { dealId } = useDealRoomContext();

  const { loading, data, error, stopPolling } = useQueryWithPollingWhileVisible<
    GetTimeline,
    GetTimelineVariables
  >(GET_DEAL_TIMELINE, {
    variables: { id: dealId },
    fetchPolicy: 'cache-and-network',
    pollInterval: POLLING_INTERVAL,
  });

  useEffect(() => {
    // stop polling when deal is complete
    if (DealStatus.COMPLETE === data?.deal?.state.status) {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [data, stopPolling]);

  /* HACK TODO(kentskinner): remove this once the backend correctly provides the PlaceBidCard. */
  const dummyGetTimeline_deal_cards: GetTimeline_deal_cards_PlaceBidCard = {
    __typename: 'PlaceBidCard',
    title: 'Bidding and Pricing',
    actions: [],
    last_updated_date: new Date().toISOString(),
    bid: {
      bid_basis_points: null,
    },
  };

  const cardsWithBidPrice = data?.deal?.cards
    ? [dummyGetTimeline_deal_cards, ...data.deal.cards]
    : [dummyGetTimeline_deal_cards];

  return (
    <>
      {error && <div>{error.message}</div>}
      <Spinner loading={loading} />
      {cardsWithBidPrice.length > 0 && (
        <Layout>
          {/* <Messaging dealId={dealId} /> */}
          <EventActionCards dealId={dealId} cards={cardsWithBidPrice} />
        </Layout>
      )}
    </>
  );
};

export default DealStages;
export { getCardId };
