import { useMutation } from '@apollo/client';
import {
  SetFileReadyToIngest,
  SetFileReadyToIngestVariables,
} from 'mutation/__generated__/SetFileReadyToIngest';
import INGEST_FILE_MUTATION from 'mutation/ingestFile';

export const useIngestFile = () => {
  const [setIngestFile] = useMutation<
    SetFileReadyToIngest,
    SetFileReadyToIngestVariables
  >(INGEST_FILE_MUTATION);

  const ingestFile = (fileId: string) => {
    return setIngestFile({ variables: { fileId: fileId } });
  };

  return {
    ingestFile,
  };
};
