import {
  FloatingFocusManager,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import React from 'react';
import { Overlay } from '../InProgressOverlay.styles';
import { BaseButton } from 'common-ui';
import { styled } from 'style/ORSNNTheme';

const OverlayContent = styled.div`
  width: 712px;
  height: 264px;
  background-color: ${(props) => props.theme.color.bgSurface};
  color: #fff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 16px 24px 0px rgba(13, 13, 13, 0.9), 0px 0px 1px 0px #0d0d0d;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2000;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin: 20px;
`;

const Filename = styled.div`
  width: 540px;
  border: 1px solid ${(props) => props.theme.color.slate400};
  border-left: 0;
  border-right: 0;
  padding: 9px;
  margin-top: 10px;
  color: ${(props) => props.theme.color.slate50};
`;

const Buttons = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 40px;
  justify-content: space-around;
`;

export interface ConfirmUploadDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  filename: string;
}

export const ConfirmUploadDialog: React.FC<ConfirmUploadDialogProps> = ({
  open,
  onClose,
  onConfirm,
  filename,
}) => {
  const { context } = useFloating({
    open,
    onOpenChange: (open) => open || onClose(),
  });

  const dismiss = useDismiss(context, { outsidePress: false });
  const { getFloatingProps } = useInteractions([dismiss]);

  if (!open) {
    return null;
  }

  return (
    <FloatingPortal>
      <Overlay>
        <FloatingFocusManager context={context}>
          <OverlayContent {...getFloatingProps()}>
            <Title>You've chosen the following tape to upload...</Title>
            <Filename>{filename}</Filename>
            <Buttons>
              <BaseButton type="secondary" label="cancel" onClick={onClose}>
                Cancel
              </BaseButton>
              <BaseButton label="confirm" onClick={onConfirm}>
                Start Upload
              </BaseButton>
            </Buttons>
          </OverlayContent>
        </FloatingFocusManager>
      </Overlay>
    </FloatingPortal>
  );
};
