/*
 * CarveTable
 *
 * Figma: https://www.figma.com/file/urbIZ8Fnnln4Knx1lfo7mV/High-Level-Screens?type=design&node-id=5089-173749&mode=design&t=4ux2aW8xJEqsrMmt-4
 *
 */

import { OnChangeFn, RowSelectionState } from '@tanstack/react-table';
import { DataDisplayTable } from 'common-ui';
import { useMemo } from 'react';
import { getColumnDefinitions } from './CarveTable.config';

export interface CarveTableRowType {
  id: string;
  name: string;
  bidPx: number | null;
  servicingFee: number;
  UPB: number;
  GWAC: number;
  numLoans: number;
  WALA: number;
  WAM: number;
  LTV: number;
  DTI: number;
  DQ?: number;
}

interface CarveTableProps {
  data: CarveTableRowType[];
  selection: {
    selected: RowSelectionState;
    onSelectionChange: OnChangeFn<RowSelectionState>;
  };
  onBidPxChanged: (id: string, bidPx: number | null) => void;
}

const CarveTable = (props: CarveTableProps) => {
  const columns = useMemo(() => getColumnDefinitions(props.onBidPxChanged), []);

  return (
    <DataDisplayTable
      data={props.data}
      columns={columns}
      noDataMessage="NO DATA"
      selection={{
        ...props.selection,
        enableMultiRowSelection: false,
      }}
    />
  );
};

export default CarveTable;
