import React, { useEffect } from 'react';
import { ModalNotStyled } from 'common-ui/modal/ModalNotStyled';
import { useMutation, useQuery } from '@apollo/client';
import { GET_DOCUMENT_LIBRARY, UPDATE_FILE_TEMPLATE } from 'query/documentLibrary';
import { GetDocumentLibrary, GetDocumentLibrary_user_company_documents } from 'query/__generated__/GetDocumentLibrary';
import { styled } from 'style/ORSNNTheme';
import { SvgIcon } from 'common-ui/Icons/SvgIcon/SvgIcon';
import { IndeterminateCheckbox } from 'common-ui/bool-inputs';
import DocumentTemplateConfirmation from './DocumentTemplateConfirmation';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
`;

const DocumentItem = styled.div`
  color: ${(props) => props.theme.color.slate200};
  padding: 8px;
  border-top: 1px solid ${(props) => props.theme.color.slate400};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: rgba(232, 94, 229, 0.15);
  }
`;

const IconLinkWrapper = styled.a`
  padding: 0 8px;
  display: flex;
  color: ${(props) => props.theme.color.slate200};
  text-decoration: none;
`;

const LinkText = styled.span`
  padding-left: 8px;
  color: ${(props) => props.theme.color.accentDefault};
`;

const ActionGroup = styled.div`
  display: flex;
  align-items: center;
`;

interface PassedProps {
  refetchData: boolean;
  setRefetchData: (refetchData: boolean) => void;
  fileType: string;
}

export default function DocumentDetails({
  refetchData,
  setRefetchData,
  fileType,
}: PassedProps) {
  const { data, refetch } = useQuery<GetDocumentLibrary>(
    GET_DOCUMENT_LIBRARY,
    {
      variables: { fileType },
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    if (refetchData) {
      // set the refetchData to false after the fetch to ensure the component rerenders with the updated fetch data on that refetchData value change.
      // The server seems to take between a second and 2 seconds to process the upload, as the graphql throws an error on is_template on undefined.
      setTimeout(() => refetch().then(() =>  setRefetchData(false)), 2000)
    }

  }, [refetch, refetchData, setRefetchData]);

  const documents = data?.user?.company?.documents;

  const [updateFileTemplate] = useMutation(UPDATE_FILE_TEMPLATE);

  const handleChange = (document: GetDocumentLibrary_user_company_documents) => {
    updateFileTemplate({
      variables: {
        id: document.id,
        isTemplate: !document.is_template,
      },
    });
  };

  return (
    <Container>
      {
        documents && documents.map((document: GetDocumentLibrary_user_company_documents) => {
          return (
            <div key={document.id}>
              <DocumentItem>
                <span>{document.name}</span>
                <ActionGroup>
                  <IconLinkWrapper target="blank" href={document.documentUrl} download>
                    <SvgIcon name="pdf" />
                    <LinkText>View</LinkText>
                  </IconLinkWrapper>
                  <ModalNotStyled
                    trigger={
                      <IndeterminateCheckbox
                        checked={document.is_template}
                        onChange={() => handleChange(document)}
                      />
                    }
                    handleClose={refetch}
                  >
                    {({ closeModal }) => (
                      <DocumentTemplateConfirmation
                        isTemplateRemoved={document.is_template}
                        documentName={document.name}
                        closeModal={closeModal}
                      />
                    )}
                  </ModalNotStyled>
                </ActionGroup>
              </DocumentItem>
            </div>
          );
        })
      }
    </Container>
  );
}
