import { gql, useQuery } from '@apollo/client';
import { GET_DEAL_GENERAL_INFO_QUERY } from 'features/deals/dealCards/gql/dealDetailsQueries';
import {
  GetDealGeneralInfo,
  GetDealGeneralInfoVariables,
} from 'features/deals/dealCards/gql/__generated__/GetDealGeneralInfo';
import { cardsConfig, CardsKey, cardsOrder } from 'features/deals/deals.config';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from 'style/ORSNNTheme';
import DealStages from 'features/deals/DealStages';
import { Messaging } from 'features/deals/Messaging/Messaging';
import StatusTracker from './StatusTracker';
import DealRoomContext from './DealRoomContext';

// TODO this is not now we want to get our deal data
// TODO a deal has a single id, and we can get all the data we need from that
// TODO this query will always fail unless your company owns the listing
const GetDealQuery = gql`
  query GetDealsByListing($listing_id: String!) {
    deals(listing_id: $listing_id) {
      id
    }
  }
`;

const DealPage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  .progress {
    flex: 0 0 25%;
    top: 0px;
    position: sticky;
  }

  .cards {
    flex: 0 0 75%;
    max-width: 75%;
  }
`;

export function DealRoom() {
  const { deal_id: dealId } = useParams<{ deal_id: string }>();

  const { loading, data } = useQuery<
    GetDealGeneralInfo,
    GetDealGeneralInfoVariables
  >(GET_DEAL_GENERAL_INFO_QUERY, {
    variables: { id: dealId as string },
    skip: !dealId,
  }); // we cannot conditionally useQuery so we skip it

  const [currentCard, setCurrentCard] = useState<CardsKey>('counterparty');

  if (dealId == null) {
    return <>Missing Deal Id</>;
  }

  const assetClass = data?.deal?.listing.assetClass;

  {
    /* Disabling messaging for now as per RC1-187 */
  }
  const enableMessaging = false;

  return (
    <DealRoomContext.Provider
      value={{
        dealId: dealId,
        currentCard: currentCard,
        setCurrentCard: setCurrentCard,
      }}
    >
      <div className="summery"></div>
      <div className="sidebar"></div>
      {assetClass ? (
        <DealPage className="page">
          <div className="progress">
            <StatusTracker deal={data.deal}/>
            {enableMessaging && <Messaging dealId={dealId} />}
          </div>
          <div className="cards">
            {
              <DealStages />
            }
          </div>
        </DealPage>
      ) : null}
    </DealRoomContext.Provider>
  );
}
