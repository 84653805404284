import { gql } from '@apollo/client';

const GET_LOAN = gql`
  query GetLoan($accountId: String!) {
    user {
      id
      given_name
      family_name
      company {
        id
        ... on UserCompany {
          id
          account(account_id: $accountId) {
            id
            account_id
            age_months
            asset_class
            borrower_credit_score
            city
            current_balance_cents
            interest_rate
            last_updated_date
            listing {
              id
              name
            }
            loan_terms_months
            ltv
            maturity_date
            next_payment_date
            original_balance_cents
            origination_date
            property_year
            property_description
            remaining_loan_terms_months
            servicing_rate
            state
            version
            ... on AutoLoan {
              auto_original_value_cents
              auto_vin
              dti
              postal_code
            }
            ... on HomeLoan {
              dti
              occupancy
              postal_code
              purpose
            }
          }
        }
      }
    }
  }
`;

export { GET_LOAN };
