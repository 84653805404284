import React, { useEffect, useMemo, useRef } from 'react';
import LoanTapeUploader from './loanTapeUploader/LoanTapeUploader';
import MapRequiredFieldsCard from './fieldMapping/MapRequiredFieldsCard';
import { FileState } from '__generated__/globalTypes';
import ProcessingReportCard from './ProcessingReportCard';
import { GetUploadedFilesWithHeaders_user_company_files as UploadedFile } from 'query/__generated__/GetUploadedFilesWithHeaders';
import { useTapeUploadContext } from './loanTapeUploader/TapeUploadContext';
import SingleFileLoanTapeUploader from './loanTapeUploader/SingleFileLoadTapeUploader';

const ReadingFileColumns = () => <div></div>;

const FileIngested = () => <div></div>;

type FileFieldMappingProps = {
  selectedFile: UploadedFile;
  mapFieldsCardRef: React.RefObject<HTMLDivElement>;
  processingReportCardRef: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
};

const FileFieldMapping = ({
  selectedFile,
  mapFieldsCardRef,
  disabled,
}: FileFieldMappingProps) => (
  <div ref={mapFieldsCardRef} style={{ position: 'relative' }}>
    <MapRequiredFieldsCard
      disabled={disabled}
      key={selectedFile.id}
      selectedFile={selectedFile}
    />
  </div>
);

export type TapeUploadFlowProps = {
  uploadedTapes?: UploadedFile[];
};

const TapeUploadFlow = ({
  uploadedTapes,
}: TapeUploadFlowProps): JSX.Element => {
  const { selectedFileId, setSelectedFileId } = useTapeUploadContext();

  const selectedFile = useMemo(() => {
    return uploadedTapes?.find((tape) => tape.id === selectedFileId);
  }, [selectedFileId, uploadedTapes]);

  const mapFieldsCardRef = useRef<HTMLDivElement>(null);
  const scrollToMapFieldsCard = () => {
    mapFieldsCardRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const processingReportCardRef = useRef<HTMLDivElement>(null);
  const scrollToProcessingReportCard = () => {
    processingReportCardRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (selectedFile?.state === FileState.PROCESSED) {
      scrollToProcessingReportCard();
    }
  }, [selectedFile?.state]);

  const onSuccessfulTapeUpload = (file: UploadedFile) => {
    setSelectedFileId(file.id);
    scrollToMapFieldsCard();
  };

  const showProcessReportCard = selectedFile?.state === FileState.PROCESSED;

  const isMappingCardDisabled = selectedFile?.state !== FileState.NEEDS_MAPPING;

  const isSingleFileUpload = true;

  return (
    <div>
      {isSingleFileUpload ? (
        <SingleFileLoanTapeUploader onSuccess={onSuccessfulTapeUpload} />
      ) : (
        <LoanTapeUploader onClickNext={scrollToMapFieldsCard} />
      )}

      {selectedFile && (
        <>
          {selectedFile.state === FileState.READING_FILE_HEADERS ||
          selectedFile.state === FileState.NEW ? (
            <ReadingFileColumns />
          ) : selectedFile.state === FileState.PERSISTED ? (
            <FileIngested />
          ) : (
            <>
              <FileFieldMapping
                disabled={isMappingCardDisabled}
                selectedFile={selectedFile}
                mapFieldsCardRef={mapFieldsCardRef}
                processingReportCardRef={processingReportCardRef}
              />
              <div ref={processingReportCardRef}>
                {' '}
                {showProcessReportCard && (
                  <ProcessingReportCard selectedFile={selectedFile} />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TapeUploadFlow;