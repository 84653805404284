import { PillDropdown } from 'common-ui/Dropdown/PillDropdown';
import { useMemo } from 'react';
import { BaseFiltersConfig, BooleanFilter, BooleanFilterConfigEntry } from '../filters.types';
import { CommonProps } from '../PillFilters';

export function BooleanPillFilters<T extends BaseFiltersConfig>(props: CommonProps<T> & {
  filter: BooleanFilter<T>;
  filterConfig: BooleanFilterConfigEntry;
}){

  const optionsMap = useMemo(
    () => ({
      false: { value: false, label: props.filterConfig.falseyLabel },
      true: { value: true, label: props.filterConfig.truthyLabel },
    }),
    [props.filterConfig.falseyLabel, props.filterConfig.truthyLabel]
  );
  const options = useMemo(() => [optionsMap.false, optionsMap.true], [optionsMap]);

  return (
    <PillDropdown
      options={options}
      value={optionsMap[props.filter.value ? 'true' : 'false']}
      iconOnClick={() => props.removeFilter(props.filter.name, props.index)}
      iconName="pill-close"
      description={props.filterConfig.displayName}
      onChange={(option) =>
        option ? props.updateFilter(props.filter.name, props.index, option.value) : undefined
      }
    />
  );

}