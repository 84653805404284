import { theme } from 'style/ORSNNTheme';
import { StyledSpinnerNext as BaseSpinner } from 'baseui/spinner';
import { withStyle } from 'styletron-react';

const StyledSpinner = withStyle(BaseSpinner, {
  height: '96px',
  width: '96px',
  borderTopColor: theme.color.brandMainPink,
  borderLeftWidth: '12px',
  borderRightWidth: '12px',
  borderBottomWidth: '12px',
  borderTopWidth: '12px',
});

const FixedSpinner = withStyle(StyledSpinner, {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});


export const Spinner = (props: { loading?: boolean, positionRelative?: boolean }): JSX.Element | null => {
  if (props.loading) {
    return props.positionRelative ? <StyledSpinner /> : <FixedSpinner />;
  } else {
    return null;
  }
};
