import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ProgressIndicator } from 'common-ui/ProgressIndicator/ProgressIndicator';
import { SvgIcon } from 'common-ui';
import { gql } from '@apollo/client';
import { TapesUploadedTableFile } from './__generated__/TapesUploadedTableFile';
import { FileState } from '__generated__/globalTypes';

const TapesUploadedTableFragments = {
  file: gql`
    fragment TapesUploadedTableFile on File {
      id
      fileName: file_name
      processingReport: processing_report {
        new_count
      }
    }
  `,
};

export type TapesUploadedTableRow = {
  rowId: string; // For uploading files, the rowId is the Uppy ID. For uploaded files, it is the server-assigned file ID.
  filename: string;
  progressPercent?: number; // This is set only for tapes in the process of being uploaded.
  uploadComplete?: boolean;
  uploadedFile?: TapesUploadedTableFile; // This is set only for fully uploaded tapes.
  state?: FileState;
};

const StyledTableWrapper = styled.div`
  height: 100px;
  // width: 600px;
  overflow-y: scroll;
  margin-bottom: 15px;
  font-size: 14px;
  color: ${(props) => props.theme.color.slate50};
`;

const TableRowContainer = styled.div<{
  isSelected: boolean;
  isSelectable: boolean;
  isUploading: boolean;
  isSaved: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => (props.isSelectable ? 'pointer' : 'not-allowed')};
  border-top: 1px solid ${(props) => props.theme.color.slate400};
  padding: 7px 16px 16px 10px;
  background-color: ${(props) =>
    props.isSelected ? props.theme.color.accentDefault : 'transparent'};
  line-height: 20px;

  color: ${(props) =>
    props.isSelected
      ? '#4d0b4c'
      : props.isUploading || props.isSaved
      ? props.theme.color.slate200
      : props.theme.color.slate50};

  border: 1px solid transparent;
  &:hover {
    background-color: ${(props) =>
      props.isSelectable && !props.isSelected && props.theme.color.slate300};
  }
`;

const FilenameCell = styled.div`
  flex: 1;
  margin-right: 16px;
  width: 300px;
`;

const ProgressCell = styled.div`
  display: flex;
  gap: 18px;
  width: 206px;
  margin-right: 16px;
`;

const StatusCell = styled.div`
  flex: 1;
  text-align: left;
  text-wrap: nowrap;
`;

const RowsCell = styled.div`
  flex: 1;
  text-align: right;
  text-wrap: nowrap;
`;

export type Props = {
  tableRows: TapesUploadedTableRow[];
  selectedRowId: string | undefined;
  onRowClick: (row: TapesUploadedTableRow) => void;
  onCancelUploadClick: (uppyId: string) => void;
};

const getRowStatus = (state?: FileState) => {
  switch (state) {
    case FileState.NEW:
    case FileState.READING_FILE_HEADERS:
      return 'Uploading...';
    case FileState.PERSISTED:
      return 'Saved';
    case FileState.PROCESSED:
      return 'Ready to save';
  }
  return state;
};

const TapesUploadedTable: React.FC<Props> = ({
  tableRows,
  selectedRowId,
  onRowClick,
  onCancelUploadClick,
}) => {
  const handleRowClick = (row: TapesUploadedTableRow) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const rowRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});

  const isInView = (ref: React.RefObject<HTMLDivElement>) => {
    if (!ref || !ref.current) return false;

    const rect = ref.current.getBoundingClientRect();
    const parentRect = ref.current.parentElement?.getBoundingClientRect();

    if (!parentRect) return false;

    return rect.top >= parentRect.top && rect.bottom <= parentRect.bottom;
  };

  tableRows.forEach((row) => {
    if (!rowRefs.current[row.rowId]) {
      rowRefs.current[row.rowId] = React.createRef();
    }
  });

  useEffect(() => {
    if (selectedRowId && !isInView(rowRefs.current[selectedRowId])) {
      rowRefs.current[selectedRowId]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [selectedRowId]);

  return (
    <StyledTableWrapper>
      {tableRows.map((row) => {
        const isSelectable =
          row.state === FileState.NEEDS_MAPPING ||
          row.state === FileState.PROCESSED ||
          row.state === FileState.PROCESSING;
        return (
          <TableRowContainer
            ref={rowRefs.current[row.rowId]}
            key={row.rowId}
            isSelected={selectedRowId === row.rowId}
            isSelectable={isSelectable}
            isUploading={row.progressPercent !== undefined}
            isSaved={row.state === FileState.PERSISTED}
            onClick={() => isSelectable && handleRowClick(row)}
            as="div"
          >
            <FilenameCell>{row.filename}</FilenameCell>
            <ProgressCell>
              {row.progressPercent !== undefined && (
                <>
                  <ProgressIndicator percent={row.progressPercent} />
                  <div onClick={() => onCancelUploadClick(row.rowId)}>
                    <SvgIcon name="close-round" />
                  </div>
                </>
              )}
            </ProgressCell>
            <StatusCell>
              <span style={{ color: '#333' }}>{getRowStatus(row.state)}</span>
            </StatusCell>
            <RowsCell>
              {row.uploadedFile &&
              row.uploadedFile.processingReport.new_count !== null ? (
                <span>
                  {row.uploadedFile.processingReport.new_count.toLocaleString()}{' '}
                  rows
                </span>
              ) : (
                <span>-</span>
              )}
            </RowsCell>
          </TableRowContainer>
        );
      })}
    </StyledTableWrapper>
  );
};

export default TapesUploadedTable;
export { TapesUploadedTableFragments };
