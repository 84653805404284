import { Link } from 'react-router-dom';
import { styled } from 'style/ORSNNTheme';

const CardLink = styled(Link)`
  position: relative;
  background-color: ${(props) => props.theme.color.cardBackground};
  border: 1px solid ${(props) => props.theme.color.borderDefault};
  color: ${(props) => props.theme.color.fgDefault};
  margin-top: 16px;
  min-height: 48px;
  text-decoration: none;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  padding: 0 12px;

  &:hover {
    border-color: ${(props) => props.theme.color.brandMainPink};
    color: unset;
    text-decoration: none;
  }
  &:active {
    background-color: ${(props) => props.theme.color.borderDefault};
  }
`;

export default CardLink;
