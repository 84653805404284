import RadioButtonGroup from 'common-ui/Buttons/RadioButtonGroup/RadioButtonGroup';
import {
  BiddingAndPricingSectionSubHeader,
  CashFlowModelingInputs,
  ColumnsContainer,
} from './BiddingAndPricingSection.styles';
import React, { useState } from 'react';
import CashFlowModelingInput from './CashFlowModelingInput';
import SelectPercentage from './SelectPercentage';
import BidSummary from './BidSummary';
import { CarveTableRowType } from './CarveTable';
import YieldToPriceChart from './YieldToPriceChart';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import styled from 'styled-components';
import { PredefinedOrCustomParameter } from './BidDetails';
import { BidDetails } from './BidDetails';
import { useBiddingPricingContext } from './BiddingPricingContext';
import {
  MAXIMUM_PARTICIPATION_PERCENT,
  MAXIMUM_SERVICING_PRICE,
  MINIMUM_PARTICIPATION_PERCENT,
  MINIMUM_SERVICING_PRICE,
  PREDEFINED_PARTICIPATION_PERCENTS,
  PREDEFINED_SERVICING_PRICES,
} from './constants';
import { formatCentsToDollars, toPercentage } from './formatting';

// TODO(kentskinner): these need to be replaced with queries.
const FAKE_MATURITIES_DATA = [
  {
    period: '3mo',
    value: 4.65,
  },
  {
    period: '6mo',
    value: 4.81,
  },
  {
    period: '12mo',
    value: 4.65,
  },
  {
    period: '2yr',
    value: 4.12,
  },
  {
    period: '5yr',
    value: 3.49,
  },
  {
    period: '10yr',
    value: 3.4,
  },
  {
    period: '30yr',
    value: 3.57,
  },
];

const selectCurveOptions = [
  {
    label: 'Treasury',
  },
  {
    label: 'SOFR (coming soon)',
    isDisabled: true,
  },
];

const SelectCurveContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const MaturityData: React.FC = () => {
  const maturityData = FAKE_MATURITIES_DATA;
  return (
    <MaturityDataWrapper>
      <MaturityDataPoints>
        {maturityData.map((m) => {
          return (
            <MaturityDataPoint key={m.period}>
              {m.period}&nbsp;
              <MaturityDataPointValue>{m.value}%</MaturityDataPointValue>
            </MaturityDataPoint>
          );
        })}
      </MaturityDataPoints>
      <SelectCurveContainer>
        Select Curve&nbsp;
        <Dropdown
          defaultValue={selectCurveOptions[0]}
          isMulti={false}
          options={selectCurveOptions}
        />
      </SelectCurveContainer>
    </MaturityDataWrapper>
  );
};

const MaturityDataWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
`;

const MaturityDataPoints = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.color.fgOnAccent};
  margin-right: 30px;
`;

const MaturityDataPoint = styled.div`
  padding: 10px 20px;
  color: ${(props) => props.theme.color.grey500};
`;

const MaturityDataPointValue = styled.span`
  color: #f2f2f2;
  margin-left: 5px;
`;

interface YieldMatrixProps {
  carve: CarveTableRowType;
}

const YieldMatrix = (props: YieldMatrixProps) => {
  const { bidDetails, updateBidDetails } = useBiddingPricingContext();
  const [_armRateProjection, setArmRateProjection] = useState<string>('');

  if (!bidDetails) return null;

  const handleInputChange = (field: keyof BidDetails) => (valueStr: string) => {
    const value = valueStr ? Number(valueStr) : 0;
    updateBidDetails({ ...bidDetails, [field]: value });
  };

  const updateParticipationPercentage = (
    selectedOption: PredefinedOrCustomParameter
  ) => {
    updateBidDetails({
      ...bidDetails,
      participationPercent: selectedOption,
    });
  };

  const updateServicingPrice = (
    selectedOption: PredefinedOrCustomParameter
  ) => {
    updateBidDetails({
      ...bidDetails,
      servicingPrice: selectedOption,
    });
  };

  return (
    <>
      <BiddingAndPricingSectionSubHeader>
        ARM RATE PROJECTION
      </BiddingAndPricingSectionSubHeader>
      <RadioButtonGroup
        name="arm_rate_projection"
        options={[
          { label: 'Flat', value: 'flat' },
          { label: 'Yield Curve', value: 'yield_curve' },
        ]}
        defaultVal={'flat'}
        onChange={(value) => setArmRateProjection(value)}
      />
      <MaturityData />
      <CashFlowModelingInputs>
        <CashFlowModelingInput
          fieldName="CPR"
          value={bidDetails.cpr.toString()}
          onValueChange={handleInputChange('cpr')}
        />
        <CashFlowModelingInput
          fieldName="CDR"
          value={bidDetails.cdr.toString()}
          onValueChange={handleInputChange('cdr')}
        />
        <CashFlowModelingInput
          fieldName="LSR"
          value={bidDetails.lsr.toString()}
          onValueChange={handleInputChange('lsr')}
        />
        <CashFlowModelingInput
          unit="s"
          max={60}
          fieldName="Delay"
          value={bidDetails.delay.toString()}
          onValueChange={handleInputChange('delay')}
        />
        <div
          style={{
            marginLeft: 15,
            paddingTop: 33,
            paddingBottom: 15,
            gap: 40,
            display: 'flex',
            color: '#e8e9dc',
          }}
        >
          <div>GWAC {toPercentage(props.carve.GWAC)}</div>
          <div>UPB {formatCentsToDollars(props.carve.UPB)}</div>
        </div>
      </CashFlowModelingInputs>
      <ColumnsContainer>
        <div>
          <YieldToPriceChart
            bidDetails={bidDetails}
            updateBidDetails={updateBidDetails}
          />
        </div>

        <div style={{ width: '100%' }}>
          <div>Select Participation Percentage *</div>
          <SelectPercentage
            options={PREDEFINED_PARTICIPATION_PERCENTS}
            customMax={MAXIMUM_PARTICIPATION_PERCENT}
            customMin={MINIMUM_PARTICIPATION_PERCENT}
            selectedOption={bidDetails.participationPercent}
            setSelectedOption={updateParticipationPercentage}
          />
          <div>
            Select Servicing Price - &lt;retained-or-cuparticipation&gt; *
          </div>
          <SelectPercentage
            options={PREDEFINED_SERVICING_PRICES}
            customMax={MAXIMUM_SERVICING_PRICE}
            customMin={MINIMUM_SERVICING_PRICE}
            selectedOption={bidDetails.servicingPrice}
            setSelectedOption={updateServicingPrice}
          />
          <BidSummary carve={props.carve} />
        </div>
      </ColumnsContainer>
    </>
  );
};

export default YieldMatrix;
