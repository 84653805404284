import { gql } from '@apollo/client';

export const REJECT_FUNDING_SCHEDULE = gql`
  mutation RejectFundingSchedule($input: RejectFundingScheduleInput!) {
    rejectFundingSchedule(input: $input) {
      id
      state {
        status
        blocker
      }
    }
  }
`;
