import { Col, Row } from './Table';
import { styled } from 'style/ORSNNTheme';

const Label = styled(Col)`
  color: ${(props) => props.theme.color.fgLabelDefault};
`;

const Value = styled.span`
  color: ${(props) => props.theme.color.fgDefault};
  font-family: PT Mono, ui-monospace, monospace;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LabelValuePair = styled(Col)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  gap: 0.5rem;
`;

type LabelAndValueProps = {
  label: string;
  value?: string;
};

const LabelAndValue = ({
  label,
  value = `   `,
}: LabelAndValueProps): JSX.Element => {
  return (
    <LabelValuePair>
      <Label>{label}</Label>
      <Value>{value.replace(/ /g, '\xa0')}</Value>
    </LabelValuePair>
  );
};

// scrollbar-less horizontal scroll for KPI strips
const RowScrollBox = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-right: 8px;
`;

const LabeledRow = styled(Row)`
  gap: 1.5rem;
  flex: 1 1 auto;
  flex-basis: 0;
  justify-content: start;
`;

const RowLabel = styled(Label)`
  min-width: 50px;
  max-width: 50px;
`;

const SummaryRow = styled(Row)`
  gap: 1.5rem;
  width: max-content;
`;

export {
  Label,
  Value,
  LabelValuePair,
  LabelAndValue,
  LabeledRow,
  RowLabel,
  SummaryRow,
  RowScrollBox,
};
