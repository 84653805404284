import { StratificationsPerformanceSummary } from './__generated__/StratificationsPerformanceSummary';
import { ChartType } from 'features/visualizations';
import { AssetClass, FilterableField } from '__generated__/globalTypes';
import { formatCurrency,  formatPercent } from 'configs/columns';
import AssetClassConfig from 'models/AssetClass';
import moment from 'moment';
import { ReactNode } from 'react';
import { isNumber, invert, mapValues } from 'lodash';
import {  FormatNumberOptions, IntlShape } from 'react-intl';
import { MayHaveLabel } from '@nivo/pie';
import { PoolSummaryPerformanceSummary } from 'features/loanPool/__generated__/PoolSummaryPerformanceSummary';

const currenctCentsCompact: FormatNumberOptions = { maximumFractionDigits: 0, style: 'currency', currency: 'USD', notation: 'compact' };
function formatKeyCents(intl:IntlShape, value: string, toAdd: number) {
  return `${intl.formatNumber(parseInt(value) / 100, currenctCentsCompact)}
    -
    ${intl.formatNumber((parseInt(value) + toAdd) / 100,currenctCentsCompact)}`;
}

function formatKeyPercents(intl: IntlShape, value: string, toAdd: number) {
  const options: FormatNumberOptions = { maximumFractionDigits: 1, style: 'percent' };
  return `${intl.formatNumber(parseFloat(value), options) }
  -
  ${intl.formatNumber(parseFloat(value) + toAdd, options) }`;
}
export const stratificationsConfig = {
  'strat_age_months': {
    label: 'Loan Age',
    chart: 'Pie',
    formatKey: (_, __, value) => `${value} - ${parseInt(value) + 2}`,
    filterField: FilterableField.age_months,
    avgField: 'averageAge',
    abbr: 'LA'
  },
  'strat_borrower_credit_score': {
    label: 'Borrower Credit Score',
    chart: 'Bar',
    formatKey: (_, __, value) =>
      parseInt(value) === 850
        ? '850'
        : `${value} - ${parseInt(value) + 9}`,
    filterField: FilterableField.borrower_credit_score,
    abbr: 'BCS'
  },
  'strat_current_balance_cents': {
    label: 'UPB',
    chart: 'Pie',
    formatKey: (intl, assetClass, value) => formatKeyCents(intl, value, AssetClassConfig[assetClass].balanceBucketSize),
    filterField: FilterableField.current_balance_cents,
    avgField: 'averageBalance',
    avgFormat: (intl, value) => intl.formatNumber(value / 100, currenctCentsCompact),
    abbr: 'UPB'
  },
  'strat_original_balance_cents': {
    label: 'Original Balance',
    chart: 'Pie',
    formatKey: (intl, assetClass, value) => formatKeyCents(intl, value, AssetClassConfig[assetClass].balanceBucketSize),
    filterField: FilterableField.original_balance_cents,
    avgField: 'averageOriginalBalance',
    avgFormat: (intl, value) => intl.formatNumber(value / 100, currenctCentsCompact),
    abbr: 'OB'
  },
  'strat_dti': {
    label: 'DTI',
    chart: 'Bar',
    formatKey: (intl, _, value) => formatKeyPercents(intl, value, 0.09999),
    filterField: FilterableField.dti,
    abbr: 'DTI'
  },
  'strat_interest_rate': {
    label: 'Interest Rate',
    chart: 'Bar',
    formatKey: (intl, _, value) => formatKeyPercents(intl, value, 0.00249),
    filterField: FilterableField.interest_rate,
    abbr: 'IR'
  },
  'strat_ltv': {
    label: 'LTV',
    chart: 'Bar',
    formatKey: (intl, _, value) => formatKeyPercents(intl, value, 0.04999),
    filterField: FilterableField.ltv,
    abbr: 'LTV'
  },
  'strat_origination_date': {
    label: 'Origination Date',
    chart: 'Bar',
    formatKey: (intl,__, value) => moment(parseInt(value)).format('[Q]Q-YYYY'),
    filterField: FilterableField.origination_date_seconds,
    abbr: 'OD'
  },
  'strat_remaining_loan_terms_months': {
    label: 'Maturity Date',
    chart: 'Bar',
    formatKey: (_, __,value) => value,
    filterField: FilterableField.maturity_date_seconds,
    abbr: 'MD'
  },
  'strat_state': {
    label: 'State',
    chart: 'States',
    formatKey: (_,__, value) => value,
    filterField: FilterableField.state,
    abbr: 'ST'
  },
} satisfies { [key in keyof StratificationsPerformanceSummary]?: {
  label: string,
  chart: ChartType,
  formatKey: (intl: IntlShape, assetClass: AssetClass, value: string) => string,
  filterField: FilterableField,
  avgField?: keyof PoolSummaryPerformanceSummary
  avgFormat?: (intl: IntlShape, value: number) => string
  abbr: string
}};

export type StratKey = keyof typeof stratificationsConfig;

export type StratRowType = StratificationsPerformanceSummary[StratKey][number] & MayHaveLabel;

type StratColConfig<T extends StratRowType, K extends keyof T> = {
  header: string;
  // sortSelector?: SortableField;
  display: (value: T[K]) => string | ReactNode;
};
export const stratsColumnsConfig = {
  't_current_balance_cents': {
    header: 'Total Current Balance',
    display: (value: number | null) => formatCurrency(value),
  },
  't_original_balance_cents': {
    header: 'Total Original Balance',
    display: (value: number | null) => formatCurrency(value),
  },
  'loanCount': {
    header: 'Total Count',
    display: (value: number | null) => formatInterger(value),
  },
  'wa_coupon': {
    header: 'GWAC',
    display: (value: number | null) => formatPercent(value, 3),
  },
  'wa_net_rate': {
    header: 'NWAC',
    display: (value: number | null) => formatPercent(value, 3),
  },
  'wa_borrower_credit_score': {
    header: 'CREDIT SCORE',
    display: (value: number | null) => formatInterger(value),
  },
  'wa_remaining_loan_terms_months': {
    header: 'WAM',
    display: (value: number | null) => formatInterger(value),
  },
  'wa_age_months': {
    header: 'WALA',
    display: (value: number | null) => formatInterger(value),
  }

} satisfies { [key in keyof StratRowType]?: StratColConfig<StratRowType, key> };

export const abbrToKeyMap = invert(mapValues(stratificationsConfig, (v) => v.abbr)) as { [key: string]: StratKey };

const formatInterger = (value: number | null) => isNumber(value) ? Math.floor(value || 0).toLocaleString() : '-';

export const stratsColumnOrder: (keyof typeof stratsColumnsConfig)[] = ['t_current_balance_cents', 't_original_balance_cents', 'loanCount', 'wa_coupon', 'wa_net_rate', 'wa_borrower_credit_score', 'wa_remaining_loan_terms_months', 'wa_age_months'];