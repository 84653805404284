import { useLazyQuery } from '@apollo/client';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Delete, Plus, Spinner } from 'baseui/icon';
import { ListItem, ListItemLabel } from 'baseui/list';
import { PillButton } from 'common-ui/inputs/Button';
import {
  FileStatus,
  UploadStatus,
  useUploadZone,
} from 'features/deals/utils';
import prettyBytes from 'pretty-bytes';
import { GET_DEAL_DOCUMENTS } from 'query/deal';
import {
  GetDealDocuments,
  GetDealDocumentsVariables,
} from 'query/__generated__/GetDealDocuments';
import { styled } from 'style/ORSNNTheme';
import { FileType } from '__generated__/globalTypes';
import { List, ListItemStyleProps, StyledLabelBody } from '../utils';
import { DealDocumentsCard_DealDocumentsCard } from './__generated__/DealDocumentsCard';

const ListContainer = styled.div`
  display: flex;
  padding
`;

const CustomButtonGroup = styled.div`
  display: flex;
`;

function renderIcon(file: FileStatus) {
  switch (file.uploadStatus) {
    case UploadStatus.Pending:
    case UploadStatus.InProgress:
      return <Spinner size={16} />;
    case UploadStatus.Complete:
      return <Delete size={16} />;
    case UploadStatus.Error:
      return <Alert size={16} />;
  }
}

type Props = { dealId: string } & DealDocumentsCard_DealDocumentsCard;

const DealDocumentsCardBody = (props: Props): JSX.Element => {
  const variables = { id: props.dealId };
  const [_getDealDocuments, { data, refetch }] = useLazyQuery<
    GetDealDocuments,
    GetDealDocumentsVariables
  >(GET_DEAL_DOCUMENTS, {
    variables,
  });

  const { uploadFiles, getRootProps, getInputProps, deleteFile } =
    useUploadZone(props.dealId, FileType.DEAL_FILE, [variables], refetch);

  const documents = data?.deal?.documents ?? props.documents;

  const optionalUploadSection = props.can_upload ? (
    <CustomButtonGroup>
      {uploadFiles.map((file) => (
        <PillButton
          key={file.name}
          endEnhancer={() => renderIcon(file)}
          onClick={() => deleteFile(file.id)}
        >
          {file.name}
        </PillButton>
      ))}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <PillButton endEnhancer={() => <Plus size={16} />}>
          Select New File
        </PillButton>
      </div>
    </CustomButtonGroup>
  ) : (
    <></>
  );

  return (
    <>
      {optionalUploadSection}
      <ListContainer>
        {[
          documents.slice(0, Math.ceil(documents.length / 2)),
          documents.slice(Math.ceil(documents.length / 2)),
        ].map((halfDocs, index) => (
          <List key={halfDocs[0]?.id ?? index}>
            {halfDocs.map((doc) => {
              return (
                <ListItem key={doc.id} {...ListItemStyleProps}>
                  <ListItemLabel>
                    <StyledLabelBody>
                      {`${doc.name} - ${prettyBytes(doc.documentSizeBytes)}`}
                      <a href={doc.documentUrl}>
                        <FontAwesomeIcon icon={faFileDownload} />
                      </a>
                    </StyledLabelBody>
                  </ListItemLabel>
                </ListItem>
              );
            })}
          </List>
        ))}
      </ListContainer>
    </>
  );
};

export default DealDocumentsCardBody;
export { default as DealDocumentsCardFragments } from './fragments';
