const config = {
  s3: {
    REGION: 'us-west-2',
    USER_UPLOAD_BUCKET: 'orsnn-app-endro-user-uploads',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://563dgvmu6c.execute-api.us-west-2.amazonaws.com/endro/graphql',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_0H5ewRWbT',
    APP_CLIENT_ID: '3e9ia2n2b2j3p6rdcv97as6dlh',
    IDENTITY_POOL_ID: 'us-west-2:8f105e5b-ff62-49cf-a963-2f5be2799a48',
  },
  fileGateway: {
    REGION: 'us-west-2',
    URL: 'https://563dgvmu6c.execute-api.us-west-2.amazonaws.com/endro/files',
  },
  smartHeaderGateway: {
    REGION: 'us-west-2',
    URL: 'https://563dgvmu6c.execute-api.us-west-2.amazonaws.com/endro/smart_header_mapping',
  },
  website: {
    URL: 'https://www.orsnn.com',
  },
};

export default config;