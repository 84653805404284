import { gql } from '@apollo/client';
import DealStagesFragments from 'features/deals/DealStages/fragments';

export const ACCEPT_BID = gql`
  mutation AcceptBid($input: AcceptBidInput!) {
    acceptBid(input: $input) {
      id
      ...DealStagesDeal
    }
  }
  ${DealStagesFragments.Deal}
`;
