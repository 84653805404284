import { Spinner } from 'common-ui';
import { CardCommonProps, CardWrapper } from './CardWrapper';
import BiddingAndPricingSection from 'features/pages/marketplace/BiddingAndPricing/BiddingAndPricingSection';
import { useQuery } from '@apollo/client';
import { GET_LISTINGS } from 'query/getListings';
import { GetListings } from 'query/__generated__/GetListings';

export function BiddingAndPricingCard(props: Omit<CardCommonProps, 'name'>) {
  const { data, loading, error } = useQuery<GetListings>(GET_LISTINGS, {});

  return (
    <CardWrapper {...props} name="bidding">
      {props.active ? (
        <BiddingAndPricingSection dealId={props.dealId} />
      ) : null}
      <Spinner loading={loading} positionRelative={true} />
    </CardWrapper>
  );
}
