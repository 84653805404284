import { CardsKey } from 'features/deals/deals.config';
import { GetTimeline_deal_cards } from '../__generated__/GetTimeline';

export const getCardKey = (card: GetTimeline_deal_cards): CardsKey => {
  switch (card.__typename) {
    case 'CounterpartyCard':
      return 'counterparty';
    case 'DealDocumentsCard':
      return 'documents';
    case 'CarveDetailsCard':
      return 'carve';
    case 'BidTermsCard':
      return 'counterparty';
    case 'PlaceBidCard':
      return 'bidding';
    case 'StratificationsCard':
      return 'strats';
    case 'LoansCard':
      return 'loans';

    case 'BidCard':
    case 'RequestDataCard':
    case 'FundingScheduleInputCard':
    case 'FundingScheduleDetailsCard':
    case 'PlaceholderCard':
    case 'DataReviewCard':
    case 'CutOffTapeCard':
    case 'MasterParticipationAgreementCard':
    case 'ParticipationCertificateCard':
    case 'WireConfirmationCard':
    default:
      return 'complition';
  }
};
