import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { DataDisplayTable, DataDisplayTableProps, SvgIcon } from 'common-ui';
import { GetMarketplaceListings_listings as ListingType } from './__generated__/GetMarketplaceListings';
import { listingColsConfig, listingColsOrder } from './marketplace.config';
import { Link } from 'react-router-dom';
import { ListingField } from '__generated__/globalTypes';


const tableColumns: ColumnDef<ListingType, any>[] = [createColumnHelper<ListingType>().display({
  id: 'view-deal',
  cell: (props) => {
    return (
      <Link to={`../listing/${props.row.original.id}`}>
        <SvgIcon name="view" />
      </Link>
    );
  }
}),
...getColumns()
];
export function MarketplaceTable(props: {
  data: ListingType[],
  selection?: DataDisplayTableProps<ListingType, unknown>['selection']
}) {
  return <DataDisplayTable
    data={props.data}
    columns={tableColumns}
    noDataMessage="No Data"
    selection={props.selection}
  />;
}

type ValueOf<T> = T[keyof T];

function getColumns() {
  const columns: ColumnDef<ListingType, ValueOf<ListingType>>[] = listingColsOrder.map((col) => ({
    accessorKey: col,
    header: listingColsConfig[col].string,
    meta: {
      alignment: listingColsConfig[col].numeric ? 'right' : 'left',
      numeric: listingColsConfig[col].numeric,
    },
    cell: (props) => {
      const fieldValue = props.getValue() as ValueOf<ListingType>;
      const config = listingColsConfig[col] as any; // typescript thinks this is display(never)
      return config.display(fieldValue);
    }
  }));

  return columns;
}