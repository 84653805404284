import { ForwardedRef, forwardRef, InputHTMLAttributes, useEffect, useRef } from 'react';
import { styled } from 'style/ORSNNTheme';
import { useMergeRefs } from '@floating-ui/react';

const StyledIndeterminateCheckbox = styled.input`
  appearance: none;
  height: 18px;
  width: 18px;
  border: 2px solid ${(props) => props.theme.color.slate200};
  border-radius: 3px;
  display: grid;
  place-content: center;
  cursor: pointer;

  &:checked::before {
    content: '✔';
    color: ${(props) => props.theme.color.slate200};
    font-size: 11px;
    font-weight: 400;
  }

  &:indeterminate::before {
    content: '';
    width: 10px;
    height: 10px;
    transform-origin: bottom left;
    clip-path: inset(37% 12% 37% 12% round 10%);
    background-color: ${(props) => props.theme.color.slate200};
  }
`;

export type IndeterminateCheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  indeterminate?: boolean;
};

export const IndeterminateCheckbox = forwardRef((props: IndeterminateCheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { indeterminate, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const mergedRef = useMergeRefs([inputRef, ref]);

  useEffect(() => {
    if (inputRef.current && typeof indeterminate === 'boolean') {
      inputRef.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [inputRef, indeterminate, rest.checked]);
  return (
    <StyledIndeterminateCheckbox ref={mergedRef} type="checkbox" {...rest} />
  );
});