import styled from 'styled-components';
import { BaseButton } from 'common-ui';

import carveConfirmationImgPath from 'assets/pngs/carve-confirmation.png';
import { typographyHeebo24 } from 'common-ui/typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const QuestionText = styled.div`
  ${typographyHeebo24}
  margin: 10px 0 30px;
  color: ${(props) => props.theme.color.slate200};
`;

interface CreateCarveQuestionProps {
  setCreateCarve: (value: boolean) => void;
}

const CreateCarveQuestion: React.FC<CreateCarveQuestionProps> = ({
  setCreateCarve,
}) => {
  return (
    <Container>
      <img src={carveConfirmationImgPath} alt="Create Carve Icon" />
      <QuestionText>
        Would you like to create a carve of the offering?
      </QuestionText>
      <ButtonContainer>
        <BaseButton type="secondary" label={'No'}>
          No
        </BaseButton>
        <BaseButton onClick={() => setCreateCarve(true)} label={'Yes'}>
          Yes
        </BaseButton>
      </ButtonContainer>
    </Container>
  );
};

export default CreateCarveQuestion;
