import { BaseButton } from 'common-ui';
import { BidButtonsGroup } from './BiddingAndPricingSection.styles';
import { useBiddingPricingContext } from './BiddingPricingContext';
import { useMutation } from '@apollo/client';
import {
  SubmitBid,
  SubmitBidVariables,
} from 'mutation/__generated__/SubmitBid';
import { SUBMIT_BID } from 'mutation/submitBid';
import { CarveTableRowType } from './CarveTable';
import { BidConfirmationDialog } from './BidConfirmationDialog';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { BidSucceededDialog } from './BidSucceededDialog';
import { DialogActions } from 'common-ui/Dialog.styles';
import { useState } from 'react';

interface BidActionButtonsProps {
  dealId?: string;
  selectedCarve: CarveTableRowType;
  clearSelected: () => void;
}

export const BidActionButtons = ({
  dealId,
  selectedCarve,
  clearSelected,
}: BidActionButtonsProps) => {
  const { bidDetails } = useBiddingPricingContext();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [submitBid, { loading, called }] = useMutation<
    SubmitBid,
    SubmitBidVariables
  >(SUBMIT_BID);

  const bidConfirmationDialog = useDialog();
  const bidSucceededDialog = useDialog();
  const errorDialog = useDialog();

  if (!dealId) {
    return <p>Invalid Deal ID</p>;
  }
  const onSubmitBid = () => {
    bidConfirmationDialog.openDialog();
  };

  const onBidConfirmed = () => {
    bidConfirmationDialog.closeDialog();
    const bidPx = bidDetails?.bidPx;
    if (bidPx) {
      submitBid({
        variables: {
          input: {
            deal_id: dealId,
            bid_basis_points: bidPx * 100,
          },
        },
      })
        .then(() => {
          bidSucceededDialog.openDialog();
        })
        .catch((err) => {
          setErrorMessage(err.message);
          errorDialog.openDialog();
        });
    }
  };

  const submitEnabled = bidDetails && bidDetails.bidPx && bidDetails.bidPx > 0;

  return (
    <>
      <BidButtonsGroup>
        <BaseButton type="secondary" label={'save_bid'}>
          Save Bid
        </BaseButton>
        <BaseButton
          onClick={clearSelected}
          type="secondary"
          label={'clear_selected'}
        >
          Clear Selected
        </BaseButton>
        <BaseButton
          disabled={!submitEnabled}
          onClick={onSubmitBid}
          label={'submit_bid'}
        >
          Submit Bid
        </BaseButton>
      </BidButtonsGroup>

      {bidDetails && (
        <Dialog dialog={bidConfirmationDialog}>
          <BidConfirmationDialog
            bidDetails={bidDetails}
            selectedCarve={selectedCarve}
            onCancel={() => bidConfirmationDialog.closeDialog()}
            onConfirm={() => onBidConfirmed()}
          />
        </Dialog>
      )}

      <Dialog dialog={bidSucceededDialog}>
        <BidSucceededDialog
          onCancel={() => bidSucceededDialog.closeDialog()}
          onConfirm={() => bidSucceededDialog.closeDialog()}
        />
      </Dialog>

      <Dialog dialog={errorDialog}>
        <>
          <div>ERROR: {errorMessage}</div>
          <DialogActions>
            <BaseButton
              size="medium"
              label="cancel"
              onClick={() => errorDialog.closeDialog()}
            >
              OK
            </BaseButton>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};