import {PngIcon} from 'common-ui';
import { HeaderMenuItemConfig } from '../Header/headers.config';
import { NavLink } from 'react-router-dom';
import './header-menu-item.scss';
import { useGetMainPageUrl } from 'app-level/navigation';
import { MainRouteKeys } from 'app-level/navigation/navigation.config';

interface PassedProps {
  tabDetails: HeaderMenuItemConfig<MainRouteKeys>;
}

const HeaderMenuItem = ({ tabDetails }: PassedProps) => {
  const getPath = useGetMainPageUrl();

  return (
    <div className="header_menu_item">
      <li className="header_menu_item__list_item">
        <NavLink
          className={({isActive}) => isActive ? 'header_menu_item__link header_menu_item__link--active' : 'header_menu_item__link'}
          to={getPath(tabDetails.routeKey || 'home')}
        > 
          {tabDetails.iconName ? <PngIcon name={tabDetails.iconName} size="extraLarge" /> : null}
          <span className="header_menu_item__label">{tabDetails.label}</span>
        </NavLink>
      </li>
    </div>
  );
};

export default HeaderMenuItem;