import { gql } from '@apollo/client';

const GET_DEAL_DOCUMENTS_FRAGMENT = gql`
  fragment DocumentFields on Document {
    id
    name
    documentUrl
    documentSizeBytes
    uploadedAt
  }
`;

export const GET_DEAL_DOCUMENTS = gql`
  query GetDealDocuments($id: ID!, $fileType: FileType, $uploader: Uploader) {
    deal(id: $id) {
      id
      documents(fileType: $fileType, uploader: $uploader) {
        id
        ...DocumentFields
      }
    }
  }
  ${GET_DEAL_DOCUMENTS_FRAGMENT}
`;

export { GET_DEAL_DOCUMENTS_FRAGMENT };
