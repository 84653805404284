import { FilterValue, PillFilters } from 'features/common-elements';
import { marketplaceFiltersConfig, abbrToFilterKey, filterKeyToAbbr } from 'features/OnMarket/marketplace.config';
import { useSearchParams } from 'react-router-dom';
import { filtersToQueryFilters, searchQueryToFilterValues, filterValuesToSearchQuery } from 'features/common-elements';
import { Spinner } from 'common-ui';
// import { useState } from 'react';
// import { RowSelectionState } from '@tanstack/react-table';
import { MarketplaceTable } from 'features/OnMarket/MarketplaceTable';
import { useMarketPlaceListings_gql } from 'features/OnMarket/useMarketPlaceListings_gql';

const MarketHome = () => {
  const [search, setSearch] = useSearchParams();
  const searchFiltersStr = search.get('filters');
  const currentFilters = searchFiltersStr ? searchQueryToFilterValues(
    searchFiltersStr,
    abbrToFilterKey
  ) : [];

  const queryFilters = filtersToQueryFilters<typeof marketplaceFiltersConfig>(
    currentFilters,
    marketplaceFiltersConfig
  );

  // const [selectedPools, setSelectedPools] = useState<RowSelectionState>({});

  const { listings, loading } = useMarketPlaceListings_gql(queryFilters);

  function setFilters(filters: FilterValue<typeof marketplaceFiltersConfig>[]) {
    const newFilters = filterValuesToSearchQuery(filters, filterKeyToAbbr);
    const newSearch = new URLSearchParams();
    newSearch.set('filters', newFilters);
    setSearch(newSearch);
  }

  return (
    <>
      <div className="summery">
        <PillFilters
          filtersConfig={marketplaceFiltersConfig}
          currentFilters={currentFilters}
          setCurrentFilters={setFilters}
        />
      </div>
      <div className="sidebar"></div>
      <div className="page">
        <MarketplaceTable
          data={listings}
          // selection={{ selected: selectedPools, onSelectionChange: setSelectedPools }}
        />
        <Spinner loading={loading} />
      </div>
    </>
  );
};

export default MarketHome;