import styled from 'styled-components';

interface ModalHeaderProps {
  toggle: () => void;
  children: React.ReactNode;
}

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  font-size: 1.25rem;
  font-weight: 500;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #000;
  cursor: pointer;
`;

/**
 * @deprecated This is a temporary replacement for reactstrap. Do not use as-is
 */
export const ModalHeader: React.FC<ModalHeaderProps> = ({ toggle, children }) => {
  return (
    <StyledModalHeader>
      {children}
      <CloseButton onClick={toggle}>&times;</CloseButton>
    </StyledModalHeader>
  );
};
