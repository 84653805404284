import { gql } from '@apollo/client';

const StratBarChartFragments = {
  performanceSummary: gql`
    fragment StratBarChartPerformanceSummary on PerformanceSummary {
      totalCurrentBalance: current_balance_cents
    }
  `,
  stratRow: gql`
    fragment StratBarChartStratRow on StratRow {
      key
      loanCount: t_count
      currentBalance: t_current_balance_cents
      waCoupon: wa_coupon
    }
  `,
};

const USAStatesMapFragments = {
  performanceSummary: gql`
    fragment USAStatesMapPerformanceSummary on PerformanceSummary {
      totalCurrentBalance: current_balance_cents
    }
  `,
  stratRow: gql`
    fragment USAStatesMapStratRow on StratRow {
      stateCode: key
      currentBalance: t_current_balance_cents
      waCoupon: wa_coupon
    }
  `,
};

const StratificationsFragments = {
  performanceSummary: gql`
    fragment StratificationsPerformanceSummary on PerformanceSummary {
      ...StratBarChartPerformanceSummary
      ...USAStatesMapPerformanceSummary
      strat_age_months {
        ...StratFields
        ...StratBarChartStratRow
      }
      strat_borrower_credit_score {
        ...StratFields
        ...StratBarChartStratRow
      }
      strat_current_balance_cents {
        ...StratFields
        ...StratBarChartStratRow
      }
      strat_dti {
        ...StratFields
        ...StratBarChartStratRow
      }
      strat_interest_rate {
        ...StratFields
        ...StratBarChartStratRow
      }
      strat_ltv {
        ...StratFields
        ...StratBarChartStratRow
      }
      strat_original_balance_cents {
        ...StratFields
        ...StratBarChartStratRow
      }
      strat_origination_date {
        ...StratFields
        ...StratBarChartStratRow
      }
      strat_remaining_loan_terms_months {
        ...StratFields
        ...StratBarChartStratRow
      }
      strat_property_description {
        ...StratFields
      }
      strat_product {
        ...StratFields
      }
      strat_state {
        ...StratFields
        ...USAStatesMapStratRow
      }
    }
    fragment StratFields on StratRow {
      key
      loanCount: t_count
      t_current_balance_cents
      t_original_balance_cents
      wa_age_months
      wa_borrower_credit_score
      wa_coupon
      wa_net_rate
      wa_remaining_loan_terms_months
    }
    ${StratBarChartFragments.stratRow}
    ${StratBarChartFragments.performanceSummary}
    ${USAStatesMapFragments.performanceSummary}
    ${USAStatesMapFragments.stratRow}
  `,
};

export default StratificationsFragments;
