import { useApolloClient } from '@apollo/client';
import { AssetClass, FileType } from '__generated__/globalTypes';
import {
  GetFilePostUrls,
  GetFilePostUrlsVariables,
} from 'mutation/__generated__/GetFilePostUrls';
import GET_FILE_POST_URLS_MUTATION from 'mutation/getFilePostUrls';

export type GetPreSignedUrlFunc = (
  fileName: string,
  contentType: string,
  assetClass: AssetClass | undefined
) => Promise<string>;

export const usePreSignedUrlQuery = () => {
  const client = useApolloClient();

  const getPresignedUrl = async (
    fileName: string,
    contentType: string,
    assetClass: AssetClass | undefined
  ) => {
    const query = {
      mutation: GET_FILE_POST_URLS_MUTATION,
      variables: {
        parentId: '', // Not used for loan tapes
        fileType: FileType.LOAN_TAPE,
        fileName: fileName,
        contentType: contentType,
        assetClass: assetClass,
      },
    };

    const result = await client.mutate<
      GetFilePostUrls,
      GetFilePostUrlsVariables
    >(query);
    if (!result.data) {
      throw new Error('Could not obtain a pre-signed upload URL.');
    }
    return result.data.getFilePostUrl;
  };

  return {
    getPresignedUrl,
  };
};
