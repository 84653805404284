import { useCurrent2ndLevelRuoteConfig } from 'app-level/navigation';
import React, { PropsWithChildren } from 'react';
import PortfolioSummary from '../PortfolioSummary';
import SideNav from './SideNav/SideNav';

export function PortfolioLayout(
  props: PropsWithChildren<{
    summeryChildren?: React.ReactNode;
    filterRow?: React.ReactNode;
    hideSummery?: boolean;
    actions?: React.ReactNode;
  }>
) {
  const routeConfig = useCurrent2ndLevelRuoteConfig('portfolio');
  const showSideNav = routeConfig?.usesAssetClass;

  return (
    <>
      <div className="sidebar">{showSideNav ? <SideNav /> : null}</div>
      <div className="summery">
        {!props.hideSummery ? <PortfolioSummary /> : null}
        {props.summeryChildren || null}
        <div className="filters-actions">
          <div>{props.filterRow || null}</div>
          <div>{props.actions || null}</div>
        </div>
      </div>
      <div className="page">{props.children || null}</div>
    </>
  );
}
