import { useMemo, useState } from 'react';
import {
  Column,
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingState,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import { PillButton } from 'common-ui/Buttons';
import { Empty, EmptyAction } from './Empty';
import {
  checkBoxSelectionColumn,
  radioButtonSelectionColumn,
} from './selectionColumn';
import {
  Container,
  THead,
  TH,
  TBody,
  EmptyCell,
  TR,
  TD,
  Alignment,
  Table,
  Cell,
  ColumnMeta,
} from './tableStyles';

const DEFAULT_ALIGNMENT: Alignment = 'left';

type WithSubData<T> = T & {
  subData?: WithSubData<T>[];
};

export type DataDisplayTableStylingOptions = {
  rowColor?: string;
  alternateRowColor?: string;
  subRowColor?: string;
  alternateLightRows?: boolean;
};

export type DataDisplayTableProps<T, V> = {
  data: WithSubData<T>[];
  columns: ColumnDef<WithSubData<T>, V>[];
  noDataMessage: string;
  emptyAction?: EmptyAction;
  stylingOptions?: DataDisplayTableStylingOptions;
  selection?: {
    selected: RowSelectionState;
    onSelectionChange: OnChangeFn<RowSelectionState>;
    enableMultiRowSelection?: boolean;
  };
  sorting?:
    | {
        state: SortingState;
        onSortingChanged: OnChangeFn<SortingState>;
        isManual?: boolean;
      }
    | undefined;
};

const renderRow = <T,>(
  row: Row<WithSubData<T>>,
  isLightRow: boolean,
  stylingOptions: DataDisplayTableStylingOptions
) => {
  let rowColor;

  const isSubRow = row.depth > 0;

  if (isSubRow) {
    rowColor = stylingOptions.subRowColor;
  } else if (isLightRow) {
    rowColor = stylingOptions.alternateRowColor;
  } else {
    rowColor = stylingOptions.rowColor || undefined;
  }

  return (
    <TR
      key={row.id}
      $customBackgroundColor={rowColor}
      $isSubRow={isSubRow}
      $isLightRow={isLightRow}
      // TODO(kentskinner): prevent expanding when the select checkbox is clicked?
      onClick={row.getToggleExpandedHandler()}
    >
      {row.getVisibleCells().map(({ id, column, getContext }) => {
        const { alignment, numeric } = extractAlignmentNumeric(column);
        return (
          <TD key={id}>
            <Cell $alignment={alignment} $numeric={numeric}>
              {flexRender(column.columnDef.cell, getContext())}
            </Cell>
          </TD>
        );
      })}
    </TR>
  );
};

const extractAlignmentNumeric = <T,>(column: Column<WithSubData<T>>) => {
  const columnDefMeta = column.columnDef.meta as ColumnMeta;
  return {
    alignment: columnDefMeta?.alignment ?? DEFAULT_ALIGNMENT,
    numeric: columnDefMeta?.numeric,
  };
};

export function DataDisplayTable<T, V>(props: DataDisplayTableProps<T, V>) {
  const defaultStylingOptions: DataDisplayTableStylingOptions = {
    alternateLightRows: true,
  };
  const stylingOptions = {
    ...defaultStylingOptions,
    ...(props.stylingOptions || {}),
  };

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const enableSelection = !!props.selection;
  const selectionColumn =
    props.selection?.enableMultiRowSelection === false
      ? radioButtonSelectionColumn
      : checkBoxSelectionColumn;

  const columns: ColumnDef<WithSubData<T>, V>[] = useMemo(
    () =>
      enableSelection
        ? [selectionColumn as ColumnDef<WithSubData<T>, V>, ...props.columns]
        : props.columns,
    [props.columns, enableSelection, selectionColumn]
  );

  //we can't pass undefined to certain fields... so there is some code to handle that
  //https://github.com/TanStack/table/issues/4764
  const baseTableOptions: TableOptions<WithSubData<T>> = {
    data: props.data,
    columns: columns,
    getSubRows: (row: WithSubData<T>) => row.subData ?? [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      enableSorting: false,
    },
    state: {
      expanded,
      rowSelection: props.selection?.selected,
    },
    onExpandedChange: setExpanded,
    onRowSelectionChange: props.selection?.onSelectionChange,
    manualSorting: props.sorting?.isManual,
    enableSortingRemoval: false,
    enableRowSelection: enableSelection,
    enableMultiRowSelection: props.selection?.enableMultiRowSelection,
  };

  const withSorting = props.sorting
    ? {
        ...baseTableOptions,
        state: {
          ...baseTableOptions.state,
          sorting: props.sorting?.state,
        },
        onSortingChange: props.sorting?.onSortingChanged,
      }
    : baseTableOptions;

  const table = useReactTable(withSorting);

  const rows = table.getRowModel().rows;

  return (
    <Container>
      <Table>
        <THead>
          <tr>
            {table.getLeafHeaders().map((header) => {
              const col = header.column;
              const colMeta = col.columnDef.meta as ColumnMeta;
              const alignment = colMeta?.alignment ?? DEFAULT_ALIGNMENT;
              const sortable = col.getCanSort();
              let iconName = undefined;
              if (sortable) {
                iconName =
                  (col.getIsSorted() || col.getNextSortingOrder()) === 'desc'
                    ? 'arrow-up'
                    : 'arrow-down';
              }
              return (
                <TH
                  key={header.id}
                  $alignment={alignment}
                  $isSortable={sortable}
                >
                  {!colMeta?.headerNoPill ? (
                    <PillButton
                      pillBtnType="muted"
                      onClick={
                        sortable ? col.getToggleSortingHandler() : undefined
                      }
                      description={col.columnDef.header as string} //TODO: type this
                      isFocused={!!col.getIsSorted()}
                      iconName={iconName}
                    />
                  ) : (
                    flexRender(col.columnDef.header, header.getContext())
                  )}
                </TH>
              );
            })}
          </tr>
        </THead>
        <TBody>
          {rows.length === 0 ? (
            <tr>
              <EmptyCell>
                <Empty
                  message={props.noDataMessage}
                  action={props.emptyAction}
                />
              </EmptyCell>
            </tr>
          ) : (
            table
              .getRowModel()
              .rows.map((row, index) => renderRow(
                row,
                stylingOptions.alternateLightRows ? index % 2 === 0 : false,
                stylingOptions
              )
              )
          )}
        </TBody>
      </Table>
    </Container>
  );
}

export type TableEmptyAction = EmptyAction;
export type TableColumnAlignment = Alignment;
export type TableColumnMeta = ColumnMeta;
