import { gql } from '@apollo/client';
import DealStagesFragments from 'features/deals/DealStages/fragments';

export const SAVE_SELLER_BID = gql`
  mutation SaveSellerBid($input: SaveSellerBidInput!) {
    saveSellerBid(input: $input) {
      id
      ...DealStagesDeal
    }
  }
  ${DealStagesFragments.Deal}
`;
