
import { styled } from 'style/ORSNNTheme';

export const DashboardContainer = styled.div`
  height: 165px;
  margin: 8px 0;
  z-index: 0;
  display: block;
  font-size: 14px;
  color: #ffffff;

  .uppy-Container,
  .uppy-Root {
    height: 100%;
  }

  .uppy-DragDrop-arrow {
    fill: ${(props) => props.theme.color.slate200};
    height: 42px;
  }

  .uppy-DragDrop-container {
    background-color: ${(props) => props.theme.color.bgSurface};

    border-radius: 4px;
    border: 1px dashed ${(props) => props.theme.color.accentEmphasis};
  }

  .uppy-DragDrop-label {
    color: ${(props) => props.theme.color.slate150};
    font-size: 14px;
  }

  .uppy-DragDrop-browse {
    color: ${(props) => props.theme.color.accentDefault};
    font-size: 14px;
  }
`;

export const Placeholder = styled.div`
  height: 100%;
  color: ${(props) => props.theme.color.slate50};
  background-color: ${(props) => props.theme.color.bgSurface};
  border: 1px dashed ${(props) => props.theme.color.themeAccentEmphasis};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionHeader = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin: 5px 0 8px 0;
  color: ${(props) => props.theme.color.slate100};
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TapesUploadedPanel = styled.div`
  flex: 1;
`;

export const UploadForm = styled.form`
  flex: 1;
`;


export const LoadingMessage = styled.div`
  color: ${(props) => props.theme.color.slate50};
  border-top: 1px solid ${(props) => props.theme.color.slate400};
  padding: 7px 16px 16px 10px;
`;