export const formatCentsToDollars = (cents: number): string => {
  if (isNaN(cents) || cents == null) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100);
};

export const toPercentage = (num: number): string => {
  if (isNaN(num) || num == null) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumSignificantDigits: 1,
  }).format(num);
};
