import { ColumnDef } from '@tanstack/react-table';
import {DataDisplayTable} from 'common-ui';
import { useMemo } from 'react';
import { StratRowType, stratsColumnOrder, stratsColumnsConfig } from './startifications.config';
import type { StratCardContentProps } from './StratCard';
import { useIntl } from 'react-intl';

const commonColumns:ColumnDef<StratRowType>[] =  stratsColumnOrder.map((field) => {
  const config = stratsColumnsConfig[field];
  return {
    header: config.header,
    accessorKey: field,
    cell: (cellContext) =>
      config.display(cellContext.getValue() as number | null),
    meta: {
      alignment: 'right',
      numeric: true,
    },
    enableSorting: true,
    enableMultiSort: false,
  };
});


export function StratTable(props: StratCardContentProps) {
  const intl = useIntl();
  
  const columns: ColumnDef<StratRowType>[] = useMemo(() => {
    return [
      {
        accessorKey: 'key',
        header: props.config.label,
        cell: (cellContext) =>
          props.config.formatKey(
            intl,
            props.assetClass,
            cellContext.getValue() as string
          ),
        meta: {
          alignment: 'right',
          numeric: true,
        },
        enableSorting: true,
        enableMultiSort: false,
      },
      ...commonColumns,
    ];
  }, [props.assetClass, props.config]);

  return (<DataDisplayTable data={props.data} columns={columns} noDataMessage="NO DATA"/>);
}