import { CashFlows } from 'features/drilldown/cashflows/CashFlows';
import * as navigation from 'app-level/navigation';
import { getUsePortfolioCashflows } from 'features/drilldown/cashflows/gql/usePortfolioCashflows_gql';
import { filtersToQueryFilters, PillFilters } from 'features/common-elements';
import { PortfolioFilterConfig } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { PortfolioLayout } from './Portfolio/PortfolioLayout';
import { usePortfolioFilters } from './Portfolio/usePortfolioFilters';

const CashFlowModeling = () => {
  const assetClass = navigation.usePortfolioAssetClass();
  const filterProps = usePortfolioFilters();
  if (!assetClass || !filterProps.filtersConfig) {
    return <PortfolioLayout />;
  }


  const queryFilters = filtersToQueryFilters<PortfolioFilterConfig>(filterProps.currentFilters, filterProps.filtersConfig);
  const useCashflowsSummery = getUsePortfolioCashflows(assetClass, queryFilters);
  const userSettingKey = `cashflowsportfolio${assetClass}`;

  return (
    <PortfolioLayout
      filterRow={<PillFilters {...filterProps} filtersConfig={filterProps.filtersConfig} />}
    >
      <CashFlows
        key={assetClass}
        settingKey={userSettingKey}
        useSummaryData={useCashflowsSummery}
      />
    </PortfolioLayout>
  );
};

export default CashFlowModeling;
