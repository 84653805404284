import {
  ApolloError,
  gql,
  NetworkStatus,
  OperationVariables,
  QueryResult,
  useQuery,
} from '@apollo/client';
import { Spinner } from 'common-ui';
import DealCard, { DealCardFragments } from 'features/deals/cards/DealCard';
import ListingCard, {
  ListingCardFragments,
} from 'features/deals/cards/ListingCard';
import { InProgressContainer } from 'features/deals/styles';
import { NETWORK_ERROR } from 'configs/messages';
import { DEAL_URL, INPROGRESS_PATH } from 'app-level/navigation/paths';
import AssetClassConfig from 'models/AssetClass';
import { Party } from 'models/DealStatus';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { styled } from 'style/ORSNNTheme';
import { DealStatus } from '__generated__/globalTypes';
import DealOverview from '../DealOverview';
import InProgressHeader from './InProgressHeader';
import { GetDeals } from './__generated__/GetDeals';
import {
  GetSellerListings,
  GetSellerListings_user_company_listings_UserCompanyListing as Listing,
  GetSellerListings_user_company_listings_UserCompanyListing_deals as Deals,
} from './__generated__/GetSellerListings';
import ActiveListingsTable from './ActiveListingsTable';
import { RowSelectionState } from '@tanstack/react-table';

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
`;

const ListingFragment = gql`
  fragment ListingFragment on Listing {
    id
    loan_count
    wa_borrower_credit_score
    wa_coupon
    wa_loan_age_months
    wa_ltv
    wa_remaining_loan_terms_months
    ...ListingCardListing
  }
  ${ListingCardFragments.listing}
`;

const GET_SELLER_LISTINGS_QUERY = gql`
  query GetSellerListings {
    user {
      id
      company {
        id
        listings {
          ...ListingFragment
          ... on UserCompanyListing {
            deals {
              id
              counterparty {
                ...DealCardCounterparty
              }
              ...DealCardDeal
            }
          }
        }
      }
    }
  }
  ${ListingFragment}
  ${DealCardFragments.counterparty}
  ${DealCardFragments.deal}
  ${ListingCardFragments.listing}
`;

const GET_DEALS_QUERY = gql`
  query GetDeals {
    user {
      id
      company {
        id
        buyingDeals: deals(role: BUYER) {
          id
          counterparty {
            ...DealCardCounterparty
          }
          ...DealCardDeal
          listing {
            ...ListingFragment
          }
        }
        sellingDeals: deals(role: SELLER) {
          id
          counterparty {
            ...DealCardCounterparty
          }
          ...DealCardDeal
          listing {
            ...ListingFragment
          }
        }
      }
    }
  }
  ${DealCardFragments.counterparty}
  ${DealCardFragments.deal}
  ${ListingFragment}
`;

export const ErrorMessage = (props: {
  err: ApolloError | undefined;
}): JSX.Element | null => {
  if (props.err) {
    if (process.env.NODE_ENV === 'development') {
      console.log('Error!', props.err); // eslint-disable-line no-console
    }
    return <Placeholder>{NETWORK_ERROR}</Placeholder>;
  }
  return null;
};

function isNotNull<T>(action: T | null): action is T {
  return action != null;
}

interface WithAssetClass {
  assetClass: string;
}

function isAssetClass<T extends WithAssetClass>(
  entity: T,
  assetClass?: string
): boolean {
  return (
    assetClass === AssetClassConfig.ALL.id ||
    entity.assetClass.toLowerCase() === assetClass
  );
}

type Props = {
  isVisible: boolean;
  isCompleteVisible: boolean;
  onDataFound: Dispatch<SetStateAction<boolean>>;
  gqlDeals: QueryResult<GetDeals, OperationVariables>;
};

function getCompleteSellerDeals(
  data?: GetDeals,
  assetClass?: string
): Map<string, Deals[]> {
  return (
    data?.user.company.sellingDeals
      .filter((deal) => deal.state.status === DealStatus.COMPLETE)
      .filter((deal) => isAssetClass(deal.listing, assetClass))
      .filter(isNotNull) ?? []
  ).reduce((previous, deal) => {
    const currentDealsForListing = previous.get(deal.listing.id) ?? [];
    return previous.set(deal.listing.id, [...currentDealsForListing, deal]);
  }, new Map<string, Deals[]>());
}

const BuyingAndCompleteDeals = (props: Props): JSX.Element => {
  const { asset_class } = useParams<{ asset_class?: string }>();
  const { gqlDeals: gql } = props;
  const company = gql.data?.user.company;

  const buyingDeals = useMemo(
    () =>
      company?.buyingDeals
        .filter((deal) => isAssetClass(deal.listing, asset_class))
        .filter((deal) => deal.state.status !== DealStatus.COMPLETE) ?? [],
    [asset_class, company, gql.loading]
  );
  const completeDeals = useMemo(
    () =>
      company?.buyingDeals
        .filter((deal) => deal.state.status === DealStatus.COMPLETE)
        .filter((deal) => isAssetClass(deal.listing, asset_class)) ?? [],
    [asset_class, company, gql.loading]
  );

  const { onDataFound } = props;
  useEffect(() => {
    onDataFound(completeDeals.length > 0 || buyingDeals.length > 0);
  }, [completeDeals, buyingDeals, asset_class, onDataFound]);

  if (props.isVisible) {
    return (
      <>
        <ErrorMessage err={gql.error} />
        {buyingDeals.map((deal) => (
          <DealCard
            key={deal.id}
            deal={deal}
            party={Party.BUYER}
            counterparty={deal.counterparty}
          />
        ))}
        {props.isCompleteVisible &&
          completeDeals.map((deal) => (
            <DealCard
              key={deal.id}
              deal={deal}
              party={Party.BUYER}
              counterparty={deal.counterparty}
            />
          ))}
        <Spinner
          loading={gql.loading || gql.networkStatus === NetworkStatus.loading}
        />
      </>
    );
  } else {
    return <></>;
  }
};

const ActiveListings = (props: Props): JSX.Element => {
  const gql = useQuery<GetSellerListings>(GET_SELLER_LISTINGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const { gqlDeals } = props;

  // invert the map of deals to listings to match the listing - subdata(deal) structure 
  // TODO we likely want to only show rows, or always expand rows since buyer would only ever have one deal per listing
  const buyingListings = useMemo(
    () => {
      const listings: Record<string, Listing> = {};
      (gqlDeals.data?.user.company.buyingDeals ?? [])
        .filter((deal) => deal.state.status !== DealStatus.COMPLETE)
        .filter(isNotNull)
        .forEach((deal) => {
          const currentListing = listings[deal.listing.id];
          const currentDealsForListing = currentListing?.deals ?? [];
          listings[deal.listing.id] = {
            ...currentListing,
            ...deal.listing,
            deals: [...currentDealsForListing, deal],
          };
        }, {} as Record<string, Listing>);
      return Object.values(listings).reduce((previous, listing) => {
        return [...previous, { ...listing, subData: listing.deals }];
      }, new Array<Listing>())
    },
    [gqlDeals.data]
  );

  const listingsToShow = useMemo(
    () =>
      gql.data?.user.company.listings
        .map((listing) => listing as Listing)
        .map((listing) => {
          return {
            ...listing,
            deals: listing.deals
              ? listing.deals.filter(
                (deal) => DealStatus.COMPLETE != deal.state.status
              )
              : listing.deals,
            subData: listing.deals
              ? listing.deals.filter(
                (deal) => DealStatus.COMPLETE != deal.state.status
              )
              : listing.deals,
          };
        }) ?? [],
    [gql.data]
  );

  const completeDeals = useMemo(
    () =>
      gqlDeals.data?.user.company.sellingDeals
        .filter((deal) => deal.state.status === DealStatus.COMPLETE)
        .filter(isNotNull) ?? [],
    [gqlDeals.data]
  );

  const { onDataFound } = props;
  useEffect(() => {
    onDataFound(completeDeals.length > 0 || listingsToShow.length > 0);
  }, [listingsToShow, onDataFound]);

  const [selectedListings, setSelectedListings] = useState<RowSelectionState>(
    {}
  );

  if (props.isVisible) {
    return (
      <>
        <ErrorMessage err={gql.error} />
        <ActiveListingsTable
          listingsToShow={[...listingsToShow, ...buyingListings]}
          selection={{
            selected: selectedListings,
            onSelectionChange: setSelectedListings,
          }}
        />
        {/* {listingsToShow.map((listing) => (
          <ListingCard
            key={listing.id}
            listing={listing}
            inquiryCount={listing.deals.length}
          >
            <>
              {listing.deals.map((deal) => (
                <DealCard
                  key={deal.id}
                  deal={deal}
                  party={Party.SELLER}
                  counterparty={deal.counterparty}
                />
              ))}
            </>
          </ListingCard>
        ))} */}
        {props.isCompleteVisible &&
          completeDeals.map((deal) => (
            <DealCard
              key={deal.id}
              deal={deal}
              party={Party.SELLER}
              counterparty={deal.counterparty}
            />
          ))}
        <Spinner
          loading={gql.loading || gql.networkStatus === NetworkStatus.loading}
        />
      </>
    );
  } else {
    return <></>;
  }
};

const InProgressPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InProgressOverview = (): JSX.Element => {
  const [foundListings, setFoundListings] = useState(true);
  const [foundDeals, setFoundDeals] = useState(true);
  const gqlDeals = useQuery<GetDeals>(GET_DEALS_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [isCompleteVisible, setIsCompleteVisible] = useState(false);
  const [isSellerVisible, setIsSellerVisible] = useState(true);
  const [isBuyerVisible, setIsBuyerVisible] = useState(true);

  // TODO restyle this to be a datatable https://orsnn.atlassian.net/browse/RC1-155
  return (
    <InProgressPage>
      <InProgressHeader
        isSellerVisible={isSellerVisible}
        isBuyerVisible={isBuyerVisible}
        sellerToggle={setIsSellerVisible}
        buyerToggle={setIsBuyerVisible}
        isCompleteVisible={isCompleteVisible}
        completeToggle={setIsCompleteVisible}
      />
      <InProgressContainer>
        <ActiveListings
          isVisible={isSellerVisible || isBuyerVisible}
          isCompleteVisible={isCompleteVisible}
          gqlDeals={gqlDeals}
          onDataFound={setFoundListings}
        />
        {!foundListings && !foundDeals && (
          <Placeholder>
            Nothing to show. Check the marketplace for loan pools, or publish
            your own
          </Placeholder>
        )}
        <BuyingAndCompleteDeals
          isVisible={isBuyerVisible}
          isCompleteVisible={isCompleteVisible}
          gqlDeals={gqlDeals}
          onDataFound={setFoundDeals}
        />
      </InProgressContainer>
    </InProgressPage>
  );
};

const InProgress = (): JSX.Element => {
  return (
    <Routes>
      <Route path={`/${INPROGRESS_PATH}/:asset_class/:deal_id${DEAL_URL}`}>
        {/* <CarveContextProvider> */}
        <DealOverview />
        {/* <CreationModal /> */}
        {/* </CarveContextProvider> */}
      </Route>
      <Route path={`/${INPROGRESS_PATH}/:asset_class`}>
        <InProgressOverview />
      </Route>
    </Routes>
  );
};

export default InProgress;
export { InProgressOverview };
