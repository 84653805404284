import * as navigation from 'app-level/navigation';
import { getPortfolioFiltersConfig } from './portfolioFilters.config';

export function usePortfolioFilters(){
  const assetClass = navigation.usePortfolioAssetClass();
  const portfolioFiltersConfig = assetClass ? getPortfolioFiltersConfig(assetClass) : null;
  const [currentFilters, setCurrentFilters] = navigation.usePortfolioFilters();

  return {
    filtersConfig: portfolioFiltersConfig,
    currentFilters,
    setCurrentFilters
  };
}