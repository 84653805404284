import { useMutation } from '@apollo/client';
import { FieldsMap } from '../fieldMapping/MapRequiredFieldsCard';
import {
  SubmitFileHeaderMap,
  SubmitFileHeaderMapVariables,
} from 'query/__generated__/SubmitFileHeaderMap';
import { SUBMIT_FILE_HEADER_MAP } from 'query/submitFileHeaderMap';
import { HeaderMapInput, LoanField } from '__generated__/globalTypes';
import _ from 'lodash';

export const useMapHeadersToFields = () => {
  const [submitFileHeaderMap] = useMutation<
    SubmitFileHeaderMap,
    SubmitFileHeaderMapVariables
  >(SUBMIT_FILE_HEADER_MAP);

  const mapHeadersToFields = async (fileId: string, fieldsMap: FieldsMap) => {
    const headerMaps = mapFieldsMapToHeaderMaps(fieldsMap);
    return submitFileHeaderMap({
      variables: {
        fileId: fileId,
        headerMaps: headerMaps,
      },
    });
  };

  return {
    mapHeadersToFields,
  };
};

const mapFieldsMapToHeaderMaps = (fieldsMap: FieldsMap): HeaderMapInput[] => {
  return _.map(fieldsMap, (file_column, fieldId) => {
    return {
      file_column,
      field: LoanField[fieldId as keyof typeof LoanField],
    };
  });
};
