import { Button, KIND, SIZE } from 'baseui/button';
import DeprecatedDropdown, { Item } from 'common-ui/inputs/DeprecatedDropdown';
import {
  LoanDatatableLoan_AutoLoan_listing,
  LoanDatatableLoan_HomeLoan_listing,
} from 'features/drilldown/LoanDatatable/__generated__/LoanDatatableLoan';
import { LISTING_PATH, OVERVIEW_URL } from 'app-level/navigation/paths';
import Dinero from 'dinero.js';
import { DateTime } from 'luxon';
import AssetClassConfig from 'models/AssetClass';
import { Link } from 'react-router-dom';
import { styled, theme } from 'style/ORSNNTheme';
import {
  AssetClass as GqlAssetClass,
  FilterableField,
  FilterOperator,
  LoanField,
  SortableField,
} from '__generated__/globalTypes';
import DatePicker from './input/DatePicker';

export type InputType =
  | 'text'
  | 'email'
  | 'select'
  | 'file'
  | 'radio'
  | 'checkbox'
  | 'textarea'
  | 'button'
  | 'reset'
  | 'submit'
  | 'date'
  | 'datetime-local'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'range'
  | 'search'
  | 'tel'
  | 'url'
  | 'week'
  | 'password'
  | 'datetime'
  | 'time'
  | 'color';

const ListingLink = styled(Link)`
  color: ${(props) => props.theme.color.brandMainPink};
  &:hover {
    color: ${(props) => props.theme.color.darkPrimaryLabel};
    text-decoration: underline solid ${theme.color.darkPrimaryLabel};
  }
`;

const DEFAULT_FILTER_OPERATORS = [
  FilterOperator.IS,
  FilterOperator.GREATER_THAN,
  FilterOperator.LESS_THAN,
  FilterOperator.GREATER_THAN_OR_EQUALS,
  FilterOperator.LESS_THAN_OR_EQUALS,
];
const ID_TO_STATE_CODE = {
  '01': 'AL',
  '02': 'AK',
  '60': 'AS',
  '04': 'AZ',
  '05': 'AR',
  '06': 'CA',
  '08': 'CO',
  '09': 'CT',
  '10': 'DE',
  '11': 'DC',
  '12': 'FL',
  '64': 'FM',
  '13': 'GA',
  '66': 'GU',
  '15': 'HI',
  '16': 'ID',
  '17': 'IL',
  '18': 'IN',
  '19': 'IA',
  '20': 'KS',
  '21': 'KY',
  '22': 'LA',
  '23': 'ME',
  '68': 'MH',
  '24': 'MD',
  '25': 'MA',
  '26': 'MI',
  '27': 'MN',
  '28': 'MS',
  '29': 'MO',
  '30': 'MT',
  '31': 'NE',
  '32': 'NV',
  '33': 'NH',
  '34': 'NJ',
  '35': 'NM',
  '36': 'NY',
  '37': 'NC',
  '38': 'ND',
  '69': 'MP',
  '39': 'OH',
  '40': 'OK',
  '41': 'OR',
  '70': 'PW',
  '42': 'PA',
  '72': 'PR',
  '44': 'RI',
  '45': 'SC',
  '46': 'SD',
  '47': 'TN',
  '48': 'TX',
  '74': 'UM',
  '49': 'UT',
  '50': 'VT',
  '51': 'VA',
  '78': 'VI',
  '53': 'WA',
  '54': 'WV',
  '55': 'WI',
  '56': 'WY',
};

const FILTERABLE_STATES = Object.values(ID_TO_STATE_CODE);
const stateItems: Array<Item<{ statecode: string }>> = FILTERABLE_STATES.sort(
  (stateA, stateB) => stateA.localeCompare(stateB)
).map((statecode) => ({
  key: statecode,
  text: statecode,
  data: {
    statecode,
  },
}));

// TODO: Pull formatting into its own util for use elsewhere
const formatCurrency = (
  value: string | number | Record<string, unknown> | null,
  withSymbol = true
): string => {
  if ((!value && value !== 0) || typeof value === 'object') {
    return '-';
  }
  const numValue = typeof value === 'string' ? Number.parseInt(value) : value;

  return Dinero({
    amount: numValue,
    currency: 'USD',
  }).toFormat(`${withSymbol ? '$' : ''}0,0.00`);
};


const CURRENCY_REGEX =
  /^\$?(?<dollars>\d{1,3},(?:\d{3},)*\d{3}|\d+)(?:\.(?<cents>\d{1,2}))?$/;
/**
 *  Parses currency string into cents
 * @param value
 * @returns undefined if the input is not a valid currency string
 */
const parseCurrencyToCents = (value: string): number | undefined => {
  const match = value.match(CURRENCY_REGEX);
  if (match != null) {
    const dollars = match.groups?.dollars.replaceAll(',', '') ?? '0';
    const rawCents = match.groups?.cents ?? '0';
    const cents = rawCents.padStart(2, '0');
    return parseInt(dollars) * 100 + parseInt(cents);
  }
};

const PERCENT_REGEX =
  /^(?<integer>\d{1,3},(?:\d{3},)*\d{3}|\d+)(?:\.(?<decimal>\d{1,4}))?$/;
const parsePercentToRate = (value: string): number | undefined => {
  const match = value.match(PERCENT_REGEX);
  if (match != null) {
    return +value / 100;
  }
};
const formatPercent = (
  value: string | number | Record<string, unknown> | null,
  decimalPlaces: number,
  withSymbol = true
): string => {
  if (typeof value === 'object' || value == null) {
    return '-';
  }
  const valueNumber = typeof value === 'string' ? parseFloat(value) : value;
  return `${(valueNumber * 100).toFixed(decimalPlaces)}${withSymbol ? '%' : ''
    }`;
};

export type ComponentProps<T> = {
  defaultValue: T | null | undefined;
  onChange: (value: T | null) => void;
  id: string;
};

type InputConfig<T> = {
  type: InputType;
  prefix?: string;
  suffix?: string;
  inputToRawValue: (value: T) => number | string[];
  inputToSearchValue?: (value: string) => number | string;
  rawToInputValue: (value: number | string) => T;
  component?: (props: ComponentProps<T>) => JSX.Element;
};

const inputCurrencyConfig: InputConfig<number> = {
  type: 'number',
  prefix: '$',
  inputToRawValue: (value) => parseCurrencyToCents(value.toString()) ?? 0,
  rawToInputValue: (value) => +value / 100,
};

const inputDateConfig: InputConfig<DateTime> = {
  type: 'date',
  inputToRawValue: (value) => {
    const result = Math.floor(value.toSeconds());
    return result;
  },
  inputToSearchValue: (localTimeSeconds) => {
    // is in local as unix timestamp, convert to UTC here
    const localTime = DateTime.fromSeconds(+localTimeSeconds);
    let utcTime = localTime.toUTC();
    utcTime = utcTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const utcSeconds = utcTime.toSeconds();
    return utcSeconds.toString();
  },
  rawToInputValue: (value) => {
    const result = DateTime.fromSeconds(+value);
    return result;
  },
  component: DatePicker,
};

const inputMonthsConfig: InputConfig<number> = {
  type: 'number',
  suffix: 'Months',
  inputToRawValue: (value) => +value,
  rawToInputValue: (value) => +value,
};

const inputCreditScoreConfig: InputConfig<number> = {
  type: 'number',
  inputToRawValue: (value) => +value,
  rawToInputValue: (value) => +value,
};

const inputPercentConfig: InputConfig<number> = {
  type: 'number',
  suffix: '%',
  inputToRawValue: (value) => value / 100,
  inputToSearchValue: (value) => parseInt(value) / 100,
  rawToInputValue: (value) => {
    if (typeof value === 'number') {
      return +(+value).toPrecision(value < 1 ? 5 : 6);
    } else {
      return +(parseFloat('' + value) * 100).toPrecision(+value < 1 ? 5 : 6);
    }
  },
};

const inputBooleanConfig: InputConfig<string> = {
  type: 'text',
  inputToRawValue: (value) => [value],
  rawToInputValue: (value) => value.toString(),
  component: (props: ComponentProps<string>) => (
    <DeprecatedDropdown
      items={[
        { key: 'true', selected: true, text: 'true', data: 'true' },
        { key: 'false', selected: false, text: 'false', data: 'false' },
      ].map((value: Item<string>) => {
        if (props.defaultValue) {
          if (props.defaultValue.includes(value.key)) {
            return { ...value, selected: true };
          }
          return { ...value, selected: false };
        }
        return value;
      })}
      placeholder={'Value:'}
      onItemSelect={
        (items) => {
          const item = items[0];
          return props.onChange(item.key);
        }
      }
      continuousUpdate
    />
  ),
};

const inputInterestRateConfig = inputPercentConfig;
const inputLtvConfig = inputPercentConfig;
const inputDtiConfig = inputPercentConfig;

const inputStateConfig: InputConfig<Array<string>> = {
  type: 'text',
  inputToRawValue: (value: Array<string>) => value,
  inputToSearchValue: (value) => value,
  rawToInputValue: (value) => {
    if (Array.isArray(value)) {
      return value.map((x) => `${x}`);
    } else {
      return [`${value}`];
    }
  },
  component: (props: ComponentProps<Array<string>>) => (
    <DeprecatedDropdown
      items={stateItems.map((state) =>
        props.defaultValue?.includes(state.key)
          ? { ...state, selected: true }
          : state
      )}
      placeholder={'States:'}
      onItemSelect={(items) => props.onChange(items.map((item) => item.key))}
      continuousUpdate
      multiselect
    />
  ),
};

function displayDate(value: any): string {
  return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT as any);
}

function displayFilterDate(value: any): string {
  const seconds = typeof value === 'number' ? value : parseInt(value);
  return DateTime.fromSeconds(seconds).toLocaleString(
    DateTime.DATE_SHORT as any
  );
}

export type ColConfig = {
  display: (value: any) => string | number | JSX.Element;
  displayFilter?: (value: any) => string | number | JSX.Element;
  inputFormat: InputConfig<number | string | Array<string> | DateTime> | null;
  selector: string;
  sortSelector: SortableField | null;
  filterSelector: FilterableField | null;
  string: string;
  validFilterOperators?: FilterOperator[];
};

const ButtonField = (props: {
  label: any;
  onClick?: ((event: any) => any) | undefined;
}): JSX.Element => (
  <Button
    kind={KIND.minimal}
    size={SIZE.mini}
    onClick={props.onClick}
    overrides={{
      BaseButton: {
        style: {
          color: theme.color.brandMainPink,
          ':hover': {
            color: theme.color.darkPrimaryLabel,
            textDecoration: `underline solid ${theme.color.darkPrimaryLabel}`,
          },
        },
      },
    }}
  >
    {props.label}
  </Button>
);

const LOAN_FIELD_CONFIG = {
  [LoanField.asset_class]: {
    display: (value: any) => {
      if (typeof value === 'object') {
        throw Error('invalid data type provided');
      }
      switch (value) {
        case GqlAssetClass.AUTO:
          return AssetClassConfig.AUTO.label;
        case GqlAssetClass.HOME:
          return AssetClassConfig.HOME.label;
        default:
          return value;
      }
    },
    filterSelector: FilterableField.asset_class,
    inputFormat: null,
    string: 'Asset Class',
    selector: 'asset_class',
    sortSelector: SortableField.asset_class,
  },
  [LoanField.account_id]: {
    display: (value: any, onClick: (() => void) | undefined = undefined) =>
      onClick ? <ButtonField label={value} onClick={onClick} /> : value,
    filterSelector: FilterableField.account_id,
    inputFormat: null,
    selector: 'account_id',
    sortSelector: SortableField.account_id,
    string: 'Account Id',
  },
  [LoanField.age_months]: {
    display: (value: any) => value,
    filterSelector: FilterableField.age_months,
    inputFormat: inputMonthsConfig,
    string: 'Age (months)',
    selector: 'age_months',
    sortSelector: SortableField.age_months,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
  },
  [LoanField.auto_original_value_cents]: {
    string: 'Vehicle Original Value',
    selector: 'auto_original_value_cents',
    sortSelector: SortableField.auto_original_value_cents,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    filterSelector: FilterableField.auto_original_value_cents,
    inputFormat: inputCurrencyConfig,
    display: (value: any) => formatCurrency(value),
  },
  [LoanField.auto_vin]: {
    string: 'Vehicle VIN',
    selector: 'auto_vin',
    sortSelector: null,
    display: (value: any) => value,
    filterSelector: null,
    inputFormat: null,
  },
  [LoanField.borrower_credit_score]: {
    string: 'Credit Score',
    selector: 'borrower_credit_score',
    sortSelector: SortableField.borrower_credit_score,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    filterSelector: FilterableField.borrower_credit_score,
    inputFormat: inputCreditScoreConfig,
    display: (value: any) => value,
  },
  [LoanField.city]: {
    string: 'City',
    selector: 'city',
    sortSelector: null,
    display: (value: any) => value,
    filterSelector: null,
    inputFormat: null,
  },
  [LoanField.current_balance_cents]: {
    string: 'UPB',
    selector: 'current_balance_cents',
    sortSelector: SortableField.current_balance_cents,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    filterSelector: FilterableField.current_balance_cents,
    inputFormat: inputCurrencyConfig,
    display: (value: any) => formatCurrency(value),
  },
  [LoanField.dti]: {
    string: 'DTI',
    selector: 'dti',
    sortSelector: SortableField.dti,
    filterSelector: FilterableField.dti,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    inputFormat: inputDtiConfig,
    display: (value: any) => formatPercent(value, 2),
  },
  [LoanField.interest_rate]: {
    string: 'Interest Rate',
    selector: 'interest_rate',
    sortSelector: SortableField.interest_rate,
    filterSelector: FilterableField.interest_rate,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    inputFormat: inputInterestRateConfig,
    display: (value: any) => formatPercent(value, 3),
  },
  [LoanField.ltv]: {
    string: 'LTV',
    selector: 'ltv',
    sortSelector: SortableField.ltv,
    filterSelector: FilterableField.ltv,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    inputFormat: inputLtvConfig,
    display: (value: any) => formatPercent(value, 2),
  },
  [LoanField.occupancy]: {
    string: 'Occupancy',
    selector: 'occupancy',
    sortSelector: null,
    filterSelector: null,
    inputFormat: null,
    display: (value: any) => value,
  },
  [LoanField.original_balance_cents]: {
    string: 'Original Balance',
    selector: 'original_balance_cents',
    sortSelector: SortableField.original_balance_cents,
    filterSelector: FilterableField.original_balance_cents,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    inputFormat: inputCurrencyConfig,
    display: (value: any) => formatCurrency(value),
  },
  [LoanField.postal_code]: {
    string: 'Postal Code',
    selector: 'postal_code',
    sortSelector: null,
    filterSelector: null,
    inputFormat: null,
    display: (value: any) => value,
  },
  [LoanField.property_description]: {
    string: 'Property Description',
    selector: 'property_description',
    sortSelector: null,
    filterSelector: null,
    inputFormat: null,
    display: (value: any) => value,
  },
  [LoanField.servicing_rate]: {
    string: 'Servicing Rate',
    selector: 'servicing_rate',
    sortSelector: SortableField.servicing_rate,
    filterSelector: null,
    inputFormat: null,
    display: (value: any) =>
      `${(
        (typeof value === 'string' ? parseFloat(value) : value) * 100
      ).toFixed(3)}%`,
  },
  [LoanField.state]: {
    string: 'State',
    selector: 'state',
    sortSelector: null,
    filterSelector: FilterableField.state,
    inputFormat: inputStateConfig,
    validFilterOperators: [FilterOperator.IS, FilterOperator.IS_NOT],
    display: (value: any) => value,
  },
  [LoanField.cra]: {
    string: 'CRA',
    selector: 'cra',
    sortSelector: null,
    filterSelector: FilterableField.cra,
    inputFormat: inputBooleanConfig,
    validFilterOperators: [FilterOperator.IS],
    display: (value: any) => value,
  },
};

const FIELD_CONFIG = {
  ...LOAN_FIELD_CONFIG,
  last_updated_date: {
    string: 'Last Updated',
    selector: 'last_updated_date',
    sortSelector: SortableField.last_updated_date_seconds,
    filterSelector: FilterableField.last_updated_date_seconds,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    inputFormat: inputDateConfig,
    display: displayDate,
    displayFilter: displayFilterDate,
  },
  listing: {
    string: 'Listing',
    selector: 'listing.id',
    sortSelector: null,
    filterSelector: FilterableField.listing_id,
    inputFormat: null,
    display: (value: any, assetClass: string | undefined = undefined) => {
      if (value && value.name && value.id && assetClass) {
        return (
          <ListingLink
            to={`/marketplace/${assetClass}/listing/${value.id}${OVERVIEW_URL}`}
          >
            {value.name}
          </ListingLink>
        );
      } else if (value && value.name && value.id) {
        return (
          <ListingLink
            to={`/marketplace/${LISTING_PATH}/${value.id}${OVERVIEW_URL}`}
          >
            {value.name}
          </ListingLink>
        );
      } else {
        return '-';
      }
    },
  },
  listing_id: {
    string: 'Listing',
    selector: 'listing.id',
    sortSelector: null,
    filterSelector: FilterableField.listing_id,
    inputFormat: null,
    display: (value: any) => {
      if (
        !value ||
        typeof value === 'number' ||
        typeof value === 'string' ||
        value.name == null
      ) {
        return '-';
      }
      const listing:
        | LoanDatatableLoan_AutoLoan_listing
        | LoanDatatableLoan_HomeLoan_listing = value;
      if (value.assetClass) {
        return (
          <Link
            to={`/marketplace/${value.assetClass}/${LISTING_PATH}/${value.id}${OVERVIEW_URL}`}
          >
            {listing.name}
          </Link>
        );
      } else {
        return (
          <Link to={`/marketplace/${LISTING_PATH}/${value.id}${OVERVIEW_URL}`}>
            {listing.name}
          </Link>
        );
      }
    },
  },
  loan_terms: {
    string: 'Age',
    selector: 'loan_terms',
    sortSelector: SortableField.loan_terms_months,
    filterSelector: FilterableField.loan_terms_months,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    inputFormat: inputMonthsConfig,
    display: (value: any) => value,
  },
  maturity_date: {
    string: 'Maturity Date',
    selector: 'maturity_date',
    sortSelector: SortableField.maturity_date_seconds,
    filterSelector: FilterableField.maturity_date_seconds,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    inputFormat: inputDateConfig,
    display: displayDate,
    displayFilter: displayFilterDate,
  },
  next_payment_date: {
    string: 'Next Payment Date',
    selector: 'next_payment_date',
    sortSelector: SortableField.next_payment_date_seconds,
    filterSelector: FilterableField.next_payment_date_seconds,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    inputFormat: inputDateConfig,
    display: displayDate,
    displayFilter: displayFilterDate,
  },
  origination_date: {
    string: 'Origination Date',
    selector: 'origination_date',
    filterSelector: FilterableField.origination_date_seconds,
    sortSelector: SortableField.origination_date_seconds,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    inputFormat: inputDateConfig,
    display: displayDate,
    displayFilter: displayFilterDate,
  },
  remaining_loan_terms: {
    string: 'Maturity',
    selector: 'remaining_loan_terms',
    sortSelector: SortableField.remaining_loan_terms_months,
    filterSelector: FilterableField.remaining_loan_terms_months,
    validFilterOperators: DEFAULT_FILTER_OPERATORS,
    inputFormat: inputMonthsConfig,
    display: (value: any) => value,
  },
};

export type FieldConfigKey = keyof typeof FIELD_CONFIG;

const OPERATOR_CONFIG = [
  {
    operator: FilterOperator.IS,
    operatorString: 'Equals',
    operatorMiniString: '=',
  },
  {
    operator: FilterOperator.IS_NOT,
    operatorString: 'Is not',
    operatorMiniString: '!=',
  },
  {
    operator: FilterOperator.GREATER_THAN,
    operatorString: 'Greater than',
    operatorMiniString: '>',
  },
  {
    operator: FilterOperator.LESS_THAN,
    operatorString: 'Less than',
    operatorMiniString: '<',
  },
  {
    operator: FilterOperator.GREATER_THAN_OR_EQUALS,
    operatorString: 'Greater than or Equals',
    operatorMiniString: '>=',
  },
  {
    operator: FilterOperator.LESS_THAN_OR_EQUALS,
    operatorString: 'Less than or Equals',
    operatorMiniString: '<=',
  },
];

const getFieldConfig = (fieldName: FilterableField) =>
  Object.values(FIELD_CONFIG).find(
    (config) => config.filterSelector === fieldName
  );

export {
  FIELD_CONFIG,
  OPERATOR_CONFIG,
  CURRENCY_REGEX,
  PERCENT_REGEX,
  formatCurrency,
  parseCurrencyToCents,
  parsePercentToRate,
  formatPercent,
  getFieldConfig,
};
