import { Table } from 'baseui/table-semantic';
import Dinero from 'dinero.js';
import { FormattedNumber } from 'react-intl';
import { SummaryTableCashFlowTotals } from './gql/__generated__/SummaryTableCashFlowTotals';
import { SummaryTablePerformanceSummary } from './gql/__generated__/SummaryTablePerformanceSummary';

type Props = {
  cashFlowTotals: SummaryTableCashFlowTotals | null;
  performanceSummary: SummaryTablePerformanceSummary | null;
};

const SummaryTable = ({
  cashFlowTotals,
  performanceSummary,
}: Props): JSX.Element => {
  const unpaidPrincipalBalance = performanceSummary?.current_balance_cents ?? 0;

  const formatedCashFlowTotal = (value?: number) => {
    return value
      ? Dinero({
        amount: value,
        currency: 'USD',
      }).toFormat('$0,0.00')
      : '-';
  };

  const formattedCashFlowPercentage = (value?: number) => {
    return value ? (
      <FormattedNumber
        style="percent"
        value={value / unpaidPrincipalBalance}
        minimumFractionDigits={3}
      /> // eslint-disable-line react/style-prop-object
    ) : (
      '-'
    );
  };

  const COLUMNS = ['', '', ''];

  const DATA = [
    [
      'Principal Net Present Value',
      formatedCashFlowTotal(cashFlowTotals?.principal_cents),
      formattedCashFlowPercentage(cashFlowTotals?.principal_cents),
    ],
    [
      'Interest NPV',
      formatedCashFlowTotal(cashFlowTotals?.interest_cents),
      formattedCashFlowPercentage(cashFlowTotals?.interest_cents),
    ],
    [
      'Prepayments NPV',
      formatedCashFlowTotal(cashFlowTotals?.prepayments_cents),
      formattedCashFlowPercentage(cashFlowTotals?.prepayments_cents),
    ],
    [
      'Defaults-Losses NPV',
      formatedCashFlowTotal(cashFlowTotals?.defaults_minus_losses_cents),
      formattedCashFlowPercentage(cashFlowTotals?.defaults_minus_losses_cents),
    ],
    [
      'Subtotal',
      formatedCashFlowTotal(cashFlowTotals?.subtotal_cents),
      formattedCashFlowPercentage(cashFlowTotals?.subtotal_cents),
    ],
    [
      'Servicing NPV',
      formatedCashFlowTotal(cashFlowTotals?.servicing_cents),
      formattedCashFlowPercentage(cashFlowTotals?.servicing_cents),
    ],
    [],
    [
      'Total NPV',
      formatedCashFlowTotal(cashFlowTotals?.total_cents),
      formattedCashFlowPercentage(cashFlowTotals?.total_cents),
    ],
    [
      'Adjustment for Days Delay',
      formatedCashFlowTotal(cashFlowTotals?.adjusted_total_cents),
      formattedCashFlowPercentage(cashFlowTotals?.adjusted_total_cents),
    ],
  ];

  return (
    <Table
      columns={COLUMNS}
      data={DATA}
      overrides={{
        TableHead: {
          style: {
            height: 0,
            display: 'none',
          },
        },
        TableBodyRow: {
          style: ({ $theme, $rowIndex }) => ({
            backgroundColor:
              $rowIndex % 2
                ? $theme.colors.backgroundPrimary
                : $theme.colors.backgroundSecondary,
            ':hover': {
              backgroundColor: $theme.colors.backgroundTertiary,
            },
          }),
        },
      }}
    />
  );
};

export default SummaryTable;