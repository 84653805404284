import { useMemo } from 'react';
import {
  ColumnDef, SortingState, Updater,
} from '@tanstack/react-table';
import {
  LoanDatatableLoan as Loan,
} from './__generated__/LoanDatatableLoan';
import { DataDisplayTable, DataDisplayTableProps, TableEmptyAction } from 'common-ui';
import { assetClassToColumnsConfig, LoanColumnsConfig } from './loanTable.config';
import { AssetClassToLoanType } from 'app-level/config/assetClassTypes';
import { ModalNotStyled } from 'common-ui/modal/ModalNotStyled';
import { SvgIcon } from 'common-ui/Icons/SvgIcon/SvgIcon';
import DetailsCard from 'common-ui/Table/DetailsCard';
import { IconBtnWrapper } from 'common-ui/Table/tableStyles';
import { invert, mapValues, compact } from 'lodash';
import { SortableField } from '__generated__/globalTypes';


type ValueOf<T> = T[keyof T];

function getColumn<LoanType extends Loan, K extends keyof LoanType>(
  columnsConfig: LoanColumnsConfig<LoanType>,
  field: K,
) {
  const config = columnsConfig[field];
  const fieldString = field.toString();
  if (!config) {
    throw new Error(`No config found for column ${fieldString}`);
  }

  const column: ColumnDef<LoanType, LoanType[K]> = {
    header: config.string,
    id: fieldString,
    accessorKey: field,
    meta: {
      alignment: config.numeric ? 'right' : 'left',
      numeric: config.numeric ?? false,
    },
    cell: (props) => {
      const fieldValue = props.getValue() as LoanType[K]; //don't have the energy to convince ts about this type
      const loan = props.row.original;
      if (field === 'listing') {
        return config.display(fieldValue, loan);
      }
      if (field === 'account_id' && loan.account_id) {
        return (
          <>
            <ModalNotStyled trigger={<IconBtnWrapper><SvgIcon name="info" /></IconBtnWrapper>}>
              {({ closeModal }) => (
                <DetailsCard header="Loan Details" selectedLoanId={loan.account_id!} />
              )}
            </ModalNotStyled>
            {config.display(fieldValue, loan)}
          </>
        )
      }

      return config.display(fieldValue, loan);
    },
    enableSorting: !!config.sortSelector
  };

  return column;
}


function getColumns<LoanType extends Loan>(
  order: (keyof LoanType)[],
  config: LoanColumnsConfig<LoanType>,
): ColumnDef<LoanType, ValueOf<LoanType>>[] {
  return order.map((field) => getColumn(config, field));
}

export type LoanColumnDef<K extends keyof AssetClassToLoanType> = ColumnDef<
  AssetClassToLoanType[K],
  ValueOf<AssetClassToLoanType[K]>
>;

export interface BaseTableProps<K extends keyof AssetClassToLoanType> {
  data: AssetClassToLoanType[K][];
  strings: {
    empty: string;
  };
  emptyAction?: TableEmptyAction;
  assetClass: K;
  selection?: DataDisplayTableProps<
    AssetClassToLoanType[K],
    unknown
  >['selection'];
  sorting: {
    state: { id: SortableField; desc: boolean }[];
    onSortingChanged: (val: { id: SortableField; desc: boolean }[]) => void;
  };

  // If undefined, the default columns for this asset class will be used.
  columns?: LoanColumnDef<K>[];
}

export const getDefaultColumns = <K extends keyof AssetClassToLoanType>(
  assetClass: K
): LoanColumnDef<K>[] => {
  const columnsConfig = assetClassToColumnsConfig[assetClass];
  if (!columnsConfig) {
    console.error('No column config found for asset class', assetClass);
    return [];
  }
  return getColumns<AssetClassToLoanType[K]>(
    columnsConfig.order,
    columnsConfig.config
  );
};

export function LoanTable<K extends keyof AssetClassToLoanType>({
  data,
  strings,
  emptyAction,
  assetClass,
  selection,
  sorting,
  columns: customColumns,
}: BaseTableProps<K>) {
  const columnsConfig = assetClassToColumnsConfig[assetClass];
  const sortFieldsToColumns = useMemo(
    () =>
      columnsConfig
        ? invert(
            mapValues(columnsConfig.config, (entry) => entry?.sortSelector)
          )
        : {},
    [columnsConfig]
  );
  if (!columnsConfig) {
    console.error('No column config found for asset class', assetClass);
    return null;
  }

  const columnsToUse = customColumns ?? getDefaultColumns(assetClass);

  const sortingState = sorting?.state.map((entry) => ({
    ...entry,
    id: sortFieldsToColumns[entry.id],
  }));
  const handleSortingChanged = (update: Updater<SortingState>) => {
    const val = typeof update === 'function' ? update(sortingState) : update;

    const sortVal = compact(
      val.map((it) => {
        const sortSelector =
          columnsConfig.config[it.id as keyof typeof columnsConfig.config]
            ?.sortSelector;
        return sortSelector
          ? {
              ...it,
              id: sortSelector,
            }
          : null;
      })
    );

    sorting?.onSortingChanged(sortVal);
  };

  return (
    <DataDisplayTable
      data={data}
      columns={columnsToUse}
      emptyAction={emptyAction}
      noDataMessage={strings.empty}
      selection={selection}
      sorting={
        sortingState
          ? {
              state: sortingState,
              onSortingChanged: handleSortingChanged,
              isManual: true,
            }
          : undefined
      }
    />
  );
}
