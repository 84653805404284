import { HomePage, SectionTitle } from './Home.styles';
import { LatestNews } from './News';
import { MarketData } from './HomeMarketData';
import { PortfolioLayout } from '../portfolio/Portfolio/PortfolioLayout';

const Home = () => {
  return (
    <PortfolioLayout hideSummery={true}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <HomePage>
          {/* <SectionTitle>Market Data</SectionTitle>
        <MarketData /> */}
          <SectionTitle>Latest News</SectionTitle>
          <LatestNews />
        </HomePage>
      </div>
    </PortfolioLayout>
  );
};

export default Home;
