import { gql } from '@apollo/client';
import { CounterpartyCardFragments } from 'features/deals/dealCards/CounterpartyCard';

const CounterpartyCardBodyFragments = {
  TimelineCard: gql`
    fragment CounterpartyCard on TimelineCard {
      ... on CounterpartyCard {
        actions {
          title
          type
          emphasis
        }
        counterparty {
          ...CounterpartyCardCompany
        }
      }
    }
    ${CounterpartyCardFragments.company}
  `,
};

export default CounterpartyCardBodyFragments;
