import { ListItem, ListItemLabel } from 'baseui/list';
import { List, ListItemStyleProps } from './utils';

type BidSummarySectionProps = {
  currentBalance: string;
  bidPrice: string;
  pricePercent: string;
  rate: string;
};
const BidSummarySection = (props: BidSummarySectionProps): JSX.Element => {
  return (
    <List>
      <ListItem
        endEnhancer={() => `${props.currentBalance}`}
        {...ListItemStyleProps}
      >
        <ListItemLabel>UPB of Carve</ListItemLabel>
      </ListItem>
      <ListItem endEnhancer={() => `${props.bidPrice}`} {...ListItemStyleProps}>
        <ListItemLabel>Price</ListItemLabel>
      </ListItem>
      <ListItem
        endEnhancer={() => `${props.pricePercent}`}
        {...ListItemStyleProps}
      >
        <ListItemLabel>Price as % of UPB</ListItemLabel>
      </ListItem>
      <ListItem endEnhancer={() => `${props.rate}`} {...ListItemStyleProps}>
        <ListItemLabel>Rate</ListItemLabel>
      </ListItem>
    </List>
  );
};

export default BidSummarySection;
