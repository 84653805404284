import { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import { AuthContext, AuthContextAction } from 'context/AuthContext';
import { PngIcon, DeprecatedDropdown } from 'common-ui';
import HeaderMenuItem from '../HeaderMenuItem/HeaderMenuItem';
import {SubHeader} from './SubHeader';

import './header.scss';
import { MAIN_HEADER_CONFIG } from './headers.config';

interface User {
  name: string;
}

const Header = () => {
  const { state: authState, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState<User>();

  useEffect(() => {
    if (authState.user) {
      authState.user.getUserAttributes((err, data) => {
        if (err) {
          if (process.env.NODE_ENV === 'development') {
            console.error(err); // eslint-disable-line no-console
          }
        }
        if (data) {
          const givenName = data
            .find((user) => user.getName() === 'given_name')
            ?.getValue();
          setUserInfo({
            name: `${givenName}`,
          });
        }
      });
    }
  }, [authState]);

  const handleSignOut = () => {
    Auth.signOut().then(() => dispatch({ type: AuthContextAction.SIGN_OUT }));
    navigate('/');
  };

  const navUserOptions = [{
      name: 'Logout',
      id: 'logout',
      onClick: () => handleSignOut(),
  }];

  return (
    <header className="header">
      <nav className="header__nav">
        <div className="header__logo">
          <PngIcon name="logo" />
        </div>
        <div className="header__menu_items">
          <ul className="header__menu_items_list">
            {
              MAIN_HEADER_CONFIG.map(headerMenuItem => {
                return <HeaderMenuItem key={headerMenuItem.routeKey} tabDetails={headerMenuItem} />;
              })
            }
          </ul>
        </div>
        <div className="header__actions">
          <div className="header__profile">
            <DeprecatedDropdown
              options={navUserOptions}
              label={userInfo?.name ?? 'User'}
              iconType="avatar"
              position="right"
            />
          </div>
        </div>
        <Routes>
          <Route path=':mainSegment/*' element={<SubHeader />}/>
        </Routes>
      </nav>
    </header>
  );
};

export default Header;