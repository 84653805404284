import { BarSvgProps, BarDatum } from '@nivo/bar';
import { PropertyAccessor } from '@nivo/core';
import { FormatNumberOptions, IntlShape } from 'react-intl';
import { Theme, styled } from 'style/ORSNNTheme';

export const chartTheme: (theme: Theme) => BarSvgProps<BarDatum>['theme'] = (
  theme
) => ({
  background: theme.color.bgSurface,
  textColor: theme.color.fgDefault,
  labels: {
    text: {
      font: theme.typography.dataL2,
    },
  },
  crosshair: {
    line: {
      stroke: theme.color.slate400,
      strokeWidth: 1,
    },
  },
  axis: {
    ticks: {
      text: {
        font: theme.typography.primaryTiny,
        fill: theme.color.fgOnDisabled,
      },
    },
  }
});

export const StyledTooltip = styled.div`
  background: ${({ theme }) => theme.color.slate300};
  color: ${({ theme }) => theme.color.slate50};
  font: ${({ theme }) => theme.typography.primarySmall};
  fontWeight: ${({ theme }) => theme.typography.primaryBoldWeight};
  padding: 12px 16px;
  max-width: 300px;
  border-radius: 4px;
`;

export type CommonChartProps<T> = {
    data: T[];
    keyField: PropertyAccessor<T, string>;
    valueField?: PropertyAccessor<T, number>;
    formatKey?: (value: string) => string
    valueFormatOptions?: FormatNumberOptions & {prepareNumber?: (value: number) => number}
};

export function formatValue<T>(
  intl: IntlShape,
  value: number | null, 
  valueFormatOptions?: CommonChartProps<T>['valueFormatOptions'])
{
  if (value === null) {
    return '-';
  }
  if (valueFormatOptions?.prepareNumber) {
    const preparedValue = valueFormatOptions.prepareNumber(value);
    return intl.formatNumber(preparedValue, valueFormatOptions);
  }

  return value.toLocaleString(intl.locale, valueFormatOptions);
}

export const chartColors = (theme: Theme) => ([
  theme.color.pink500,
  theme.color.pink300,
  theme.color.blue600,
  theme.color.blue400,
  theme.color.blue200,
]);