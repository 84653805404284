import { gql } from '@apollo/client';

const GET_POOL_SETTINGS = gql`
  query GetPoolSettings($settingName: String!) {
    user {
      id
      setting(name: $settingName)
    }
  }
`;

const SAVE_POOL_SETTINGS = gql`
  mutation SavePoolSettings($settingName: String!, $value: String!) {
    addUserSetting(name: $settingName, value: $value)
  }
`;

export { GET_POOL_SETTINGS, SAVE_POOL_SETTINGS };
