export function isNotNullOrUndefined<T>(val: T | undefined | null): val is T {
  return val !== null && typeof val !== 'undefined';
}

export function getStringAsKeyOf<T extends object>(obj: T, key: string | undefined): keyof T | null {
  if (!key) {
    return null;
  }
  if (key in obj) {
    return key as keyof T;
  }
  return null;
}