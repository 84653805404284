import { BaseButton, SvgIcon } from 'common-ui';
import {
  NewsSource,
  Summary,
  News,
  SmallTitle,
  StyledProminentNewsItem,
  StyledRegularNewsItem,
  Subject,
  PublicationDate,
  StyledNewsListItem,
} from './Home.styles';
import {
  GetNewsArticles,
  GetNewsArticlesVariables,
} from 'query/__generated__/GetNewsArticles';
import { GET_NEWS_ARTICLES } from 'query/getNewsArticles';
import { useQuery } from '@apollo/client';
import { GetNewsArticles_newsArticles as NewsArticle } from 'query/__generated__/GetNewsArticles';
import { NewsArticleField } from '__generated__/globalTypes';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dialog, useDialog } from 'common-ui/Dialog';
import ArticleDialog from './ArticleDialog';
import { ContentDiv } from 'common-ui/Dialog.styles';

// Format date as MM.DD.YYYY as shown in Figma: https://www.figma.com/file/urbIZ8Fnnln4Knx1lfo7mV/High-Level-Screens?type=design&node-id=8525-43616&mode=design&t=NQkWzefFekrWhX2g-4
const formatDate = (dateAsString: string) => {
  const formatted = new Date(dateAsString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return formatted.replace(/\//g, '.');
};

const ReadStoryButton: React.FC<{ url: string }> = ({ url }) => {
  const navigateToUrl = (url: string) => () => {
    window.open(url, '_blank');
  };

  return (
    <div style={{ position: 'absolute', bottom: '10px' }}>
      <BaseButton
        size="small"
        type="secondary"
        label="Read More"
        onClick={navigateToUrl(url)}
      >
        <b>Read More</b>
      </BaseButton>
    </div>
  );
};

export const Byline: React.FC<{
  source: string;
  publication_date: string;
}> = ({ source, publication_date }) => (
  <div>
    <NewsSource>{source}</NewsSource>&nbsp;
    <PublicationDate>{formatDate(publication_date)}</PublicationDate>
  </div>
);

const ProminentNewsItem: React.FC<{ item: NewsArticle }> = ({ item }) => (
  <StyledProminentNewsItem>
    <Byline
      source={item.news_source}
      publication_date={item.publication_date}
    />
    <Subject>{item.subject}</Subject>
    <Summary>{item.summary}</Summary>
    <ReadStoryButton url={item.url} />
  </StyledProminentNewsItem>
);

const RegularNewsItem: React.FC<{ item: NewsArticle }> = ({ item }) => (
  <StyledRegularNewsItem>
    <Byline
      source={item.news_source}
      publication_date={item.publication_date}
    />
    <SmallTitle>{item.subject}</SmallTitle>
    <ReadStoryButton url={item.url} />
  </StyledRegularNewsItem>
);

const NewsListItem: React.FC<{
  item: NewsArticle;
  onReadStory: () => void;
}> = ({ item, onReadStory }) => {
  return (
    <StyledNewsListItem>
      <Byline
        source={item.news_source}
        publication_date={item.publication_date}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <SmallTitle>{item.subject}</SmallTitle>
        <NewsListItemReadStory href={'#'} onClick={onReadStory}>
          READ STORY <SvgIcon name="arrow-circle-right" />
        </NewsListItemReadStory>
      </div>
    </StyledNewsListItem>
  );
};

const NewsListItemReadStory = styled.a`
  width: 125px;
  min-width: 125px;
  // height: 50px;
  padding: 24px 23px 0 0;
  text-align: right;
  color: ${(props) => props.theme.color.accentDefault};
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;

  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const PAGE_SIZE = 12;

const LatestNews = () => {
  const [offset, setOffset] = useState(0);
  const [allArticles, setAllArticles] = useState<NewsArticle[]>([]);
  const [currentArticleIndex, setCurrentArticleIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const dialog = useDialog();

  const { data, error, loading, refetch } = useQuery<
    GetNewsArticles,
    GetNewsArticlesVariables
  >(GET_NEWS_ARTICLES, {
    variables: {
      sort: {
        sort_field: NewsArticleField.publication_date_seconds,
      },
      pagination: {
        page_size: PAGE_SIZE,
        offset: offset,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const loadMoreArticles = () => {
    setOffset((offset) => offset + PAGE_SIZE);
  };

  useEffect(() => {
    if (data) {
      if (data.newsArticles.length < PAGE_SIZE) {
        setHasMore(false);
      }
      setAllArticles((prevArticles) => [...prevArticles, ...data.newsArticles]);
    }
  }, [data]);

  useEffect(() => {
    refetch({
      pagination: {
        page_size: PAGE_SIZE,
        offset: offset,
      },
    });
  }, [offset, refetch]);

  const handleReadStory = (index: number) => {
    setCurrentArticleIndex(index);
    dialog.openDialog();
  };

  const currentArticle = allArticles[currentArticleIndex];

  const handleNavigateArticle = (direction: 'next' | 'previous') => {
    if (direction === 'next' && currentArticleIndex < allArticles.length - 1) {
      setCurrentArticleIndex((prevIndex) => prevIndex + 1);
    }

    if (direction === 'previous' && currentArticleIndex > 0) {
      setCurrentArticleIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <>
      <News>
        {allArticles.map((item, index) => {
          // const NewsItemComponent =
          //   index === 0 ? ProminentNewsItem : RegularNewsItem;
          const NewsItemComponent = NewsListItem;
          return (
            <NewsItemComponent
              key={index}
              item={item}
              onReadStory={() => handleReadStory(index)}
            />
          );
        })}
        {hasMore ? (
          <BaseButton
            type="secondary"
            size="small"
            label="View More"
            onClick={loadMoreArticles}
          >
            See More Articles
          </BaseButton>
        ) : (
          <div style={{ margin: 10 }}>No more articles available</div>
        )}
        {loading && <div>Loading news articles...</div>}
        {error && <div>Error loading news articles: {error.message}.</div>}
      </News>

      <Dialog dialog={dialog} contentComponent={ArticleDialogContentDiv}>
        <ArticleDialog
          currentArticle={currentArticle}
          currentArticleIndex={currentArticleIndex}
          allArticles={allArticles}
          hasMore={hasMore}
          loadMoreArticles={loadMoreArticles}
          handleNavigateArticle={handleNavigateArticle}
        />
      </Dialog>
    </>
  );
};

export { LatestNews };

const ArticleDialogContentDiv = styled(ContentDiv)`
  background-color: rgba(19, 19, 22, 1);
  border: 1px solid ${(props) => props.theme.color.accentEmphasis};
  border-radius: 8px;
`;

