import { useMutation } from '@apollo/client';
import { Button, KIND } from 'baseui/button';
import { ListItem, ListItemLabel } from 'baseui/list';
import { CheckboxWithLabel } from 'common-ui';
import { CONFIRM_WIRE_TRANSFER } from 'mutation/confirmWireTransfer';
import { SENT_WIRE_TRANSFER } from 'mutation/sentWireTransfer';
import {
  ConfirmWireTransfer,
  ConfirmWireTransferVariables,
} from 'mutation/__generated__/ConfirmWireTransfer';
import {
  SentWireTransfer,
  SentWireTransferVariables,
} from 'mutation/__generated__/SentWireTransfer';
import { FunctionComponent, useState } from 'react';
import { styled } from 'style/ORSNNTheme';
import { DealActionOptionType, Emphasis } from '__generated__/globalTypes';
import { GET_DEAL_TIMELINE } from '../../fragments';
import { List } from '../utils';
import { WireConfirmationCard_WireConfirmationCard } from './__generated__/WireConfirmationCard';

const ListContainer = styled.div`
  display: flex;
`;

type WireConfirmationCardBodyProps = {
  dealId: string;
} & WireConfirmationCard_WireConfirmationCard;

const WireConfirmationCardBody: FunctionComponent<
  WireConfirmationCardBodyProps
> = (props) => {
  const [isSelected, setIsSelected] = useState(false);
  const [sendWireConfirmation] = useMutation<
    SentWireTransfer,
    SentWireTransferVariables
  >(SENT_WIRE_TRANSFER);
  const [confirmWireTransfer] = useMutation<
    ConfirmWireTransfer,
    ConfirmWireTransferVariables
  >(CONFIRM_WIRE_TRANSFER);

  const action = props.actions[0];

  const shouldShowCheckbox =
    action != null && action.type === DealActionOptionType.SENT_WIRE_TRANSFER;
  const optionalCheckbox = (
    <CheckboxWithLabel
      checked={isSelected}
      onClick={() => setIsSelected((previous) => !previous)}
    >
      I have completed the transaction
    </CheckboxWithLabel>
  );

  const handleSubmit = () => {
    if (isSelected) {
      sendWireConfirmation({
        variables: {
          input: {
            deal_id: props.dealId,
          },
        },
        refetchQueries: [
          {
            query: GET_DEAL_TIMELINE,
            variables: {
              id: props.dealId,
            },
          },
        ],
      });
    } else if (!shouldShowCheckbox) {
      confirmWireTransfer({
        variables: {
          input: {
            deal_id: props.dealId,
          },
        },
        refetchQueries: [
          {
            query: GET_DEAL_TIMELINE,
            variables: {
              id: props.dealId,
            },
          },
        ],
      });
    }
  };

  const wireDetails = props.wire_details;

  return (
    <>
      <ListContainer>
        <List>
          <ListItem
            endEnhancer={() => (
              <ListItemLabel>{wireDetails.account_name}</ListItemLabel>
            )}
          >
            <ListItemLabel>Account Name</ListItemLabel>
          </ListItem>
          <ListItem
            endEnhancer={() => (
              <ListItemLabel>{wireDetails.account_number}</ListItemLabel>
            )}
          >
            <ListItemLabel>Account Number</ListItemLabel>
          </ListItem>
        </List>
        <List>
          <ListItem
            endEnhancer={() => (
              <ListItemLabel>{wireDetails.bank_name}</ListItemLabel>
            )}
          >
            <ListItemLabel>Bank Name</ListItemLabel>
          </ListItem>
          <ListItem
            endEnhancer={() => (
              <ListItemLabel>{wireDetails.routing_number}</ListItemLabel>
            )}
          >
            <ListItemLabel>Routing Number</ListItemLabel>
          </ListItem>
        </List>
      </ListContainer>
      {shouldShowCheckbox && optionalCheckbox}
      {action != null && (
        <Button
          kind={
            action.emphasis === Emphasis.STRONG ? KIND.primary : KIND.secondary
          }
          disabled={!isSelected && shouldShowCheckbox}
          onClick={handleSubmit}
        >
          {action.title}
        </Button>
      )}
    </>
  );
};

export default WireConfirmationCardBody;
export { default as WireConfirmationCardFragments } from './fragments';
