import { TwoColumns } from 'features/deals/DealStages/EventActionCards/utils';
import React, { useMemo, useState } from 'react';
import { DealRoomCard } from '../DealRoom.styles';
import {
  CarveActions,
  CarveCardContainer,
  CarveName,
  Subheader,
} from './TradeCarveManager.styles';

import { BaseButton } from 'common-ui';
import { GetFilterBounds_deal_performance_summary } from 'features/core/filter/__generated__/GetFilterBounds';
import { CarveSummary } from 'features/deals/DealStages/EventActionCards/CarveDiffTable/__generated__/CarveSummary';
import { CarveCard, CarveDetailsTable } from './CarveCard';
import { CreateCarveForm, Criteria } from './CreateCarveForm';
import CreateCarveQuestion from './CreateCarveQuestion';
import { CarvesColumn } from './TradeCarveManager.styles';
import { criteriaToFilters } from './criteriaToFilterMapper';
import { CarveAction, CarveSpecification } from 'features/deals/DealStages/EventActionCards/CarveDetailsCardBody/types';
import { UseCarveFunctions } from 'features/deals/DealStages/EventActionCards/CarveDetailsCardBody/hooks';

interface TradeCarveManagerProps {
  dealId: string;
  carveSpecifications: CarveSpecification[];
  originalOffer: CarveSummary | null;
  filterBounds: GetFilterBounds_deal_performance_summary | undefined;
  onCarveAction: (action: CarveAction) => void;
  carveFunctions: UseCarveFunctions;
}

const TradeCarveManager: React.FC<TradeCarveManagerProps> = ({
  dealId,
  carveSpecifications,
  originalOffer,
  filterBounds,
  onCarveAction,
  carveFunctions,
}) => {
  const [isInCreateCarvesMode, setIsInCreateCarvesMode] = useState<boolean>(
    carveSpecifications != null && carveSpecifications.length > 0
  );

  const [selectedCarveId, setSelectedCarveId] = useState<string>();

  const selectedCarve = useMemo(() => carveSpecifications.find(
    (carve) => carve.id === selectedCarveId
  ), [carveSpecifications, selectedCarveId]);

  const { deleteDealCarve, deleteLoading, createDealCarve, createLoading } = carveFunctions;

  // TODO remove these functions from the component and pass them in as props
  const handleCreateNewCarve = () => {
    const newId = '' + Date.now();
    onCarveAction({
      type: 'create-draft-carve',
      id: newId,
      name: '',
      criteria: [],
    });
    setSelectedCarveId(newId);
  };

  const handleSaveCarve = async (carveName: string, criteria: Criteria[]) => {
    const filters = criteriaToFilters(criteria);
    await createDealCarve({
      variables: {
        input: {
          deal_id: dealId,
          name: carveName,
          carve: filters,
        },
      },
      onCompleted: ({ createDealCarve }) => {
        onCarveAction({
          type: 'save-carve',
          id: selectedCarve?.id || '',
          name: carveName,
          newId: createDealCarve.id,
        });
        setSelectedCarveId(createDealCarve.id);
      }
    });
  };

  const handleCriteriaChanged = (criteria: Criteria[]) => {
    onCarveAction({
      type: 'update-carve',
      carveSpec: { ...selectedCarve!, criteria },
    });
  };

  const handleRemoveCarve = async (carveId: string | undefined) => {
    if (carveId === undefined) return;
    await deleteDealCarve({
      variables: {
        input: {
          id: carveId,
        },
      },
      onCompleted: () => {
        setSelectedCarveId(undefined);
      }
    });
  };

  if (originalOffer === null) {
    return null;
  }

  return (
    <DealRoomCard>
      <Subheader>
        Create and save pool carves from the offering. Select saved Carves for
        Pr.
      </Subheader>
      {isInCreateCarvesMode ? (
        <TwoColumns style={{ marginTop: 20 }}>
          <CreateCarveForm
            selectedCarve={selectedCarve}
            filterBounds={filterBounds}
            onSaveCarve={handleSaveCarve}
            disabled={!selectedCarve || createLoading}
            onCriteriaChanged={handleCriteriaChanged}
            isUpdating={selectedCarve?.isUpdating || false}
          />
          <div>
            <CarveActions>
              <BaseButton
                onClick={handleCreateNewCarve}
                label={'create new carve'}
              >
                Create New Carve
              </BaseButton>
              <BaseButton
                type="secondary"
                disabled={selectedCarveId === undefined || deleteLoading}
                onClick={() => handleRemoveCarve(selectedCarveId)}
                label={'Remove carve'}
              >
                Remove Carve
              </BaseButton>
            </CarveActions>
            <CarvesColumn>
              <CarveCardContainer highlight={true}>
                <CarveName>Original offer</CarveName>
                <CarveDetailsTable carveSummary={originalOffer} />
              </CarveCardContainer>

              {carveSpecifications?.map((carve) => {
                return (
                  <CarveCardContainer highlight={carve.id === selectedCarveId} key={carve.id}>
                    <CarveCard
                      carve={carve}
                      isSelected={carve.id === selectedCarveId}
                      onCarveSelected={() => setSelectedCarveId(carve.id)}
                    />
                  </CarveCardContainer>
                );
              })}
            </CarvesColumn>
          </div>
        </TwoColumns>
      ) : (
        <CreateCarveQuestion setCreateCarve={setIsInCreateCarvesMode} />
      )}
    </DealRoomCard>
  );
};

export type {
  TradeCarveManagerProps
};

export {
  TradeCarveManager
};

