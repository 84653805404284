import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import {SvgIcon} from 'common-ui';

import './side-nav.scss';
import { SIDE_NAV_CONFIG } from './SideNav.config';

const SideNav = (props: {className?: string}) => {
  return (
    <div className={'side-nav ' + props.className || ''}>{SIDE_NAV_CONFIG.map((navConfig) => (
      <li className="side-nav__list_item" key={navConfig.routeSegment}>
        <NavLink
          to={`${navConfig.routeSegment}`}
          className={({ isActive }) => classnames('side-nav__link', {
            'side-nav__link--disabled': navConfig.disabled,
            'side-nav__link--active': isActive
          })}
          tabIndex={navConfig.disabled ? -1 : 0}
        >
          <SvgIcon name={navConfig.iconName} />
          <div className="side-nav__label">{navConfig.text}</div>
        </NavLink>
      </li>
    ))}
    </div>
  );
};

export default SideNav;
