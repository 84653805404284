import config from 'config';
import { styled } from 'style/ORSNNTheme';

const Header = styled.header`
  display: flex;
  width: 1340px;
  position: fixed;
  top: 0;
  height: 100px;
  z-index: 1;
  background-color: rgba(3, 4, 22, 0.9);
  align-items: center;
  padding: 1rem;
`;

const LogoLink = styled.a.attrs({ href: config.website.URL })``;

const Logo = () => (
  <svg
    width="176"
    height="30"
    viewBox="0 0 176 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7967 27.3384C10.1497 27.3384 4.76389 21.9526 4.76389 15.3056C4.76389 8.65864 10.1497 3.27283 16.7967 3.27283C23.34 3.27283 28.661 8.4945 28.8251 14.9946C28.661 7.1772 22.2732 0.888725 14.4126 0.888725C6.45261 0.888725 0 7.34134 0 15.3013C0 23.2612 6.45261 29.7138 14.4126 29.7138C22.2689 29.7138 28.6567 23.4254 28.8251 15.6079C28.661 22.1167 23.34 27.3384 16.7967 27.3384Z"
      fill="url(#paint0_radial_0_1)"
    ></path>
    <path
      d="M16.7964 27.3384C10.1495 27.3384 4.76367 21.9526 4.76367 15.3056C4.76367 20.6353 9.08268 24.9586 14.4167 24.9586C19.7507 24.9586 24.0697 20.6396 24.0697 15.3056C24.0697 9.97162 19.7507 5.65261 14.4167 5.65261C9.08268 5.65261 4.76367 9.97162 4.76367 15.3056C4.76367 8.65864 10.1495 3.27283 16.7964 3.27283C23.3398 3.27283 28.6608 8.4945 28.8249 14.9946C28.8292 15.0983 28.8292 15.2019 28.8292 15.3056C28.8292 15.4093 28.8292 15.5129 28.8249 15.6166C28.6608 22.1167 23.3398 27.3384 16.7964 27.3384Z"
      fill="url(#paint1_radial_0_1)"
    ></path>
    <path
      d="M110.83 29.7959V15.0724L115.49 19.8319V29.7959H110.83Z"
      fill="white"
    ></path>
    <path
      d="M110.83 6.9051V0.5L133.22 24.9111V0.966448H137.703V29.7959H131.592L110.83 6.9051Z"
      fill="white"
    ></path>
    <path
      d="M63.8096 29.5627H58.3115L45.6352 17.3658H53.4224C54.3812 17.3658 55.2579 17.1844 56.057 16.8173C56.856 16.4501 57.5514 15.9664 58.1474 15.3661C58.7434 14.7657 59.2056 14.079 59.5381 13.3059C59.8707 12.5328 60.0391 11.7294 60.0391 10.8916C60.0391 10.0537 59.8707 9.25034 59.5381 8.47724C59.2056 7.70413 58.7391 7.0174 58.1474 6.41706C57.5514 5.81672 56.856 5.33299 56.057 4.96588C55.2579 4.59876 54.3812 4.41735 53.4224 4.41735H40.1025V0.72892H52.8566C55.4696 0.72892 57.7587 1.29039 59.7325 2.41766C60.864 3.06983 61.8272 3.86885 62.6262 4.8104C63.4252 5.75194 64.0256 6.8101 64.4315 7.98919C64.5482 8.27856 64.6216 8.52474 64.6475 8.73637C64.6475 8.81411 64.6605 8.86596 64.6907 8.89187C64.7209 9.04736 64.7425 9.20715 64.7555 9.36263C64.7684 9.51812 64.79 9.66496 64.8203 9.79453C64.8505 10.0278 64.8721 10.1876 64.885 10.3431C64.898 10.4985 64.9067 10.6713 64.9067 10.8527C64.9067 11.1162 64.898 11.2846 64.885 11.4401C64.8721 11.5956 64.8505 11.7554 64.8203 11.9109L64.6907 12.8913C64.6605 12.9172 64.6475 12.969 64.6475 13.0468C64.6173 13.1763 64.5913 13.2973 64.5611 13.4009C64.5309 13.5046 64.4877 13.6212 64.4315 13.7551C64.0256 14.9601 63.4252 16.0312 62.6262 16.9727C61.8272 17.9143 60.864 18.7133 59.7325 19.3655C58.7736 19.8881 57.7155 20.2984 56.5537 20.6007C56.0138 20.7389 55.4566 20.8469 54.8822 20.9247L63.8096 29.5627Z"
      fill="white"
    ></path>
    <path
      d="M83.5905 17.1282C79.2196 17.1282 76.3389 13.1288 76.3389 9.02143C76.3389 4.91405 79.1851 0.785065 83.5905 0.785065H94.4226L98.2968 4.7456H83.5905C81.3187 4.7456 79.8502 6.87488 79.8502 8.9912C79.8502 11.0989 81.3489 13.172 83.5905 13.172H92.1594C96.5303 13.172 99.4111 17.1714 99.4111 21.2788C99.4111 25.3862 96.5648 29.5152 92.1594 29.5152H80.822L76.9522 25.5546H92.1594C94.4312 25.5546 95.8954 23.4254 95.8954 21.309C95.8954 19.2014 94.3967 17.1282 92.1594 17.1282H83.5905Z"
      fill="white"
    ></path>
    <path
      d="M149.127 29.7182V14.9903L153.783 19.7499V29.7138H149.127V29.7182ZM149.127 6.9051V0.5L171.517 24.9111V0.970771H176V29.8002H169.889L149.127 6.9051Z"
      fill="white"
    ></path>
    <defs>
      <radialGradient
        id="paint0_radial_0_1"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28.9374 16.0485) rotate(-180) scale(28.9374)"
      >
        <stop offset="0.165085" stopColor="#1E00EA"></stop>
        <stop offset="1" stopColor="#FD00DF"></stop>
      </radialGradient>
      <radialGradient
        id="paint1_radial_0_1"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.7506 15.1847) scale(24.1865)"
      >
        <stop offset="0.178267" stopColor="#1E00EA"></stop>
        <stop offset="1" stopColor="#FE00DE"></stop>
      </radialGradient>
    </defs>
  </svg>
);

const HeaderMenu = (): JSX.Element => (
  <Header>
    <LogoLink>
      <Logo />
    </LogoLink>
  </Header>
);

export default HeaderMenu;
