import { gql } from '@apollo/client';

const PlaceBidCardFragments = {
  TimelineCard: gql`
    fragment PlaceBidCard on TimelineCard {
      ... on PlaceBidCard {
        actions {
          title
          type
          emphasis
        }
        bid {
          bid_basis_points
        }
      }
    }
  `,
};

export default PlaceBidCardFragments;
