import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { KPI } from '../types';

const isSynonymMatch = (synonyms: string[], searchVal: string) => {
  return synonyms.some(synonym => synonym.toLowerCase().includes(searchVal));
};

// TODO clean this type up since its a FilterOptionOption<KPI> i think
export const isMetricMatchingFilter = (metric: FilterOptionOption<KPI>, searchInput: string) => {
  const searchVal = searchInput.toLowerCase();
  const matchesDesc = metric.data.description.toLowerCase().includes(searchVal);
  const matchesSynonym = metric.data.synonyms && isSynonymMatch(metric.data.synonyms, searchVal);

  return matchesDesc || matchesSynonym || false;
};
