import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { InProgressOverlay } from './InProgressOverlay';
import { useTapeUploadContext } from './loanTapeUploader/TapeUploadContext';
import { FileState } from '__generated__/globalTypes';
import { useMapHeadersToFields } from './hooks/useMapHeadersToFields';
import { FieldsMap } from './fieldMapping/MapRequiredFieldsCard';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { DialogActions, StyledHeading } from 'common-ui/Dialog.styles';
import { BaseButton } from 'common-ui';

interface MapFieldsOperationContextProps {
  startMapFields: (fileId: string, fieldMap: FieldsMap) => void;
}

const MapFieldsOperationContext =
  createContext<MapFieldsOperationContextProps | null>(null);

const MapFieldsOperationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { selectedFile, setSelectedFileId } = useTapeUploadContext();
  const { mapHeadersToFields } = useMapHeadersToFields();
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [description, setDescription] = useState<string>('Initializing...');
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    console.log('selectedFile', selectedFile);
    if (selectedFile) {
      switch (selectedFile.state) {
        case FileState.NEEDS_MAPPING:
          setProgress(0);
          setDescription('Starting...');
          break;

        case FileState.READY_TO_PROCESS:
          setProgress(10);
          setDescription('Mapping fields');
          break;

        case FileState.PROCESSING:
          setProgress(20);
          setDescription('Mapping fields');
          break;

        case FileState.PROCESSED:
          setProgress(100);
          setDescription('Fields mapped.');
          setIsStarted(false);
          break;

        default:
          console.log('unexpected error in mapping', selectedFile.state);
          if (isStarted) {
            setIsError(true);
          }
          break;
      }
    }
  }, [isStarted, selectedFile]);

  const startMapFields = async (fileId: string, fieldMap: FieldsMap) => {
    setIsStarted(true);

    // TODO(kentskinner): handle error.
    await mapHeadersToFields(fileId, fieldMap);
  };

  const reset = () => {
    setSelectedFileId(undefined);
    setIsStarted(false);
    setIsError(false);
  };

  return (
    <MapFieldsOperationContext.Provider value={{ startMapFields }}>
      {children}
      <MapFieldsOperation
        isStarted={isStarted}
        setIsStarted={setIsStarted}
        progress={progress}
        description={description}
        isError={isError}
        reset={reset}
      />
    </MapFieldsOperationContext.Provider>
  );
};

const useMapFieldsOperation = () => {
  const context = useContext(MapFieldsOperationContext);
  if (!context) {
    throw new Error(
      'useMapFieldsOperation must be used within MapFieldsOperationProvider'
    );
  }
  return context;
};

const MapFieldsOperation: React.FC<{
  isStarted: boolean;
  setIsStarted: React.Dispatch<React.SetStateAction<boolean>>;
  progress: number;
  description: string;
  isError: boolean;
  reset: () => void;
}> = ({ isStarted, setIsStarted, progress, description, isError, reset }) => {
  return (
    <>
      <InProgressOverlay
        title="Mapping In Progress"
        description={description}
        open={isStarted}
        cancel={() => setIsStarted(false)}
        onSuccessClicked={() => setIsStarted(false)}
        progress={progress}
      />
      {isError && <ErrorDialog onErrorDialogClose={reset} />}
    </>
  );
};

interface ErrorDialogProps {
  onErrorDialogClose: () => void;
}

const ErrorDialog = ({ onErrorDialogClose }: ErrorDialogProps) => {
  const dialog = useDialog();
  useEffect(() => {
    dialog.setIsOpen(true);
  }, [dialog]);
  return (
    <Dialog dialog={dialog}>
      <StyledHeading>Upload Error</StyledHeading>
      <div>An error occurred while mapping fields</div>
      <DialogActions>
        <BaseButton size="medium" label="cancel" onClick={onErrorDialogClose}>
          OK
        </BaseButton>
      </DialogActions>
    </Dialog>
  );
};


export { MapFieldsOperationProvider, useMapFieldsOperation };
