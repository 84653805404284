import { PortfolioLayout } from '../Portfolio/PortfolioLayout';
import DocumentCategory from './DocumentCategory';
import DocumentTemplates from './DocumentTemplates';
import { FileType } from '__generated__/globalTypes';
import './document-library.scss';

// TODO: combine with existing helper types
const validCompanyTypes = [
  {
    type: FileType.TRANSACTION_DOCUMENT,
    name: 'Transaction Documents',
  },
  {
    type: FileType.LENDING_POLICY,
    name: 'Lending Policies',
  },
  {
    type: FileType.DQ_AND_CHARGE_OFF,
    name: 'Delinquencies & charge-off data',
  },
  {
    type: FileType.SAMPLE_COLLATERAL,
    name: 'Sample Collateral Files',
  },
  {
    type: FileType.OTHER,
    name: 'Other',
  },
];

const DocumentLibrary = () => {
  return (
    <PortfolioLayout hideSummery={true}>
      <div className="document-library">
        <div className="document-library__container">
          <div className="document-library__header">Document Library</div>
          <DocumentTemplates />
          {
            validCompanyTypes.map((companyType) => {
              const { type, name } = companyType;
              return (
                <DocumentCategory
                  key={type}
                  id={type}
                  name={name}
                />
              );
            })
          }
        </div>
      </div>
    </PortfolioLayout>
  );
};

export default DocumentLibrary;