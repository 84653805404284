import { gql } from '@apollo/client';

const GET_DOCUMENT_LIBRARY = gql`
  query GetDocumentLibrary($fileType: FileType) {
    user {
      id
      company {
        id
        documents(fileType: $fileType) {
          name
          id
          uploadedAt
          uploadedBy {
            given_name
            family_name
          }
          documentUrl
          documentSizeBytes
          is_template
        }
      }
    }
  }
`;

const UPDATE_FILE_TEMPLATE = gql`
  mutation UpdateFileTemplate($id: ID!, $isTemplate: Boolean!) {
    updateFileTemplate(id: $id, isTemplate: $isTemplate) {
      id
      is_template
    }
  }
`;

export { GET_DOCUMENT_LIBRARY, UPDATE_FILE_TEMPLATE };
