import { useQuery } from '@apollo/client';
import {
  GetUploadedFilesWithHeaders,
  GetUploadedFilesWithHeaders_user_company_files as ServerFile,
} from 'query/__generated__/GetUploadedFilesWithHeaders';
import { GET_UPLOADED_FILES_WITH_HEADERS } from 'query/getUploadedFilesWithHeaders';

export const POLL_INTERVAL = 1000;
export const TIMEOUT = 30 * 1000;

export const useFilePolling = () => {
  const { refetch } = useQuery<GetUploadedFilesWithHeaders>(
    GET_UPLOADED_FILES_WITH_HEADERS,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const pollFiles = async (
    predicate: (file: ServerFile) => boolean,
    signal?: AbortSignal
  ): Promise<ServerFile | null> => {
    const startTime = new Date().getTime();

    const checkState = async () => {
      try {
        const { data } = await refetch();
        const uploadedFiles = data?.user.company.files;
        const matchingFile = uploadedFiles?.find(predicate);
        return matchingFile;
      } catch (error) {
        throw new Error('Failed to refetch data.');
      }
    };

    return new Promise<ServerFile | null>((resolve, reject) => {
      const interval = setInterval(async () => {
        if (signal?.aborted) {
          clearInterval(interval);
          reject(new Error('Polling was aborted.'));
          return;
        }

        try {
          const currentTime = new Date().getTime();
          const elapsedTime = currentTime - startTime;
          const matchingFile = await checkState();

          if (matchingFile) {
            clearInterval(interval);
            resolve(matchingFile);
          } else if (elapsedTime >= TIMEOUT) {
            clearInterval(interval);
            reject(new Error('Timed out waiting for desired state.'));
          }
        } catch (error) {
          clearInterval(interval);
          reject(new Error('An error occurred during polling.'));
        }
      }, POLL_INTERVAL);
    });
  };

  return { pollFiles };
};
