import { Card, CardHeading } from './styles';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { ProcessingReportCardFile } from './__generated__/ProcessingReportCardFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faFileCsv,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import { useFileIngestOperation } from './FileIngestOperation';
import { FileState } from '__generated__/globalTypes';
import { BaseButton } from 'common-ui';

const ProcessingReportCardFragments = {
  file: gql`
    fragment ProcessingReportCardFile on File {
      id
      state
      processingReport: processing_report {
        duplicate_count
        duplicate_records_csv_url
        duplicate_records_excel_url
        failed_count
        failed_records_csv_url
        failed_records_excel_url
        flagged_count
        flagged_records_csv_url
        flagged_records_excel_url
        new_count
        new_records_csv_url
        new_records_excel_url
        updated_count
        updated_records_csv_url
        updated_records_excel_url
      }
    }
  `,
};

export type Props = {
  selectedFile: ProcessingReportCardFile;
};

const Status = styled.div`
  color: ${(props) => props.theme.color.slate200};
`;

const Table = styled.table`
  width: 775px;
  border-collapse: collapse;

  th,
  td {
    font-weight: 400;
    text-align: start;
    padding: 8px;
    border: none;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 380px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    text-align: end;
    padding-right: 20px;
  }

  td:nth-child(2) {
    color: ${(props) => props.theme.color.slate100};
  }

  tr {
    border-top: 1px solid ${(props) => props.theme.color.slate400};
  }
`;

const ReportFooter = styled.div`
  margin-top: 10px;
  button {
    font-weight: bold;
  }
`;

const IconLinkWrapper = styled.a`
  display: flex;
  color: ${(props) => props.theme.color.accentDefault};
  text-decoration: none;
  cursor: pointer;
`;

const DisabledLink = styled(IconLinkWrapper)`
  opacity: 30%;
  pointer-events: none;
`;

const LinkText = styled.span`
  padding-right: 5px;
`;

const ProcessingReportCard = (props: Props): JSX.Element => {
  const { processingReport, state, id } = props.selectedFile;

  const { startFileIngest } = useFileIngestOperation();

  const ingestEnabled = state === FileState.PROCESSED;

  const rows = [
    {
      name: 'Flagged Records',
      count: processingReport.flagged_count,
      csvUrl: processingReport.flagged_records_csv_url,
      excelUrl: processingReport.flagged_records_excel_url,
    },
    {
      name: 'Error Records',
      count: processingReport.failed_count,
      csvUrl: processingReport.failed_records_csv_url,
      excelUrl: processingReport.failed_records_excel_url,
    },
    {
      name: 'Duplicate Records',
      count: processingReport.duplicate_count,
      csvUrl: processingReport.duplicate_records_csv_url,
      excelUrl: processingReport.duplicate_records_excel_url,
    },
    {
      name: 'Updated Records',
      count: processingReport.updated_count,
      csvUrl: processingReport.updated_records_csv_url,
      excelUrl: processingReport.updated_records_excel_url,
    },
    {
      name: 'Successful Records',
      count: processingReport.new_count,
      csvUrl: processingReport.new_records_csv_url,
      excelUrl: processingReport.new_records_excel_url,
    },
  ];

  const numberSaved =
    (processingReport.new_count || 0) +
    (processingReport.updated_count || 0) +
    (processingReport.flagged_count || 0);

  return (
    <Card>
      <CardHeading>Field Mapping Report</CardHeading>
      <Status>
        <Table>
          <thead>
            <tr>
              <th>REPORT TYPE</th>
              <th>COUNT</th>
              <th>DOWNLOAD CSV</th>
              <th>DOWNLOAD EXCEL</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.name}>
                <td>{row.name}</td>
                <td>{row.count}</td>
                <td>
                  <DownloadLink
                    count={row.count}
                    url={row.csvUrl}
                    icon={faFileCsv}
                    text="Download CSV"
                  />
                </td>
                <td>
                  <DownloadLink
                    count={row.count}
                    url={row.excelUrl}
                    icon={faFileExcel}
                    text="Download Excel"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Status>
      <ReportFooter>
        <BaseButton
          onClick={() => startFileIngest(id)}
          disabled={!ingestEnabled}
          label={'save'}
          size="medium"
        >
          Save {numberSaved} Records to My Portfolio
        </BaseButton>
      </ReportFooter>
    </Card>
  );
};

type DownloadLinkProps = {
  count: number | null;
  url: string | null;
  icon: IconDefinition;
  text: string;
};

const DownloadLink: React.FC<DownloadLinkProps> = ({
  count,
  url,
  icon,
  text,
}) => {
  return count === 0 || !url ? (
    <DisabledLink>
      <LinkText>{text}</LinkText>&nbsp;
      <FontAwesomeIcon icon={icon} />
    </DisabledLink>
  ) : (
    <IconLinkWrapper target="blank" href={url} download>
      <LinkText>{text}</LinkText>&nbsp;
      <FontAwesomeIcon icon={icon} />
    </IconLinkWrapper>
  );
};

export default ProcessingReportCard;
export { ProcessingReportCardFragments };

