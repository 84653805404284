import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_DOCUMENT_LIBRARY } from 'query/documentLibrary';
import { GetDocumentLibrary, GetDocumentLibrary_user_company_documents } from 'query/__generated__/GetDocumentLibrary';
import { styled } from 'style/ORSNNTheme';
import { SvgIcon } from 'common-ui/Icons/SvgIcon/SvgIcon';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  padding: 8px 0px;
`;

const DocumentItem = styled.div`
  color: ${(props) => props.theme.color.slate200};
  padding: 8px;
  border-top: 1px solid ${(props) => props.theme.color.accentDefault};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: rgba(232, 94, 229, 0.15);
  }
`;

const IconLinkWrapper = styled.a`
  padding: 0 8px;
  display: flex;
  color: ${(props) => props.theme.color.slate200};
  text-decoration: none;
`;

const LinkText = styled.span`
  padding-left: 8px;
  color: ${(props) => props.theme.color.accentDefault};
`;

const ActionGroup = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderSection = styled.div`
  padding: 8px 0px;
  color: ${(props) => props.theme.color.accentDefault};
`;

const EmptySection = styled.div`
  color: ${(props) => props.theme.color.slate200};
`;

export default function Templates() {
  const { data } = useQuery<GetDocumentLibrary>(
    GET_DOCUMENT_LIBRARY,
    {

      fetchPolicy: 'cache-and-network',
    }
  );

  const documents = data?.user?.company?.documents?.filter((file: GetDocumentLibrary_user_company_documents) => file.is_template);

  return (
    <div>
      <HeaderSection>TEMPLATES</HeaderSection>
      <Container>
        {
          documents && documents.map((document: GetDocumentLibrary_user_company_documents) => {
            return (
              <DocumentItem key={document.id}>
                <span>{document.name}</span>
                <ActionGroup>
                  <IconLinkWrapper target="blank" href={document.documentUrl} download>
                    <SvgIcon name="pdf" />
                    <LinkText>View</LinkText>
                  </IconLinkWrapper>
                </ActionGroup>
              </DocumentItem>);
          })
        }
        {
          !documents?.length && <EmptySection>No saved templates.</EmptySection>
        }
      </Container>
    </div>
  );
}
