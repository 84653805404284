/* eslint-disable */

const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id: 'us-west-2:8f105e5b-ff62-49cf-a963-2f5be2799a48',
  aws_cognito_region: 'us-west-2',
  oauth: {},
  aws_mobile_analytics_app_id: '3244d7c9a767491bac80e22c75ab0761',
  aws_mobile_analytics_app_region: 'us-west-2',
};

export default awsmobile;
