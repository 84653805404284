import PoolHeader from './Dashboard/PoolManager/PoolHeader';
import { filtersToQueryFilters } from 'features/common-elements/filters/filterValuesParsing';
import * as navigation from 'app-level/navigation';
import { KPI } from './Dashboard/PoolManager/types';
import { FIELD_CONFIG } from 'configs/columns';
import { useUserSettings } from 'features/pages/portfolio/Dashboard/PoolManager/utils/useUserSettings';
import { GetPortfolioSummary, GetPortfolioSummaryVariables, GetPortfolioSummary_user_company_performanceData } from './__generated__/GetPortfolioSummary';
import { gql, useQuery } from '@apollo/client';
import { usePortfolioFilters } from './Portfolio/usePortfolioFilters';
import { PortfolioFilterConfig } from './Portfolio/portfolioFilters.config';
import { createQueryFilters } from 'context/DataContext';
import { ASSET_CLASS_IDS } from 'models/AssetClass';

const GET_PORTFOLIO_SUMMARY_QUERY = gql`
  query GetPortfolioSummary($hasAssetClass: Boolean!, $assetClass: String, $filters: [Filter!]) {
    user {
      id
      company {
        id
        performanceData: performance_summary(filters: []) {
          ...PortfolioSummaryMetrics
        }
        assetClassPerformanceData: performance_summary(filters: [{ field_name: asset_class, operator: IS, operand: $assetClass }]) @include(if: $hasAssetClass) {
          ...PortfolioSummaryMetrics
        }
        filteredPerformanceData: performance_summary(filters: $filters) {
          ...PortfolioSummaryMetrics
        }
      }
    }
  }
  fragment PortfolioSummaryMetrics on PerformanceSummary {
    unpaidBalance: current_balance_cents
    originalBalance: original_balance_cents
    loanCount: loan_count
    averageBalance: wa_current_balance_cents
    netCoupon: wa_coupon
    averageAge: wa_age_months
    averageMaturity: wa_remaining_loan_terms_months
    fico: wa_borrower_credit_score
    dti: wa_dti
    ltv: wa_ltv
  }
`;

type KPIConfig = {
  description: string;
  synonyms?: string[];
  selector: keyof GetPortfolioSummary_user_company_performanceData;
  display: (value: number) => string;
}

const kpiConfigs: KPIConfig[] = [
  {
    description: 'UPB',
    synonyms: ['Unpaid Principal Balance'],
    selector: 'unpaidBalance',
    display: FIELD_CONFIG.original_balance_cents.display,
  },
  {
    description: '#',
    selector: 'loanCount',
    display: (value: number) => (value ?? 0).toLocaleString(),
  },
  {
    description: 'WALA',
    synonyms: ['Weighted Average Loan Age'],
    selector: 'averageAge',
    display: (value: number) => (value ?? 0).toLocaleString(),
  },
  {
    description: 'WAM',
    synonyms: ['Weighted Average Maturity'],
    selector: 'averageMaturity',
    display: (value: number) => (value ?? 0).toLocaleString(),
  },
  {
    description: 'GWAC',
    selector: 'netCoupon',
    display: FIELD_CONFIG.interest_rate.display,
  },
  {
    description: 'FICO',
    selector: 'fico',
    display: (value: number) => (value ?? 0).toLocaleString(),
  },
  {
    description: 'DTI',
    selector: 'dti',
    display: FIELD_CONFIG.dti.display,
  },
  {
    description: 'LTV',
    selector: 'ltv',
    display: FIELD_CONFIG.ltv.display,
  },
];

const generateMetrics = (
  performanceData: GetPortfolioSummary_user_company_performanceData | undefined
): KPI[] => {
  return kpiConfigs.map((kpi) => {
    const value = performanceData?.[kpi.selector] ?? 0;
    return {
      ...kpi,
      value: kpi.display(value),
    };
  });
};

const PortfolioSummary = () => {
  const assetClass = navigation.usePortfolioAssetClass();
  const gqlAssetClass = assetClass && ASSET_CLASS_IDS[assetClass];
  const [portfolioFilters] = navigation.usePortfolioFilters();
  const filterProps = usePortfolioFilters();
  const queryFilters = filterProps.filtersConfig 
      ? filtersToQueryFilters<PortfolioFilterConfig>(portfolioFilters, filterProps.filtersConfig) 
      : [];
  const hasAssetClass = Boolean(gqlAssetClass);

  const generalUserSettings = useUserSettings('pool-manager-performanceData');
  const assetClassUserSettings = useUserSettings(`pool-manager-assetClassPerformanceData-${gqlAssetClass}`);
  const filteredUserSettings = useUserSettings('pool-manager-filteredPerformanceData');

  const { data } = useQuery<GetPortfolioSummary, GetPortfolioSummaryVariables>(
    GET_PORTFOLIO_SUMMARY_QUERY,
    {
      variables: {
        hasAssetClass,
        assetClass: gqlAssetClass,
        filters: createQueryFilters(gqlAssetClass, queryFilters),
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const { company } = data?.user || {};

  return (
    <>
      <PoolHeader
        title="Total in your Portfolio"
        metrics={generateMetrics(company?.performanceData)}
        {...generalUserSettings}
      />

      {hasAssetClass && (
        <PoolHeader
          title="Total in your Asset Class"
          metrics={generateMetrics(company?.assetClassPerformanceData)}
          {...assetClassUserSettings}
        />
      )}

      {queryFilters.length > 0 && (
        <PoolHeader
          title="Total Filtered"
          metrics={generateMetrics(company?.filteredPerformanceData)}
          {...filteredUserSettings}
        />
      )}
    </>
  );
};

export default PortfolioSummary;