
import { gql } from '@apollo/client';

const SUBMIT_FILE_HEADER_MAP = gql`
  mutation SubmitFileHeaderMap($fileId: ID!, $headerMaps: [HeaderMapInput!]!) {
    submitFileHeaderMap(id: $fileId, header_maps: $headerMaps) {
      id
      headers
      processing_report {
        new_count
      }
      state
    }
  }
`;

export { SUBMIT_FILE_HEADER_MAP };