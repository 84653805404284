import { useMutation, useQuery } from '@apollo/client';
import { GET_POOL_SETTINGS, SAVE_POOL_SETTINGS } from 'query/poolManager';
import { SavePoolSettings } from 'query/__generated__/SavePoolSettings';
import { GetPoolSettings } from 'query/__generated__/GetPoolSettings';

export const useUserSettings = (settingName: string) => {
  const savedPoolSettings = useQuery<GetPoolSettings>(GET_POOL_SETTINGS, {
    variables: { settingName },
    fetchPolicy: 'network-only',
  });

  const savedUserSettings = savedPoolSettings.data?.user?.setting;
  const userPoolSettings = savedUserSettings ? JSON.parse(savedUserSettings) : null;

  const [addUserSetting] = useMutation<SavePoolSettings>(SAVE_POOL_SETTINGS, {
    onCompleted: () => {
      savedPoolSettings.refetch();
    }
  });

  return {
    addUserSetting: (value: string) => addUserSetting({ variables: { settingName, value } }),
    savedPoolSettings,
    userPoolSettings,
  };
};
