import Select, { Props } from 'react-select';
import { useTheme } from 'styled-components';

// Figma Spec here:
// https://www.figma.com/file/k0BCnAOFwrRh6GU5KKacJq/Orsnn-Product-DS?type=design&node-id=512%3A16619&t=c3jQ6wnkh43RhtsV-1

export function Dropdown<T, Multi extends boolean>(props: Props<T, Multi>) {
  const theme = useTheme();
  return (
    <Select
      className='common-ui-dropdown'
      unstyled
      {...props}
      styles={{
        control: (provided, state) => ({
          ...provided,

          borderRadius: '4px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme.color.grey700,
          textAlign: 'left',
          fontSize: 14,
          backgroundColor: state.menuIsOpen
            ? theme.color.slate500
            : theme.color.grey950,
          color: state.hasValue ? theme.color.slate50 : theme.color.slate200,
          padding: '5px 15px',
        }),
        dropdownIndicator: (provided, state) => ({
          color: theme.color.accentDefault,
        }),
        option: (provided, state) => ({
          ...provided,
          padding: '8px',
          borderRadius: '4px',
          width: '100%',
          fontSize: 14,
          fontWeight: 500,

          backgroundColor: state.isDisabled
            ? theme.color.bgDisabled
            : state.isFocused
            ? theme.color.accentDefault
            : theme.color.grey950,
          color: state.isDisabled
            ? theme.color.fgOnDisabled
            : state.isFocused
            ? theme.color.fgOnAccent
            : theme.color.white,
        }),
        menu: (provided, state) => ({
          ...provided,
          backgroundColor: theme.color.grey950,
          borderRadius: 6,
        }),
      }}
    />
  );
}
