import { gql } from '@apollo/client';
import { LabelAndValue } from 'common-ui';
import { OVERVIEW_URL } from 'app-level/navigation/paths';
import { DateTime } from 'luxon';
import { Fragment, ReactNode } from 'react';
import { styled } from 'style/ORSNNTheme';
import { css } from 'styled-components';
import { FIELD_CONFIG } from '../../../configs/columns';
import CardLink from './CardLink';
import { ListingCardListing } from './__generated__/ListingCardListing';

type NameProps = {
  $highlighted?: boolean;
};
const Name = styled.span<NameProps>`
  ${(props) =>
    props.$highlighted &&
    css`
      color: ${(props) => props.theme.color.brandMainPink};
    `}
`;

const Deals = styled.div`
  padding-left: 32px;
`;

type Props = {
  listing: ListingCardListing;
  inquiryCount: number;
  children: ReactNode;
};

const ListingCardFragments = {
  listing: gql`
    fragment ListingCardListing on Listing {
      id
      name
      assetClass: asset_class
      upb: t_current_balance_cents
      wac: wa_coupon
      createdDate: created_date
      providedName: provided_name
    }
  `,
};

const ListingCard = ({
  listing,
  inquiryCount,
  children,
}: Props): JSX.Element => {
  return (
    <Fragment>
      <CardLink
        to={`/marketplace/${listing.assetClass.toLowerCase()}/${
          listing.id
        }${OVERVIEW_URL}`}
      >
        {listing.providedName != null ? (
          <Name>{listing.providedName}</Name>
        ) : null}
        <Name $highlighted={true}>
          {(listing.providedName != null ? '(' : '') +
            listing.name +
            (listing.providedName != null ? ')' : '')}
        </Name>
        <span>{FIELD_CONFIG['asset_class'].display(listing.assetClass)}</span>
        <LabelAndValue
          label={`LISTED`}
          value={DateTime.fromISO(listing.createdDate)
            .toFormat('M/dd/yyyy')
            .padEnd(10)}
        />
        <LabelAndValue label={`#`} value={inquiryCount.toLocaleString()} />
      </CardLink>
      <Deals>{children}</Deals>
    </Fragment>
  );
};

export default ListingCard;
export { ListingCardFragments };
