import { gql } from '@apollo/client';

const BidTermsCardBodyFragments = {
  TimelineCard: gql`
    fragment BidTermsCard on TimelineCard {
      ... on BidTermsCard {
        can_upload
        bidDocument: bid {
          terms {
            id
          }
        }
        buyer_documents {
          id
          name
          documentUrl
          documentSizeBytes
        }
        seller_documents {
          id
          name
          documentUrl
          documentSizeBytes
        }
      }
    }
  `,
};

export default BidTermsCardBodyFragments;
