import Uppy, { UppyFile } from '@uppy/core';
import { AssetClass } from '__generated__/globalTypes';
import { useEffect } from 'react';
import { GetPreSignedUrlFunc } from './usePresignedUrlQuery';

const useUppySetup = (
  uppy: Uppy,
  getPresignedUrl: GetPreSignedUrlFunc,
  loanType: AssetClass | undefined
) => {
  useEffect(() => {
    uppy.getPlugin('AwsS3')?.setOptions({
      getUploadParameters: async (file: UppyFile) => {
        try {
          const presignedUrl = await getPresignedUrl(
            file.name,
            file.type || '',
            loanType
          );
          return {
            method: 'PUT',
            url: presignedUrl,
            fields: {},
            headers: {},
          };
        } catch (err) {
          console.error(err);
          return null;
        }
      },
    });
  }, [getPresignedUrl, loanType, uppy]);
};

export default useUppySetup;
