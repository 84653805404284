import { gql } from '@apollo/client';
import State from 'models/State';
import { Fragment } from 'react';
import { FIELD_CONFIG } from '../../configs/columns';
import {
  Label,
  LabelAndValue,
  LabeledRow,
  LabelValuePair,
  RowLabel,
  RowScrollBox,
  SummaryRow,
  Value,
} from '../../common-ui/layout/utils';
import { PoolSummaryPerformanceSummary } from './__generated__/PoolSummaryPerformanceSummary';

const PoolSummaryFragments = {
  performanceSummary: gql`
    fragment PoolSummaryPerformanceSummary on PerformanceSummary {
      unpaidBalance: current_balance_cents
      originalBalance: original_balance_cents
      loanCount: loan_count
      averageBalance: wa_current_balance_cents
      averageOriginalBalance: wa_original_balance_cents
      netCoupon: wa_coupon
      averageAge: wa_age_months
      averageMaturity: wa_remaining_loan_terms_months
      fico: wa_borrower_credit_score
      dti: wa_dti
      ltv: wa_ltv
      states: strat_state {
        stateCode: key
        loanCount: t_count
      }
    }
  `,
};

const formatTop3States = (states: State[], loanCount: number) => {
  const top3StateCols: JSX.Element[] = [];
  for (let i = 0; i < Math.min(states.length, 3); ++i) {
    const stateLoanCount = states[i].loanCount;
    if (stateLoanCount != null) {
      top3StateCols.push(
        <Fragment key={states[i].stateCode}>
          <span>{`${states[i].stateCode}:\xa0`}</span>
          <span>
            {`${((100 * stateLoanCount) / loanCount).toFixed(2)}%`
              .padStart(6)
              .replace(/ /g, '\xa0')}
          </span>
          {i < Math.min(states.length, 3) - 1 ? '\xa0\xa0' : null}
        </Fragment>
      );
    }
  }
  return top3StateCols;
};

const renderRow = (
  data?: PoolSummaryPerformanceSummary | null
): JSX.Element => {
  const totalTop3StateCols =
    data?.loanCount != null && data.states != null ? (
      formatTop3States(data.states, data.loanCount)
    ) : (
      <></>
    );

  return (
    <SummaryRow>
      <LabelAndValue
        label={`O BAL`}
        value={FIELD_CONFIG.original_balance_cents
          .display(data?.originalBalance)
          .padStart(16)}
      />
      <LabelAndValue
        label={`UPB`}
        value={FIELD_CONFIG.current_balance_cents
          .display(data?.unpaidBalance)
          .padStart(16)}
      />
      <LabelAndValue
        label={`#`}
        value={(data?.loanCount ?? 0).toLocaleString().padStart(6)}
      />
      <LabelAndValue
        label={`WALA`}
        value={data?.averageAge?.toFixed().padStart(3)}
      />
      <LabelAndValue
        label={`WAM`}
        value={data?.averageMaturity?.toFixed().padStart(3)}
      />
      <LabelAndValue
        label={`GWAC`}
        value={FIELD_CONFIG.interest_rate.display(data?.netCoupon).padStart(6)}
      />
      <LabelAndValue label={`FICO`} value={data?.fico.toString().padStart(3)} />
      <LabelAndValue
        label={`LTV`}
        value={FIELD_CONFIG.ltv.display(data?.ltv).padStart(7)}
      />
      <LabelAndValue
        label={`DTI`}
        value={FIELD_CONFIG.dti.display(data?.dti).padStart(6)}
      />
      <LabelValuePair>
        <Label>Top 3 States</Label>
        <Value>{totalTop3StateCols}</Value>
      </LabelValuePair>
    </SummaryRow>
  );
};

type SummaryProps = {
  filteredData?: PoolSummaryPerformanceSummary | null;
  totalData?: PoolSummaryPerformanceSummary | null;
  label?: string;
};

const PoolSummary = ({
  label,
  totalData,
  filteredData,
}: SummaryProps): JSX.Element => {
  return filteredData == null ? (
    <LabeledRow>
      <RowLabel>{label ?? `POOL: `}</RowLabel>
      <RowScrollBox>{renderRow(totalData)}</RowScrollBox>
    </LabeledRow>
  ) : (
    <>
      <LabeledRow>
        <RowLabel>BASE: </RowLabel>
        <RowScrollBox>{renderRow(totalData)}</RowScrollBox>
      </LabeledRow>
      <LabeledRow>
        <RowLabel>CARVE:</RowLabel>
        <RowScrollBox>{renderRow(filteredData)}</RowScrollBox>
      </LabeledRow>
    </>
  );
};

export default PoolSummary;
export { PoolSummaryFragments };
