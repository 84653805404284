import React from 'react';
import { BaseButton } from 'common-ui';
import styled from 'styled-components';
import templateConfirmationImgPath from 'assets/pngs/template-confirmation.png';
import templateRemovalConfirmationImgPath from 'assets/svgs/check-filled.svg';

interface PassedProps {
  isTemplateRemoved: boolean;
  documentName: string;
  closeModal: () => void;
}

const ModalContainer = styled.div`
  min-width: 430px;
  padding: 40px;
  color: ${(props) => props.theme.color.fgDefault};
  background: ${(props) => props.theme.color.bgSurface};
  text-align: center;
  border-radius: 6px;
  box-shadow: rgba(13, 13, 13, 0.9);
`;

const ModalTemplateRemovedContainer = styled.div`
  min-width: 430px;
  padding: 16px;
  color: ${(props) => props.theme.color.fgDefault};
  display: flex;
  background: ${(props) => props.theme.color.bgSurface};
  border-radius: 6px;
  box-shadow: rgba(13, 13, 13, 0.9);
`;

const ModalCloseContainer = styled.div`
  margin-left: auto;
`;

const ModalBody = styled.div`
  padding: 24px;
  display: flex;
`;

const HeaderTextLarge = styled.div`
  font-size: 24px;
  line-height: 36px;
`;

const HeaderTextSmall = styled.div`
  font-size: 20px;
  line-height: 28px;
`;

const DocumentName = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const DescriptionText = styled.div`
  font-size: 16px;
`;

const FooterContainer = styled.div`
  padding-top: 32px;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  background: ${(props) => props.theme.color.accentSubtle};
  border-radius: 8px;
  margin-right: 24px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: ${(props) => props.theme.color.grey700};
  cursor: pointer;
`;

const DocumentTemplateConfirmation = ({
  isTemplateRemoved,
  documentName,
  closeModal,
}: PassedProps) => {

  const handleClick = () => {
    closeModal();
  };

  if (isTemplateRemoved) {
    return (
      <ModalTemplateRemovedContainer>
        <ModalBody>
          <div>
            <Image
              src={templateRemovalConfirmationImgPath}
              alt="template removal confirmation check"
            />
          </div>
          <div>
            <HeaderTextSmall>
              Template Removed
            </HeaderTextSmall>
            <DocumentName>{documentName}</DocumentName>
            <DescriptionText>will now be removed as a template</DescriptionText>
          </div>
        </ModalBody>
        <ModalCloseContainer>
          <CloseButton onClick={handleClick}>&times;</CloseButton>
        </ModalCloseContainer>
      </ModalTemplateRemovedContainer>
    );
  } else {
    return (
      <ModalContainer>
        <img
          src={templateConfirmationImgPath}
          alt="template confirmation logo"
        />
        <HeaderTextLarge>
          Template Created
        </HeaderTextLarge>
        <DocumentName>{documentName}</DocumentName>
        <DescriptionText>will now be available as a template</DescriptionText>
        <FooterContainer>
          <BaseButton label="Close" size="small" onClick={handleClick}>
            Close
          </BaseButton>
        </FooterContainer>
      </ModalContainer>
    );
  }

  return <div></div>;
};

export default DocumentTemplateConfirmation;