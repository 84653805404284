import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import {
  GetUploadedFilesWithHeaders,
  GetUploadedFilesWithHeaders_user_company_files,
} from 'query/__generated__/GetUploadedFilesWithHeaders';
import { createContext, useContext } from 'react';
import { GetUploadedFilesWithHeaders_user_company_files as UploadedFile } from 'query/__generated__/GetUploadedFilesWithHeaders';

const TapeUploadContext = createContext<TapeUploadContextProps | undefined>(
  undefined
);

export interface TapeUploadContextProps {
  /* The list of uploaded tapes from the server */
  uploadedTapes: GetUploadedFilesWithHeaders_user_company_files[] | undefined;

  /* Immediately refetch uploaded tapes from the server */
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetUploadedFilesWithHeaders>>;

  /* Get and set the selected file ID */
  selectedFileId: string | undefined;
  setSelectedFileId: (id: string | undefined) => void;

  /* Selected file */
  selectedFile: UploadedFile | undefined;
}

export const useTapeUploadContext = () => {
  const context = useContext(TapeUploadContext);
  if (!context) {
    throw new Error(
      'This component must be used within a TapeUploadContext.Provider'
    );
  }
  return context;
};

export default TapeUploadContext;
