import { DataDisplayTable } from 'common-ui';
import { GetSellerListings_user_company_listings_UserCompanyListing as Listing } from './__generated__/GetSellerListings';
import { activeListingsColumnDefinitions } from './ActiveListingsTable.config';
import { OnChangeFn, RowSelectionState } from '@tanstack/react-table';
import { useTheme } from 'styled-components';

type Props = {
  listingsToShow: Listing[];
  selection: {
    selected: RowSelectionState;
    onSelectionChange: OnChangeFn<RowSelectionState>;
  };
};

const ActiveListingsTable = (props: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <DataDisplayTable
      data={props.listingsToShow}
      columns={activeListingsColumnDefinitions}
      noDataMessage="NO DATA"
      selection={props.selection}
      stylingOptions={{
        alternateLightRows: false,
        subRowColor: theme.color.black,
        rowColor: theme.color.grey900,
      }}
    />
  );
};

export default ActiveListingsTable;
