import { styled } from 'style/ORSNNTheme';

const Outer = styled.div`
  width: 100%;
  height: 16px;
  background-color: ${(props) => props.theme.color.slate300};
  border-radius: 12px;
`;

const Inner = styled.div<{ percent: number }>`
  height: 100%;
  background-color: ${(props) => props.theme.color.slate200};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.color.accentEmphasis};
  width: ${({ percent }) => percent}%;
`;

export const ProgressIndicator = ({ percent }: { percent: number }) => {
  // Display a minimum of 10% progress because less than that squeezes the progress bar circle
  // into a oval, which looks bad.
  const displayPercent = Math.max(10, percent);
  return (
    <Outer>
      <Inner percent={displayPercent} />
    </Outer>
  );
};
