import React, { ForwardedRef, forwardRef, ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';
import {SvgIcon} from 'common-ui';
import './pill-button.scss';

interface PassedProps extends ButtonHTMLAttributes<HTMLButtonElement>{
  iconName: string;
  label: string;
  fill?: string;
  isSelected?: boolean;
}

export const SvgPillButton = forwardRef(({ iconName, label, fill = 'none', isSelected, ...buttonProps }: PassedProps, ref: ForwardedRef<HTMLButtonElement>) => {
  return (
    <button
      {...buttonProps}
      ref={ref}
      aria-label={label}
      className={classnames('pill-icon-button', {
        'pill-icon-button--selected': isSelected
      })}
    >
      <SvgIcon name={iconName} fill={fill} />
    </button>
  );
});