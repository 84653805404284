import { MayHaveLabel } from '@nivo/pie';
import { BaseButton, ButtonGroup, ModalNotStyled, PrimitiveCard, SvgPillButton } from 'common-ui';
import { PoolSummaryPerformanceSummary } from 'features/loanPool/__generated__/PoolSummaryPerformanceSummary';
import { useState } from 'react';
import { styled } from 'style/ORSNNTheme';
import { AssetClass } from '__generated__/globalTypes';
import { stratificationsConfig, StratKey, StratRowType } from './startifications.config';
import { StratChart } from './StratChart';
import { StratTable } from './StratTable';
import { StratificationsPerformanceSummary } from './__generated__/StratificationsPerformanceSummary';

export type StratCardContentProps = {
  data: (StratRowType & MayHaveLabel)[];
  config: typeof stratificationsConfig[StratKey];
  strat: StratKey;
  assetClass: AssetClass;
  summary: StratificationsPerformanceSummary;
  avgSummary: PoolSummaryPerformanceSummary;
};
const CardTop = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
  flex-grow: 0;

  & > span:first-child {
    flex: auto;
  }
`;

const CardLabel = styled.span`
  font: ${({ theme }) => theme.typography.primaryLead};
  color: ${({ theme }) => theme.color.grey50}; //not exactly right
`;

const StratsPrimitiveCard = styled(PrimitiveCard)<{ $mode: 'card' | 'modal' }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: ${({ $mode }) => ($mode === 'modal' ? 'calc(100vw - 40px)' : 'auto')};
  aspect-ratio: 16/9;
  max-height: ${({ $mode }) =>
    $mode === 'modal' ? '80vh' : 'auto'};
`;

export function StratCard(props: {
  strat: StratKey;
  assetClass: AssetClass;
  summary: StratificationsPerformanceSummary;
  avgSummary: PoolSummaryPerformanceSummary;
  mode?: 'card' | 'modal';
  displayType?: 'chart' | 'table';
  onMinimizeClick?: () => void;
}) {
  const [isChart, setIsChart] = useState<boolean>(
    props.displayType ? props.displayType === 'chart' : true
  );
  const config = stratificationsConfig[props.strat];
  if (!config) return null;

  const contentProps: StratCardContentProps = {
    data: props.summary[props.strat],
    config,
    strat: props.strat,
    assetClass: props.assetClass,
    summary: props.summary,
    avgSummary: props.avgSummary,
  };

  return (
    <StratsPrimitiveCard $mode={props.mode || 'card'}>
      <CardTop>
        <CardLabel>{config.label}</CardLabel>
        <ButtonGroup type="secondary" size="small" label="select card type">
          <BaseButton
            type="primary"
            size="small"
            isSelected={isChart}
            onClick={() => setIsChart(true)}
            label="Chart"
          >
            Chart
          </BaseButton>
          <BaseButton
            isSelected={!isChart}
            onClick={() => setIsChart(false)}
            label="Table"
          >
            Table
          </BaseButton>
        </ButtonGroup>
        {props.mode !== 'modal' ? (
          <ModalNotStyled
            trigger={<SvgPillButton iconName="expand" label="expand" />}
          >
            {({ closeModal }) => (
              <StratCard
                {...props}
                mode="modal"
                displayType={isChart ? 'chart' : 'table'}
                onMinimizeClick={closeModal}
              />
            )}
          </ModalNotStyled>
        ) : (
          <SvgPillButton
            iconName="minimize"
            label="minimize"
            onClick={props.onMinimizeClick}
          />
        )}
      </CardTop>
      <div style={{ flex: 1, height: 1 }}>
        {isChart ? (
          <StratChart {...contentProps} />
        ) : (
          <StratTable {...contentProps} />
        )}
      </div>
    </StratsPrimitiveCard>
  );
}